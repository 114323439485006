import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

const Work = () => {
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyBlsHGa4BjOPWONDTO6zaJzpHyoT_TqOco",
    onPlaceSelected: (place) => {
      console.log(place);
    },
    options: {
      types: ["geocode", "establishment"],
      // fields: ["geometry", "formatted_address", "address_components", "name"],
      componentRestrictions: { country: "jp" },
    },
  });
  return (
    <div>
      <input ref={ref} style={{ width: "400px" }} />
    </div>
  );
};

export default Work;
