import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { login, checkEmailBeforeRegister } from "../../actions/auth";
import { useHistory, useLocation } from "react-router-dom";
import googleIcon from "../../images/google-icon.svg";
import CustomerRegister from "./CustomerRegister";
import { useTranslation } from "react-i18next";
import { useLoading } from "../front-pages-new-theme/LoadingContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CustomerLogin = ({
  login,
  isAuthenticated,
  useModal,
  isRegister,
  setIsRegister,
  modalId,
  isBooking,
  book,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [loginFailed, setLoginFailed] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  let registerFormQuery = queryParams.get("is_register");


  const settings = useSelector((state) => state.setting);
  const { lang } = settings;


  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formDataRegister, setFormDataRegister] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    sign_up_with_google: false,
    access_token_google: null,
    avatar: null,
  });
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const { email, password } = formData;
  const { showLoading, closeLoading } = useLoading();
  const buttonCLoseModalLogin = document.getElementById("closeModalLogin");
  const [loginErrorMsg, setLoginErrorMsg] = useState("")

  useEffect(() => {
    window.gapi.load("client:auth2", () => {
      window.gapi.client.init({
        client_id:
          "341935228570-l3uovc1akibqn63tjf2lfhiaamvnuk61.apps.googleusercontent.com",
        scope: "email",
        plugin_name: "chat",
        cookie_policy: "single_host_origin",
      });
    });
  }, []);

  const resetState = () => {
    setFormDataRegister({
      email: "",
      password: "",
      password_confirmation: "",
      sign_up_with_google: false,
      access_token_google: null,
      avatar: null,
    });
    setFormData({
      email: "",
      password: "",
    });
    setIsGoogleSignUp(false);
    setLoginFailed(false);
  };

  const handleSignInGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();

      // Handle the signed-in user
      const user = await auth2.signIn({ prompt: "consent" });
      const profile = user.getBasicProfile();
      const authResponse = user.getAuthResponse();
      const accessToken = authResponse.access_token;

      const userId = profile.getId();
      const userName = profile.getName();
      const userEmail = profile.getEmail();
      const userImageURL = profile.getImageUrl();
      showLoading(true);
      const checkLoginWithGoogle = await login(
        userEmail,
        accessToken,
        "google"
      );
      // console.log(checkLoginWithGoogle);

      // console.log("RESPONSE LOGIN GOOGLE");
      // console.log(checkLoginWithGoogle);

      // Log user details
      // console.log("User ID: ", userId);
      // console.log("User Name: ", userName);
      // console.log("User Email: ", userEmail);
      // console.log("User Image URL: ", userImageURL);
      // console.log("Access Token: ", accessToken);

      if (!checkLoginWithGoogle) {
        const checkEmailStatus = await checkEmailBeforeRegister({
          ...formDataRegister,
          sign_up_with_google: true,
          email: userEmail,
          access_token_google: accessToken,
          avatar: userImageURL,
          role: "individual"
        })

        if (checkEmailStatus.data.code == 200) {
          setFormDataRegister({
            ...formDataRegister,
            sign_up_with_google: true,
            email: userEmail,
            access_token_google: accessToken,
            avatar: userImageURL,
          });
          setIsGoogleSignUp(true);
        } else {
          setLoginErrorMsg(checkEmailStatus.data.desc);
          setLoginFailed(true);
          closeLoading(true);
        }
      }else{
      buttonCLoseModalLogin.click();
      closeLoading(true);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
    }
  };

  useEffect(() => {
    if (isGoogleSignUp) {
      closeLoading(true);
      buttonCLoseModalLogin.click();
      
      history.push({
        pathname: "/register",
        state: { formData: formDataRegister },
      });
    }
  }, [isGoogleSignUp, formDataRegister]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    showLoading(true);
    e.preventDefault();
    const res = await login(email, password);
    
    if (!res) {
      setLoginErrorMsg("login.error");
      setLoginFailed(true);
      closeLoading(true);
    } else {
      buttonCLoseModalLogin.click();
      closeLoading(true);
    }
  };
  if (isAuthenticated) {
    if (isBooking) {
      resetState();
      book();
    } else {
      resetState();
      closeLoading(true);
      buttonCLoseModalLogin.click();
      history.push("/");
    }
  }

  return (
    <Fragment>
      
      {!useModal && <h2 className="d-block d-md-none">{(isRegister == true || registerFormQuery == "true") ? t("sign_up.sign_up") : t("login.login") }</h2>}
            <hr className="d-block d-md-none" />
      {useModal ? (
        <div
          className={`modal fade`}
          id={modalId}
          tabIndex="0"
          aria-labelledby="loginLabel"
          aria-hidden="true"
        >
          <div className=" modal-dialog modal-login modal-dialog-centered ">
            <div className="modal-content  border-top-gold">
              <div className="modal-body px-4">
                <div className="row d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close p-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeModalLogin"
                  ></button>
                </div>
                <div className="row d-flex align-items-center mb-3">
                  <div className="col-auto">
                    <h3 className="fw-900 text-secondary">
                      {t("login.login")}
                    </h3>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                {/* START FORM */}
                {loginFailed && (
                  <>
                    <div className="alert alert-danger">{t(loginErrorMsg)}</div>
                  </>
                )}
                <form onSubmit={(e) => onSubmit(e)} action="#">
                  <div className="row g-3 mb-3">
                    <div className="col form-floating fs-8">
                      <input
                        type="text"
                        className={`form-control contact-form `}
                        id="emailAddress1"
                        placeholder="Email address"
                        name="email"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="emailAddress1">
                        <div className="d-flex align-items-center">
                          <svg
                            width="17"
                            height="14"
                            viewBox="0 0 17 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.3 0.5H1.7C0.765 0.5 0.00849999 1.23125 0.00849999 2.125L0 11.875C0 12.7688 0.765 13.5 1.7 13.5H15.3C16.235 13.5 17 12.7688 17 11.875V2.125C17 1.23125 16.235 0.5 15.3 0.5ZM15.3 11.875H1.7V3.75L8.5 7.8125L15.3 3.75V11.875ZM8.5 6.1875L1.7 2.125H15.3L8.5 6.1875Z"
                              fill="#DBBE78"
                            />
                          </svg>
                          &nbsp; {t("login.email_address")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col form-floating fs-8">
                      <input
                        type="password"
                        className={`form-control contact-form `}
                        id="password1"
                        placeholder="Password"
                        name="password"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="password1">
                        <div className="d-flex align-items-center">
                          <svg
                            width="12"
                            height="16"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                              fill="#DBBE78"
                            />
                          </svg>
                          &nbsp; {t("login.password")}
                        </div>
                      </label>
                    </div>
                  </div>
                  <a href="/forgot-password" className="text-muted noto-sans" style={{fontSize : "14px"}}>

                    {t("login.forgot_password")}
                  </a>
                  <div className="row mt-4 justify-content-center">
                    <div className="col-auto d-grid text-center">
                      <button
                        name=""
                        id=""
                        type="submit"
                        className="btn btn-primary rounded py-2 px-4"
                        href="#"
                        role="button"
                      >
                        {t("login.login")}
                        <i className="ps-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 5L20 12L13 19M4 12H20H4Z"
                              stroke="#4A4A4A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </i>
                      </button>
                      <a
                        href="#"
                        className="text-muted mt-3 noto-sans"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#register"
                        style={{fontSize : "14px"}}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("login.sign_up_link"),
                          }}
                        ></span>
                      </a>
                    </div>
                  </div>
                </form>

                <div className="row align-items-center mt-4">
                  <div className="col">
                    <hr />
                  </div>
                  <div className="col-auto">
                    <p className="p-0 m-0">{t("login.or")}</p>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                <div className="row my-4 justify-content-center ">
                  <div className="col-auto d-grid text-center ">
                    <a
                      name=""
                      id=""
                      className="btn btn-secondary rounded py-2 px-4 m-0"
                      role="button"
                      onClick={handleSignInGoogle}
                    >
                      <img
                        src={googleIcon}
                        className="img-fluid"
                        alt="Efficiency"
                      />
                      &nbsp; {t("login.continue_w_google")}
                    </a>
                  </div>
                  {isBooking ? (
                    <div className="col-6 d-grid text-center ">
                      <button
                        type="button"
                        name=""
                        id=""
                        className="btn btn-secondary rounded py-2 px-4 m-0"
                        href="#"
                        role="button"
                        onClick={book}
                        data-bs-dismiss="modal"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 2.51875C10.805 2.51875 11.8625 3.5175 11.8625 4.75C11.8625 5.9825 10.805 6.98125 9.5 6.98125C8.195 6.98125 7.1375 5.9825 7.1375 4.75C7.1375 3.5175 8.195 2.51875 9.5 2.51875ZM9.5 12.0812C12.8413 12.0812 16.3625 13.6325 16.3625 14.3125V15.4813H2.6375V14.3125C2.6375 13.6325 6.15875 12.0812 9.5 12.0812ZM9.5 0.5C7.01375 0.5 5 2.40188 5 4.75C5 7.09812 7.01375 9 9.5 9C11.9862 9 14 7.09812 14 4.75C14 2.40188 11.9862 0.5 9.5 0.5ZM9.5 10.0625C6.49625 10.0625 0.5 11.4862 0.5 14.3125V17.5H18.5V14.3125C18.5 11.4862 12.5037 10.0625 9.5 10.0625Z"
                            fill="#DBBE78"
                          />
                        </svg>
                        &nbsp; {t("login.continue_as_guest")}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* END FORM */}
              </div>
            </div>
          </div>
        </div>
      ) : (isRegister == true || registerFormQuery == "true") ? (
        <CustomerRegister setIsRegister={setIsRegister} />
      ) : (
        <>
          {loginFailed && (
            <>
              <div className="alert alert-danger">{t(loginErrorMsg)}</div>
            </>
          )}
          <form onSubmit={(e) => onSubmit(e)} action="#">
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form `}
                  id="emailAddress2"
                  placeholder="Email address"
                  name="email"
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="emailAddress2">
                  <div className="d-flex align-items-center">
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3 0.5H1.7C0.765 0.5 0.00849999 1.23125 0.00849999 2.125L0 11.875C0 12.7688 0.765 13.5 1.7 13.5H15.3C16.235 13.5 17 12.7688 17 11.875V2.125C17 1.23125 16.235 0.5 15.3 0.5ZM15.3 11.875H1.7V3.75L8.5 7.8125L15.3 3.75V11.875ZM8.5 6.1875L1.7 2.125H15.3L8.5 6.1875Z"
                        fill="#DBBE78"
                      />
                    </svg>
                    &nbsp; {t("login.email_address")}
                  </div>
                </label>
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="password"
                  className={`form-control contact-form `}
                  id="password2"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="password2">
                  <div className="d-flex align-items-center">
                    <svg
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                        fill="#DBBE78"
                      />
                    </svg>
                    &nbsp; {t("login.password")}
                  </div>
                </label>
              </div>
            </div>
            <a href="/forgot-password" className="text-muted noto-sans">
              {t("login.forgot_password")}
            </a>
            <div className="row mt-4 justify-content-center">
              <div className="col-md-6 d-grid text-center">
                <button
                  name=""
                  id=""
                  className="btn btn-primary rounded py-2 px-4"
                  href="#"
                  role="button"
                  type="submit"
                >
                  {t("login.login")}
                  <i className="ps-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 5L20 12L13 19M4 12H20H4Z"
                        stroke="#4A4A4A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </i>
                </button>
                <Link className="text-muted mt-3 noto-sans" to="/login?is_register=true"> <span
                    dangerouslySetInnerHTML={{ __html: t("login.sign_up_link") }}
                  ></span>
                </Link>
                {/* <a
                  href="#"
                  // onClick={() => setIsRegister(true)}
                  className="text-muted mt-3 noto-sans"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: t("login.sign_up_link") }}
                  ></span>
                </a> */}
              </div>
            </div>
            <div className="row align-items-center mt-4">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto">
                <p className="p-0 m-0">{t("login.or")}</p>
              </div>
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row my-4 justify-content-center ">
              <div className="col-md-7 d-grid text-center">
                <a
                  name=""
                  id=""
                  className="btn btn-secondary rounded py-2 px-4 m-0"
                  role="button"
                  onClick={handleSignInGoogle}
                >
                  <img src={googleIcon} className="img-fluid" alt="Efficiency" />
                  &nbsp; {t("login.continue_w_google")}
                </a>
              </div>
            </div>
          </form>
        </>
      )}
    </Fragment>
  );
};

CustomerLogin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(CustomerLogin);
