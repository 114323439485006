import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { getAbandenedBookingsFilter } from "../../../actions/admin/booking";
import BookingItem from "../booking/BookingItem";
import { Fragment } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";

const AbandentCartList = () => {
  const [dateRange, setdateRange] = useState({
    startDate:
      localStorage.getItem("abstartDate") !== null
        ? moment(localStorage.getItem("abstartDate"))
        : moment(),
    endDate:
      localStorage.getItem("abendDate") !== null
        ? moment(localStorage.getItem("abendDate"))
        : moment().add(30, "days"),
  });

  // const [dateRange, setdateRange] = useState({
  //   startDate: moment(),
  //   endDate: moment().add(30, "days"),
  // });

  const [search, setSearch] = useState("null");
  const booking = useSelector((state) => state.booking);
  const { loading, bookings } = booking;
  const dispatch = useDispatch();

  // console.log(bookings);
  useEffect(() => {
    dispatch(
      getAbandenedBookingsFilter(dateRange.startDate, dateRange.endDate, true)
    );
  }, [loading]);

  // useEffect(() => {
  //   tableSort();
  // }, []);

  const handleCallback = (start, end, label) => {
    // console.log(start, end, label);
    setdateRange({ startDate: start, endDate: end });
    localStorage.setItem("abstartDate", start);
    localStorage.setItem("abendDate", end);
    // console.log(dateRange);
  };

  const searchClick = (e) => {
    // dispatch(
    //   getAbandenedBookingsFilter(dateRange.startDate, dateRange.endDate, true)
    // );
  };

  useEffect(() => {
    dispatch(
      getAbandenedBookingsFilter(dateRange.startDate, dateRange.endDate, true)
    );
  }, [dateRange]);

  //   console.log(bookings);
  return (
    <Layout>
      <main
        className="col-md-9 ms-sm-auto col-lg-10 px-md-4"
        style={{ backgroundColor: "#fbfbfb" }}
      >
        {/* <TitleBar title="Booking List" /> */}

        <div className="row py-4 d-flex align-items-center mb-3">
          <hr />
          <div className="col-md-6">
            <h1 className="h3">Abandoned Carts List</h1>
          </div>
          <div className="col-md-6">
            <div className="input-group input-group-sm">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Pickup Date [From -To]
              </span>
              <DateRangePicker
                onCallback={handleCallback}
                initialSettings={dateRange}
                onApply={(e) => {
                  searchClick(e);
                }}
              >
                <input type="text" className="form-control form-control-date" />
              </DateRangePicker>
              {/* <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={(e) => searchClick(e)}
              >
                Search
              </button> */}
            </div>
          </div>
        </div>

        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <table className="table-sort table table-hover table-sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">CartID</th>
                    <th scope="col">Vehicle Type</th>
                    <th scope="col">Booking Status</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Pickup</th>
                    <th scope="col">Dropoff</th>
                    <th scope="col">Pickup Location</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {bookings.map((booking, i = 0) => (
                    <BookingItem
                      key={booking._id}
                      bookings={booking}
                      count={i++}
                    />
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default AbandentCartList;
