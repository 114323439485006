import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminDashboard from "./components/admin/admindashbord/AdminDashboard";
import DriversList from "./components/admin/drivers/DriversList";
import VehicleList from "./components/admin/vehicles/VehicleList";
import BookingList from "./components/admin/booking/BookingList";
import AbandentCartList from "./components/admin/abandentcarts/AbandentCartList";
import BookingCalander from "./components/admin/booking/BookingCalander";
import AddDriver from "./components/admin/drivers/AddDriver";
import AddUser from "./components/admin/adminusers/AddUser";
import CreateBooking from "./components/admin/booking/CreateBooking";
import AddVehicle from "./components/admin/vehicles/AddVehicle";
import AddVehicleType from "./components/admin/vehicletype/AddVehicleType";
import VehicleTypeList from "./components/admin/vehicletype/VehicleTypeList";
import VehicleInfo from "./components/admin/vehicles/VehicleInfo";
import AdminLogin from "./components/admin/login/AdminLogin";
import UpdateVehicle from "./components/admin/vehicles/UpdateVehicle";
import UpdateDriver from "./components/admin/drivers/UpdateDriver";
import UpdateVehicleType from "./components/admin/vehicletype/UpdateVehicleType";
import BookingConfirmationPage from "./components/front-pages-new-theme/booking-confirmation/BookingConfirmationPage";
import NewCheckout from "./components/front-pages-new-theme/NewCheckout";
import CancelRescheduleRequest from "./components/admin/cancelreschedulerequest/CancelRescheduleRequestList";
import ManageCancelRescheduleRequest from "./components/admin/cancelreschedulerequest/ManageCancelRescheduleRequest";
// import "./App.css";
import "./custom.css";
// redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import PrivateRoute from "./components/routing/PrivateRoute";
import CustomerPrivateRoute from "./components/routing/CustomerPrivateRoute";
import history from "./components/routing/history";
import ManageBooking from "./components/admin/booking/ManageBooking";
// import VariantCart from "./components/front-pages/VariantCart";
// import PaymentSuccess from "./components/front-pages/PaymentSuccess";
import AdminUserList from "./components/admin/adminusers/AdminUserList";
import UserList from "./components/admin/users/UserList";
import UpdateUserAdmin from "./components/admin/adminusers/UpdateUserAdmin";
import Settings from "./components/admin/settings/Settings";
import AddPackage from "./components/admin/pakages/AddPackage";
import PackageList from "./components/admin/pakages/PackageList";
import UpdatePackage from "./components/admin/pakages/UpdatePackage";
import Index from "./components/front-pages-new-theme/Index";
import Fleet from "./components/front-pages-new-theme/Fleet";
import Pricing from "./components/front-pages-new-theme/Pricing";
import Corporate from "./components/front-pages-new-theme/Corporate";
import Discover from "./components/front-pages-new-theme/Discover";
import Cart from "./components/front-pages-new-theme/Cart";
import AddAirport from "./components/admin/airport/AddAirport";
import AirportList from "./components/admin/airport/AirportList";
import UpdateAirport from "./components/admin/airport/UpdateAirport";
import AddPackageNew from "./components/admin/pakages/AddPackageNew";
import AddCategory from "./components/admin/category/AddCategory";
import CategoryList from "./components/admin/category/CategoryList";
import UpdateCategory from "./components/admin/category/UpdateCategory";
import NewPackageList from "./components/admin/pakages/NewPackageList";
import UpdateNewPackage from "./components/admin/pakages/UpdateNewPackage";
import Careers from "./components/front-pages-new-theme/Careers";
import NaritaAirportTransfer from "./components/front-pages-new-theme/NaritaAirportTransfer";
import HanedaAirportTransfer from "./components/front-pages-new-theme/HanedaAirportTransfer";
import AlphardCarPage from "./components/front-pages-new-theme/AlphardCarPage";
import GranaceCarPage from "./components/front-pages-new-theme/GranaceCarPage";
import HiaceGrandCabinCarPage from "./components/front-pages-new-theme/HiaceGrandCabinCarPage";
import HiaceLuxuryCabinCarPage from "./components/front-pages-new-theme/HiaceLuxuryCabinCarPage";
import AirportMaps from "./components/front-pages-new-theme/AirportMaps";
import HanedaAirportMaps from "./components/front-pages-new-theme/HanedaAirportMaps";
import NaritaAirportMaps from "./components/front-pages-new-theme/NaritaAirportMaps";
import TokyoStationMaps from "./components/front-pages-new-theme/TokyoStationMaps";
import ShinagawaStationMaps from "./components/front-pages-new-theme/ShinagawaStationMaps";
import Gads from "./components/front-pages-new-theme/Gads";
import AirportTransferPage from "./components/front-pages-new-theme/AirportTransferPage";
import WeddingsAnniversaries from "./components/front-pages-new-theme/WeddingsAnniversaries";
import PrivateTour from "./components/front-pages-new-theme/PrivateTour";
import ExecutiveTransfer from "./components/front-pages-new-theme/ExecutiveTransfer";
import CheckOut from "./components/front-pages-new-theme/CheckOut";
import Contact from "./components/front-pages-new-theme/Contact";
import PaymentSuccess from "./components/front-pages-new-theme/PaymentSuccess";
import ScrollToTop from "./utils/scrollToTop";
import Terms from "./components/front-pages-new-theme/Terms";
import CreateBookingNew from "./components/admin/booking/CreateBookingNew";
import AddEmailTemplate from "./components/admin/emailtemplate/AddEmailTemplate";
import PageNotFound from "./components/404page/PageNotFound";
import TemplateList from "./components/admin/emailtemplate/TemplateList";
import UpdateEmailTemplate from "./components/admin/emailtemplate/UpdateEmailTemplate";
import CancellationPolicy from "./components/front-pages-new-theme/CancellationPolicy";
import CMSIndex from "./components/admin/cms/CMSIndex";
import CMSAirportTransfer from "./components/admin/cms/CMSAirportTransfer";
import CMSWeddingAniversary from "./components/admin/cms/CMSWeddingAniversary";
import CMSPrivateTour from "./components/admin/cms/CMSPrivateTour";
import CMSExecutiveTansfer from "./components/admin/cms/CMSExecutiveTansfer";
import CMSFleet from "./components/admin/cms/CMSFleet";
import CMSPricing from "./components/admin/cms/CMSPricing";
import CMSAlphard from "./components/admin/cms/CMSAlphard";
import CMSGranace from "./components/admin/cms/CMSGranace";
import CMSHiaceGrandCabin from "./components/admin/cms/CMSHiaceGrandCabin";
import CMSHiaceLuxuryCabin from "./components/admin/cms/CMSHiaceLuxuryCabin";
import CMSMaps from "./components/admin/cms/CMSMaps";
import CMSHanedaAirport from "./components/admin/cms/CMSHanedaAirport";
import CMSNaritaAirport from "./components/admin/cms/CMSNaritaAirport";
import CMSTokyoStation from "./components/admin/cms/CMSTokyoStation";
import CMSCancellationPolicy from "./components/admin/cms/CMSCancellationPolicy";
import CMSCareers from "./components/admin/cms/CMSCareers";
import CMSCorporate from "./components/admin/cms/CMSCorporate";
import CMSCart from "./components/admin/cms/CMSCart";
import CMSTerms from "./components/admin/cms/CMSTerms";
import CMSHanedaAirportTransfer from "./components/admin/cms/CMSHanedaAirportTransfer";
import CMSNaritaAirportTransfer from "./components/admin/cms/CMSNaritaAirportTransfer";
import CMSContact from "./components/admin/cms/CMSContact";
import CMSCheckOut from "./components/admin/cms/CMSCheckOut";
import Work from "./work";
import TagManager from "react-gtm-module";
import CustomerLoginPage from "./components/front-pages-new-theme/CustomerLoginPage";
import CustomerRegisterPage from "./components/front-pages-new-theme/CustomerRegisterPage";
import BusinessRegisterPage from "./components/front-pages-new-theme/BusinessRegisterPage";
import EmailVerification from "./components/front-pages-new-theme/EmailVerificationPage";
import CustomerMyPage from "./components/front-pages-new-theme/CustomerMyPage";
import Notifications from "./components/front-pages-new-theme/myPage/notification/Notifications";
import NotificationDetail from "./components/front-pages-new-theme/myPage/notification/NotificationDetail";
import BookingDetailPage from "./components/front-pages-new-theme/myPage/BookingDetailPage";
import NewCart from "./components/front-pages-new-theme/NewCart";
import { LoadingProvider } from "./components/front-pages-new-theme/LoadingContext";
import NotificationPage from "./components/front-pages-new-theme/NotificationPage";
import NotificationDetailPage from "./components/front-pages-new-theme/NotificationDetailPage";
import ForgotPassword from "./components/front-pages-new-theme/ForgotPassword";
import ResetPassword from "./components/front-pages-new-theme/ResetPassword";
// import { useTranslation } from "react-i18next";
// import { setLanguage } from "./actions/admin/settings";
// import { useDispatch } from "react-redux";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
// phase 1 completed.
const App = ({ props }) => {
  const gtm_id = "GTM-MMKDDMVW";
  // const gtm_id = "GTM-MMKDJKJHAS";

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: gtm_id,
    };

    TagManager.initialize(tagManagerArgs);

    if (history.location.pathname.includes("/")) store.dispatch(loadUser());
    if (history.location.pathname.includes("admin")) store.dispatch(loadUser());
    if (history.location.pathname.includes("my-page"))
      store.dispatch(loadUser());
    if (history.location.pathname.includes("booking-detail"))
      store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <LoadingProvider>
        <Router history={history}>
          <ScrollToTop />
          <Fragment>
            <section>
              <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/work" component={Work} />
                <Route exact path="/book" component={Discover} />
                <Route exact path="/fleet" component={Fleet} />
                <Route exact path="/pricing" component={Pricing} />
                <Route exact path="/login" component={CustomerLoginPage} />
                <Route
                  exact
                  path="/register"
                  component={CustomerRegisterPage}
                />
                <Route
                  exact
                  path="/business-register"
                  component={BusinessRegisterPage}
                />
                <Route
                  path="/booking-confirmation"
                  component={BookingConfirmationPage}
                />
                <Route
                  exact
                  path="/emailverification/:token"
                  component={EmailVerification}
                />
                <Route
                  exact
                  path="/cancellation-policy"
                  component={CancellationPolicy}
                />
                <Route exact path="/corporate" component={Corporate} />
                {/* <Route exact path="/Cart" component={Cart} /> */}
                <Route exact path="/Cart" component={NewCart} />
                <Route exact path="/Careers" component={Careers} />
                <Route
                  exact
                  path="/Narita-airport-transfer"
                  component={NaritaAirportTransfer}
                />
                <Route
                  exact
                  path="/Haneda-airport-transfer"
                  component={HanedaAirportTransfer}
                />
                <Route exact path="/Alphard" component={AlphardCarPage} />
                <Route exact path="/Granace" component={GranaceCarPage} />
                <Route
                  exact
                  path="/Hiace-grand-cabin"
                  component={HiaceGrandCabinCarPage}
                />
                <Route
                  exact
                  path="/Hiace-luxury-cabin"
                  component={HiaceLuxuryCabinCarPage}
                />
                <Route exact path="/Maps" component={AirportMaps} />
                <Route
                  exact
                  path="/haneda-airport"
                  component={HanedaAirportMaps}
                />
                <Route
                  exact
                  path="/narita-airport"
                  component={NaritaAirportMaps}
                />
                <Route
                  exact
                  path="/tokyo-station"
                  component={TokyoStationMaps}
                />

<Route
                  exact
                  path="/shinagawa-station"
                  component={ShinagawaStationMaps}
                />
                <Route exact path="/gads" component={Gads} />
                <Route exact path="/gads-jp" component={Gads} />
                <Route
                  exact
                  path="/airport-transfer"
                  component={AirportTransferPage}
                />
                <Route
                  exact
                  path="/weddings-anniversaries"
                  component={WeddingsAnniversaries}
                />
                <Route exact path="/private-tour" component={PrivateTour} />
                <Route
                  exact
                  path="/executive-transfer"
                  component={ExecutiveTransfer}
                />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/terms" component={Terms} />
                {/* <Route exact path="/CheckOut" component={CheckOut} /> */}
                <Route exact path="/CheckOut" component={NewCheckout} />
                <Route
                  exact
                  path="/PaymentSuccess/:stripe_ref"
                  component={PaymentSuccess}
                />

                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <Route exact path="/admin" component={AdminLogin} />

                <PrivateRoute
                  exact
                  path="/my-page"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />

                <PrivateRoute
                  exact
                  path="/my-page/bookings"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />
                 <PrivateRoute
                  exact
                  path="/my-page/invoices-billings"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />
                <PrivateRoute
                  exact
                  path="/my-page/notifications"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />
                <PrivateRoute
                  exact
                  path="/my-page/notifications/:notif_id"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />

                <PrivateRoute
                  exact
                  path="/notifications"
                  component={NotificationPage}
                  redirectTo="/login"
                />
                <PrivateRoute
                  exact
                  path="/notifications/:notif_id"
                  component={NotificationDetailPage}
                  redirectTo="/login"
                />  

                <PrivateRoute
                  exact
                  path="/my-page/account"
                  component={CustomerMyPage}
                  redirectTo="/login"
                />

                <PrivateRoute
                  exact
                  path="/booking-detail/:id"
                  component={BookingDetailPage}
                  redirectTo="/login"
                />
                <PrivateRoute
                  exact
                  path="/admin/categorylist/edit/:id"
                  component={UpdateCategory}
                />
                <PrivateRoute
                  exact
                  path="/admin/categorylist"
                  component={CategoryList}
                />
                <PrivateRoute
                  exact
                  path="/admin/addcategory"
                  component={AddCategory}
                />
                <PrivateRoute
                  exact
                  path="/admin/airportlist/edit/:id"
                  component={UpdateAirport}
                />
                <PrivateRoute
                  exact
                  path="/admin/addairport"
                  component={AddAirport}
                />
                <PrivateRoute
                  exact
                  path="/admin/airportlist"
                  component={AirportList}
                />
                <PrivateRoute
                  exact
                  path="/admin/packagelist"
                  component={PackageList}
                />
                <PrivateRoute
                  exact
                  path="/admin/newpackagelist"
                  component={NewPackageList}
                />
                <PrivateRoute
                  exact
                  path="/admin/userlist-admin"
                  component={AdminUserList}
                />
                <PrivateRoute
                  exact
                  path="/admin/userlist"
                  component={UserList}
                />
                <PrivateRoute
                  exact
                  path="/admin/settings"
                  component={Settings}
                />
                <PrivateRoute exact path="/admin/adduser" component={AddUser} />
                <PrivateRoute
                  exact
                  path="/admin/addpackage"
                  component={AddPackageNew}
                />
                {/* <PrivateRoute exact path="/admin/addpackage" component={AddPackage} /> */}
                <PrivateRoute
                  exact
                  path="/admin/useredit/edit/:id"
                  component={UpdateUserAdmin}
                />
                <PrivateRoute
                  exact
                  path="/admin/packagelist/edit/:id"
                  component={UpdatePackage}
                />
                <PrivateRoute
                  exact
                  path="/admin/newpackagelist/edit/:id"
                  component={UpdateNewPackage}
                />
                <PrivateRoute
                  exact
                  path="/admin/dashboard"
                  component={AdminDashboard}
                />
                <PrivateRoute
                  exact
                  path="/admin/driverlist"
                  component={DriversList}
                />
                <PrivateRoute
                  exact
                  path="/admin/vehiclelist"
                  component={VehicleList}
                />
                <PrivateRoute
                  exact
                  path="/admin/vehicletypelist"
                  component={VehicleTypeList}
                />
                <PrivateRoute
                  exact
                  path="/admin/bookinglist"
                  component={BookingList}
                />
                <PrivateRoute
                  exact
                  path="/admin/abandentcartlist"
                  component={AbandentCartList}
                />
                <PrivateRoute
                  exact
                  path="/admin/addvehicle"
                  component={AddVehicle}
                />
                <PrivateRoute
                  exact
                  path="/admin/addvehicletype"
                  component={AddVehicleType}
                />
                <PrivateRoute
                  exact
                  path="/admin/vehicleinfo"
                  component={VehicleInfo}
                />
                <PrivateRoute
                  exact
                  path="/admin/bookingcalander"
                  component={BookingCalander}
                />
                <PrivateRoute
                  exact
                  path="/admin/addemailtemplate"
                  component={AddEmailTemplate}
                />
                <PrivateRoute
                  exact
                  path="/admin/adddriver"
                  component={AddDriver}
                />
                <PrivateRoute
                  exact
                  path="/admin/createbooking"
                  component={CreateBooking}
                />

                <PrivateRoute
                  exact
                  path="/admin/createbookingnew"
                  component={CreateBookingNew}
                />

                <PrivateRoute
                  exact
                  path="/admin/cancel-reschedule-request"
                  component={CancelRescheduleRequest}
                />

                <PrivateRoute
                  exact
                  path="/admin/cancel-reschedule-request/manage/:id"
                  component={ManageCancelRescheduleRequest}
                />

                <PrivateRoute
                  exact
                  path="/admin/vehicletypelist/edit/:id"
                  component={UpdateVehicleType}
                />
                <PrivateRoute
                  exact
                  path="/admin/vehiclelist/edit/:id"
                  component={UpdateVehicle}
                />
                <PrivateRoute
                  exact
                  path="/admin/driverlist/edit/:id"
                  component={UpdateDriver}
                />
                <PrivateRoute
                  exact
                  path="/admin/bookinglist/edit/:id"
                  component={ManageBooking}
                />
                <PrivateRoute
                  exact
                  path="/admin/templatelist"
                  component={TemplateList}
                />
                <PrivateRoute
                  exact
                  path="/admin/templatelist/edit/:id"
                  component={UpdateEmailTemplate}
                />
                {/* CMS routing */}

                <PrivateRoute
                  exact
                  path="/admin/cms/cmsindex"
                  component={CMSIndex}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-airport-transfer"
                  component={CMSAirportTransfer}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-weddings-anniversaries"
                  component={CMSWeddingAniversary}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-private-tour"
                  component={CMSPrivateTour}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-executive-transfer"
                  component={CMSExecutiveTansfer}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-fleet"
                  component={CMSFleet}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-pricing"
                  component={CMSPricing}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-alphard"
                  component={CMSAlphard}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-granace"
                  component={CMSGranace}
                />
                <PrivateRoute
                  exact
                  path="/admin/cms/cms-hiace-grand-cabin"
                  component={CMSHiaceGrandCabin}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-hiace-grand-cabin"
                  component={CMSHiaceGrandCabin}
                />
                <PrivateRoute
                  exact
                  path="/admin/cms/cms-hiace-luxury-cabin"
                  component={CMSHiaceLuxuryCabin}
                />
                <PrivateRoute
                  exact
                  path="/admin/cms/cms-maps"
                  component={CMSMaps}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-haneda-airport"
                  component={CMSHanedaAirport}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-narita-airport"
                  component={CMSNaritaAirport}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-tokyo-station"
                  component={CMSTokyoStation}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-cancellation-policy"
                  component={CMSCancellationPolicy}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-careers"
                  component={CMSCareers}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-corporate"
                  component={CMSCorporate}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-cart"
                  component={CMSCart}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-terms"
                  component={CMSTerms}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-haneda-airport-transfer"
                  component={CMSHanedaAirportTransfer}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-narita-airport-transfer"
                  component={CMSNaritaAirportTransfer}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-contact"
                  component={CMSContact}
                />

                <PrivateRoute
                  exact
                  path="/admin/cms/cms-checkout"
                  component={CMSCheckOut}
                />

                <Route path="*" component={PageNotFound} />
              </Switch>
            </section>
          </Fragment>
        </Router>
      </LoadingProvider>
    </Provider>
  );
};

export default App;
