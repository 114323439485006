import FrontLayout from "../layout-new-theme/FrontLayout";
import Notifications from "./myPage/notification/Notifications";

const NotificationPage = ({}) => {
  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container-fluid  mt-1 pt-1">
          <div className="custom-mx">
            <div className="container pb-5 custom-mt-9 col-lg-12">
              <h2>Notifications</h2>
              <hr className="divider"/>
                <Notifications/>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default NotificationPage;
