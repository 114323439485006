import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import tlf_logo from "../../images/logo.png";
import vehicle from "../../images/vehicle-in-ciy-corporate.png";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";

const Corporate = () => {
  const page_name = "cms-corporate";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-corporate d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("About.Us")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Corporate.Profile")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Corporate.Profile.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-5 pb-3">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column justify-content-center align-items-center flex-sm-row justify-content-sm-between border-bottom px-0 pb-3">
            <div className="col-12 col-sm-9 d-flex flex-column align-items-center align-items-sm-start mt-auto order-2 order-sm-1">
              <div
                className="card-header pt-3 pb-2 ps-0 pe-0"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content4
                      : cmspage?.page_content_jp.content4,
                }}
              >
                {/* {t("Corporate.Profile")} */}
              </div>
              <div className="card-body pt-1 px-0">
                <h2
                  className="card-title fs-3 mb-0"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content5
                        : cmspage?.page_content_jp.content5,
                  }}
                >
                  {/* {t("Corporate.Heading")} */}
                </h2>
              </div>
            </div>

            <div className="col-8 col-sm-3 pe-0 order-1 order-sm-2">
              <img
                src={tlf_logo}
                className="card-img-top w-100 pe-0"
                alt="THE LUXURY FLEET logo"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2 banner img --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-3 px-md-0 pb-2">
        <div className="row mb-4 mb-lg-5">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-sm-column flex-lg-row justify-content-lg-between">
            <img
              src={
                lang === "EN"
                  ? cmspage?.page_content_en.image1
                  : cmspage?.page_content_jp.image1
              }
              className="card-img-top"
              alt="Vehicle in city"
            />
          </div>
        </div>
      </div>
      {/* <!-- section 2 img end --> */}
      {/* <!-- section 2 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto px-md-0 pb-md-5">
        <div className="row mb-5">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div
              className="col-12 col-md-7 d-flex flex-column mb-3 mb-md-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content6
                    : cmspage?.page_content_jp.content6,
              }}
            >
              {/* <p className="text-dark lh-sm">{t("Company.Intro.One")} </p>
              <p className="text-dark lh-dm">{t("Company.Intro.Two")}</p> */}
            </div>

            <div className="col-12 col-md-5 ps-md-5 mb-5">
              <div className="row mb-3">
                <h5
                  className="fs-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content7
                        : cmspage?.page_content_jp.content7,
                  }}
                >
                  {/* {t("Company.Profile")} */}
                </h5>
              </div>

              <div className="row">
                <div
                  className="col"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content8
                        : cmspage?.page_content_jp.content8,
                  }}
                >
                  {/* <p>
                    {t("Company.Profile.Name")}
                    <br /> {t("Company.Profile.Business.Type")}
                  </p>
                  <p>
                    {t("Company.Profile.Address.Row.One")}
                    {t("Company.Profile.Address.Row.Two")}
                  </p>
                  <p>{t("Company.Profile.Established")}</p>
                  <p>{t("Company.Profile.Capital")}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default Corporate;
