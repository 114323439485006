import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import VehicleTypeCard from "./VehicleTypeCard";
import PassengerCard from "./PassengerCard";
import { FaUsers, FaSuitcaseRolling } from "react-icons/fa";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";

const CarSelection = ({
  //to vehicleTypeCard - start
  vehicleTypeNames,
  clickedVehicleType,
  applyToTheEquation,
  packageAdditionaltime,
  activatedPackages,
  total_hours,
  tollFare,
  travelAreaPrice,
  zone_hours,
  //to vehicleTypeCard - end
  index,
  applyPaxAndLugageToTheEquation,
  idScroll,
  currentMaxPassenger,
  currentMaxLugage,
  clickedManyVehicleType,
  isEditBooking = false,
  longDistanceSurcharge,
  totalTimeWihtoutPackage,
  dropoffLocations
}) => {
  const { t, i18n } = useTranslation();
  const [maxPassengerInput, setMaxPassengerInput] = useState(0);
  const [lugageVisible, setLugageVisible] = useState(false);
  const wrapperRef = useRef(null);
  const [passengerVisible, setPassangerVisible] = useState(false);
  const [pax, setPax] = useState(null);
  const [lugage, setLugage] = useState(null);
  const [totalPax, setTotalPax] = useState(0);
  const [totalLugage, setTotalLugage] = useState(0);
  const [styleSelectedVehicle, setStyleSelectedVehicle] = useState([]);
  const [clickedVt, setClickedVt] = useState(null);
  const [useModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let resultArray = [];
    for (let i = 0; i < vehicleTypeNames.length; i++) {
      resultArray.push("");
    }

    setStyleSelectedVehicle(resultArray);
  }, []);

  const setStyle = (ind) => {
    let resultArray = [];
    for (let i = 0; i < vehicleTypeNames.length; i++) {
      resultArray.push("");
    }

    resultArray[ind] = "selected-vehicle-card";

    setStyleSelectedVehicle(resultArray);
    setPax(null);
    setLugage(null);
    setTotalLugage(0);
    setTotalPax(0);
  };

  /**
   * * PAX selection popup closing function. Click anywhere outside the popup modal to close it.
   */
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassangerVisible(false);
          setLugageVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * * Show and hide pax panel / modal
   */
  const setPaxAndLuggaePannalVisibility = (status) => {
    setPassangerVisible(status);
  };

  const setLugageFormDataToState = (formData) => {
    var summedLugage = getTotalLuggageCount(formData);
    setTotalLugage(summedLugage);
    setLugage(formData);
  };

  const setPaxFormDataToState = (formData) => {
    var summedPax = getTotalPaxCount(formData);
    setTotalPax(summedPax);
    setPax(formData);
  };

  return (
    <div className="container px-md-3 mt-4 " id={idScroll}>
      <div className="row mb-3 gy-4 d-flex align-items-center justify-content-center">
        <div className="col-auto text-center">
          <h3>
            <b>{t("Discover.SelectVehicle.Multiple.Car")} #{index + 1}</b>
          </h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      <div className="row gy-4 mb-3">
        {vehicleTypeNames.map((vt, i = 0) =>
          clickedManyVehicleType.length === 0 ? (
            <VehicleTypeCard
              key={vt._id}
              onClick={(e) => {
                applyToTheEquation(vt, index);
                setStyle(i);
                setClickedVt(vt);
              }}
              vehicle_type={vt}
              customerAddedAditionalMinutes={packageAdditionaltime}
              activatedPackages={activatedPackages}
              total_hours={total_hours}
              toll_fare={tollFare}
              travel_area_price={travelAreaPrice}
              zone_hours={zone_hours}
              styleSelectedVehicle={styleSelectedVehicle[i]}
              handleShowModal={handleShowModal}
              isEditBooking={isEditBooking}
              longDistanceSurcharge={longDistanceSurcharge}
              totalTimeWihtoutPackage={totalTimeWihtoutPackage}
              dropoffLocations={dropoffLocations}
            />
          ) : (
            <VehicleTypeCard
              key={vt._id}
              onClick={(e) => {
                applyToTheEquation(vt, index);
                setStyle(i);
                setClickedVt(vt);
              }}
              vehicle_type={vt}
              customerAddedAditionalMinutes={packageAdditionaltime}
              activatedPackages={activatedPackages}
              total_hours={total_hours}
              toll_fare={tollFare}
              travel_area_price={travelAreaPrice}
              zone_hours={zone_hours}
              styleSelectedVehicle={styleSelectedVehicle[i]}
              handleShowModal={handleShowModal}
              disabledCard={
                clickedManyVehicleType.filter((item) => item === vt.typename)
                  .length >= vt.count
              }
              isEditBooking={isEditBooking}
              longDistanceSurcharge={longDistanceSurcharge}
            />
          )
        )}
      </div>

      <div className="row gy-4 mb-5">
        <div className="col-12">
          <div className="card h-100 custom-card-passenger d-none d-lg-block">
            <div className="card-body">
              <div className="container">
              <div ref={wrapperRef} className="row d-flex align-items-center justify-content-center">
                  <div className="col">
                    <b className="noto" style={{ fontWeight: 900 }}>
                      Please specify the number of passengers on this vehicle:
                    </b>
                  </div>
                  <div className="col-auto">
                    <div className="form-floating position-relative">
                      <input
                        name="pax"
                        type="number"
                        id="passengers"
                        placeholder="Passengers"
                        aria-label="Passengers"
                        max={maxPassengerInput}
                        value={totalPax == 0 ? "" : totalPax}
                        onClick={() => {
                          setPassangerVisible(!passengerVisible);
                          setLugageVisible(false);
                        }}
                        readOnly={true}
                        className={ "form-control form-control-icon"}
                      />
                      {passengerVisible && (
                        <div
                          className="position-absolute passanger-card-position"
                          // style={{ right: "30%" }}
                        >
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setPaxFormDataToState}
                            passengerFormData={pax}
                            passangerForm={true}
                            luggageForm={false}
                            passangerMax={currentMaxPassenger}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}

                      <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                        <div className="d-flex justify-content-center">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.49549 16H8.51151C8.35451 15.9796 8.19715 15.962 8.04088 15.9383C6.19121 15.6561 4.6654 14.7964 3.5109 13.3244C2.20927 11.6644 1.74667 9.77227 2.13077 7.7016C2.47982 5.82039 3.47403 4.32726 5.04511 3.23898C6.58991 2.16933 8.30997 1.8017 10.1596 2.0996C11.6259 2.3358 12.8892 2.9995 13.94 4.04907C14.9799 5.08733 15.6371 6.33295 15.8927 7.7841C15.935 8.02505 15.9646 8.26819 16 8.51059V9.49446C15.9796 9.65144 15.9613 9.80842 15.9383 9.96503C15.7364 11.3355 15.1913 12.5512 14.277 13.5927C13.1963 14.8241 11.8487 15.5955 10.2338 15.8908C9.98913 15.9354 9.74158 15.9639 9.49549 16ZM13.652 12.5045C15.2256 10.5349 15.3968 7.14304 13.1203 4.87413C10.8252 2.58697 7.08318 2.61545 4.83955 4.9205C2.59447 7.22737 2.82266 10.601 4.35797 12.5023C4.37367 12.4603 4.38937 12.4238 4.40105 12.3858C4.57631 11.824 4.84576 11.311 5.21197 10.8511C5.27039 10.778 5.27149 10.7258 5.23278 10.6466C4.81327 9.78067 4.7103 8.87384 4.90747 7.93524C5.40147 5.58419 7.83568 4.1261 10.1487 4.79199C12.6581 5.5141 13.9024 8.28535 12.776 10.6473C12.7377 10.7273 12.7384 10.7806 12.7972 10.8532C13.0816 11.2059 13.3047 11.5965 13.4741 12.0167C13.5384 12.1755 13.5913 12.3391 13.6512 12.5048L13.652 12.5045ZM12.0279 8.81981C12.0188 7.11931 10.666 5.79338 8.94892 5.80251C7.31065 5.81127 5.97579 7.17736 5.98565 8.83514C5.99587 10.5298 7.33876 11.8543 9.0391 11.8466C10.688 11.8389 12.0367 10.4732 12.0279 8.82017V8.81981ZM6.00573 11.7652C5.99551 11.7736 5.98273 11.7805 5.97543 11.7911C5.63368 12.2931 5.43579 12.8476 5.36641 13.4504C5.35583 13.5424 5.39745 13.5905 5.4639 13.6402C6.68265 14.5492 8.04891 14.9391 9.5634 14.8029C10.6646 14.7036 11.6511 14.3083 12.5354 13.6475C12.6179 13.5858 12.6618 13.5237 12.6391 13.4211C12.5876 13.1864 12.5508 12.9477 12.4836 12.7173C12.3832 12.3719 12.2225 12.0529 12.01 11.7575C10.3641 13.4332 7.64655 13.4358 6.00536 11.7652H6.00573Z"
                              fill="#DBBE78"
                            />
                          </svg>
                        </div>
                      </span>
                      <label for="floatingInput" className="label-icon">
                        {t("Passengers")}
                      </label>
                    </div>
                  </div>
                  
                  <div className="col-auto">
                    <div className="form-floating position-relative">
                      <input
                      max={maxPassengerInput}
                      value={totalLugage == 0 ? "" : totalLugage}
                      onClick={() => {
                        setPassangerVisible(false);
                        setLugageVisible(!lugageVisible);
                      }}
                      readOnly={true}
                        className={"form-control form-control-icon"}
                      />

                      <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                        <div className="d-flex justify-content-center">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.4286 4.2H10.8571V1.8C10.8571 1.36 10.5036 1 10.0714 1H6.92857C6.49643 1 6.14286 1.36 6.14286 1.8V4.2H4.57143C3.70714 4.2 3 4.92 3 5.8V14.6C3 15.48 3.70714 16.2 4.57143 16.2C4.57143 16.64 4.925 17 5.35714 17C5.78929 17 6.14286 16.64 6.14286 16.2H10.8571C10.8571 16.64 11.2107 17 11.6429 17C12.075 17 12.4286 16.64 12.4286 16.2C13.2929 16.2 14 15.48 14 14.6V5.8C14 4.92 13.2929 4.2 12.4286 4.2ZM6.53571 13.8H5.35714V6.6H6.53571V13.8ZM9.08929 13.8H7.91071V6.6H9.08929V13.8ZM9.67857 4.2H7.32143V2.2H9.67857V4.2ZM11.6429 13.8H10.4643V6.6H11.6429V13.8Z"
                              fill="#DBBE78"
                            />
                          </svg>
                        </div>
                      </span>
                      {lugageVisible && (
                      <div className="position-absolute passanger-card-position">
                        <PassengerCard
                          setPaxPanel={setPaxAndLuggaePannalVisibility}
                          setFormDataToState={setLugageFormDataToState}
                          passangerForm={false}
                          luggageForm={true}
                          passengerFormData={lugage}
                          lugageMax={currentMaxLugage}
                          clickedVt={clickedVt}
                        />
                      </div>
                    )}
                      <label className="label-icon">{t("Luggage")}</label>
                    </div>
                  </div>
                  {/* <div className="col-auto form-floating">
                    <input
                      type="number"
                      name="paxPerCar"
                      className={"form-control"}
                      placeholder="Pick-up time"
                      aria-label="Pick-up time"
                      max={maxPassengerInput}
                      value={totalLugage == 0 ? "" : totalLugage}
                      onClick={() => {
                        setPassangerVisible(false);
                        setLugageVisible(!lugageVisible);
                      }}
                      readOnly={true}
                    />
                    {lugageVisible && (
                      <div className="position-absolute passanger-card-position">
                        <PassengerCard
                          setPaxPanel={setPaxAndLuggaePannalVisibility}
                          setFormDataToState={setLugageFormDataToState}
                          passangerForm={false}
                          luggageForm={true}
                          passengerFormData={lugage}
                          lugageMax={currentMaxLugage}
                          clickedVt={clickedVt}
                        />
                      </div>
                    )}
                    <label htmlFor="paxPerCar">
                      <div className="d-flex align-items-center gap-2">
                        <FaUsers />
                        {t("Discover.No.Of.Luggages.Multiple.Car")}
                      </div>
                    </label>
                  </div> */}

                  <div className="col-auto">
                    <div className="d-grid gap-2 d-md-block mt-auto">
                      <button
                        type="button"
                        onClick={(e) => {
                          applyPaxAndLugageToTheEquation(pax, lugage, index);
                        }}
                        className="btn btn-primary btn-sm rounded-3 px-4 d-flex align-items-center justify-content-center px-md-5 py-2"
                        style={{ height: 50 }}
                      >
                        <div className="d-flex align-items-center px-2 d-inline">
                          {t("Discover.Proceed.Multiple.Car")}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {clickedVt !== null ? (
                <div className="row d-flex align-items-center gap-4 justify-content-center mt-3">
                  <div className="container">
                    <div className="col py-2 bg-gold-light-100 rounded-3 justify-content-center text-center">
                      Maximum Luggage Capacity available for{" "}
                      {clickedVt.maxpassenger ?? "-"} passengers:{" "}
                      {clickedVt.maxluggages ?? "-"} suitcases
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {useModal ? (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block px-3"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg "
              role="document"
            >
              <div className="modal-content border-top-gold">
                <div className="modal-body px-4">
                  <div className="row d-flex justify-content-end">
                    <button
                      onClick={handleCloseModal}
                      type="button"
                      className="btn-close p-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <b className="noto" style={{ fontWeight: 900 }}>
                    Please specify the number of passengers on this vehicle:
                  </b>
                  <div className="row gap-3 mt-3">
                    <div className="col-12 form-floating">
                      <input
                        type="number"
                        name="paxPerCar"
                        className={"form-control passenger-select"}
                        placeholder="Pick-up time"
                        aria-label="Pick-up time"
                        max={maxPassengerInput}
                        value={totalPax == 0 ? "" : totalPax}
                        onClick={() => {
                          setPassangerVisible(!passengerVisible);
                          setLugageVisible(false);
                        }}
                        readOnly={true}
                      />
                      {passengerVisible && (
                        <div className="position-absolute passanger-card-position">
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setPaxFormDataToState}
                            passengerFormData={pax}
                            passangerForm={true}
                            luggageForm={false}
                            passangerMax={currentMaxPassenger}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}
                      <label htmlFor="paxPerCar">
                        <div className="d-flex align-items-center gap-2">
                          <FaSuitcaseRolling />
                          Number Of Passengers
                        </div>
                      </label>
                    </div>

                    <div className="col-12 form-floating">
                      <input
                        type="number"
                        name="paxPerCar"
                        className={"form-control passenger-select"}
                        placeholder="Pick-up time"
                        aria-label="Pick-up time"
                        max={maxPassengerInput}
                        value={totalLugage == 0 ? "" : totalLugage}
                        onClick={() => {
                          setPassangerVisible(false);
                          setLugageVisible(!lugageVisible);
                        }}
                        readOnly={true}
                      />
                      {lugageVisible && (
                        <div className="position-absolute passanger-card-position">
                          <PassengerCard
                            setPaxPanel={setPaxAndLuggaePannalVisibility}
                            setFormDataToState={setLugageFormDataToState}
                            passangerForm={false}
                            luggageForm={true}
                            passengerFormData={lugage}
                            lugageMax={currentMaxLugage}
                            clickedVt={clickedVt}
                          />
                        </div>
                      )}
                      <label htmlFor="paxPerCar">
                        <div className="d-flex align-items-center gap-2">
                          <FaUsers />
                          Number Of Lugages
                        </div>
                      </label>
                    </div>

                    <div className="col-12">
                      <div className="d-grid gap-2 d-md-block mt-auto">
                        <button
                          type="button"
                          onClick={(e) => {
                            handleCloseModal();
                            applyPaxAndLugageToTheEquation(pax, lugage, index);
                          }}
                          className="btn btn-primary btn-sm rounded-3 px-4 d-flex align-items-center justify-content-center px-md-5 py-2"
                          style={{ height: 50 }}
                        >
                          <div className="d-flex align-items-center px-2 d-inline">
                            Proceed
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CarSelection;
