import React, { Fragment, useEffect } from "react";
import Footer from "./Footer";
import LanguageCard from "./LanguageCard";
import NavigationBar from "./NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { getSetting } from "../../actions/admin/settings";

const FrontLayout = (props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.setting);
  const { setting } = settings;

  useEffect(() => {
    if (!setting) dispatch(getSetting());
  }, []);

  return (
    <Fragment>
      <NavigationBar />
      {props.children}
      <div className="d-lg-none d-sm-block fixed">
        <LanguageCard />
      </div>
      {/* <br />
      <br /> */}
      <Footer />
    </Fragment>
  );
};

export default FrontLayout;
