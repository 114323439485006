import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { getAutocompleteAddresses, getLocationByPlaceid } from "../../../actions/googleAPI";
import AirportLogoMaps from "../../../images/airport-logo-maps.svg";
import PointLogoMaps from "../../../images/point-logo-maps.svg";
import StationLogoMaps from "../../../images/station-logo-maps.svg";
import HotelLogoMaps from "../../../images/hotel-logo-maps.svg";
import RestaurantLogoMaps from "../../../images/restaurant-logo-maps.svg";

const CustomGoogleAutoComplete = ({
    id,
    isInvalid = false,
    invalidText,
    onPlaceSelected,
    defaultValue = null,
    placeholder
}) => {
    const { t } = useTranslation();
    const [selectedPickupTestLocation, setSelectedPickupTestLocation] = useState(null);
    const [pickupTestList, setPickupTestList] = useState([]);
    const [focused, setFocused] = useState(false);
    const [typedLocation, setTypedLocation] = useState("");
    const [filled, setFilled] = useState(defaultValue !== null ? true : false);

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const setSearchLocation = async (loc) => {
        setTypedLocation(loc)
        if (loc.trim().length === 0) {
            setPickupTestList([]);
        } else {
            const list = await getAutocompleteAddresses(loc);

            setPickupTestList(list);
        }
    }

    const handleSelectPickupTestLocation = async (e) => {
        console.log(e)
        let detailLocation = await getLocationByPlaceid(e.place_id)
        detailLocation.name = e?.structured_formatting?.main_text

        setTypedLocation(e.description)
        setSelectedPickupTestLocation(e.description)
        setPickupTestList([])
        setFilled(true)

        onPlaceSelected(detailLocation);
    }

    return (
        <>
            <input
                type="text"
                className={`form-control fw-700 form-control-icon ${isInvalid ? 'invalid' : ""}`}
                onChange={(e) =>
                    setSearchLocation(e.target.value)
                }
                id={id}
                // ref={id}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="off"
                value={focused ? typedLocation : selectedPickupTestLocation !== null ? selectedPickupTestLocation : defaultValue !== null ? defaultValue : typedLocation}
                style={{
                    color: filled ? "#282828 !important" : "",
                    // fontWeight: filled ? "900" : "",
                    fontSize: "14px",
                  }}
            />

            

            {typedLocation.length !== 0 && pickupTestList.length > 0 && (
                <ul style={{
                    position: "absolute",
                    padding: '0',
                    margin: '0',
                    backgroundColor: 'white', /* Sesuaikan dengan warna latar belakang yang Anda inginkan */
                    border: '1px solid #ccc', /* Atur border sesuai kebutuhan */
                    zIndex: '1000', /* Sesuaikan dengan kebutuhan, pastikan lebih tinggi dari z-index konten lainnya */
                    width: 'calc(100% - 2px)', /* Biarkan lebar ul sama dengan lebar input text */
                }}>
                    {pickupTestList
                        ?.map((location) => (
                            <Fragment key={location.place_id}>
                                <li
                                    className="dropdown-item m-0"
                                    onClick={(e) =>
                                        handleSelectPickupTestLocation(location)
                                    }
                                >
                                    <div className="row px-1 align-items-center" style={{ whiteSpace: 'break-spaces' }}>
                                        <div className="col-1 d-flex align-items-center justify-content-center">
                                            <img
                                                src={location.types.includes("airport") ? AirportLogoMaps : location.types.includes("lodging") ? HotelLogoMaps : location.types.includes("train_station") ? StationLogoMaps : location.types.includes("restaurant") ? RestaurantLogoMaps : PointLogoMaps}
                                                className=" text-center"
                                                alt="Airport icon"
                                            />
                                        </div>
                                        <div className="col ps-0">
                                            <div className="row ">
                                                <div className="col ">
                                                    <span className="place-name">{location?.structured_formatting?.main_text}</span>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col">
                                                    <span className="place-city-name">{location?.structured_formatting?.secondary_text?.replace(", Japan", "")}</span>
                                                    {/* <span className="place-city-name">{location.description.replace(", Japan", "")}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <hr className="my-0" />
                            </Fragment>
                        ))}
                </ul>
            )}
            {/* {isInvalid && (
                <p className="text-danger">
                    {t(invalidText)}
                </p>
            )} */}
        </>
    );
};

export default CustomGoogleAutoComplete;
