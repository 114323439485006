import React, { useEffect, useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import CustomerLogin from "../auth/CustomerLogin";

const CustomerLoginPage = () => {  
  const { t } = useTranslation();
  const [isRegister, setIsRegister] = useState(false)
  return (  
    <FrontLayout>
      <div className="container custom-mt-9" >
          <div className="row justify-content-center">
            <div className="col-md-6">
            <div className="container mb-5">
            <CustomerLogin
            login={() => null}
            loginWithGoogle={() => null}
            isAuthenticated={false}
            useModal={false}
            isRegister={isRegister}
            setIsRegister={setIsRegister}
          />
            </div>
            </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default CustomerLoginPage;
