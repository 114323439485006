import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const CancelRescheduleRequestList = ({
  cancelreschedulerequest,
  count,
}) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{cancelreschedulerequest.userId.name ?? '' + " - " + cancelreschedulerequest.userId.email}</td>
      <td>
        {cancelreschedulerequest.requestType == "reschedule" ? (
          <span className="badge bg-info py-2">Edit Booking</span>
        ) : (
          <span className="badge bg-primary py-2">Cancellation</span>
        )}
      </td>
      <td>
        <Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.requestCreatedAt}</Moment>
      </td>
      <td><Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.bookingId.travelDate}</Moment></td>
      <td><Moment format="YYYY/MM/DD HH:mm">{cancelreschedulerequest.bookingId.bookingInfo[0].cutomerActualDropOffTime}</Moment></td>
      <td>
        {cancelreschedulerequest.stripeRef && cancelreschedulerequest.paidAt && cancelreschedulerequest.currentStatus == "WAITING_CONFIRMATION" ? (
          <span className="badge bg-success py-2">Paid</span>
        ) : cancelreschedulerequest.currentStatus == "WAITING_CONFIRMATION" && !cancelreschedulerequest.stripeRef ? (
          <span className="badge bg-grey-2 py-2">No need to pay</span>
        ) : cancelreschedulerequest.currentStatus == "WAITING_FOR_PAYMENT" ? (
          <span className="badge bg-danger py-2">Waiting for Payment</span>
        ) : ""}
      </td>
      <td>
        {cancelreschedulerequest.approvalStatus == 1 ? (
          <span className="badge bg-success py-2">Approved</span>
        ) : cancelreschedulerequest.approvalStatus == 0 ? (
          <span className="badge bg-grey-2 py-2">Need Response</span>
        ) : cancelreschedulerequest.approvalStatus == 2 ? (
          <span className="badge bg-danger py-2">Rejected</span>
        ) : ""}
      </td>
      <td>{cancelreschedulerequest.responseBy?.name}</td>
      <td>
        <Link
          type="button"
          className="btn btn-secondary btn-sm py-2 px-4"
          to={`/admin/cancel-reschedule-request/manage/${cancelreschedulerequest._id}`}
        >
          Manage
        </Link>
      </td>
    </tr>
  );
};

export default CancelRescheduleRequestList;
