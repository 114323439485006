import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "../../../utils/customMoment";
import EditBooking from "../../front-pages-new-theme/EditBooking";
import {
  addToCartReduxStore,
  addBooking,
} from "../../../actions/admin/booking";
import ReactTooltip from "react-tooltip";

const NewCartItemCard = ({ cart, removeItemFromCart }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [meetAssist, setMeetAssist] = useState("");
  const [travelArea, setTravelArea] = useState("");
  const [allChildSeatSize, setAllChildSeatSize] = useState(0);
  const [allInfantSeatSize, setAllInfantSeatSize] = useState(0);
  const [allToddlerSeatSize, setAllToddlerSeatSize] = useState(0);
  const [childSeatCount, setChildSeatCount] = useState({});
  const [editBookingForm, setEditBookingForm] = useState({});
  const [keyBook, setKeyBook] = useState(null);
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;

  //edit booking
  const [useModalEditBooking, setUseModalEditBooking] = useState(false);
  const handleCloseModalEditBooking = () => {
    setUseModalEditBooking(false);
  };
  const handleOpenModalEditBooking = () => {
    setUseModalEditBooking(true);
  };
  const [inputFields, setInputFields] = useState([""]);
  const [useModalDuplicateBooking, setUseModalDuplicateBooking] =
    useState(false);
  const currentDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    if (
      cart.meetAssist[0].meetAssistName == "none" &&
      cart.meetAssist[1].meetAssistName != "none"
    ) {
      setMeetAssist("departure");
    } else if (
      cart.meetAssist[0].meetAssistName != "none" &&
      cart.meetAssist[1].meetAssistName == "none"
    ) {
      setMeetAssist("arrival");
    } else if (
      cart.meetAssist[0].meetAssistName != "none" &&
      cart.meetAssist[1].meetAssistName != "none"
    ) {
      setMeetAssist("both");
    } else if (
      cart.meetAssist[0].meetAssistName == "none" &&
      cart.meetAssist[1].meetAssistName == "none"
    ) {
      setMeetAssist("");
    }

    if ( cart.travelArea && cart.travelArea !== "withinCity"){
      setTravelArea("Within The City");
    } else if( cart.travelArea && cart.travelArea !== "withinCity"){
      setTravelArea("To a Neighboring Perfecture");
    }

    setKeyBook(cart.key);
    if (cart.byTheHourBook) {
      setEditBookingForm({
        pickuplocation: cart.pickuplocation,
        dropofflocations: cart.destinations,
        traveldate: moment(cart.travelDateWithPickupTime).format("YYYY-MM-DD"),
        pickuptime: moment(cart.travelDateWithPickupTime).format("HH:mm"),
        dropofftime: "",
        pax: {
          adult: cart.paxList[0].paxcount,
          child: cart.paxList[1].paxcount,
          toddler: cart.paxList[2].paxcount,
          baby: cart.paxList[3].paxcount,
        },
        lugage: {
          cabinSize: cart.luggagelist[0].luggagecount,
          checkinSize: cart.luggagelist[1].luggagecount,
        },
        duration: cart.duration,
        byTheHourBook: true,
        isFromAirport: false,
        isToAirport: false,
      });
    } else {
      setEditBookingForm({
        pickuplocation: cart.pickuplocation,
        dropofflocations: cart.destinations,
        traveldate: moment(cart.travelDateWithPickupTime).format("YYYY-MM-DD"),
        pickuptime: moment(cart.travelDateWithPickupTime).format("HH:mm"),
        pax: {
          adult: cart.paxList[0].paxcount,
          child: cart.paxList[1].paxcount,
          toddler: cart.paxList[2].paxcount,
          baby: cart.paxList[3].paxcount,
        },
        lugage: {
          cabinSize: cart.luggagelist[0].luggagecount,
          checkinSize: cart.luggagelist[1].luggagecount,
        },
        isAirportTransfer: cart.isAirportTransfer,
      });
    }

    cart?.bookingInfo?.map((i) => {
      setAllInfantSeatSize(allInfantSeatSize + i.childSeat[0].childSeatCount);
      setAllToddlerSeatSize(allToddlerSeatSize + i.childSeat[1].childSeatCount);
      setAllChildSeatSize(allChildSeatSize + i.childSeat[2].childSeatCount);
    });
    var seatTypeSum = {};


  }, []);

  const getCountChildSeat = () => {
    const totalCount = cart?.bookingInfo?.reduce((acc, booking) => {
      return booking.childSeat.reduce((acc, seat) => {
        return acc + seat.childSeatCount;
      }, acc);
    }, 0);

    return totalCount
  }

  const getChildSeatView = () => {
    var count = 0
    var infant = 0;
    var toddler = 0;
    var child = 0;
    cart?.bookingInfo.forEach(booking => {
      // Iterate over childSeat array within each booking
      infant += booking.childSeat[0].childSeatCount
      toddler += booking.childSeat[1].childSeatCount
      child += booking.childSeat[2].childSeatCount
    });

    return `Child Seats (${(infant > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Infant")} x${infant}` : "")} ${(toddler > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Toddler")} x${toddler}` : "")} ${(child > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Child")} x${child}` : "")})`;
  }

  function sum(prev, next) {
    return prev + next;
  }

  const getChildSeat = (type = null, cartItm) => {
    if (type == null) {
      cartItm.bookingInfo.map((i) => {
        i.childSeat.map((c) => {
          if (c.childSeatCount > 0) {
            return true;
          }
        });
        return false;
      });
    } else {
      var tot = 0;
      cartItm.bookingInfo.map((i) => {
        if (type == "infant") {
          tot += i.childSeat[0].childSeatCount;
        } else if (type == "toddler") {
          tot += i.childSeat[1].childSeatCount;
        } else if (type == "child") {
          tot += i.childSeat[2].childSeatCount;
        }
      });
      return tot;
    }
  };

  const onChangeDuplicateDate = (e, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index] = e.target.value;
    setInputFields(newInputFields);
  };

  const removeDateByIndex = (indexToRemove) => {
    setInputFields(prevArray => {
      // Create a copy of the array
      const newArray = [...prevArray];
      // Remove the element at the specified index
      newArray.splice(indexToRemove, 1);
      // Update the state with the new array

    console.log(newArray);
      return newArray;
    });
  };

  const handleAddDate = () => {
    // if (inputFields[inputFields.length - 1] != "") {
      const newInputFields = [...inputFields, ""];
      setInputFields(newInputFields);
    // }
  };

  // Fungsi untuk rekursif menelusuri JSON dan mengubah tanggal
  const recursiveChangeDate = async (obj, newDate) => {
    // Date strings
    const dateString1 = obj.tripStartTime;
    const dateString2 = newDate;

    // Parse the date strings using Moment.js
    const date1 = moment(dateString1).format("YYYY-MM-DD");
    const date2 = moment(dateString2).format("YYYY-MM-DD");

    // Calculate the difference in days
    const daysDifference = moment(date2).diff(date1, "days");

    const updatedCart = await changeDate(cart, obj, daysDifference);

    return updatedCart;
  };

  // Fungsi untuk mengubah hanya bagian tanggal pada objek
  const changeDate = async (cart, duplicateCart, diffDays) => {
    duplicateCart.key = Math.floor(Math.random() * 10000000000 + 1);

    const cutomerActualDropOffTime = moment(cart.cutomerActualDropOffTime);
    duplicateCart.cutomerActualDropOffTime = cutomerActualDropOffTime
      .add(diffDays, "days")
      ._d.toString();

    const garageArivalTime = moment(cart.garageArivalTime);
    duplicateCart.garageArivalTime = garageArivalTime
      .add(diffDays, "days")
      ._d.toString();

    const garageArivalTimeWithAdditionalTime = moment(
      cart.garageArivalTimeWithAdditionalTime
    );
    duplicateCart.garageArivalTimeWithAdditionalTime =
      garageArivalTimeWithAdditionalTime.add(diffDays, "days")._d.toString();

    const travelDateWithPickupTime = moment(cart.travelDateWithPickupTime);
    duplicateCart.travelDateWithPickupTime = travelDateWithPickupTime
      .add(diffDays, "days")
      ._d.toString();

    const tripStartTime = moment(cart.tripStartTime);
    duplicateCart.tripStartTime = tripStartTime
      .add(diffDays, "days")
      .toISOString();

    //bookingInfo
    await Promise.all(
      duplicateCart.bookingInfo.map(async (i, ind) => {
        const bookcutomerActualDropOffTime = moment(
          cart.bookingInfo[ind].cutomerActualDropOffTime
        );
        i.cutomerActualDropOffTime = bookcutomerActualDropOffTime
          .add(diffDays, "days")
          ._d.toString();

        const bookcutomerDropOffTimeWithAdditinalTime = moment(
          cart.bookingInfo[ind].cutomerDropOffTimeWithAdditinalTime
        );
        i.cutomerDropOffTimeWithAdditinalTime =
          bookcutomerDropOffTimeWithAdditinalTime
            .add(diffDays, "days")
            ._d.toString();

        const bookgarageArivalTime = moment(
          cart.bookingInfo[ind].garageArivalTime
        );
        i.garageArivalTime = bookgarageArivalTime
          .add(diffDays, "days")
          ._d.toString();

        const bookgarageArivalTimeWithAdditionalTime = moment(
          cart.bookingInfo[ind].garageArivalTimeWithAdditionalTime
        );
        i.garageArivalTimeWithAdditionalTime =
          bookgarageArivalTimeWithAdditionalTime
            .add(diffDays, "days")
            ._d.toString();

        const booktripStartTime = moment(cart.bookingInfo[ind].tripStartTime);
        i.tripStartTime = booktripStartTime.add(diffDays, "days").toISOString();
      })
    );

    
    const flightTracking = cart.flightTracking;
    duplicateCart.flightTracking = flightTracking;

    return duplicateCart;
  };

  const duplicateBooking = async () => {
    let cartArray = [];

    await Promise.all(
      inputFields.map(async (i, ind) => {
        if (i !== "") {
          // Mendefinisikan tanggal yang spesifik (25 Desember 2023)
          const specificDate = new Date(i);

          // Duplikat JSON
          const duplicatedJson = JSON.parse(JSON.stringify(cart));

          // Mengubah hanya bagian tanggal pada duplicatedJson dengan tanggal yang spesifik
          const updatedDuplicateCart = await recursiveChangeDate(
            duplicatedJson,
            specificDate
          );

          cartArray.push(updatedDuplicateCart);
        }
      })
    );

    await Promise.all(
      cartArray.map((i) => {
        addItemCartToStore(JSON.parse(JSON.stringify(cart)), i);
        console.log(i);
      })
    );

    setUseModalDuplicateBooking(false);
    setInputFields([""]);

    // const nowDateTime = moment();
    // localStorage.setItem("lastAddedBookToCart", nowDateTime);

    // window.location.reload();
  };

  /**
   * * manage cart storage
   * * local store,redux state,DB
   */
  const addItemCartToStore = async (duplicateFrom, item, storeLocalStorage = true) => {
    if (storeLocalStorage) {
      const localStoteItem = JSON.parse(localStorage.getItem("cart"));
      if (localStoteItem !== null && localStoteItem.length > 0) {
        var store_cart = JSON.parse(localStorage.getItem("cart"));
        if (store_cart[0].randomId !== item.randomId) {
          item.randomId = store_cart[0].randomId;
        }
        store_cart.push(item);
        localStorage.setItem("cart", JSON.stringify(store_cart));
        dispatch(addToCartReduxStore(store_cart));
      } else {
        var cart_array_store = [];
        cart_array_store.push(item);
        localStorage.setItem("cart", JSON.stringify(cart_array_store));
        dispatch(addToCartReduxStore(cart_array_store));
      }

      const nowDateTime = moment();
      localStorage.setItem("lastAddedBookToCart", nowDateTime);
    }
    addCartToDB(item, duplicateFrom);
  };

  /**
   * * Insert data to the database
   */
  const addCartToDB = (item, duplicateFrom) => {
    dispatch(
      addBooking(
        item.key,
        item.travelDateWithPickupTime,
        item.pickuptime,
        item.pickuplocation,
        item.destinations,
        item.paxList,
        item.luggagelist,
        item.pickupFlightNo,
        item.dropoffFlightNo,
        item.isFromAirport,
        item.cutomerActualDropOffTime,
        item.dropoffDatetime,
        item.garageArivalTime,
        item.cutomerChangedDropOffTimeOnly,
        "cart user",
        "cart@email.com",
        "0000000000",
        "cart address",
        "Japan",
        "JP",
        "+81",
        "0000",
        0,
        item.availableVehicleId,
        null,
        item.bookingInfo,
        "Confirmed",
        item.totalDistance,
        item.tripStartTime,
        item.garageArivalTimeWithAdditionalTime,
        item.note_arr,
        item.randomId,
        item.activePackage,
        item.passenger_name,
        item.specialRequest,
        item.meetAssist,
        item.byTheHourBook,
        item.duration,
        item.isManyPeople,
        item.totalPrice,
        item.childSeat,
        item.isAirportTransfer,
        "",
        true,
        "Mr.",
        "Mr.",
        "+81",
        duplicateFrom.flightTracking
      )
    );
  };

  const resetDuplicateBookingForm = () => {
    setInputFields([""]);
  };

  const discardDuplicateBooking = () => {
    setInputFields([""]);
    setUseModalDuplicateBooking(false);
  };

  return (
    <>
      <div className="card shadow-sm border-top-gold">
        <div className="card-body pt-4 px-4">
          {/* DESKTOP */}
          <div className="">
            <div className="row">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-6  col-md-4">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.PassengerName")}
                    </p>
                    <b className="fs-14">{cart?.pronounced} {cart?.passenger_name}</b>
                  </div>

                  <div className={cart.byTheHourBook ? 'col-5 col-md-2' : 'col-5 col-md-7' } >
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.TypeOfTransfer")}
                    </p>
                    <b className="fs-14">
                      {cart?.isAirportTransfer
                        ? t("TypeOfTransfer.AirportTransfer")
                        : cart.byTheHourBook
                          ? t("TypeOfTransfer.ByTheHour")
                          : t("TypeOfTransfer.SpecifiedDestination")}
                    </b>
                  </div>
                  {cart.byTheHourBook && <div className='col-5 col-md-2' >
                    <p className="m-0 fw-bold text-grey-200 ">
                                    {t("BookingConfirmation.Car.UsageDuration")}
                    </p>
                    <b className="fs-14">
                    {lang == "EN" ? cart?.bookingInfo[0].totalHoursUsage + " Hours" : cart?.bookingInfo[0].totalHoursUsage + "時間"} &nbsp;
                    <i
                      data-tip
                      data-for="usageDuration"
                      className="fas fa-info-circle text-primary"
                    ></i>
                    <ReactTooltip
                      id="usageDuration"
                      backgroundColor="#F6EEDC"
                      textColor="#4E4E4E"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("usageDurationTooltip"),
                        }}
                      ></span>
                    </ReactTooltip>
                    </b>
                  </div>}
                  {cart.byTheHourBook && <div className='col-5 col-md-2' >
                    <p className="m-0 fw-bold text-grey-200 ">
                                    {t("BookingConfirmation.Car.BilledDuration")}
                    </p>
                    <b className="fs-14">
                    {lang == "EN" ? cart?.bookingInfo[0].totalhours + " Hours" : cart?.bookingInfo[0].totalhours + "時間"} &nbsp;
                    <i
                      data-tip
                      data-for="billedDuration"
                      className="fas fa-info-circle text-primary"
                    ></i>
                    <ReactTooltip
                      id="billedDuration"
                      backgroundColor="#F6EEDC"
                      textColor="#4E4E4E"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("billedDurationTooltip"),
                        }}
                      ></span>
                    </ReactTooltip>
                    </b>
                  </div>}
                  <div className={cart.byTheHourBook ? 'col-2 text-end' : 'col-1 text-end' } >
                    <div class="dropdown dropdown-lg d-inline-block ms-auto">
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenu1"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="text-dark"
                      >
                        <i class="fas fa-ellipsis-h"></i>
                      </a>

                      <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenu1" style={{padding : "0"}}
                      >
                        {process.env.REACT_APP_EDIT_BOOKING == "true" ? (
                          <>
                            <li>
                              <a
                                className="dropdown-item text-dark py-2"
                                onClick={handleOpenModalEditBooking}
                              >
                                Edit Booking
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider p-0 m-0" />
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        <li>
                          <a
                            onClick={() => setUseModalDuplicateBooking(true)}
                            className="dropdown-item text-dark py-2"
                          >
                            {t("NewCart.DuplicateBooking.Title")}
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider p-0 m-0" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item text-dark py-2"
                            onClick={() => {
                              removeItemFromCart(cart);
                            }}
                          >
                            {t("NewCart.RemoveBooking.Title")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr className="divider" />
                <div className="row">
                  <div className="col-6 col-md-4  order-1 order-lg-1 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">{t("NewCart.Card.PickupTime")}</p>
                    <b className="fs-14">
                      {moment(cart?.tripStartTime).format(
                        "MMM D, yyyy (ddd)"
                      )}
                      , {cart.pickuptime}
                    </b>
                  </div>
                  <div className="col-12 col-md-8 order-3 order-lg-2 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.PickupLocation")}
                    </p>
                    <b className="fs-14">{cart?.pickuplocation?.label}</b>
                  </div>
                  <div className="col-6 col-md-4 order-2 order-lg-3 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.DropoffTime")}
                    </p>

                    <b className="fs-14">
                      {moment(cart.dropoffDatetime, 'YYYY-MM-DD HH:mm').format('MMM D, yyyy (ddd), HH:mm')}
                    </b>
                  </div>
                  <div className="col-md-8 order-4 order-lg-4 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.DropoffLocation")}
                    </p>
                    {cart?.destinations.map((item, index) => {
                      return (
                        <div>
                          <b className="fs-14">{item.label}</b>
                          {index == cart.destinations.length - 1 ? "" : <br />}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr className="divider" />
                {cart.bookingInfo && cart?.bookingInfo?.length > 0 && cart?.bookingInfo?.map((item, index) => {
                  return (
                    <div className="row">
                      <div className="col-md-4 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Vehicle") + " " + (index + 1)}
                        </p>

                        <b className="fs-14">{item.vehicleTypeName}</b>
                      </div>
                      <div className="col-md-3 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Passenger")}
                        </p>
                        <b className="fs-14">
                          {item.pax
                            .filter((passenger) => passenger.paxcount > 0)
                            .map((passenger, index, array) => (
                              <span key={passenger.paxtype}>
                                {passenger.paxtype}: {passenger.paxcount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                      <div className="col-md-3 my-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("NewCart.Card.Luggage")}
                        </p>
                        <b className="fs-14">
                          {item.luggage
                            .filter((luggage) => luggage.luggagecount > 0)
                            .map((luggage, index, array) => (
                              <span key={luggage.luggagetype}>
                                {luggage.luggagetype == "CHECK-IN SIZE"
                                  ? t("Check-in")
                                  : t("Cabin")}
                                : {luggage.luggagecount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                    </div>
                  );
                })}

                <hr className="divider" />
                <div className="row">
                  <div className="col-md-4 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.AddOnServices")}
                    </p>
                    {meetAssist != "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.meetAssist")} (${t("NewCart.AddOnServices." + meetAssist)})`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart.travelArea && cart.byTheHourBook && cart.travelArea != "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.TravelArea.Title")} ${t("NewCart.AddOnServices.TravelArea."+cart.travelArea)}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {getCountChildSeat() > 0 && (
                      <div>
                        <b className="fs-14">
                          {getChildSeatView()}
                        </b>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-md-8 my-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t("NewCart.Card.AdditionalInformation")}
                    </p>
                    {cart?.pickupFlightNo && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.ArrivalFlightNumber")} ${cart?.pickupFlightNo}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart?.dropoffFlightNo && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.DepartureFlightNumber")} ${cart?.dropoffFlightNo}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {cart?.note_arr[0]?.note !== "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.Card.AdditionalInformation.SpecialRequest")} ${cart?.note_arr[0].note}`}
                        </b>
                        <br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer py-3 ">
          <div className="row  align-items-center  justify-content-between">
            <div className="col-auto">
              <p className="m-0 fs-14">{t("NewCart.Card.Price")}</p>
            </div>
            <div className="col-auto">
              <h5>{`${t("JPY")} ${cart.totalPrice.toLocaleString()}`}{t("JPY.JP")}</h5>
            </div>
          </div>
        </div>
      </div>
      <EditBooking
        useModal={useModalEditBooking}
        handleCloseModal={handleCloseModalEditBooking}
        typeOfTransfer={
          cart.byTheHourBook ? "byTheHour" : "specifiedDestination"
        }
        editBookingForm={editBookingForm}
        keyBook={keyBook}
      />

      {/* DUPLICATE BOOKING MODAL */}
      {useModalDuplicateBooking ? (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xxl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="d-flex mt-2 justify-content-between">
                    <h5 className="d-none d-lg-block">{t("NewCart.DuplicateBooking.Title")}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setUseModalDuplicateBooking(false);
                        resetDuplicateBookingForm();
                      }}
                    ></button>
                  </div>
                  {/* DESKTOP */}
                  <div className="card border-gold">
                    <div className="card-body">
                      <b>
                      {t("BookingConfirmation.DuplicateBooking.Description")}
                      </b>
                      <div className="row mt-3 ">
                        {inputFields.map((item, index) => (
                          <div className="col-12 col-lg-3 mb-3 input-box" key={index}>
                            <div className="form-floating">
                            <input
                              type="date"
                              id="date"
                              name="traveldate"
                              min={currentDate}
                              className={"form-control"}
                              placeholder="Travel date"
                              aria-label="Travel date"
                              onChange={(e) => {
                                onChangeDuplicateDate(e, index);
                              }}
                              value={inputFields[index]}
                            />
                          <i class="fa fa-close icon"   onClick={() => removeDateByIndex(index)}></i> 
                            <label htmlFor="travelDate">
                              <div className="d-flex align-items-center">
                                <i className="pe-2 d-flex align-self-center">
                                  <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.32109 0.506328C10.7789 0.506328 13.2363 0.514738 15.6937 0.500021C16.2467 0.496657 16.6293 0.904545 16.6149 1.37929C16.5917 2.14461 16.6096 2.91077 16.6083 3.67692C16.6075 4.15882 16.2554 4.50237 15.7588 4.50237C10.789 4.50279 5.81959 4.50279 0.849777 4.50237C0.352272 4.50237 0.00109198 4.15966 0.000655187 3.6765C-0.000218396 2.89479 -0.000218396 2.11307 0.000655187 1.33136C0.00152877 0.84231 0.353582 0.506749 0.866812 0.506749C3.35172 0.506328 5.83662 0.506749 8.32109 0.506749V0.506328Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M6.2393 6.10265C6.52802 6.10265 6.81674 6.09971 7.10589 6.10349C7.35006 6.10644 7.47848 6.22838 7.48022 6.46471C7.48372 7.02061 7.48372 7.57694 7.48022 8.13284C7.47891 8.37758 7.34962 8.50162 7.09716 8.50289C6.51404 8.50541 5.93092 8.50541 5.34825 8.50289C5.10583 8.50162 4.9761 8.37505 4.97479 8.14083C4.97217 7.57946 4.97173 7.01809 4.97479 6.45672C4.9761 6.23133 5.10626 6.10686 5.34038 6.10391C5.64002 6.09971 5.93966 6.10307 6.2393 6.10265Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M10.3777 8.50379C10.0833 8.50379 9.78936 8.50674 9.49496 8.50295C9.26128 8.50001 9.12893 8.37512 9.12762 8.15099C9.12413 7.58457 9.12413 7.01773 9.12762 6.45132C9.12893 6.23476 9.25953 6.10608 9.48229 6.1044C10.0816 6.10062 10.6813 6.10062 11.2806 6.1044C11.4963 6.10566 11.6291 6.22929 11.6313 6.4387C11.637 7.01563 11.6374 7.59298 11.6313 8.16991C11.6291 8.3768 11.492 8.49875 11.2771 8.50169C10.9774 8.50631 10.6778 8.50295 10.3782 8.50295L10.3777 8.50379Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M14.531 6.10278C14.8197 6.10278 15.1084 6.10068 15.3976 6.10362C15.6544 6.10614 15.7833 6.22472 15.785 6.4682C15.7885 7.0241 15.7885 7.58043 15.785 8.13633C15.7837 8.37854 15.6518 8.50217 15.3989 8.50343C14.8215 8.50595 14.2436 8.50595 13.6661 8.50343C13.4032 8.50217 13.28 8.37896 13.2791 8.12246C13.2774 7.57706 13.2774 7.03125 13.2791 6.48586C13.28 6.2222 13.4028 6.10572 13.681 6.10362C13.9645 6.10152 14.2475 6.1032 14.531 6.10362V6.10278Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M6.22778 12.5017C5.93906 12.5017 5.65034 12.5038 5.36118 12.5008C5.10566 12.4983 4.97593 12.3768 4.97462 12.135C4.972 11.5791 4.972 11.0228 4.97462 10.4669C4.9755 10.2242 5.10391 10.1019 5.35987 10.101C5.93731 10.0985 6.51518 10.0985 7.09262 10.101C7.35644 10.1023 7.47918 10.2234 7.48049 10.4816C7.48268 11.027 7.48268 11.5728 7.48049 12.1182C7.47918 12.3801 7.35251 12.4991 7.07777 12.5012C6.79429 12.5033 6.51125 12.5017 6.22778 12.5012V12.5017Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M10.3786 12.5014C10.0842 12.5014 9.79023 12.5044 9.49584 12.5006C9.26172 12.4976 9.12893 12.3736 9.12762 12.1499C9.12413 11.5835 9.12413 11.0166 9.12762 10.4502C9.12893 10.2332 9.25909 10.1037 9.48098 10.1024C10.0803 10.0987 10.68 10.0987 11.2793 10.1024C11.4955 10.1037 11.6287 10.2265 11.6309 10.4359C11.637 11.0128 11.637 11.5902 11.6309 12.1671C11.6282 12.3803 11.4898 12.4981 11.2613 12.501C10.9669 12.5044 10.673 12.5018 10.3786 12.5018V12.5014Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M14.5302 10.1003C14.8189 10.1003 15.1076 10.0982 15.3968 10.1012C15.654 10.1037 15.7838 10.2223 15.7851 10.4649C15.7886 11.0208 15.7886 11.5771 15.7851 12.133C15.7838 12.3757 15.6523 12.5001 15.4003 12.501C14.8228 12.5035 14.2449 12.5035 13.6675 12.501C13.4037 12.4997 13.2805 12.3774 13.2796 12.1209C13.2779 11.5755 13.2779 11.0296 13.2796 10.4843C13.2805 10.2202 13.4032 10.1033 13.6806 10.1012C13.9641 10.0991 14.2471 10.1008 14.5306 10.1008L14.5302 10.1003Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M6.22488 16.4984C5.93049 16.4984 5.63653 16.5014 5.34213 16.4976C5.10845 16.4946 4.9761 16.3697 4.97479 16.1456C4.97129 15.5792 4.97129 15.0124 4.97479 14.4459C4.9761 14.229 5.1067 14.1007 5.32946 14.099C5.92874 14.0952 6.52845 14.0952 7.12773 14.099C7.34351 14.1003 7.47629 14.2239 7.47848 14.4333C7.48415 15.0103 7.48459 15.5876 7.47848 16.1645C7.47629 16.3714 7.33914 16.4934 7.12424 16.4963C6.8246 16.5009 6.52496 16.4976 6.22532 16.4976L6.22488 16.4984Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M10.3715 16.4991C10.0881 16.4991 9.80503 16.5012 9.52155 16.4987C9.25598 16.4962 9.128 16.3759 9.12713 16.1249C9.12494 15.574 9.12494 15.0232 9.12713 14.4723C9.128 14.2259 9.25336 14.1001 9.50495 14.0989C10.0881 14.0959 10.6712 14.0959 11.2543 14.0989C11.5063 14.1001 11.6321 14.2242 11.6334 14.4715C11.6361 15.0223 11.6361 15.5732 11.6334 16.124C11.6321 16.3755 11.5024 16.4966 11.2386 16.4987C10.9499 16.5012 10.6611 16.4991 10.372 16.4991H10.3715Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M14.5172 16.4988C14.2285 16.4988 13.9397 16.5017 13.6506 16.498C13.409 16.495 13.2806 16.3693 13.2793 16.1338C13.2767 15.5779 13.2767 15.0216 13.2793 14.4657C13.2802 14.2218 13.4073 14.0998 13.6637 14.0986C14.2411 14.096 14.819 14.096 15.3964 14.0986C15.6603 14.0998 15.7843 14.2209 15.7852 14.4779C15.7874 15.0233 15.7874 15.5691 15.7852 16.1145C15.7843 16.3773 15.6581 16.4967 15.3833 16.4988C15.0946 16.5009 14.8059 16.4992 14.5167 16.4988H14.5172Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M2.07744 8.5039C1.78304 8.5039 1.48908 8.50684 1.19512 8.50306C0.961872 8.50011 0.827777 8.3769 0.82603 8.15236C0.821662 7.58594 0.821662 7.01952 0.82603 6.4531C0.827777 6.22898 0.960998 6.10535 1.19643 6.10409C1.78479 6.10072 2.37314 6.10072 2.9615 6.10409C3.2013 6.10535 3.32972 6.23444 3.33103 6.47034C3.33365 7.02625 3.33365 7.58215 3.33103 8.13806C3.32972 8.37943 3.19955 8.50053 2.94403 8.50348C2.65531 8.50642 2.36659 8.50432 2.07787 8.50348L2.07744 8.5039Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M2.08203 10.1C2.37075 10.1 2.65947 10.0975 2.94818 10.1009C3.20065 10.1038 3.32907 10.2279 3.33038 10.4713C3.333 11.0272 3.333 11.5831 3.33038 12.139C3.32907 12.3724 3.19847 12.499 2.95605 12.5003C2.36769 12.5032 1.77933 12.5032 1.19097 12.5003C0.959475 12.499 0.827127 12.372 0.82538 12.1462C0.821012 11.5852 0.821449 11.0239 0.82538 10.4625C0.82669 10.2274 0.956417 10.1042 1.19971 10.1013C1.49367 10.0975 1.78807 10.1004 2.08246 10.1004L2.08203 10.1Z"
                                      fill="#DBBE78"
                                    />
                                    <path
                                      d="M2.07525 16.4985C1.78086 16.4985 1.48689 16.5014 1.19293 16.4976C0.960998 16.4947 0.827777 16.3698 0.82603 16.1448C0.821662 15.5784 0.821662 15.012 0.82603 14.4456C0.827777 14.2282 0.959688 14.1008 1.18201 14.0995C1.77561 14.0957 2.36965 14.0957 2.96325 14.0995C3.19606 14.1008 3.32841 14.2278 3.33015 14.4519C3.33408 15.0183 3.33408 15.5847 3.33015 16.1512C3.32841 16.3732 3.19387 16.4951 2.95801 16.4981C2.66405 16.5014 2.36965 16.4989 2.07525 16.4989V16.4985Z"
                                      fill="#DBBE78"
                                    />
                                  </svg>
                                </i> 
                                {t("Travel.Date")}
                              </div>
                            </label>
                            </div>
                            
                          </div>
                        ))}
                        <div className="col-lg-3 pb-3">
                          <button
                            type="button"
                            className="btn bg-gold-light-100" 
                            style={{height : "58px"}}
                            onClick={handleAddDate}
                          >
                            <svg
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_2743_24698)">
                                <path
                                  d="M5.56986 1.44291L4.6235 1.44291L4.6235 5.19479L0.871618 5.19479V6.14115L4.6235 6.14115L4.6235 9.89303L5.56986 9.89303L5.56986 6.14115H9.32174V5.19479H5.56986L5.56986 1.44291Z"
                                  fill="#666666"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2743_24698">
                                  <rect
                                    width="6.64432"
                                    height="6.64432"
                                    fill="white"
                                    transform="translate(0.398438 5.66797) rotate(-45)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            &nbsp; {t("BookingConfirmation.DuplicateBooking.Adddate")}
                          </button>
                        </div>
                      </div>
                      <div className="row mt-3">
                        {/* <div className="col-lg-3 d-grid">
                          <button
                            type="button"
                            className="btn bg-gold-light-100"
                            onClick={handleAddDate}
                          >
                            <svg
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_2743_24698)">
                                <path
                                  d="M5.56986 1.44291L4.6235 1.44291L4.6235 5.19479L0.871618 5.19479V6.14115L4.6235 6.14115L4.6235 9.89303L5.56986 9.89303L5.56986 6.14115H9.32174V5.19479H5.56986L5.56986 1.44291Z"
                                  fill="#666666"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2743_24698">
                                  <rect
                                    width="6.64432"
                                    height="6.64432"
                                    fill="white"
                                    transform="translate(0.398438 5.66797) rotate(-45)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            &nbsp; {t("BookingConfirmation.DuplicateBooking.Adddate")}
                          </button>
                        </div> */}
                        <p className="mt-3 ">
                        {lang == "EN" ? <> Duplicate booking for another {" "}
                          <b>{inputFields.length} days</b></> : <>さらに{inputFields.length}日同じ予約を作成</> }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-lg-3 d-grid">
                      <button
                        type="button"
                        className="btn btn-secondary rounded"
                        onClick={() => {
                          discardDuplicateBooking();
                        }}
                      >
                        {t("NewCart.DuplicateBooking.DiscardButton")}
                      </button>
                    </div>
                    <div className="col-lg-3 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary rounded"
                        data-bs-dismiss="modal"
                        disabled={inputFields[0] == "" || inputFields.length < 1 ? true : false}
                        onClick={() => {
                          duplicateBooking();
                        }}
                      >
                        {t("NewCart.DuplicateBooking.DuplicateButton")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewCartItemCard;
