import { ON_GET_INDIVIDUAL_BOOKINGS, GET_INDIVIDUAL_BOOKINGS, INDIVIDUAL_BOOKINGS_ERROR } from "./types";
import customAxios from "../utils/customAxios";
import setAuthToken from "../utils/setAuthToken";

/**
 * * Get individual bookings based on the provided to the parameters
 * @param status  - type status
 * @param paymentStatus - payment status
 * @param sortBy - pick date
 */
export const getIndividualBookings =
  (status, paymentStatus, sortBy) => async (dispatch) => {
    dispatch({
      type: ON_GET_INDIVIDUAL_BOOKINGS,
    });
    if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
    try {
      const res = await customAxios.get(
        `/api/admin/bookings/getBooking/individual/${status}/${paymentStatus}/${sortBy}`
      );
      console.log(res.request);
      console.log(res.data);
      dispatch({
        type: GET_INDIVIDUAL_BOOKINGS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INDIVIDUAL_BOOKINGS_ERROR,
        payload: { msg: err.response, status: err.response },
      });
    }
  };

  export const getBookings =
  (status, paymentStatus, sortBy) => async (dispatch) => {
    dispatch({
      type: ON_GET_INDIVIDUAL_BOOKINGS,
    });
    if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
    try {
      const res = await customAxios.get(
        `/api/admin/bookings/getBooking/${status}/${paymentStatus}/${sortBy}`
      );
      console.log(res.request);
      console.log(res.data);
      dispatch({
        type: GET_INDIVIDUAL_BOOKINGS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INDIVIDUAL_BOOKINGS_ERROR,
        payload: { msg: err.response, status: err.response },
      });
    }
  };
