import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";

const BookingItem = ({
  bookings: {
    _id,
    bookingStatus,
    picuplocation,
    travelDate,
    date,
    customer,
    bookingAmountInfo,
    cartID,
    bookingInfo,
    byTheHourBook,
    destinations,
    isAirportTransfer
  },
  count,
}) => {
  const [style, setStyle] = useState("null");

  useEffect(() => {
    setBookingStyle();
  }, []);

  const setBookingStyle = () => {
    switch (bookingStatus) {
      case "Confirmed":
        setStyle("badge bg-success py-2");
        break;
      case "Payment Pending":
        setStyle("badge bg-primary py-2");
        break;
      case "Inquiry":
        setStyle("badge bg-warning py-2");
        break;
      case "Cancelled":
        setStyle("badge bg-danger py-2");
        break;
      default:
        setStyle("badge bg-dark py-2");
        break;
    }
  };

  // Extract vehicle type names from bookingInfo array
  const vehicleTypeNames = bookingInfo.map((info) => info.vehicleTypeName);

  // Combine vehicle type names with commas
  const combinedVehicleTypeNames = vehicleTypeNames.join(', ');

  return (
    <tr>
      <td>{count + 1}</td>
      <td>{customer.name}</td>
      <td>
        <span
          className="badge text-dark py-2"
          style={{
            border: "1px solid #" + cartID.substring(0, 6),
          }}
        >
          {cartID}
        </span>
      </td>
      <td>{combinedVehicleTypeNames}</td>
      <td>
        <div className={style}>{bookingStatus}</div>
      </td>
      <td>
        <div className={byTheHourBook ? 'badge bg-secondary py-2' : isAirportTransfer ? 'badge bg-light py-2 text-dark' : 'badge bg-dark py-2'}>{byTheHourBook ? 'By The Hour' : isAirportTransfer ? 'Airport Transfer' : 'Specified Destination'}</div>
      </td>
      <td>
        <Moment format="YYYY/MM/DD">{date}</Moment>
      </td>
      <td>
        <Moment format="YYYY/MM/DD hh:mm A">{travelDate}</Moment>
      </td>

      <td>
        <Moment format="YYYY/MM/DD hh:mm A">
          {bookingInfo[0].cutomerDropOffTimeWithAdditinalTime}
        </Moment>
      </td>
      <td>{picuplocation.label}</td>
      <td>
        <Link
          type="button"
          className="btn btn-secondary btn-sm py-2"
          to={`/admin/bookinglist/edit/${_id}`}
        >
          Manage
        </Link>
      </td>

      {/*  <td>
      <Moment format="YYYY/MM/DD">{date}</Moment>
    </td>
    
     */}
    </tr>
  );
};

export default BookingItem;
