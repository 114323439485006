import FrontLayout from "../layout-new-theme/FrontLayout";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { Route, Redirect } from "react-router-dom";
import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

import MyPageBookings from "./myPage/bookings/MyPageBookings";
import Notifications from "./myPage/notification/Notifications";
import AccountInformation from "./myPage/account/AccountInformation";
import InvoiceAndBIlling from "./myPage/invoiceAndBilling/InvoiceAndBilling";

import BussinessAccountInformation from "./myPage/account/BussinessAccountInformation";
import { Link, useLocation } from "react-router-dom";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import NotificationDetail from "./myPage/notification/NotificationDetail";
import { useTranslation } from "react-i18next";
const CustomerMyPage = ({ match }) => {
  const [page, setpage] = useState("Bookings");
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const notifId = match.params.notif_id;
  const { t } = useTranslation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);
      setUserInfo(decodedJwt.user);
    }

    switch (location.pathname) {
      case "/my-page/bookings":
        {t('my_page.tab_bookings')}
        break;
      case "/my-page/notifications":
        setpage(t('my_page.tab_notifications'));
        break;
      case "/my-page/account":
        setpage(t('my_page.tab_accounts'));
        break;

      default:
        break;
    }
    setIsLoading(false);
  }, [location]);

  const renderPage = () => {
    switch (location.pathname) {
      case "/my-page/bookings":
        return <MyPageBookings userRole={userInfo.role} />;
      case "/my-page/notifications":
        return <Notifications />;
      case "/my-page/notifications/" + notifId:
        return <NotificationDetail/>; // this will change to botification detail
      case "/my-page/account":
        return !isLoading && userInfo.role === "business" ? (
          <BussinessAccountInformation />
        ) : (
          <AccountInformation />
        );
      case "/my-page/invoices-billings":
        return !isLoading && userInfo.role === "business" ? (
          <InvoiceAndBIlling/>
        ) : (
          <></>
        );
      default:
        return <MyPageBookings userRole={userInfo.role} />;
    }
  };

  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container-fluid  mt-1 pt-1">
          <div className="custom-mx">
            <div className="container pb-5 custom-mt-9 col-lg-12">
              <div className="row">
                {/* DESTOP VIEW */}
                <div className="col-12 d-none d-md-block mb-2">
                  <h3>{t('my_page.title')} </h3>
                  <ul className="nav nav-pills mt-4 gap-3">
                    <li className="nav-item">
                      <Link
                        to="/my-page/bookings"
                        className={`nav-link bg-grey-light px-5 py-3 text-dark my-page-nl ${
                          location.pathname === "/my-page/bookings" ||
                          location.pathname === "/my-page"
                            ? "active"
                            : ""
                        }`}
                      >
                        {t('my_page.tab_bookings')}
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link
                        to="/my-page/notifications"
                        className={`nav-link bg-grey-light px-5 py-3 text-dark my-page-nl ${
                          location.pathname.includes("/my-page/notifications")
                            ? "active"
                            : ""
                        }`}
                      >
                      {t('my_page.tab_notifications')}
                      </Link>
                    </li>

                    {userInfo.role === "business" ? (
                      <li className="nav-item" role="presentation">
                        {/* <button
                          className="nav-link bg-grey-light px-5 py-3 text-dark my-page-nl"
                          data-bs-toggle="tab"
                          data-bs-target="#invoice-and-billing"
                          type="button"
                          role="tab"
                          aria-controls="invoice-and-billing"
                          aria-selected="false"
                        >
                        {t('my_page.tab_invoice')}
                        </button> */}
                        <Link
                          to="/my-page/invoices-billings"
                          className={`nav-link bg-grey-light px-5 py-3 text-dark my-page-nl ${
                            location.pathname.includes("/my-page/invoices-billings")
                              ? "active"
                              : ""
                          }`}
                        >
                        {t('my_page.tab_invoice')}
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li className="nav-item" role="presentation">
                      <Link
                        to="/my-page/account"
                        className={`nav-link bg-grey-light px-5 py-3 text-dark my-page-nl ${
                          location.pathname === "/my-page/account"
                            ? "active"
                            : ""
                        }`}
                      >
                      {t('my_page.tab_accounts')}
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* MOBILE VIEW */}
                <div className="col-12 d-block d-md-none">
                  <h3>{t('my_page.title')} </h3>
                  <hr />
                  <div className="mb-3">
                    <div className="d-grid gap-2 ">
                      <button
                        className="btn btn-primary py-3 rounded accordion-button-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#menu"
                        aria-expanded="false"
                        aria-controls="menu"
                      >
                        <div className="row align-items-center">
                          <div className="col text-start white-text">
                            {page}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="collapse" id="menu">
                      <div
                        className="card card-body border-gold"
                        style={{
                          marginTop: "-3px ",
                          fontSize: "0.875rem",
                        }}
                      >
                        <ul className="nav flex-column">
                          <li
                            className="nav-item active noto"
                            role="presentation"
                          >
                            <Link
                              to="/my-page/bookings"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                            >
                            {t('my_page.tab_bookings')}
                            </Link>
                          </li>
                          <hr />{" "}
                          <li
                            className="nav-item active noto"
                            role="presentation"
                          >
                            <Link
                              to="/my-page/notifications"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                            >
                            {t('my_page.tab_notifications')}
                            </Link>
                          </li>

                          {userInfo.role === "business" && (
                            <>
                              <hr />
                               <li
                                  className="nav-item active noto"
                                  role="presentation"
                                >
                                  <Link
                                    to="/my-page/invoices-billings"
                                    className="text-dark"
                                    style={{ textDecoration: "none" }}
                                  >
                                  {t('my_page.tab_invoice')}
                                  </Link>
                                </li>
                            </>
                          )}
                          <hr />
                          <li className="nav-item noto" role="presentation">
                            <Link
                              to="/my-page/account"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                            >
                            {t('my_page.tab_accounts')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* TAB CONTENT  */}
                {renderPage()}

                {/* <div className="tab-content mb-3" id="bookingsTabContent">
                  <div
                    className={`tab-pane fade ${
                      location.pathname === "/my-page/bookings"
                        ? "show active"
                        : ""
                    }`}
                    id="bookings"
                    role="tabpanel"
                    aria-labelledby="bookings-tab"
                  >
                    {!isLoading && <MyPageBookings userRole={userInfo.role} />}
                  </div>
                  <div
                    className={`tab-pane fade ${
                      location.pathname === "/my-page/notifications"
                        ? "show active"
                        : ""
                    }`}
                    id="notifications"
                    role="tabpanel"
                    aria-labelledby="notification-tab"
                  >
                    <Notifications />
                  </div>
                  <div
                    className={`tab-pane fade ${
                      location.pathname === "/my-page/account" || notifId != null || notifId != undefined
                        ? "show active"
                        : ""
                    }`}
                    id="account-information"
                    role="tabpanel"
                    aria-labelledby="account-information-tab"
                  >
                    {!isLoading && userInfo.role === "business" ? (
                      <BussinessAccountInformation />
                    ) : (
                      <AccountInformation />
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="invoice-and-billing"
                    role="tabpanel"
                    aria-labelledby="invoice-and-billing-tab"
                  >
                    <InvoiceAndBIlling />
                  </div>
                </div>
                 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};
export default CustomerMyPage;
