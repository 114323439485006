import React from "react";
const AlertModal = ({ text = "Please input all required fields (highlighted  in red)", handleClose, show }) => {
  return (
    <>
      {show && <div className="modal-backdrop show"></div>}
      <div
        className={show ? "modal fade show" : "modal fade"}
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content border-top-danger">
           
            <div className="text-end me-4 mt-3">
            <button
              onClick={handleClose}
                type="button"
                className="text-end btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body my-1 text-center" style={{color : "#ED553B"}}>
              <h5 className="mb-4">
              <span className="me-2"><svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 14H19L9.5 0L0 14ZM10.3636 11.7895H8.63636V10.3158H10.3636V11.7895ZM10.3636 8.8421H8.63636V5.89474H10.3636V8.8421Z" fill="#ED553B"/>
              </svg></span>
{text}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
