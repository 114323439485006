import React, { useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { requestForgotPassword } from "../../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoading } from "./LoadingContext";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true); // Initially set to true to avoid initial error display
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const { showLoading, closeLoading } = useLoading();
  const { t } = useTranslation();
  const history = useHistory();

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
    setIsValidEmail(isValid);
    // Set error message based on validation result
    setEmailError(isValid ? "" : "Please enter a valid email address");
  };

  const handleResetPassword = async () => {
    showLoading(true);
    if (isValidEmail) {
      const requestForgotPasswordResponse = await requestForgotPassword(
        email,
        lang
      );
      if (requestForgotPasswordResponse.code == "201") {
        setEmailSent(true);
      } else if (requestForgotPasswordResponse.code == "401") {
        setError("Email not found");
      }
    } else {
      setEmailError("Please enter a valid email address");
    }
    closeLoading(true);
  };

  return (
    <FrontLayout>
      <div className="custom-mx mt-3 pt-3 mt-sm-5 pt-sm-5">
        <div className="container pb-5 pb-md-0 pt-5 pt-md-0 custom-mt-6 col-lg-12">
          <div className="row pb-5 d-flex justify-content-lg-center">
            <div className="col-12 col-md-10 px-5 py-5 ">
              <div className="row mb-5">
                {emailSent ? (
                  <div className="container">
                    <div className="text-center mb-4">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM24 45L9 30L13.23 25.77L24 36.51L46.77 13.74L51 18L24 45Z"
                          fill="#3CAEA3"
                        />
                      </svg>
                    </div>
                    <h2 className="text-center my-4">
                      {t("forget_password.success_title")}
                    </h2>
                    <p
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: t("forget_password.success_body", {
                          email: email,
                        }),
                      }}
                    ></p>
                    <div className="mt-5">
                      <div className="d-flex justify-content-center align-items-center gap-4">
                        <p className="m-0 p-0">
                          {t("forget_password.email_not_received")}
                        </p>
                        <button
                          type="button"
                          onClick={handleResetPassword}
                          className="btn btn-outline-primary px-5 rounded-3"
                        >
                          {t("forget_password.resend_email_button")}
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm rounded-3 col-4 offset-md-4 d-grid py-2 fs-14 mt-5"
                        onClick={() => history.push("./")}
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {t("forget_password.back_to_home")}
                          <i className="ps-2">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 5L20 12L13 19M4 12H20H4Z"
                                stroke="#4A4A4A"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </i>
                        </div>
                      </button>

                      <p
                        className="text-center mt-5"
                        dangerouslySetInnerHTML={{
                          __html: t("forget_password.success_footer"),
                        }}
                      ></p>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 offset-md-3">
                    {error && <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      {error}
                    </div>
                    }
                    <div className="card">
                      <div className="card-body">
                        <h5 className="mb-3">Forgot Password</h5>
                        <div
                          className={`form-floating mb-3 ${isValidEmail ? "" : "has-danger"
                            }`}
                        >
                          <input
                            type="email"
                            className={`form-control ${isValidEmail ? "" : "invalid "
                              }`}
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={email}
                            onChange={handleEmailChange}
                            required
                          />
                          <label htmlFor="floatingInput">Email address</label>

                          {emailError != "" && (
                            <p className="text-danger">{emailError}</p>
                          )}
                        </div>
                        <div className="d-grid">
                          <button
                            className={`btn btn-primary py-2 ${isValidEmail && email != "" ? "" : "disabled"
                              }`}
                            onClick={handleResetPassword}
                            disabled={!isValidEmail}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default ForgotPassword;
