import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";

const Terms = () => {
  const page_name = "cms-terms";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  return (
    <FrontLayout>
      {/* <!-- hero --> */}
      <div className="hero-image-terms d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Pricing")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Pricing.Heading.Three")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Terms.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- end of hero --> */}
      {/* <!-- section 1 --> */}
      {/* <!-- section 1, general terms --> */}
      <div className="container col-md-11 col-lg-7 mx-auto pb-3 px-3 mt-3 mt-lg-5 pt-5 pb-5 px-md-0">
        <div className="row mb-3 mb-lg-5">
          <div className="col-12 col-md-3">
            <h5
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content4
                    : cmspage?.page_content_jp.content4,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.One")} */}
            </h5>
          </div>
          <div
            className="col-12 col-md-9"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content5
                  : cmspage?.page_content_jp.content5,
            }}
          >
            {/* <p>{t("Pricing.Heading.Three.Subheading.One.Paragraph.One")}</p>
            <ul>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Two")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Three")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Four")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Five")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Six")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.One.Paragraph.Seven")}
              </li>
            </ul> */}
          </div>
        </div>
        <hr className="card-line-light-grey"></hr>
        {/* <!-- section 1, airport transfer terms --> */}
        <div className="row mb-3 mb-lg-5 pt-3 mt-md-5 pt-md-0">
          <div className="col-7 col-md-3">
            <h5
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content6
                    : cmspage?.page_content_jp.content6,
              }}
            >
              {/* {t("Pricing.Heading.Three.Subheading.Two")} */}
            </h5>
          </div>
          <div
            className="col-12 col-md-9"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content7
                  : cmspage?.page_content_jp.content7,
            }}
          >
            {/* <p>{t("Pricing.Heading.Three.Subheading.Two.Paragraph.One")}</p>
            <ul>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Two")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Three")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Four")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Five")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Six")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Seven")}{" "}
                &nbsp;
                <span>
                  <Link className={``} to="./pricing">
                    {t(
                      "Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Link"
                    )}
                  </Link>
                </span>
                &nbsp;{" "}
                {t(
                  "Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Part.Two"
                )}
              </li>
            </ul> */}
          </div>
        </div>
        <hr className="card-line-light-grey"></hr>
        {/* <!-- section 1, cancellation policy --> */}
        <div className="row mb-3 mb-lg-5 pt-3 mt-md-5 pt-md-0">
          <div className="col-7 col-md-3">
            <h5
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content8
                    : cmspage?.page_content_jp.content8,
              }}
            >
              {/* {t("Cancellation.Policy")} */}
            </h5>
          </div>
          <div
            className="col-12 col-md-9"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content9
                  : cmspage?.page_content_jp.content9,
            }}
          >
            {/* <p>{t("Pricing.Heading.Three.Subheading.Three.Paragraph.One")}</p>
            <ul>
              <li className="lh-lg">
                {t("Pricing.Heading.Three.Subheading.Three.Paragraph.Two")}{" "}
              </li>
              <li className="lh-lg">
                {t("Pricing.Heading.Three.Subheading.Three.Paragraph.Three")}{" "}
              </li>
              <li className="lh-lg">
                {t("Pricing.Heading.Three.Subheading.Three.Paragraph.Four")}
              </li>
              <li className="lh-lg">
                {t("Pricing.Heading.Three.Subheading.Three.Paragraph.Five")}
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
    </FrontLayout>
  );
};

export default Terms;
