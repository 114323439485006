import React, { useState, useRef, useEffect } from "react";
import GoogleAutoComplete from "../google-input/GoogleAutoComplete";
import MultipleDropOff from "./MultipleDropOff";
import PassengerCard from "../cards/PassengerCard";
import {
  addSingleDestinationFormToState,
  addMultipleDestinationFormToState,
  addAirportFormToState,
  addByTheHourFormToState,
  addSpecifiedDestinationFormToState,
} from "../../../actions/admin/booking";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/ja";
import ja from "date-fns/locale/ja";
import LocationPinIcon from "../../../icons/form-icons/location-pin.svg"
import ClockIcon from "../../../icons/form-icons/clock.svg"
import DateIcon from "../../../icons/form-icons/date.svg"
import PaxIcon from "../../../icons/form-icons/pax.svg"
import LuggageIcon from "../../../icons/form-icons/luggage.svg"
import AirportTransferIcon from "../../../icons/form-icons/airport-transfer.svg"
import SearchVehicleIcon from "../../../icons/form-icons/search-vehicle.svg"
import CustomGoogleAutoComplete from "../../../components/layout-new-theme/google-input/CustomGoogleAutoComplete"

const SpecifiedDestinationForm = ({
  specifiedDestinationForm,
  checkSpecifiedDestinationFormAvailableVehicle,
  cutomerChangedDropOffTimeOnly,
  setcutomerChangedDropOffTimeOnly = null,
  availabilityValueReset,
  formValidationData,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const search = useLocation();
  const [passengerVisible, setPassangeVisible] = useState(false);
  const [lugageVisible, setLugageVisible] = useState(false);
  const [pickuplocation, setpickuplocation] = useState("");
  const [dropofflocations, setdropofflocations] = useState([{}]);
  const [totalPax, setTotalPax] = useState(0);
  const [totalLugage, setTotalLugage] = useState(0);
  const [formatedTravelDate, setFormatedTravelDate] = useState("");
  const [travelDatePicker, setTravelDatePicker] = useState(new Date());
  const [isUseAddDestination, setIsUseAddDestination] = useState(true);
  const [isDisabledWihtoutAirportTransfer, setIsDisabledWithoutAirportTransfer] = useState(null);

  const [specifiedDestinationFormData, setspecifiedDestinationFormData] =
    useState({
      pickuplocation: "",
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      dropOffDateTime: "",
      pax: null,
      lugage: {
        checkinSize: 0,
        cabinSize: 0,
      },
      isAirportTransfer: false,
      isFromAirport: false,
      isToAirport: false
    });
  const settings = useSelector((state) => state.setting);
  const [changeLuggage, setChangeLuggage] = useState(
    specifiedDestinationForm != null ? true : false
  );
  const [changeIsAirport, setChangeIsAirport] = useState(
    specifiedDestinationForm != null ? true : false
  );
  const { lang } = settings;

  const [formDataValidation, setformDataValidation] =
    useState(formValidationData);

  if (lang == "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  registerLocale("ja", ja);

  useEffect(() => {
    setformDataValidation(formValidationData);
  }, [formValidationData]);

  /**
   * * When the page is loading, check if the form data exists. If it exists, then this data will be filled to the input controllers.
   */
  useEffect(() => {
    if (
      specifiedDestinationForm === null ||
      specifiedDestinationForm === undefined
    )
      return;

    setpickuplocation(specifiedDestinationForm.pickuplocation);
    setdropofflocations(specifiedDestinationForm.dropofflocations);
    setTotalPaxValues(specifiedDestinationForm.pax);
    setTotalLugageValues(specifiedDestinationForm.lugage);
    setspecifiedDestinationFormData({
      pickuplocation: specifiedDestinationForm.pickuplocation,
      dropofflocations: specifiedDestinationForm.dropofflocations,
      traveldate: specifiedDestinationForm.traveldate,
      pickuptime: specifiedDestinationForm.pickuptime,
      dropofftime: specifiedDestinationForm.dropofftime,
      dropOffDateTime: specifiedDestinationFormData.dropOffDateTime,
      pax: specifiedDestinationForm.pax,
      lugage: specifiedDestinationForm.lugage,
      isAirportTransfer: specifiedDestinationForm.isAirportTransfer,
      isToAirport: specifiedDestinationForm.isToAirport,
      isFromAirport: specifiedDestinationForm.isFromAirport,
    });
    if (
      specifiedDestinationForm.traveldate != null ||
      specifiedDestinationForm.traveldate != ""
    ) {
      setTravelDatePicker(new Date(specifiedDestinationForm.traveldate));
      setFormatedTravelDate(
        moment(specifiedDestinationForm.traveldate).format(
          "MMM D, yyyy (ddd)"
        )
      );
    }
    
    if(specifiedDestinationForm.isAirportTransfer != null){
      setChangeIsAirport(true);
    }
  }, []);

  /**
   * * set total pax
   */
  const setTotalPaxValues = (formData) => {
    var summed = getTotalPaxCount(formData);
    setTotalPax(summed);
  };

  const setTotalLugageValues = (formData) => {
    var summed = getTotalLuggageCount(formData);
    setTotalLugage(summed);
  };

  /**
   * * Set state from data variable values when changes are made
   */

  const setPaxFormDataToState = (formData) => {
    var summedPax = getTotalPaxCount(formData);
    setTotalPax(summedPax);
    if (summedPax > 0) {
      handleChangeValidation("pax", true);
    } else {
      handleChangeValidation("pax", false);
    }
    setspecifiedDestinationFormData({
      ...specifiedDestinationFormData,
      pax: formData,
    });
  };

  const setLugageFormDataToState = (formData) => {
    setChangeLuggage(true);
    setformDataValidation({
      ...formDataValidation,
      luggage: true,
    });
    var summedLugage = getTotalLuggageCount(formData);
    setTotalLugage(summedLugage);
    setspecifiedDestinationFormData({
      ...specifiedDestinationFormData,
      lugage: formData,
    });
  };

  /**
   * * On-change controller values
   */
  const onChange = (e) => {
    setspecifiedDestinationFormData({
      ...specifiedDestinationFormData,
      [e.target.name]: e.target.value,
    });
    handleChangeValidation(e.target.name, true);
  };

  /**
   * * On-change airport transfer
   */
  const onChangeAirportTransfer = (e, pickupStatus, dropoffStatus) => {
    handleChangeValidation("isAirportTransfer", true);
    setChangeIsAirport(true);
    setspecifiedDestinationFormData({
      ...specifiedDestinationFormData,
      isAirportTransfer: e.target.value === "true",
      isFromAirport: pickupStatus,
      isToAirport: dropoffStatus
    });

    setIsUseAddDestination(true);
  };

  const onChangeAirportTransferMobile = (value) => {
    handleChangeValidation("isAirportTransfer", true);
    setChangeIsAirport(true);
    setspecifiedDestinationFormData({
      ...specifiedDestinationFormData,
      isAirportTransfer: value === "true",
    });
    console.log(value)
    console.log(value === "true")

    // disable add destination if selected yes
    if (value === "true") {
      const onlyFirstDropofflocations = dropofflocations.slice(0, 1);
      setdropofflocations(onlyFirstDropofflocations);
      setIsUseAddDestination(false);
    } else {
      setIsUseAddDestination(true);
    }
  };

  /**
   * * Use for pax popup as a reference element
   */
  useOutsideAlerter(wrapperRef);

  /**
   * * PAX selection popup closing function. Click anywhere outside the popup modal to close it.
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassangeVisible(false);
          setLugageVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * * Search for available vehicles
   */
  const searchAvailabeVehicles = () => {
    const status = setFormValidationValues();
    if (search.pathname === "/") {
      if (!status) {
        const mfdObj = specifiedDestinationFormData;
        mfdObj.pickuplocation = pickuplocation;
        mfdObj.dropofflocations = dropofflocations;
        if(changeIsAirport == false){
          mfdObj.isAirportTransfer = false;
        }
        setSpecificDestination(mfdObj);
      }
    } else {
      if (!status) {
        const mfdObj = specifiedDestinationFormData;
        mfdObj.pickuplocation = pickuplocation;
        mfdObj.dropofflocations = dropofflocations;
        if(changeIsAirport == false){
          mfdObj.isAirportTransfer = false;
        }
        setSpecificDestination(mfdObj);
        checkSpecifiedDestinationFormAvailableVehicle(mfdObj);
        const dataToPush = {
          event: "search",
          search_term: "Search Available Vehicles",
        };
        console.log(dataToPush);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
      }
    }
  };

  /**
   * * Form validation
   */
  const setFormValidationValues = () => {
    let status = true;
    if (
      pickuplocation === "" ||
      dropofflocations[0].place_id === undefined ||
      specifiedDestinationFormData.traveldate === "" ||
      specifiedDestinationFormData.pickuptime === "" ||
      getTotalPaxCount(specifiedDestinationFormData.pax) === 0 ||
      // changeIsAirport === false ||
      changeLuggage === false
    ) {
      status = true;
      setformDataValidation({
        pickuplocation: pickuplocation === "" ? false : true,
        dropofflocations:
          dropofflocations[0].place_id === undefined ? false : true,
        traveldate:
          specifiedDestinationFormData.traveldate === "" ? false : true,
        pickuptime:
          specifiedDestinationFormData.pickuptime === "" ? false : true,
        pax:
          getTotalPaxCount(specifiedDestinationFormData.pax) === 0
            ? false
            : true,
        // isAirportTransfer: changeIsAirport === false ? false : true,
        luggage: changeLuggage === false ? false : true,
      });
    } else {
      status = false;
    }

    return status;
  };

  /**
   * * Set multi-destination state and dispatch it to the redux store
   */
  const setSpecificDestination = (specifiedDestinationFormData) => {
    const specifiedDestinationFormDataObj = {
      pickuplocation: specifiedDestinationFormData.pickuplocation
        ? specifiedDestinationFormData.pickuplocation
        : pickuplocation,
      dropofflocations: specifiedDestinationFormData.dropofflocations
        ? specifiedDestinationFormData.dropofflocations
        : dropofflocations,
      traveldate: specifiedDestinationFormData.traveldate,
      pickuptime: specifiedDestinationFormData.pickuptime,
      dropofftime: specifiedDestinationFormData.dropofftime,
      dropOffDateTime: specifiedDestinationFormData.dropOffDateTime,
      pax: specifiedDestinationFormData.pax,
      lugage: specifiedDestinationFormData.lugage,
      isAirportTransfer: specifiedDestinationFormData.isAirportTransfer,
      isFromAirport: specifiedDestinationFormData.isFromAirport,
      isToAirport: specifiedDestinationFormData.isToAirport,
    };
    dispatch(
      addSpecifiedDestinationFormToState(specifiedDestinationFormDataObj)
    );
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addAirportFormToState(null));
    dispatch(addByTheHourFormToState(null));
    history.push("/book");
  };

  const checkIsAirport = (pickupLocation, dropoffLocations) => {
    var pickUpStatus = false;
    var dropoffStatus = false;

    if (pickupLocation) {
      if (pickupLocation.types.includes("airport")) pickUpStatus =  true;

      const trimmedPlace = pickupLocation.formatted_address.toLowerCase().split(",");

      for (let index = 0; index < trimmedPlace.length; index++) {
        if (trimmedPlace[index].includes("haneda") && trimmedPlace[index].includes("airport") || trimmedPlace[index].includes("羽田") && trimmedPlace[index].includes("空港")) {
          pickUpStatus =  true;
        }

        if (trimmedPlace[index].includes("narita") && trimmedPlace[index].includes("airport") || trimmedPlace[index].includes("成田") && trimmedPlace[index].includes("空港")) {
          pickUpStatus =  true;
        }

      }
    }

    if (dropofflocations[dropofflocations.length - 1].address_components) {
        if (dropoffLocations[dropofflocations.length - 1].types.includes("airport")) dropoffStatus = true;

        const trimmedPlaceDropoff = dropoffLocations[dropofflocations.length - 1].formatted_address.toLowerCase().split(",");

        for (let ind = 0; ind < trimmedPlaceDropoff.length; ind++) {
          if (trimmedPlaceDropoff[ind].includes("haneda") && trimmedPlaceDropoff[ind].includes("airport") || trimmedPlaceDropoff[ind].includes("羽田") && trimmedPlaceDropoff[ind].includes("空港")) {
            dropoffStatus = true;
          }

          if (trimmedPlaceDropoff[ind].includes("narita") && trimmedPlaceDropoff[ind].includes("airport") || trimmedPlaceDropoff[ind].includes("成田") && trimmedPlaceDropoff[ind].includes("空港")) {
            dropoffStatus = true;
          }

        }
    }

    return {
      check: pickUpStatus || dropoffStatus,
      pickupStatus: pickUpStatus,
      dropoffStatus: dropoffStatus,
    }

  }

  useEffect(() => {
    if (!pickuplocation && !dropofflocations[dropofflocations.length - 1].address_components) return;

    const {check, pickupStatus, dropoffStatus} = checkIsAirport(pickuplocation, dropofflocations);

    if (check) {
      const e = {
        target: {
          value: "true"
        }
      }

      onChangeAirportTransfer(e, pickupStatus, dropoffStatus)
      setIsDisabledWithoutAirportTransfer(true)
    } else {
      setIsDisabledWithoutAirportTransfer(false)
    }
  }, [pickuplocation, dropofflocations])

  /**
   * * Set pickup location
   * Note: c.types[0].includes("administrative_area_level_1") => "administrative area level 1 is where the city is returned in the API call"
   */
  const pickupLocationSelected = async (place) => {
    if (place === undefined) return;

    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    place.lat = place.geometry.location.lat;
    place.lon = place.geometry.location.lng;
    place.label = place.formatted_address;
    place.formatted_address = place.formatted_address;
    place.value = place.place_id;
    place.city = city;
    place.types = place.types;
    setpickuplocation(place);
    availabilityValueReset();
    handleChangeValidation("pickuplocation", true);
  };

  /**
   * * Set multiple destinations
   */
  const setDestinations = (places) => {
    console.log("places==========")
    console.log(places)
    setdropofflocations(places);
    availabilityValueReset();
  };

  /**
   * * Show and hide pax panel / modal
   */
  const setPaxAndLuggaePannalVisibility = (status) => {
    setPassangeVisible(status);
  };

  const handleEmptyValue = () => {
    setpickuplocation("");
  };

  const handleChangeDropoffValidation = (condition) => {
    setformDataValidation((formDataValidation) => ({
      ...formDataValidation,
      dropofflocations: condition,
    }));
  };

  const handleChangeValidation = (key, condition) => {
    setformDataValidation((formDataValidation) => ({
      ...formDataValidation,
      [key]: condition,
    }));
  };

  const dateTimeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  return (
    <div className="bg-white p-3 p-md-3 mb-5 card-form-custom">
      {/* FIRST LINE INPUT */}
      <div className="row g-3 mb-3">
        {/* PICKUP ADDRESS */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
          <div className="form-floating position-relative">
          <CustomGoogleAutoComplete
              id="pickup1"
              placeholder="Pick-up: Airport, Hotel, Attraction, etc. "
              onPlaceSelected={pickupLocationSelected}
              defaultValue={
                specifiedDestinationFormData.pickuplocation?.formatted_address
              }
              invalidText={"SpecifiedDestination.Pickup.Specific.Validation"}
              isInvalid={formDataValidation.pickuplocation ? false : true}
            />
            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
              <div className="d-flex justify-content-center">
                <img src={LocationPinIcon} />
              </div>
            </span>
            <label htmlFor="pickUpAddress" className="label-icon">
              {t("Pick.Up.Address")}
            </label>
          </div>
          {!formDataValidation.pickuplocation ? (
            <p className="text-danger">
              {t("SpecifiedDestination.Pickup.Specific.Validation")}
            </p>
          ) : (
            ""
          )}
        </div>
        {/* DESTINATION DROP DESKTOP */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
          <MultipleDropOff
            isUseAddDestination={true}
            setPaxPanel={setPaxAndLuggaePannalVisibility}
            setDestinations={setDestinations}
            dropofflocations={dropofflocations}
            isNotValidFirstLocation={
              formDataValidation.dropofflocations ? false : true
            }
            changeValidation={handleChangeDropoffValidation}
          />
        </div>


        {/* TRAVEL DATE */}

        <div className="col-md-6">
          <DatePicker
            locale={lang == "EN" ? "en" : "ja"}
            selected={travelDatePicker}
            minDate={new Date()}
            calendarContainer={dateTimeContainer}
            onSelect={(date) => {
              setTravelDatePicker(date);
              var formatedDate = date.toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              var momentDate = date.toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              var tge = moment(momentDate);
              if (lang == "EN") {
                setFormatedTravelDate(tge.format("MMM D, yyyy (ddd)"));
              } else {
                setFormatedTravelDate(tge.format("YYYY年M月D日（ddd）"));
              }
              availabilityValueReset();
              handleChangeValidation("traveldate", true);
              setspecifiedDestinationFormData({
                ...specifiedDestinationFormData,
                traveldate: formatedDate,
              });
            }}
            customInput={
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  autocomplete="off"
                  type="text"
                  name="traveldate"
                  className={
                    formDataValidation.traveldate
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  style={{
                    borderColor: !formDataValidation.traveldate ? "red" : "",
                    color: specifiedDestinationFormData.traveldate
                      ? "#282828"
                      : "",
                    fontWeight: specifiedDestinationFormData.traveldate
                      ? "bold"
                      : "",
                  }}
                  placeholder="Travel date"
                  aria-label="Travel date"
                  defaultValue={formatedTravelDate}
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img src={DateIcon} />
                  </div>
                </span>
                <label htmlFor="travelDate" className="label-icon">
                  {t("Travel.Date")}
                </label>
              </div>
            }
          />

        {formDataValidation.traveldate ? (
          ""
        ) : (
          <p className="text-danger">
            {t("SpecifiedDestination.TravelDate.Validation")}
          </p>
        )}
        </div>

        {/* PICKUP TIME */}
        <div className="col-md-6 ">
          <DatePicker
            locale={lang == "EN" ? "en" : "ja"}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption={t("Pick.Up.Time")}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            calendarContainer={dateTimeContainer}
            onChange={(date) => {
              var formatedDate = date.toLocaleDateString("sv-SE", {
                hour: '2-digit',
                minute: '2-digit'
              });

              var tge = moment(formatedDate);
              setspecifiedDestinationFormData({
                ...specifiedDestinationFormData,
                pickuptime: tge.format("HH:mm"),
              });
              handleChangeValidation("pickuptime", true);
              availabilityValueReset();
            }}
            customInput={
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  type="text"
                  name="pickuptime"
                  style={{
                    borderColor: !formDataValidation.pickuptime ? "red" : "",
                    color: specifiedDestinationFormData.pickuptime
                      ? "#282828"
                      : "",
                    fontWeight: specifiedDestinationFormData.pickuptime
                      ? "bold"
                      : "",
                  }}
                  onChange={(e) => {
                    // onChange(e);
                    availabilityValueReset();
                  }}
                  className={
                    formDataValidation.pickuptime
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  placeholder="Pick-up time"
                  aria-label="Pick-up time"
                  value={specifiedDestinationFormData.pickuptime}
                />
                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img src={ClockIcon} />
                  </div>
                </span>
                <label htmlFor="pickUpTime" className="label-icon">
                  {t("Pick.Up.Time")}
                </label>
              </div>
            }
          />

          {!formDataValidation.pickuptime && (
            <p className="text-danger">
              {t("AirportTransfer.PickupTime.Required")}
            </p>
          )}
        </div>

        {/* PAX AND LUGAGE */}
        <div
          ref={wrapperRef}
          className="col-lg-12 col-md-12 col-sm-12 position-relative "
        >
          <div className="row">
            {/* PASSENGER FORM */}
            <div className="col pe-1 ">
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  onClick={() => {
                    setPassangeVisible(!passengerVisible);
                    setLugageVisible(false);
                  }}
                  style={{
                    color: totalPax > 0 ? "#282828" : "",
                    fontWeight: totalPax > 0 ? "bold" : "",
                  }}
                  name="pax"
                  type="number"
                  id="passengers"
                  className={
                    formDataValidation.pax
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  placeholder="Passengers"
                  aria-label="Passengers"
                  min={0}
                  value={totalPax ?? 0}
                />
                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img src={PaxIcon} />
                  </div>
                </span>
                <label for="floatingInput" className="label-icon">
                  {t("Passengers")}
                </label>
              </div>

              {!formDataValidation.pax && (
                <p className="text-danger">
                  {t("SpecifiedDestination.Passenger.Validation")}
                </p>
              )}
            </div>
            {/* LUGGAGE FORM */}
            <div className="col ">
              <div className="form-floating position-relative">
                <input
                  readOnly={false}
                  onClick={() => {
                    setLugageVisible(!lugageVisible);
                    setPassangeVisible(false);
                  }}
                  name="lugage"
                  style={{
                    color: totalLugage > 0 ? "#282828" : "",
                    fontWeight: totalLugage > 0 ? "bold" : "",
                  }}
                  type={
                    (formDataValidation.luggage == null &&
                      changeLuggage == false) ||
                      (formDataValidation.luggage == false &&
                        changeLuggage == false)
                      ? "text"
                      : "number"
                  }
                  id="passengers"

                  className={
                    formDataValidation.luggage ||
                    formDataValidation.luggage == null ||
                    changeLuggage == true
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  placeholder="Lugages"
                  aria-label="Lugages"
                  min={0}
                  value={
                    (formDataValidation.luggage == null &&
                      changeLuggage == false) ||
                      (formDataValidation.luggage == false &&
                        changeLuggage == false)
                      ? "-"
                      : totalLugage ?? 0
                  }
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                  <img src={LuggageIcon} />
                  </div>
                </span>
                {lugageVisible === true && (
                  <PassengerCard
                    setPaxPanel={setPaxAndLuggaePannalVisibility}
                    setFormDataToState={setLugageFormDataToState}
                    passengerFormData={specifiedDestinationFormData.lugage}
                    passangerForm={false}
                    luggageForm={true}
                  />
                )}
                {passengerVisible === true && (
                  <PassengerCard
                    setPaxPanel={setPaxAndLuggaePannalVisibility}
                    setFormDataToState={setPaxFormDataToState}
                    passengerFormData={specifiedDestinationFormData.pax}
                    passangerForm={true}
                    luggageForm={false}
                  />
                )}

                <label className="label-icon">{t("Luggage")}</label>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* LAST LINE (BUTTON)*/}
      <div className="d-grid ">
        <button
          type="button"
          className="btn btn-primary btn-sm rounded-3 px-4 d-flex justify-content-center px-md-5 py-2 fs-14"
          onClick={searchAvailabeVehicles}
        >
          <div className="d-flex align-items-center px-2 d-inline text-white">
            {t("Search.Available.Vehicles")}
            <i className="ps-2">
              <img src={SearchVehicleIcon} />
            </i>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SpecifiedDestinationForm;
