import React, { useState, useRef, useEffect } from "react";
import GoogleAutoComplete from "../google-input/GoogleAutoComplete";
import { useDispatch } from "react-redux";
import {
  addSingleDestinationFormToState,
  addMultipleDestinationFormToState,
  addAirportFormToState,
  addByTheHourFormToState,
} from "../../../actions/admin/booking";
import PassengerCard from "../cards/PassengerCard";
import {
  getLocationByPlaceid
} from "../../../actions/googleAPI";
import { useHistory, useLocation } from "react-router-dom";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getRouteCarForTotalTime } from "../../../actions/navitimeAPI";
import { withAlert, types } from "react-alert";
import moment from "../../../utils/customMoment";

const ByTheHourEditBookingForm = ({
  byTheHourForm = null,
  checkByTheHourFormAvailableVehicle,
  cutomerChangedDropOffTimeOnly,
  setcutomerChangedDropOffTimeOnly = null,
  changeCustomerDropOffValue,
  getTimeDifferenceIN30MinRounded,
  availabilityValueReset,
  setDisableButton,
  handleCloseModal,
  // checkTotalTime,
}) => {
  const { t } = useTranslation();
  let history = useHistory();
  const search = useLocation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [passengerVisible, setPassangeVisible] = useState(false);
  const [lugageVisible, setLugageVisible] = useState(false);
  const [pickuplocation, setpickuplocation] = useState();
  const [pickuptime, setpickuptime] = useState("");
  const [dropofflocations, setdropofflocations] = useState([]);
  const [halfHourIntervalsData, setHalfHourIntervalsData] = useState([])
  const [totalPax, setTotalPax] = useState(0);
  const [totalLugage, setTotalLugage] = useState(0);
  const [byTheHourFormData, setByTheHourData] = useState({
    pickuplocation: "",
    dropofflocations: [],
    traveldate: "",
    pickuptime: "",
    dropofftime: "",
    pax: null,
    lugage: {
      checkinSize: 0,
      cabinSize: 0
    },
    duration: "",
    byTheHourBook: true,
    isFromAirport: false,
    isToAirport: false,
  });
  const currentDate = new Date().toISOString().slice(0, 10);
  const [durationTime, setDurationTime] = useState("");
  const [formDataValidation, setformDataValidation] = useState({
    pickuplocation: true,
    dropofflocations: true,
    traveldate: true,
    pickuptime: true,
    pax: true,
    duration: true,
  });
  const [dropOffTimeToShow, setDropOffTimeToShow] = useState("");

  useEffect(() => {
    if (byTheHourForm === null) return;
    setpickuplocation(byTheHourForm.pickuplocation);
    setdropofflocations(byTheHourForm.dropofflocations);
    setTotalPaxValues(byTheHourForm.pax);
    setTotalLugageValues(byTheHourForm.lugage);
    const sfdObj = byTheHourFormData;
    sfdObj.pickuplocation = byTheHourForm.pickuplocation;
    sfdObj.dropofflocations = byTheHourForm.dropofflocations;
    sfdObj.traveldate = byTheHourForm.traveldate;
    sfdObj.pickuptime = byTheHourForm.pickuptime;
    sfdObj.dropofftime = byTheHourForm.dropofftime;
    sfdObj.pax = byTheHourForm.pax;
    sfdObj.lugage = byTheHourForm.lugage;
    sfdObj.duration = byTheHourForm.duration;
    // setTotalPax(byTheHourForm.pax);
    // setTotalLugage(byTheHourForm.lugage);
    if (byTheHourForm.dropofflocations.length != 0 && byTheHourForm.pickuplocation != "") {
      checkTotalTime(byTheHourForm);
    }
  }, []);

  const setPaxAndLuggaePannalVisibility = (status) => {
    setPassangeVisible(status);
    setLugageVisible(status);
  };

  const searchAvailableVehicles = async () => {
    const st = setFormValidationValues();
    if (search.pathname === "/") {
      if (st) setByTheHourForm();
    } else {
      const isToAirport = await checkToAndFromAirport(dropofflocations[0].value);
      const isFromAirport = await checkToAndFromAirport(pickuplocation.value);

      const sfdObj = byTheHourFormData;
      sfdObj.pickuplocation = pickuplocation;
      sfdObj.dropofflocations = dropofflocations;
      sfdObj.isFromAirport = isFromAirport;
      sfdObj.isToAirport = isToAirport;
      checkByTheHourFormAvailableVehicle(sfdObj);
    }
  };

  const setFormValidationValues = () => {
    let status = true;
    if (
      pickuplocation === undefined ||
      dropofflocations.length === 0 ||
      byTheHourFormData.traveldate === "" ||
      byTheHourFormData.pickuptime === "" ||
      getTotalPaxCount(byTheHourFormData.pax) === 0 ||
      byTheHourFormData.duration === ""
    )
      status = false;
    else status = true;
    setformDataValidation({
      pickuplocation: pickuplocation !== undefined ? true : false,
      dropofflocations: dropofflocations.length > 0 ? true : false,
      traveldate: byTheHourFormData.traveldate !== "" ? true : false,
      pickuptime: byTheHourFormData.pickuptime !== "" ? true : false,
      pax: getTotalPaxCount(byTheHourFormData.pax) > 0 ? true : false,
      duration: byTheHourFormData.duration !== "" ? true : false,
    });
    return status;
  };

  const checkToAndFromAirport = async (location) => {
    //check airport
    const resultGeocode = await getLocationByPlaceid(location);

    for (const result of resultGeocode.address_components) {
      const types = result.types;
      if (types.includes('airport')) {
        return true
      } else {
        return false
      }
    }
    if (resultGeocode.formatted_address.includes("Airport")) {
      return true
    } else {
      return false
    }
  }

  const setByTheHourForm = async () => {
    const isToAirport = await checkToAndFromAirport(dropofflocations[0].value);
    const isFromAirport = await checkToAndFromAirport(pickuplocation.value);
    const byTheHourDataObj = {
      pickuplocation: pickuplocation,
      dropofflocations: dropofflocations,
      traveldate: byTheHourFormData.traveldate,
      pickuptime: byTheHourFormData.pickuptime,
      dropofftime: byTheHourFormData.dropofftime,
      pax: byTheHourFormData.pax,
      lugage: byTheHourFormData.lugage,
      duration: byTheHourFormData.duration,
      byTheHourBook: true,
      isFromAirport: isFromAirport,
      isToAirport: isToAirport,
    };
    dispatch(addByTheHourFormToState(byTheHourDataObj));
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addAirportFormToState(null));
    history.push("/book");
  };

  const setTotalPaxValues = (formData) => {
    var summed = getTotalPaxCount(formData);
    setTotalPax(summed);
  };

  const setTotalLugageValues = (formData) => {
    var summed = getTotalLuggageCount(formData);
    setTotalLugage(summed);
  };

  const setPaxFormDataToState = (formData) => {
    var summedPax = getTotalPaxCount(formData);
    setTotalPax(summedPax);
    setByTheHourData({ ...byTheHourFormData, pax: formData });
  };

  const setLugageFormDataToState = (formData) => {
    var summedLugage = getTotalLuggageCount(formData);
    setTotalLugage(summedLugage);
    setByTheHourData({ ...byTheHourFormData, lugage: formData });
  };

  const onChange = (e) => {
    if (e.target.name == "pax") {
      let pax = e.target.value
      if (e.target.value < 0) {
        pax = 0
      }
      setTotalPax(pax)
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: pax,
      });
    } else if (e.target.name == "lugage") {
      let lugage = e.target.value
      if (e.target.value < 0) {
        lugage = 0
      }
      setTotalLugage(lugage)
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: lugage,
      });
    } else if (e.target.name == "pickuptime") {
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: e.target.value,
      });

      if (byTheHourFormData.dropofflocations.length > 0 && byTheHourFormData.pickuplocation != "") {
        estimateTimeToDestination(byTheHourFormData, e.target.value)
      }
    } else {
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassangeVisible(false);
          setLugageVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleDropoffEmpty = () => {
    setdropofflocations([]);
  };

  const handlePickupEmpty = () => {
    setpickuplocation("");
  };

  const pickupLocationSelected = async (place) => {
    if (place === undefined) return;
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    place.lat = place.geometry.location.lat();
    place.lon = place.geometry.location.lng();
    place.label = place.formatted_address;
    place.value = place.place_id;
    place.city = city;
    setpickuplocation(place);
  };

  const dropOffLocationSelected = async (place) => {
    if (place === undefined) return;
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    place.lat = place.geometry.location.lat();
    place.lon = place.geometry.location.lng();
    place.label = place.formatted_address;
    place.value = place.place_id;
    place.city = city;
    setdropofflocations([place]);
  };

  function populateDurationSelect(minimal_duration) {
    setHalfHourIntervalsData([])
    const start_from = Math.round(minimal_duration / 30);
    const halfHourIntervals = Array.from({ length: 49 }, (_, i) => {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30;
      const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      return {
        value: time,
        label: time
      };
    });
    const final_hour_data = halfHourIntervals.slice(start_from + 2)
    setHalfHourIntervalsData(final_hour_data)
  }

  /**
   * * If the user changes the airport selection dropdown list, this funtion will fire.
   * * Set state variables
   */
  const onChangeDuration = (e) => {
    setDurationTime(e.value);
    setByTheHourData({ ...byTheHourFormData, duration: e.value });
  };

  /**
 * * Function to get minimal duration count by the selected pickuplocation and dropofflocation. Fire when both pickup and dropoff location is filled.
 * ? sfd - Selected Form Data
 * @param sfd
 */
  const checkTotalTime = async (sfd) => {
    availabilityValueReset();
    const start = sfd.pickuplocation.lat + "%2C" + sfd.pickuplocation.lon
    const end = sfd.dropofflocations[0].lat + "%2C" + sfd.dropofflocations[0].lon

    const car_r = await getRouteCarForTotalTime(
      start,
      end
    );

    const {
      total_time
    } = getSelectedRouteData(car_r);

    populateDurationSelect(total_time);

  };

  /**
   * * Get the selected route's data
   * @param total_time - return parameter - total time in minutes
   */
  const getSelectedRouteData = (car_r) => {
    const summary_arr = car_r[car_r.length - 1].summary;

    const total_time = summary_arr.move.time;

    return {
      total_time,
    };
  };

  /**
* * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
* ? sfd - Selected Form Data
* ? timeString - pickup time in string 'HH:MM'
* @param sfd
* @param timeString - not required
*/
  const estimateTimeToDestination = async (sfd, timeString) => {
    availabilityValueReset();
    const start = sfd.pickuplocation.lat + "%2C" + sfd.pickuplocation.lon
    const end = sfd.dropofflocations[0].lat + "%2C" + sfd.dropofflocations[0].lon

    const car_r = await getRouteCarForTotalTime(
      start,
      end
    );

    const {
      total_time
    } = getSelectedRouteData(car_r);

    const timeToAdd = timeString === undefined ? sfd.pickuptime : timeString;

    const [hours, minutes] = timeToAdd.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    const newTotalMinutes = (totalMinutes + total_time) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    setDropOffTimeToShow(`${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`)
  };

  useEffect(() => {
    if (byTheHourFormData.dropofflocations.length > 0 && byTheHourFormData.pickuplocation != "" && byTheHourFormData.pickuptime != "") {
      estimateTimeToDestination(byTheHourFormData)
    }
    if (byTheHourFormData.dropofflocations.length > 0 && byTheHourFormData.pickuplocation != "") {
      checkTotalTime(byTheHourFormData);
    }
  }, [byTheHourFormData.dropofflocations, byTheHourFormData.pickuplocation, byTheHourFormData.pickuptime]);

  useEffect(() => {
    setByTheHourData({
      ...byTheHourFormData,
      pickuplocation: pickuplocation,
      dropofflocations: dropofflocations,
    });
  }, [pickuplocation, dropofflocations]);

  return (
    <div className={`bg-white mt-3`}>
      {/* FIRST LINE INPUT */}
      <div className="row g-3 mb-3">
        {/* PICKUP ADDRESS */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 form-floating">
          <GoogleAutoComplete
            isNotValid={formDataValidation.pickuplocation ? false : true}
            locationOnPlaceSelected={(l) => {
              pickupLocationSelected(l);
              availabilityValueReset();
            }}
            emptyValue={handlePickupEmpty}
            id="pickup1single"
            placeholder="Pick-up address"
            ariaLabel="Pick-up address"
            // defaultValue={pickuplocation.formatted_address}
            defaultValue={
              byTheHourFormData?.pickuplocation?.formatted_address !== undefined
                ? byTheHourFormData?.pickuplocation?.formatted_address
                : byTheHourForm?.pickuplocation?.formatted_address !== undefined
                  ? byTheHourForm?.pickuplocation?.formatted_address
                  : ""
            }
          />
          <label htmlFor="pickUpAddress">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.06 16.5C5.31857 16.0509 4.68539 15.469 4.06732 14.8716C2.78586 13.6332 1.69952 12.2486 0.929135 10.6349C0.465897 9.66519 0.144903 8.65128 0.040842 7.58144C-0.213436 4.96757 0.715559 2.88715 2.94154 1.43996C4.09837 0.687775 5.3941 0.399756 6.77248 0.530408C9.56156 0.794634 11.8035 2.81076 12.3292 5.52858C12.5424 6.63098 12.4434 7.72503 12.169 8.80573C11.7825 10.3268 11.0444 11.6763 10.102 12.9206C9.11973 14.2176 7.97128 15.3508 6.66339 16.3234C6.57317 16.3906 6.47079 16.4416 6.37428 16.5H6.06ZM11.2177 6.74535C11.1863 6.44606 11.1716 6.14343 11.1208 5.84748C10.6286 2.98858 7.79295 1.1628 4.9766 1.88994C2.65831 2.4881 0.992075 4.63989 1.25978 7.3072C1.37139 8.41878 1.71547 9.45899 2.23871 10.4412C3.19959 12.2444 4.55196 13.7204 6.12294 15.0123C6.1926 15.0695 6.24043 15.0708 6.3105 15.0136C7.79966 13.7914 9.09287 12.3989 10.0449 10.7167C10.7419 9.4857 11.1833 8.17668 11.2173 6.74535H11.2177Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.22103 3.34473C8.13315 3.34682 9.69952 4.90462 9.69616 6.80179C9.6928 8.70187 8.12686 10.2534 6.2139 10.2522C4.30262 10.2509 2.73584 8.69186 2.73877 6.79469C2.74213 4.89502 4.3085 3.34306 6.22103 3.34473ZM6.22103 9.01911C7.47018 9.01452 8.45205 8.02733 8.44911 6.77841C8.44617 5.55788 7.44668 4.57402 6.21348 4.57778C4.95762 4.58154 3.98079 5.57082 3.98582 6.83351C3.99086 8.04027 4.99622 9.0237 6.22145 9.01911H6.22103Z"
                    fill="#DBBE78"
                  />
                </svg>
              </i>
              {t("Pick.Up.Address")}
            </div>
          </label>
        </div>

        {/* DROPOFF ADDRESS MOBILE */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-lg-none d-sm-block form-floating">
          <GoogleAutoComplete
            id="dropoff1"
            isNotValid={formDataValidation.dropofflocations ? false : true}
            locationOnPlaceSelected={(l) => {
              dropOffLocationSelected(l);
              availabilityValueReset();
            }}
            emptyValue={handleDropoffEmpty}
            placeholder="Drop-off address"
            ariaLabel="Drop-off address"
            defaultValue={
              byTheHourFormData?.dropofflocations[0]?.formatted_address !== undefined
                ? byTheHourFormData?.dropofflocations[0]?.formatted_address
                : byTheHourForm?.dropofflocations[0]?.formatted_address !== undefined
                  ? byTheHourForm?.dropofflocations[0]?.formatted_address
                  : ""
            }
          />
          <label htmlFor="dropOffAddress">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.06 16.5C5.31857 16.0509 4.68539 15.469 4.06732 14.8716C2.78586 13.6332 1.69952 12.2486 0.929135 10.6349C0.465897 9.66519 0.144903 8.65128 0.040842 7.58144C-0.213436 4.96757 0.715559 2.88715 2.94154 1.43996C4.09837 0.687775 5.3941 0.399756 6.77248 0.530408C9.56156 0.794634 11.8035 2.81076 12.3292 5.52858C12.5424 6.63098 12.4434 7.72503 12.169 8.80573C11.7825 10.3268 11.0444 11.6763 10.102 12.9206C9.11973 14.2176 7.97128 15.3508 6.66339 16.3234C6.57317 16.3906 6.47079 16.4416 6.37428 16.5H6.06ZM11.2177 6.74535C11.1863 6.44606 11.1716 6.14343 11.1208 5.84748C10.6286 2.98858 7.79295 1.1628 4.9766 1.88994C2.65831 2.4881 0.992075 4.63989 1.25978 7.3072C1.37139 8.41878 1.71547 9.45899 2.23871 10.4412C3.19959 12.2444 4.55196 13.7204 6.12294 15.0123C6.1926 15.0695 6.24043 15.0708 6.3105 15.0136C7.79966 13.7914 9.09287 12.3989 10.0449 10.7167C10.7419 9.4857 11.1833 8.17668 11.2173 6.74535H11.2177Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.22103 3.34473C8.13315 3.34682 9.69952 4.90462 9.69616 6.80179C9.6928 8.70187 8.12686 10.2534 6.2139 10.2522C4.30262 10.2509 2.73584 8.69186 2.73877 6.79469C2.74213 4.89502 4.3085 3.34306 6.22103 3.34473ZM6.22103 9.01911C7.47018 9.01452 8.45205 8.02733 8.44911 6.77841C8.44617 5.55788 7.44668 4.57402 6.21348 4.57778C4.95762 4.58154 3.98079 5.57082 3.98582 6.83351C3.99086 8.04027 4.99622 9.0237 6.22145 9.01911H6.22103Z"
                    fill="#DBBE78"
                  />
                </svg>
              </i>
              {t("Drop.Off.Address")}
            </div>
          </label>
        </div>

        {/* USAGE DURATION MOBILE */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-lg-none d-sm-block form-floating">
          <Select
            // type="select"
            name="duration"
            classNamePrefix="react-select-time"
            className={formDataValidation.duration ? "" : "invalid"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#dbbe78",
                height: "calc(3.5rem + 2px)",
                fontSize: "0.75rem",
                color: byTheHourFormData.duration ? "#282828" : "",
                fontWeight: byTheHourFormData.duration ? "bolder" : "",
              }),
              control: (baseStyles, isActive) => ({
                ...baseStyles,
                paddingTop: "",
                paddingLeft: "8px",
                paddingBotton: "0.75rem",
                border: "none",
                borderBottom: "1px solid #DBBE78",
                borderColor: "#dbbe78",
                height: "calc(3.5rem + 2px)",
                borderRadius: "0px",
                background: "rgba(167, 167, 167, 0.1)",
                fontSize: "0.75rem",
                color: byTheHourFormData.duration ? "#282828" : "",
                fontWeight: byTheHourFormData.duration ? "bolder" : "",
              }),
            }}
            autoComplete="off"
            placeholder={byTheHourFormData?.duration ? byTheHourFormData?.duration : t("Usage Duration (e.g., 2 hours)")}
            // aria-label="Duration time"
            defaultValue={byTheHourFormData?.duration !== ""
              ? byTheHourFormData?.duration
              : byTheHourForm?.duration !== ""
                ? byTheHourForm?.duration
                : ""}
            onChange={(e) => {
              onChangeDuration(e);
              setDurationTime(e)
              availabilityValueReset();
            }}
            options={halfHourIntervalsData}
          />
        </div>

        {/* TRAVEL DATE */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl form-floating">
          <input
            type="date"
            id="date"
            name="traveldate"
            min={currentDate}
            onChange={(e) => {
              onChange(e);
              availabilityValueReset();
            }}
            className={
              formDataValidation.traveldate
                ? "form-control"
                : "form-control invalid"
            }
            style={{
              borderColor: !formDataValidation.traveldate ? "red" : "",
              color: byTheHourFormData.traveldate ? "#282828" : "",
              fontWeight: byTheHourFormData.traveldate ? "bold" : "",
            }}
            placeholder="Travel date"
            aria-label="Travel date"
            defaultValue={byTheHourFormData?.traveldate !== ""
              ? byTheHourFormData?.traveldate
              : byTheHourForm?.traveldate !== ""
                ? byTheHourForm?.traveldate
                : ""}
          />
          <label htmlFor="travelDate">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.32109 0.506328C10.7789 0.506328 13.2363 0.514738 15.6937 0.500021C16.2467 0.496657 16.6293 0.904545 16.6149 1.37929C16.5917 2.14461 16.6096 2.91077 16.6083 3.67692C16.6075 4.15882 16.2554 4.50237 15.7588 4.50237C10.789 4.50279 5.81959 4.50279 0.849777 4.50237C0.352272 4.50237 0.00109198 4.15966 0.000655187 3.6765C-0.000218396 2.89479 -0.000218396 2.11307 0.000655187 1.33136C0.00152877 0.84231 0.353582 0.506749 0.866812 0.506749C3.35172 0.506328 5.83662 0.506749 8.32109 0.506749V0.506328Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.2393 6.10265C6.52802 6.10265 6.81674 6.09971 7.10589 6.10349C7.35006 6.10644 7.47848 6.22838 7.48022 6.46471C7.48372 7.02061 7.48372 7.57694 7.48022 8.13284C7.47891 8.37758 7.34962 8.50162 7.09716 8.50289C6.51404 8.50541 5.93092 8.50541 5.34825 8.50289C5.10583 8.50162 4.9761 8.37505 4.97479 8.14083C4.97217 7.57946 4.97173 7.01809 4.97479 6.45672C4.9761 6.23133 5.10626 6.10686 5.34038 6.10391C5.64002 6.09971 5.93966 6.10307 6.2393 6.10265Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M10.3777 8.50379C10.0833 8.50379 9.78936 8.50674 9.49496 8.50295C9.26128 8.50001 9.12893 8.37512 9.12762 8.15099C9.12413 7.58457 9.12413 7.01773 9.12762 6.45132C9.12893 6.23476 9.25953 6.10608 9.48229 6.1044C10.0816 6.10062 10.6813 6.10062 11.2806 6.1044C11.4963 6.10566 11.6291 6.22929 11.6313 6.4387C11.637 7.01563 11.6374 7.59298 11.6313 8.16991C11.6291 8.3768 11.492 8.49875 11.2771 8.50169C10.9774 8.50631 10.6778 8.50295 10.3782 8.50295L10.3777 8.50379Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M14.531 6.10278C14.8197 6.10278 15.1084 6.10068 15.3976 6.10362C15.6544 6.10614 15.7833 6.22472 15.785 6.4682C15.7885 7.0241 15.7885 7.58043 15.785 8.13633C15.7837 8.37854 15.6518 8.50217 15.3989 8.50343C14.8215 8.50595 14.2436 8.50595 13.6661 8.50343C13.4032 8.50217 13.28 8.37896 13.2791 8.12246C13.2774 7.57706 13.2774 7.03125 13.2791 6.48586C13.28 6.2222 13.4028 6.10572 13.681 6.10362C13.9645 6.10152 14.2475 6.1032 14.531 6.10362V6.10278Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.22778 12.5017C5.93906 12.5017 5.65034 12.5038 5.36118 12.5008C5.10566 12.4983 4.97593 12.3768 4.97462 12.135C4.972 11.5791 4.972 11.0228 4.97462 10.4669C4.9755 10.2242 5.10391 10.1019 5.35987 10.101C5.93731 10.0985 6.51518 10.0985 7.09262 10.101C7.35644 10.1023 7.47918 10.2234 7.48049 10.4816C7.48268 11.027 7.48268 11.5728 7.48049 12.1182C7.47918 12.3801 7.35251 12.4991 7.07777 12.5012C6.79429 12.5033 6.51125 12.5017 6.22778 12.5012V12.5017Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M10.3786 12.5014C10.0842 12.5014 9.79023 12.5044 9.49584 12.5006C9.26172 12.4976 9.12893 12.3736 9.12762 12.1499C9.12413 11.5835 9.12413 11.0166 9.12762 10.4502C9.12893 10.2332 9.25909 10.1037 9.48098 10.1024C10.0803 10.0987 10.68 10.0987 11.2793 10.1024C11.4955 10.1037 11.6287 10.2265 11.6309 10.4359C11.637 11.0128 11.637 11.5902 11.6309 12.1671C11.6282 12.3803 11.4898 12.4981 11.2613 12.501C10.9669 12.5044 10.673 12.5018 10.3786 12.5018V12.5014Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M14.5302 10.1003C14.8189 10.1003 15.1076 10.0982 15.3968 10.1012C15.654 10.1037 15.7838 10.2223 15.7851 10.4649C15.7886 11.0208 15.7886 11.5771 15.7851 12.133C15.7838 12.3757 15.6523 12.5001 15.4003 12.501C14.8228 12.5035 14.2449 12.5035 13.6675 12.501C13.4037 12.4997 13.2805 12.3774 13.2796 12.1209C13.2779 11.5755 13.2779 11.0296 13.2796 10.4843C13.2805 10.2202 13.4032 10.1033 13.6806 10.1012C13.9641 10.0991 14.2471 10.1008 14.5306 10.1008L14.5302 10.1003Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.22488 16.4984C5.93049 16.4984 5.63653 16.5014 5.34213 16.4976C5.10845 16.4946 4.9761 16.3697 4.97479 16.1456C4.97129 15.5792 4.97129 15.0124 4.97479 14.4459C4.9761 14.229 5.1067 14.1007 5.32946 14.099C5.92874 14.0952 6.52845 14.0952 7.12773 14.099C7.34351 14.1003 7.47629 14.2239 7.47848 14.4333C7.48415 15.0103 7.48459 15.5876 7.47848 16.1645C7.47629 16.3714 7.33914 16.4934 7.12424 16.4963C6.8246 16.5009 6.52496 16.4976 6.22532 16.4976L6.22488 16.4984Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M10.3715 16.4991C10.0881 16.4991 9.80503 16.5012 9.52155 16.4987C9.25598 16.4962 9.128 16.3759 9.12713 16.1249C9.12494 15.574 9.12494 15.0232 9.12713 14.4723C9.128 14.2259 9.25336 14.1001 9.50495 14.0989C10.0881 14.0959 10.6712 14.0959 11.2543 14.0989C11.5063 14.1001 11.6321 14.2242 11.6334 14.4715C11.6361 15.0223 11.6361 15.5732 11.6334 16.124C11.6321 16.3755 11.5024 16.4966 11.2386 16.4987C10.9499 16.5012 10.6611 16.4991 10.372 16.4991H10.3715Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M14.5172 16.4988C14.2285 16.4988 13.9397 16.5017 13.6506 16.498C13.409 16.495 13.2806 16.3693 13.2793 16.1338C13.2767 15.5779 13.2767 15.0216 13.2793 14.4657C13.2802 14.2218 13.4073 14.0998 13.6637 14.0986C14.2411 14.096 14.819 14.096 15.3964 14.0986C15.6603 14.0998 15.7843 14.2209 15.7852 14.4779C15.7874 15.0233 15.7874 15.5691 15.7852 16.1145C15.7843 16.3773 15.6581 16.4967 15.3833 16.4988C15.0946 16.5009 14.8059 16.4992 14.5167 16.4988H14.5172Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M2.07744 8.5039C1.78304 8.5039 1.48908 8.50684 1.19512 8.50306C0.961872 8.50011 0.827777 8.3769 0.82603 8.15236C0.821662 7.58594 0.821662 7.01952 0.82603 6.4531C0.827777 6.22898 0.960998 6.10535 1.19643 6.10409C1.78479 6.10072 2.37314 6.10072 2.9615 6.10409C3.2013 6.10535 3.32972 6.23444 3.33103 6.47034C3.33365 7.02625 3.33365 7.58215 3.33103 8.13806C3.32972 8.37943 3.19955 8.50053 2.94403 8.50348C2.65531 8.50642 2.36659 8.50432 2.07787 8.50348L2.07744 8.5039Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M2.08203 10.1C2.37075 10.1 2.65947 10.0975 2.94818 10.1009C3.20065 10.1038 3.32907 10.2279 3.33038 10.4713C3.333 11.0272 3.333 11.5831 3.33038 12.139C3.32907 12.3724 3.19847 12.499 2.95605 12.5003C2.36769 12.5032 1.77933 12.5032 1.19097 12.5003C0.959475 12.499 0.827127 12.372 0.82538 12.1462C0.821012 11.5852 0.821449 11.0239 0.82538 10.4625C0.82669 10.2274 0.956417 10.1042 1.19971 10.1013C1.49367 10.0975 1.78807 10.1004 2.08246 10.1004L2.08203 10.1Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M2.07525 16.4985C1.78086 16.4985 1.48689 16.5014 1.19293 16.4976C0.960998 16.4947 0.827777 16.3698 0.82603 16.1448C0.821662 15.5784 0.821662 15.012 0.82603 14.4456C0.827777 14.2282 0.959688 14.1008 1.18201 14.0995C1.77561 14.0957 2.36965 14.0957 2.96325 14.0995C3.19606 14.1008 3.32841 14.2278 3.33015 14.4519C3.33408 15.0183 3.33408 15.5847 3.33015 16.1512C3.32841 16.3732 3.19387 16.4951 2.95801 16.4981C2.66405 16.5014 2.36965 16.4989 2.07525 16.4989V16.4985Z"
                    fill="#DBBE78"
                  />
                </svg>
              </i>
              {t("Travel.Date")}
            </div>
          </label>
        </div>

        {/* PICKUP TIME */}
        <div className="col-6 col-md-6 col-lg-2 col-xl form-floating">
          <input
            type="time"
            name="pickuptime"
            style={{
              borderColor: !formDataValidation.pickuptime ? "red" : "",
              color: byTheHourFormData.pickuptime ? "#282828" : "",
              fontWeight: byTheHourFormData.pickuptime ? "bold" : "",
            }}
            onChange={(e) => {
              onChange(e);
              availabilityValueReset();
            }}
            className={
              formDataValidation.pickuptime
                ? "form-control"
                : "form-control invalid"
            }
            placeholder="Pick-up time"
            aria-label="Pick-up time"
            value={byTheHourFormData?.pickuptime !== ""
              ? byTheHourFormData?.pickuptime
              : byTheHourForm?.pickuptime !== ""
                ? byTheHourForm?.pickuptime
                : ""}
          />
          {formDataValidation.pickuptime ? (
            ""
          ) : (
            <p className="text-danger">
              {t("AirportTransfer.PickupTime.Required")}
            </p>
          )}
          <label htmlFor="pickUpTime">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_104_3083)">
                    <path
                      d="M16 7.9357V9.06055C15.9587 9.34228 15.9257 9.62569 15.8744 9.90575C15.5184 11.8453 14.5795 13.4535 13.0517 14.6902C11.1659 16.2166 9.00302 16.7842 6.60892 16.3685C4.49234 16.0008 2.79515 14.9198 1.53488 13.1872C0.191564 11.3395 -0.262467 9.26632 0.143157 7.02288C0.461563 5.26152 1.31371 3.77813 2.63615 2.5744C3.76622 1.54596 5.08784 0.891924 6.59557 0.625215C6.876 0.575547 7.15894 0.541321 7.44104 0.5C7.81578 0.5 8.19052 0.5 8.56568 0.5C8.82191 0.53506 9.0798 0.561355 9.33436 0.606016C10.9877 0.898184 12.4212 1.62652 13.6155 2.8073C14.8174 3.99517 15.5765 5.42388 15.8752 7.09091C15.9253 7.37098 15.9591 7.65396 16.0004 7.9357H16ZM8.00023 15.1602C11.6909 15.164 14.6642 12.1897 14.6634 8.49499C14.6625 4.80699 11.698 1.84272 8.00816 1.83939C4.32083 1.83605 1.3521 4.79822 1.34667 8.48748C1.34083 12.1826 4.30664 15.1564 8.00065 15.1602H8.00023Z"
                      fill="#DBBE78"
                    />
                    <path
                      d="M8.67765 6.32278C8.67765 6.89543 8.68099 7.46808 8.67515 8.04073C8.6739 8.16929 8.7077 8.26195 8.80201 8.35502C9.63079 9.17518 10.4554 9.99993 11.2783 10.8255C11.6042 11.1523 11.57 11.6348 11.2107 11.8798C10.9386 12.0651 10.6019 12.0364 10.3548 11.7959C10.0823 11.5309 9.81691 11.2592 9.54816 10.9904C8.8934 10.3351 8.24032 9.67771 7.58222 9.02493C7.41697 8.86089 7.33601 8.67432 7.33643 8.441C7.33935 7.14461 7.33893 5.84821 7.33684 4.55224C7.33643 4.26132 7.44201 4.03301 7.70867 3.90195C7.94737 3.78467 8.18356 3.8047 8.40098 3.95997C8.61005 4.10939 8.67849 4.32602 8.67807 4.57352C8.67682 5.15661 8.67807 5.7397 8.67807 6.32278H8.67765Z"
                      fill="#DBBE78"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_104_3083">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              {t("Pick.Up.Time")}
            </div>
          </label>
        </div>

        {/* DROPOFF TIME MOBILE */}
        <div className="col-6 col-sm-6 col-md-6 col-lg-3 d-lg-none d-sm-block form-floating">
          <input
            disabled={true}
            type="time"
            name="dropofftime"
            // onChange={(e) => onChange(e)}
            className="form-control form-control-disabled"
            placeholder="Drop-off time"
            aria-label="Drop-off time"
            // value={byTheHourFormData.dropofftime}
            value={dropOffTimeToShow !== undefined
              ? dropOffTimeToShow
              : ""}
          />
          <label htmlFor="dropOffTime">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_104_3083)">
                    <path
                      d="M16 7.9357V9.06055C15.9587 9.34228 15.9257 9.62569 15.8744 9.90575C15.5184 11.8453 14.5795 13.4535 13.0517 14.6902C11.1659 16.2166 9.00302 16.7842 6.60892 16.3685C4.49234 16.0008 2.79515 14.9198 1.53488 13.1872C0.191564 11.3395 -0.262467 9.26632 0.143157 7.02288C0.461563 5.26152 1.31371 3.77813 2.63615 2.5744C3.76622 1.54596 5.08784 0.891924 6.59557 0.625215C6.876 0.575547 7.15894 0.541321 7.44104 0.5C7.81578 0.5 8.19052 0.5 8.56568 0.5C8.82191 0.53506 9.0798 0.561355 9.33436 0.606016C10.9877 0.898184 12.4212 1.62652 13.6155 2.8073C14.8174 3.99517 15.5765 5.42388 15.8752 7.09091C15.9253 7.37098 15.9591 7.65396 16.0004 7.9357H16ZM8.00023 15.1602C11.6909 15.164 14.6642 12.1897 14.6634 8.49499C14.6625 4.80699 11.698 1.84272 8.00816 1.83939C4.32083 1.83605 1.3521 4.79822 1.34667 8.48748C1.34083 12.1826 4.30664 15.1564 8.00065 15.1602H8.00023Z"
                      fill="#DBBE78"
                    />
                    <path
                      d="M8.67765 6.32278C8.67765 6.89543 8.68099 7.46808 8.67515 8.04073C8.6739 8.16929 8.7077 8.26195 8.80201 8.35502C9.63079 9.17518 10.4554 9.99993 11.2783 10.8255C11.6042 11.1523 11.57 11.6348 11.2107 11.8798C10.9386 12.0651 10.6019 12.0364 10.3548 11.7959C10.0823 11.5309 9.81691 11.2592 9.54816 10.9904C8.8934 10.3351 8.24032 9.67771 7.58222 9.02493C7.41697 8.86089 7.33601 8.67432 7.33643 8.441C7.33935 7.14461 7.33893 5.84821 7.33684 4.55224C7.33643 4.26132 7.44201 4.03301 7.70867 3.90195C7.94737 3.78467 8.18356 3.8047 8.40098 3.95997C8.61005 4.10939 8.67849 4.32602 8.67807 4.57352C8.67682 5.15661 8.67807 5.7397 8.67807 6.32278H8.67765Z"
                      fill="#DBBE78"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_104_3083">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              {t("Drop.Off.Time")}
            </div>
          </label>
        </div>

        {/* DROPOFF ADDRESS DESKTOP */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-lg-block d-none form-floating">
          <GoogleAutoComplete
            id="dropoff1"
            isNotValid={formDataValidation.dropofflocations ? false : true}
            locationOnPlaceSelected={(l) => {
              dropOffLocationSelected(l);
              availabilityValueReset();
            }}
            emptyValue={handleDropoffEmpty}
            placeholder="Drop-off address"
            ariaLabel="Drop-off address"
            defaultValue={
              byTheHourFormData?.dropofflocations[0]?.formatted_address !== undefined
                ? byTheHourFormData?.dropofflocations[0]?.formatted_address
                : byTheHourForm?.dropofflocations[0]?.formatted_address !== undefined
                  ? byTheHourForm?.dropofflocations[0]?.formatted_address
                  : ""
            }
          />
          <label htmlFor="dropOffAddress">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.06 16.5C5.31857 16.0509 4.68539 15.469 4.06732 14.8716C2.78586 13.6332 1.69952 12.2486 0.929135 10.6349C0.465897 9.66519 0.144903 8.65128 0.040842 7.58144C-0.213436 4.96757 0.715559 2.88715 2.94154 1.43996C4.09837 0.687775 5.3941 0.399756 6.77248 0.530408C9.56156 0.794634 11.8035 2.81076 12.3292 5.52858C12.5424 6.63098 12.4434 7.72503 12.169 8.80573C11.7825 10.3268 11.0444 11.6763 10.102 12.9206C9.11973 14.2176 7.97128 15.3508 6.66339 16.3234C6.57317 16.3906 6.47079 16.4416 6.37428 16.5H6.06ZM11.2177 6.74535C11.1863 6.44606 11.1716 6.14343 11.1208 5.84748C10.6286 2.98858 7.79295 1.1628 4.9766 1.88994C2.65831 2.4881 0.992075 4.63989 1.25978 7.3072C1.37139 8.41878 1.71547 9.45899 2.23871 10.4412C3.19959 12.2444 4.55196 13.7204 6.12294 15.0123C6.1926 15.0695 6.24043 15.0708 6.3105 15.0136C7.79966 13.7914 9.09287 12.3989 10.0449 10.7167C10.7419 9.4857 11.1833 8.17668 11.2173 6.74535H11.2177Z"
                    fill="#DBBE78"
                  />
                  <path
                    d="M6.22103 3.34473C8.13315 3.34682 9.69952 4.90462 9.69616 6.80179C9.6928 8.70187 8.12686 10.2534 6.2139 10.2522C4.30262 10.2509 2.73584 8.69186 2.73877 6.79469C2.74213 4.89502 4.3085 3.34306 6.22103 3.34473ZM6.22103 9.01911C7.47018 9.01452 8.45205 8.02733 8.44911 6.77841C8.44617 5.55788 7.44668 4.57402 6.21348 4.57778C4.95762 4.58154 3.98079 5.57082 3.98582 6.83351C3.99086 8.04027 4.99622 9.0237 6.22145 9.01911H6.22103Z"
                    fill="#DBBE78"
                  />
                </svg>
              </i>
              {t("Drop.Off.Address")}
            </div>
          </label>
        </div>

        {/* PAX AND LUGAGE */}
        <div
          ref={wrapperRef}
          className="col-lg-3 col-md-12 col-sm-12 position-relative "
        >
          <div className="row">
            <div className="col form-floating">
              <input
                readOnly={true}
                // onChange={(e) => {
                //   onChange(e);
                //   setTotalPax(e.target.value);
                //   availabilityValueReset();
                // }}
                onClick={() => {
                  setPassangeVisible(!passengerVisible);
                  setLugageVisible(false);
                }}
                style={{
                  borderColor: !formDataValidation.pax ? "red" : "",
                  color: totalPax > 0 ? "#282828" : "",
                  fontWeight: totalPax > 0 ? "bold" : "",
                }}
                name="pax"
                type="number"
                id="passengers"
                className={
                  formDataValidation.pax
                    ? "form-control"
                    : "form-control invalid"
                }
                placeholder="Passengers"
                aria-label="Passengers"
                min={0}
                value={totalPax ?? 0}
              />
              <label htmlFor="numberOfPassengers">
                <div className="d-flex align-items-center">
                  <i className="pe-2 d-flex align-self-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.56613 16.5H7.4416C7.26217 16.4766 7.08233 16.4566 6.90374 16.4295C4.78987 16.107 3.04611 15.1244 1.72672 13.4422C0.239165 11.5451 -0.289512 9.3826 0.149452 7.01611C0.548359 4.86616 1.68457 3.15972 3.48007 1.91598C5.24552 0.69352 7.21126 0.273377 9.32514 0.61383C11.0009 0.883773 12.4446 1.64228 13.6455 2.8418C14.8339 4.02838 15.585 5.45194 15.877 7.1104C15.9255 7.38577 15.9592 7.66364 15.9997 7.94068V9.06509C15.9764 9.2445 15.9555 9.4239 15.9292 9.60289C15.6985 11.1691 15.0755 12.5585 14.0306 13.7488C12.7955 15.1561 11.2554 16.0377 9.40984 16.3752C9.13027 16.4262 8.84737 16.4587 8.56613 16.5ZM13.3163 12.5051C15.1147 10.2542 15.3104 6.37776 12.7087 3.78472C10.0858 1.17082 5.80925 1.20337 3.24515 3.83771C0.67938 6.47414 0.940172 10.3297 2.69478 12.5026C2.71272 12.4546 2.73066 12.4129 2.74401 12.3695C2.9443 11.7274 3.25224 11.1412 3.67076 10.6155C3.73752 10.532 3.73878 10.4724 3.69455 10.3818C3.21511 9.39219 3.09744 8.35581 3.32276 7.28313C3.88732 4.59622 6.66924 2.92983 9.31262 3.69085C12.1805 4.51611 13.6025 7.68325 12.3153 10.3827C12.2715 10.4741 12.2723 10.535 12.3395 10.618C12.6645 11.021 12.9195 11.4675 13.1131 11.9477C13.1865 12.1292 13.247 12.3161 13.3155 12.5055L13.3163 12.5051ZM11.4603 8.29406C11.4499 6.35064 9.90388 4.83529 7.94148 4.84572C6.06921 4.85573 4.54368 6.41698 4.55495 8.31159C4.56663 10.2483 6.10134 11.762 8.04455 11.7533C9.92892 11.7445 11.4703 10.1837 11.4603 8.29448V8.29406ZM4.5779 11.6602C4.56621 11.6698 4.55161 11.6777 4.54326 11.6898C4.1527 12.2635 3.92655 12.8973 3.84726 13.5861C3.83516 13.6913 3.88273 13.7463 3.95868 13.8031C5.35151 14.842 6.91292 15.2876 8.64374 15.1319C9.90221 15.0184 11.0297 14.5666 12.0403 13.8114C12.1346 13.7409 12.1847 13.67 12.1588 13.5527C12.1 13.2845 12.0578 13.0116 11.981 12.7483C11.8663 12.3536 11.6827 11.989 11.4398 11.6515C9.5588 13.5665 6.45309 13.5694 4.57748 11.6602H4.5779Z"
                        fill="#DBBE78"
                      />
                    </svg>
                  </i>
                  {t("Passengers")}
                </div>
              </label>
            </div>

            <div className="col form-floating">
              <input
                readOnly={true}
                // onChange={(e) => {
                //   onChange(e);
                //   setTotalLugage(e.target.value);
                //   availabilityValueReset();
                // }}

                onClick={() => {
                  setLugageVisible(!lugageVisible);
                  setPassangeVisible(false);
                }}
                name="lugage"
                style={{
                  color: totalLugage > 0 ? "#282828" : "",
                  fontWeight: totalLugage > 0 ? "bold" : "",
                }}
                type="number"
                id="passengers"
                className={"form-control"}
                placeholder="Lugages"
                aria-label="Lugages"
                min={0}
                value={totalLugage ?? 0}
              />

              {lugageVisible === true ? (
                <PassengerCard
                  setPaxPanel={setPaxAndLuggaePannalVisibility}
                  setFormDataToState={setLugageFormDataToState}
                  passengerFormData={byTheHourFormData.lugage}
                  passangerForm={false}
                  luggageForm={true}
                />
              ) : (
                <></>
              )}
              {passengerVisible === true ? (
                <PassengerCard
                  setPaxPanel={setPaxAndLuggaePannalVisibility}
                  setFormDataToState={setPaxFormDataToState}
                  passengerFormData={byTheHourFormData.pax}
                  passangerForm={true}
                  luggageForm={false}
                />
              ) : (
                <></>
              )}
              <label htmlFor="numberOfPassengers">
                <div className="d-flex align-items-center">
                  <i className="pe-2 d-flex align-self-center">
                    <svg
                      width="11"
                      height="16"
                      viewBox="0 0 11 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Vector"
                        d="M9.42857 3.2H7.85714V0.8C7.85714 0.36 7.50357 0 7.07143 0H3.92857C3.49643 0 3.14286 0.36 3.14286 0.8V3.2H1.57143C0.707143 3.2 0 3.92 0 4.8V13.6C0 14.48 0.707143 15.2 1.57143 15.2C1.57143 15.64 1.925 16 2.35714 16C2.78929 16 3.14286 15.64 3.14286 15.2H7.85714C7.85714 15.64 8.21071 16 8.64286 16C9.075 16 9.42857 15.64 9.42857 15.2C10.2929 15.2 11 14.48 11 13.6V4.8C11 3.92 10.2929 3.2 9.42857 3.2ZM3.53571 12.8H2.35714V5.6H3.53571V12.8ZM6.08929 12.8H4.91071V5.6H6.08929V12.8ZM6.67857 3.2H4.32143V1.2H6.67857V3.2ZM8.64286 12.8H7.46429V5.6H8.64286V12.8Z"
                        fill="#DBBE78"
                      />
                    </svg>
                  </i>
                  {t("Luggage")}
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* DROPOFF TIME DESKTOP */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 d-lg-block d-none form-floating">
          <input
            disabled={true}
            type="time"
            name="dropofftime"
            // onChange={(e) => onChange(e)}
            className="form-control form-control-disabled"
            placeholder="Drop-off time"
            aria-label="Drop-off time"
            // value={byTheHourFormData.dropofftime}
            value={dropOffTimeToShow !== undefined
              ? dropOffTimeToShow
              : ""}
          />
          <label htmlFor="dropOffTime">
            <div className="d-flex align-items-center">
              <i className="pe-2 d-flex align-self-center">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_104_3083)">
                    <path
                      d="M16 7.9357V9.06055C15.9587 9.34228 15.9257 9.62569 15.8744 9.90575C15.5184 11.8453 14.5795 13.4535 13.0517 14.6902C11.1659 16.2166 9.00302 16.7842 6.60892 16.3685C4.49234 16.0008 2.79515 14.9198 1.53488 13.1872C0.191564 11.3395 -0.262467 9.26632 0.143157 7.02288C0.461563 5.26152 1.31371 3.77813 2.63615 2.5744C3.76622 1.54596 5.08784 0.891924 6.59557 0.625215C6.876 0.575547 7.15894 0.541321 7.44104 0.5C7.81578 0.5 8.19052 0.5 8.56568 0.5C8.82191 0.53506 9.0798 0.561355 9.33436 0.606016C10.9877 0.898184 12.4212 1.62652 13.6155 2.8073C14.8174 3.99517 15.5765 5.42388 15.8752 7.09091C15.9253 7.37098 15.9591 7.65396 16.0004 7.9357H16ZM8.00023 15.1602C11.6909 15.164 14.6642 12.1897 14.6634 8.49499C14.6625 4.80699 11.698 1.84272 8.00816 1.83939C4.32083 1.83605 1.3521 4.79822 1.34667 8.48748C1.34083 12.1826 4.30664 15.1564 8.00065 15.1602H8.00023Z"
                      fill="#DBBE78"
                    />
                    <path
                      d="M8.67765 6.32278C8.67765 6.89543 8.68099 7.46808 8.67515 8.04073C8.6739 8.16929 8.7077 8.26195 8.80201 8.35502C9.63079 9.17518 10.4554 9.99993 11.2783 10.8255C11.6042 11.1523 11.57 11.6348 11.2107 11.8798C10.9386 12.0651 10.6019 12.0364 10.3548 11.7959C10.0823 11.5309 9.81691 11.2592 9.54816 10.9904C8.8934 10.3351 8.24032 9.67771 7.58222 9.02493C7.41697 8.86089 7.33601 8.67432 7.33643 8.441C7.33935 7.14461 7.33893 5.84821 7.33684 4.55224C7.33643 4.26132 7.44201 4.03301 7.70867 3.90195C7.94737 3.78467 8.18356 3.8047 8.40098 3.95997C8.61005 4.10939 8.67849 4.32602 8.67807 4.57352C8.67682 5.15661 8.67807 5.7397 8.67807 6.32278H8.67765Z"
                      fill="#DBBE78"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_104_3083">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              {t("Drop.Off.Time")}
            </div>
          </label>
        </div>

        {/* USAGE DURATION DESKTOP*/}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-lg-block d-none form-floating">
          {
            (byTheHourFormData?.duration != "") ? (
              <Select
                // type="select"
                name="duration"
                classNamePrefix="react-select-time"
                className={formDataValidation.duration ? "" : "invalid"}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    fontSize: "0.75rem",
                    color: byTheHourFormData.duration ? "#282828" : "",
                    fontWeight: byTheHourFormData.duration ? "bolder" : "",
                  }),
                  control: (baseStyles, isActive) => ({
                    ...baseStyles,
                    paddingTop: "",
                    paddingLeft: "8px",
                    paddingBotton: "0.75rem",
                    border: "none",
                    borderBottom: "1px solid #DBBE78",
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    borderRadius: "0.3rem",
                    background: "rgba(167, 167, 167, 0.1)",
                    fontSize: "0.75rem",
                    color: byTheHourFormData.duration ? "#282828" : "",
                    fontWeight: byTheHourFormData.duration ? "bolder" : "",
                  }),
                }}
                autoComplete="off"
                placeholder={t("Usage Duration (e.g., 2 hours)")}
                value={
                  byTheHourFormData?.duration !== ""
                    ? {
                      value: byTheHourFormData?.duration,
                      label: byTheHourFormData?.duration
                    }
                    : byTheHourForm?.duration !== undefined
                      ? {
                        value: byTheHourForm?.duration,
                        label: byTheHourForm?.duration
                      }
                      : ""
                }
                onChange={(e) => {
                  onChangeDuration(e);
                  setDurationTime(e);
                  availabilityValueReset();
                }}
                options={halfHourIntervalsData}
              />
            ) : (
              <Select
                // type="select"
                name="duration"
                classNamePrefix="react-select-time"
                className={formDataValidation.duration ? "" : "invalid"}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    fontSize: "0.75rem",
                  }),
                  control: (baseStyles, isActive) => ({
                    ...baseStyles,
                    paddingTop: "",
                    paddingLeft: "8px",
                    paddingBotton: "0.75rem",
                    border: "none",
                    borderBottom: "1px solid #DBBE78",
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    borderRadius: "0.3rem",
                    background: "rgba(167, 167, 167, 0.1)",
                    fontSize: "0.75rem",
                  }),
                }}
                autoComplete="off"
                placeholder={t("Usage Duration (e.g., 2 hours)")}
                onChange={(e) => {
                  onChangeDuration(e);
                  setDurationTime(e);
                  availabilityValueReset();
                }}
                options={halfHourIntervalsData}
              />
            )}
        </div>
      </div>

      {/* FOURTH LINE INPUT */}
      <div className="d-lg-flex justify-content-center gap-2 d-md-block mt-auto">
        {/* BUTTON */}
        <button
          type="button"
          className="btn btn-secondary btn-sm rounded-3 px-4 d-flex justify-content-center px-md-5 py-2"
          onClick={() => {
            handleCloseModal();
          }}
        >
          <div className="d-flex align-items-center px-2 ">
            {t("Discard Edit")}
          </div>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm rounded-3 px-4 d-flex justify-content-center px-md-5 py-2"
          onClick={() => {
            searchAvailableVehicles();
          }}
        >
          <div className="d-flex align-items-center px-2 d-inline">
            {t("Proceed")}
            <i className="ps-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 5L20 12L13 19M4 12H20H4Z"
                  stroke="#4A4A4A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ByTheHourEditBookingForm;
