import PreviousBookings from "./PreviousBookings";
import { useState, useEffect } from "react";
import UpcomingBookings from "./UpcomingBookings";
import CurrentBookings from "./CurrentBookings";
import ThisMonthBookings from "./ThisMonthBookings";
import CancelledBookings from "./CancelledBookings";
import QuotationBookings from "./QuotationBookings";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
const MyPageBookings = ({ userRole }) => {
  const [page, setpage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var content = searchParams.get("content");
  const { t } = useTranslation();

  if (!content) {
    if (userRole === "individual") {
      content = "current";
    } else {
      content = "previous";
    }
  }
  useEffect(() => {
    if (!content) {
      if (userRole === "individual") {
        setpage("Current booking(s)");
      } else {
        setpage("Current booking(s)");
      }
    } else {
      if (userRole === "individual") {
        switch (content) {
          case "current":
            setpage(t('my_page.bookings_page.tab_current_bookings'));
            break;

          case "previous":
            setpage(t('my_page.bookings_page.tab_previous_bookings'));
            break;

          case "cancelled":
            setpage(t('my_page.bookings_page.tab_cancelled_bookings'));
            break;
          default:
            setpage(t('my_page.bookings_page.tab_current_bookings'));
            break;
        }
      } else {
        switch (content) {
          case "this_month":
            setpage(t('my_page.bookings_page.tab_this_month_bookings'));
            break;
          case "upcoming":
            setpage(t('my_page.bookings_page.tab_upcoming_bookings'));
            break;
          case "previous":
            setpage(t('my_page.bookings_page.tab_previous_bookings'));
            break;
          case "cancelled":
            setpage(t('my_page.bookings_page.tab_cancelled_bookings'));
            break;
          default:
            setpage(t('my_page.bookings_page.tab_this_month_bookings'));
            break;
        }
      }
    }
  }, []);

  const renderContent = (contentType) => {
    switch (contentType) {
      case "current":
        return <CurrentBookings userRole={userRole} />;
      case "previous":
        return <PreviousBookings userRole={userRole} />;
      case "cancelled":
        return <CancelledBookings userRole={userRole} />;
      case "this_month":
        return <ThisMonthBookings userRole={userRole} />;
      case "upcoming":
        return <UpcomingBookings userRole={userRole} />;
        case "quotation":
          return <QuotationBookings userRole={userRole} />;
      default:
        return <p></p>;
    }
  };

  return (
    <>
      <div className="card card-myPage mt-3 ">
        {/* // DESKTOP VERSION */}
        <div className="d-none d-md-block">
          <div className="card-body pb-0">
            {userRole === "individual" && (
              <ul className="nav">
                <li className="nav-item">
                  <Link
                    to="?content=current"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "current" ? "active" : ""
                    }`}
                  >
                    {t('my_page.bookings_page.tab_current_bookings')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?content=previous"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "previous" ? "active" : ""
                    }`}
                  >
                   {t('my_page.bookings_page.tab_previous_bookings')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?content=cancelled"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "cancelled" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_cancelled_bookings')}
                  </Link>
                </li>
              </ul>
            )}
            {userRole === "business" && (
              <ul className="nav">
                <li className="nav-item">
                  <Link
                    to="?content=previous"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "previous" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_previous_bookings')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?content=this_month"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "this_month" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_this_month_bookings')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?content=upcoming"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "upcoming" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_upcoming_bookings')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?content=cancelled"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "cancelled" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_cancelled_bookings')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="?content=quotation"
                    className={`nav-link my-page-booking-nl text-black-30 bg-transparent ${
                      content === "quotation" ? "active" : ""
                    }`}
                  >
                  {t('my_page.bookings_page.tab_quotation_bookings')}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>

        {/* MOBILE VIEW */}
        <div className="d-block d-md-none">
          <div className="card-body">
            <div className="d-grid gap-2 ">
              <button
                className="btn bg-gold-light-100 py-3 rounded accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#booking-menu"
                aria-expanded="false"
                aria-controls="booking-menu"
              >
                <div className="row align-items-center">
                  <div className="col text-start ">{page}</div>
                </div>
              </button>
            </div>
            <div className="collapse" id="booking-menu">
              <div
                className="card card-body border-gold"
                style={{
                  marginTop: "-3px ",
                  fontSize: "0.875rem",
                }}
              >
                {userRole === "individual" ? (
                  <ul className="nav flex-column">
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=current"
                        className={`nav-item noto text-dark ${
                          content === "current" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Current booking(s)
                      </Link>
                    </li>
                    <hr />
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=previous"
                        className={`nav-item noto text-dark ${
                          content === "previous" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Previous booking(s)
                      </Link>
                    </li>
                    <hr />
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=cancelled"
                        className={`nav-item noto text-dark ${
                          content === "cancelled" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Cancelled booking(s)
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
                {userRole === "business" ? (
                  <ul className="nav flex-column">
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=this_month"
                        className={`nav-item noto text-dark ${
                          content === "this_month" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        This month’s booking(s)
                      </Link>
                    </li>
                    <hr />
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=upcoming"
                        className={`nav-item noto text-dark ${
                          content === "upcoming" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Upcoming booking(s)
                      </Link>
                    </li>

                    <hr />
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=previous"
                        className={`nav-item noto text-dark ${
                          content === "previous" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Previous booking(s)
                      </Link>
                    </li>
                    <hr />
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=cancelled"
                        className={`nav-item noto text-dark ${
                          content === "cancelled" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Cancelled booking(s)
                      </Link>
                    </li>
                    <li className="nav-item noto" role="presentation">
                      <Link
                        to="?content=cancelled"
                        className={`nav-item noto text-dark ${
                          content === "cancelled" ? "active" : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Cancelled booking(s)
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 mt-3 ">{renderContent(content)}</div>
      </div>
    </>
  );
};

export default MyPageBookings;
