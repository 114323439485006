import React, { useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import hiace from "../../images/toyota_hiace.png";
import hiace1 from "../../images/hiace-sm-1-new.jpeg";
import hiace2 from "../../images/hiace-sm-2-new.jpeg";
import hiace3 from "../../images/hiace-sm-3-new.jpeg";
import hiace4 from "../../images/hiace-sm-4-new.jpeg";
import hiace5 from "../../images/hiace-sm-5-new.jpeg";
import hiace6 from "../../images/hiace-sm-6-new.jpeg";
import hiace7 from "../../images/hiace-sm-7-new.jpeg";
import hiace8 from "../../images/hiace-sm-8-new.jpeg";
import hiace9 from "../../images/hiace-sm-9-new.jpeg";
import hiace10 from "../../images/hiace-sm-10-new.jpeg";
import hiace11 from "../../images/hiace-sm-11-new.jpeg";
import hiace12 from "../../images/hiace-sm-12-new.jpeg";
import hiace13 from "../../images/hiace-sm-13-new.jpeg";
import video_icon from "../../icons/car-icons/360-icon.svg";
import hiace_seatplan from "../../images/hiace_seatmap.png";

const HiaceGrandCabinCarPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = useState(null);

  return (
    <FrontLayout>
      <div className="hero-image-hiace-grand d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p className="card-header ps-0 text-gold">{t("Fleet")}</p>
            <h1 className="fw-bold mb-4 mt-2">{t("Toyota.Hiace")}</h1>
            <p className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0">
              {t("Toyota.Hiace.Description")}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- vehicle type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./alphard"
              >
                {t("Toyota.Alphard")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./granace"
              >
                {t("Toyota.Granace")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                aria-current="page"
                to="./hiace-grand-cabin"
              >
                {t("Hiace")}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./hiace-luxury-cabin"
              >
                {t("Hiace.Luxury")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- vehicle type nav --> */}
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-md-3 mt-lg-5 pt-5 pb-3">
        <div className="row mx-0 mb-3 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between border-bottom-gold">
            <div className="col-12 col-md-6 col-lg-7 d-flex flex-column mt-auto pb-3 pb-md-0 order-2 order-md-1">
              <div className="card-header ps-0 pt-3 pb-2">
                {t("Toyota.Hiace.Tag")}
              </div>
              <div className="card-body ps-0 pt-1">
                <h2 className="card-title fs-7 mb-3">{t("Hiace")}</h2>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-5 pb-3 order-1 order-md-2">
              <img src={hiace} className="card-img-top" alt="Toyota Hiace" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto pb-3">
        <div className="row mx-0 mb-3 mb-lg-5 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 d-flex flex-column flex-md-row justify-content-md-between">
            <div className="col-12 col-md-6 d-flex flex-column mt-auto">
              <div className="row mb-3">
                <h3 className="fs-6">{t("Vehicle.Details")}</h3>
              </div>
              <p className="text-dark">
                {t("Toyota.Hiace.Description.Longer")}
              </p>
            </div>

            <div className="col-12 col-md-5 mt-4 mt-md-0">
              <div className="row mb-3">
                <h3 className="fs-6">{t("Features")}</h3>
              </div>
              <div className="row row-cols-2 gy-2 gy-md-0 gx-5 gx-md-0 my-3">
                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Hiace.Passengers")}</p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M144 56c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v72H144V56zm176 72H288V56c0-30.9-25.1-56-56-56H152C121.1 0 96 25.1 96 56v72H64c-35.3 0-64 28.7-64 64V416c0 35.3 28.7 64 64 64c0 17.7 14.3 32 32 32s32-14.3 32-32H256c0 17.7 14.3 32 32 32s32-14.3 32-32c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64zM112 224H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 128H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Hiace.Suitcases")}</p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8C297 398 352 333.4 352 256V224c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">
                      {t("Toyota.Hiace.Mobile.Device.Charger")}
                    </p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Hiace.Wifi")}</p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M64 64V352H576V64H64zM0 64C0 28.7 28.7 0 64 0H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM128 448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Hiace.Television")}</p>
                  </div>
                </div>

                <div className="col d-flex flex-row align-items-center">
                  <div className="col-auto me-2 d-flex align-self-center">
                    <svg
                      className="vehicle-specs-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 224c17.7 0 32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32zm-96 32c0 53 43 96 96 96s96-43 96-96s-43-96-96-96s-96 43-96 96zM96 240c0-35 17.5-71.1 45.2-98.8S205 96 240 96c8.8 0 16-7.2 16-16s-7.2-16-16-16c-45.4 0-89.2 22.3-121.5 54.5S64 194.6 64 240c0 8.8 7.2 16 16 16s16-7.2 16-16z" />
                    </svg>
                  </div>
                  <div className="col ps-0 d-flex align-self-center">
                    <p className="card-text">{t("Toyota.Hiace.DVD.Player")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2 end --> */}
      {/* <!-- section 3, image slider/ carousel --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mb-lg-0 pb-lg-3">
        <div className="row d-flex justify-content-center align-items-center mx-0 mb-3 mb-lg-0 px-3 px-md-0">
          <div className="col col-lg-10 pb-2">
            {/* Carousel Desktop */}
            <div
              id="carouselExampleIndicatorsDesktop"
              className="carousel slide d-none d-md-block"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsDesktop"
                  data-bs-slide-to="0"
                  className="carousel-indicators-item active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsDesktop"
                  data-bs-slide-to="1"
                  className="carousel-indicators-item"
                  aria-label="Slide 2"
                ></button>

                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsDesktop"
                  data-bs-slide-to="2"
                  className="carousel-indicators-item"
                  aria-label="Slide 3"
                ></button>

                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsDesktop"
                  data-bs-slide-to="3"
                  className="carousel-indicators-item"
                  aria-label="Slide 4"
                ></button>
              </div>

              <div className="d-flex align-items-center">
                <div className="carousel-button-container-left">
                  <button
                    className="carousel-button-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicatorsDesktop"
                    data-bs-slide="prev"
                  >
                    <i className="bi bi-arrow-left icon-next-prev"></i>
                  </button>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row row-cols-4 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace1}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(1)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace2}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(2)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace3}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(3)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace4}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(4)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-4 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace5}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(5)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace6}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(6)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace7}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(7)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace8}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(8)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-4 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace9}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(9)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace10}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(10)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace11}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(11)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace12}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(12)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row row-cols-4 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace13}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(13)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-button-container-right">
                  <button
                    className="carousel-button-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicatorsDesktop"
                    data-bs-slide="next"
                  >
                    <i className="bi bi-arrow-right icon-next-prev"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Carousel Mobile */}
            <div
              id="carouselExampleIndicatorsMobile"
              className="carousel slide d-block d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsMobile"
                  data-bs-slide-to="0"
                  className="carousel-indicators-item active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsMobile"
                  data-bs-slide-to="1"
                  className="carousel-indicators-item"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsMobile"
                  data-bs-slide-to="2"
                  className="carousel-indicators-item"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsMobile"
                  data-bs-slide-to="3"
                  className="carousel-indicators-item"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicatorsMobile"
                  data-bs-slide-to="4"
                  className="carousel-indicators-item"
                  aria-label="Slide 5"
                ></button>
              </div>

              <div className="d-flex align-items-center">
                <div className="carousel-button-container-left">
                  <button
                    className="carousel-button-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicatorsMobile"
                    data-bs-slide="prev"
                  >
                    <i className="bi bi-arrow-left icon-next-prev"></i>
                  </button>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row row-cols-3 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace1}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(1)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace2}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(2)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace3}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(3)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace4}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(4)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace5}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(5)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace6}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(6)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace7}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(7)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace8}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(8)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace9}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(9)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace10}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(10)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace11}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(11)}
                        />
                      </div>
                      <div className="col p-1">
                        <img
                          src={hiace12}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(12)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row row-cols-3 px-2">
                      <div className="col p-1">
                        <img
                          src={hiace13}
                          className="d-block w-100 carousel-image rounded-3"
                          alt="Toyota Hiace Grand Cabin"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setPreviewImage(13)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-button-container-right">
                  <button
                    className="carousel-button-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicatorsMobile"
                    data-bs-slide="next"
                  >
                    <i className="bi bi-arrow-right icon-next-prev"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3, image slider end --> */}
      {/* <!-- section 4 video --> */}
      <div className="container col-md-11 col-lg-9 mx-auto">
        <hr className="col-12 col-lg-10 mx-auto card-line-light-grey mt-5"></hr>
        <div className="row mx-0 mb-3 px-3 px-md-0 pt-3">
          <div className="col col-lg-10 offset-lg-1 pb-5 px-0">
            <div className="row row-cols-1 mb-3">
              <div className="card-body d-flex flex-column flex-md-row pb-0 align-items-md-center justify-content-between">
                <div className="col-sm-12 col-md-7 d-flex flex-column justify-content-center order-2 order-md-1">
                  <h3 className="fs-6 col-lg-9 mb-3">
                    {t("Toyota.Alphard.Video.Intro")}
                  </h3>
                  <p>{t("Toyota.Alphard.Video.Intro.Additional")}</p>
                </div>
                <div className="d-flex align-items-center order-1 order-md-2 pb-4 pb-md-0">
                  <div className="col-auto d-flex align-items-center">
                    <img
                      src={video_icon}
                      className="card-img"
                      alt="360-degree"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mx-0 px-0 bg-light-grey">
        <div className="container col-md-11 col-lg-9 mx-auto px-md-4 mt-5 pt-5 bg-light-grey">
          <div className="row mx-2 mx-sm-0 mt-n2 mb-5">
            <div className="col col-lg-10 offset-lg-1 px-0">
              <div className="ratio ratio-16x9">
                <iframe
                  width="956"
                  height="538"
                  src="https://www.youtube.com/embed/pkdMR98j8_k"
                  title="Toyota HiAce Grand Cabin VR 360 Video by THE LUXURY FLEET Co., Ltd."
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 4 end --> */}
      {/* <!-- section 5 specifications, heading --> */}
      <div className="row mx-0 mb-4 mb-lg-5 pb-md-4 pt-3 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3>{t("Specifications")}</h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs, mob --> */}
      <div className="d-block d-md-none container col-md-11 col-lg-9 mx-auto">
        <div className="row mx-0 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 px-0">
            <div className="row row-cols-2">
              <div className="col-12 col-md-5 col-lg-5 d-flex justify-content-center">
                <img
                  src={hiace_seatplan}
                  className="img-fluid"
                  alt="Toyota Hiace Grand Cabin seat map"
                />
              </div>

              <div className="col-12 col-md-7 col-lg-7 d-flex flex-column mb-auto">
                <div className="card-header ps-0 pt-5 pb-2">
                  {t("Toyota.Hiace.Tag")}
                </div>
                <div className="card-body ps-0 pt-1">
                  <h3 className="card-title mb-3">{t("Toyota.Hiace")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-md-none container col-md-11 col-lg-9 mx-auto px-0">
        <div className="row mx-0 mb-3 mb-md-5 px-md-0">
          <div className="col col-lg-10 offset-lg-1 pb-5 px-0">
            <ul
              className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable border-bottom border-top pt-3"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 active"
                  id="sizeSpecifications-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sizeSpecifications-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="sizeSpecifications-tab-pane"
                  aria-selected="true"
                >
                  {t("Size.Specifications")}
                </button>
              </li>
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4"
                  id="interiorSpace-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#interiorSpace-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="interiorSpace-tab-pane"
                  aria-selected="false"
                >
                  {t("Interior.Space")}
                </button>
              </li>
              <li className="nav-item mt-auto" role="presentation">
                <button
                  className="nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4"
                  id="capacity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#capacity-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="capacity-tab-pane"
                  aria-selected="false"
                >
                  {t("Capacity")}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="sizeSpecifications-tab-pane"
                role="tabpanel"
                aria-labelledby="sizeSpecifications-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Size.Dimensions")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <p>{t("Length")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Hiace.L")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("Width")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Hiace.W")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("Height")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Hiace.H")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="interiorSpace-tab-pane"
                role="tabpanel"
                aria-labelledby="interiorSpace-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Interior.Space")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <p>{t("No1")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Drivers.Seat")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("No2")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Passenger.Area")}</p>
                          </div>
                          <div className="col-6">
                            <p>{t("No3")}</p>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Luggage.Area")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="capacity-tab-pane"
                role="tabpanel"
                aria-labelledby="capacity-tab"
                tabIndex="0"
              >
                <div className="row g-0 border-bottom">
                  <div>
                    <div className="row g-0">
                      <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                        <div className="row">
                          <p className="card-title mb-0">
                            {t("Passengers.Luggage")}
                          </p>
                        </div>
                      </div>

                      <div className="col-10 mx-auto py-3 mb-2">
                        <div className="row">
                          <div className="col-6">
                            <i>
                              {" "}
                              <svg
                                className="vehicle-specs-icons"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                              >
                                <path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                              </svg>
                            </i>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Hiace.Passengers")}</p>
                          </div>
                          <div className="col-6">
                            <i>
                              <svg
                                className="vehicle-specs-icons"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                              >
                                <path d="M144 56c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v72H144V56zm176 72H288V56c0-30.9-25.1-56-56-56H152C121.1 0 96 25.1 96 56v72H64c-35.3 0-64 28.7-64 64V416c0 35.3 28.7 64 64 64c0 17.7 14.3 32 32 32s32-14.3 32-32H256c0 17.7 14.3 32 32 32s32-14.3 32-32c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64zM112 224H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 128H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                              </svg>
                            </i>
                          </div>
                          <div className="col-6 text-end">
                            <p>{t("Toyota.Hiace.Suitcases")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs, mob end --> */}
      {/* <!-- section 5 specifications, image and info tabs --> */}
      <div className="d-none d-md-block container col-md-11 col-lg-9 mx-auto pb-3">
        <div className="row mx-0 px-3 px-md-0">
          <div className="col col-lg-10 offset-lg-1 pb-lg-5 px-0">
            <div className="row row-cols-2 mb-lg-5">
              <div className="col-12 col-md-5 col-lg-5  d-flex justify-content-center">
                <img
                  src={hiace_seatplan}
                  className="img-fluid"
                  alt="Toyota Hiace Grand Cabin seat map"
                />
              </div>

              <div className="col-12 col-md-7 col-lg-7 d-flex flex-column mb-auto">
                <div className="card-header ps-0 pt-3 pb-2">
                  {t("Toyota.Hiace.Tag")}
                </div>
                <div className="card-body ps-0 pt-1">
                  <h3 className="card-title mb-3">{t("Toyota.Hiace")}</h3>
                </div>
                <div className="card">
                  <ul
                    className="nav nav-tabs nav-justified"
                    id="myTabMd"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 tab-link-light-bg-alt active py-4 tab-link-light-bg-radius-left border-end-0"
                        id="sizeSpecifications-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#sizeSpecifications-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="sizeSpecifications-tab-pane-md"
                        aria-selected="true"
                      >
                        {t("Size.Specifications")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 nav-link tab-link-light-bg-alt py-4 rounded-0 border-top-0 border-start border-end"
                        id="interiorSpace-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#interiorSpace-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="interiorSpace-tab-pane-md"
                        aria-selected="false"
                      >
                        {t("Interior.Space")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="h-100 tab-link-light-bg-alt py-4 tab-link-light-bg-radius-right border-start-0"
                        id="capacity-tab-md"
                        data-bs-toggle="tab"
                        data-bs-target="#capacity-tab-pane-md"
                        type="button"
                        role="tab"
                        aria-controls="capacity-tab-pane-md"
                        aria-selected="false"
                      >
                        {t("Capacity")}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContentMd">
                    <div
                      className="tab-pane fade show active"
                      id="sizeSpecifications-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="sizeSpecifications-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Size.Dimensions")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <p>{t("Length")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Hiace.L")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("Width")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Hiace.W")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("Height")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Hiace.H")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="interiorSpace-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="interiorSpace-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Interior.Space")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <p>{t("No1")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Drivers.Seat")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("No2")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Passenger.Area")}</p>
                                </div>
                                <div className="col-6">
                                  <p>{t("No3")}</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Luggage.Area")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="capacity-tab-pane-md"
                      role="tabpanel"
                      aria-labelledby="capacity-tab-md"
                      tabIndex="0"
                    >
                      <div className="row g-0 border-bottom">
                        <div>
                          <div className="row g-0">
                            <div className="col-10 mx-auto pt-4 pb-3 d-flex align-items-center border-bottom">
                              <div className="row">
                                <p className="card-title mb-0">
                                  {t("Passengers.Luggage")}
                                </p>
                              </div>
                            </div>

                            <div className="col-10 mx-auto py-3 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <i>
                                    {" "}
                                    <svg
                                      className="vehicle-specs-icons"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                                    </svg>
                                  </i>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Hiace.Passengers")}</p>
                                </div>
                                <div className="col-6">
                                  <i>
                                    <svg
                                      className="vehicle-specs-icons"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                    >
                                      <path d="M144 56c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v72H144V56zm176 72H288V56c0-30.9-25.1-56-56-56H152C121.1 0 96 25.1 96 56v72H64c-35.3 0-64 28.7-64 64V416c0 35.3 28.7 64 64 64c0 17.7 14.3 32 32 32s32-14.3 32-32H256c0 17.7 14.3 32 32 32s32-14.3 32-32c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64zM112 224H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 128H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                    </svg>
                                  </i>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{t("Toyota.Hiace.Suitcases")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 5 specifications, image and info tabs end --> */}
      {/* <!-- banner --> */}
      <div className="container-fluid cta-banner-hiace-grand pt-5 mt-3 mt-sm-5 mt-lg-0 pt-md-0">
        <div className="container px-md-5 pt-5 pt-md-0">
          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">
              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4 className="card-title-banner">
                  {t("CTA.Banner.Vehicle.Heading")}
                </h4>
              </div>

              <p className="col-12 col-md-11 col-lg-8 intro mb-4">
                {t("CTA.Banner.Vehicle.Intro")}
              </p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {t("Book.Your.Journey")}
                    <i className="ps-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 5L20 12L13 19M4 12H20H4Z"
                          stroke="#4A4A4A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Preview Image */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered ">
          <div className="modal-content custom-modal-preview-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="button-close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x icon-close"></i>
              </button>
            </div>
            <div className="card bg-transparent border-0">
              <div className="card-body ">
                <div className="row align-items-center ">
                  <div className="col-auto p-1">
                    <button
                      type="button"
                      className="custom-modal-preview-next-prev"
                      onClick={() =>
                        setPreviewImage(
                          previewImage === 1 ? 13 : previewImage - 1
                        )
                      }
                    >
                      <i className="bi bi-arrow-left icon-next-prev"></i>
                    </button>
                  </div>
                  <div className="col px-2">
                    <img
                      src={
                        previewImage === 1
                          ? hiace1
                          : previewImage === 2
                            ? hiace2
                            : previewImage === 3
                              ? hiace3
                              : previewImage === 4
                                ? hiace4
                                : previewImage === 5
                                  ? hiace5
                                  : previewImage === 6
                                    ? hiace6
                                    : previewImage === 7
                                      ? hiace7
                                      : previewImage === 8
                                        ? hiace8
                                        : previewImage === 9
                                          ? hiace9
                                          : previewImage === 10
                                            ? hiace10
                                            : previewImage === 11
                                              ? hiace11
                                              : previewImage === 12
                                                ? hiace12
                                                : previewImage === 13
                                                  ? hiace13
                                                  : ""
                      }
                      className="img-fluid img-modal-carousel"
                      alt="Preview"
                    />
                  </div>
                  <div className="col-auto p-1">
                    <button
                      type="button"
                      className="custom-modal-preview-next-prev"
                      onClick={() =>
                        setPreviewImage(
                          previewImage === 13 ? 1 : previewImage + 1
                        )
                      }
                    >
                      <i className="bi bi-arrow-right icon-next-prev"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default HiaceGrandCabinCarPage;
