import React, { useState, useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { withAlert, types } from "react-alert";
import { useTranslation } from "react-i18next";
import Line from "../../images/tlf-line-qr.svg";
import { sendMail } from "../../actions/admin/mail";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";

const Contact = ({ alert }) => {
  const page_name = "cms-contact";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  const [isloading, setIsloading] = useState(false);
  const [formData, setFormData] = useState({
    companyname: "",
    contactperson: "",
    phone: "",
    email: "",
    inquiry: "",
  });

  const clearForm = () => {
    setFormData({
      companyname: "",
      contactperson: "",
      phone: "",
      email: "",
      inquiry: "",
    });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmitForm = async () => {
    if (validateFrom()) await sendMailToClient();
  };

  const validateFrom = () => {
    if (
      formData.companyname === "" ||
      formData.contactperson === "" ||
      formData.phone === "" ||
      formData.email === "" ||
      formData.inquiry === ""
    ) {
      alert.show(t("Please fill all the fields"), { type: types.ERROR });
      return false;
    } else return true;
  };

  const sendMailToClient = async () => {
    setIsloading(true);
    const mailRes = await sendMail(
      "info@theluxuryfleet.com",
      // "supun1986@gmail.com",
      "New inquiry from contact form " + formData.contactperson,
      getEmailBody()
    );
    if (mailRes.msg === "success") {
      clearForm();
      console.log("Inquiry has been sent");
      alert.show(t("Your Inquiry has been sent"), { type: types.INFO });
    } else {
      console.log("Something wrong");
      alert.show(t("Something wrong."), { type: types.ERROR });
    }
    setIsloading(false);
  };

  const getEmailBody = () => {
    return (
      "<hr/><p>Company Name : " +
      formData.companyname +
      "</p><p>Full Name: " +
      formData.contactperson +
      "</p><p>Contact Number: " +
      formData.phone +
      "</p><p>Email : " +
      formData.email +
      "</p><p>Inquiry details : " +
      formData.inquiry +
      "</p><hr/>"
    );
  };

  return (
    <FrontLayout>

    {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-contact d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Contact.Us")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Contact.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Contact.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- section 1 --> */}
      <div className="container-fluid bg-light-grey pt-3 pt-lg-5">
        <div className="custom-mx">
          <div className="container pb-2 pt-5 py-lg-5 col-lg-12">
            <div className="row mb-5 gy-2 d-flex justify-content-center">
              <div className="col-12 col-lg-7 me-2 me-lg-0">
                <div className="card shadow-sm mb-2 px-3 py-3 border-bottom-gold-thick">
                  <div className="card-body">
                    <div className="row mb-4">
                      <h1
                        className="fw-bold text-center text-lg-start text-dark"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content4
                              : cmspage?.page_content_jp.content4,
                        }}
                      >
                        {/* {t("Contact.Form.Heading")} */}
                      </h1>
                    </div>
                    <div className="row">
                      <form>
                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="text"
                              className={`form-control contact-form`}
                              id="companyName"
                              placeholder="Company name"
                              name="companyname"
                              onChange={(e) => onChange(e)}
                              value={formData.companyname}
                              required
                            />
                            <label htmlFor="companyName">
                              {t("Company.Name")}
                            </label>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="text"
                              className={`form-control contact-form`}
                              id="contactPerson"
                              placeholder="Contact person"
                              name="contactperson"
                              onChange={(e) => onChange(e)}
                              value={formData.contactperson}
                              required
                            />
                            <label htmlFor="contactPerson">
                              {t("Contact.Person")}
                            </label>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="text"
                              className={`form-control contact-form`}
                              id="phoneNumber"
                              placeholder="Phone Number"
                              name="phone"
                              onChange={(e) => onChange(e)}
                              value={formData.phone}
                              required
                            />
                            <label htmlFor="phoneNumber">
                              {t("Phone.Number")}
                            </label>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <input
                              type="email"
                              className={`form-control contact-form`}
                              id="emailInquiry"
                              placeholder="Email Address"
                              name="email"
                              onChange={(e) => onChange(e)}
                              value={formData.email}
                              required
                            />
                            <label htmlFor="emailInquiry">
                              {t("Email.Address")}
                            </label>
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col form-floating fs-8">
                            <textarea
                              className="form-control contact-form py-5 h-100"
                              placeholder="Inquiry details"
                              id="inquiryDetailsTextarea"
                              rows="7"
                              onChange={(e) => onChange(e)}
                              name="inquiry"
                              value={formData.inquiry}
                              required
                            ></textarea>
                            <label htmlFor="inquiryDetailsTextarea">
                              {t("Inquiry.Details")}
                            </label>
                          </div>
                        </div>

                        <div className="d-grid gap-2 d-md-block mt-auto">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm px-4 d-flex justify-content-center px-md-5 py-2"
                            onClick={onSubmitForm}
                          >
                            <div className="d-flex align-items-center px-2 d-inline">
                              {t("Contact.Form.Button.Text")}
                              <i className="ps-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 5L20 12L13 19M4 12H20H4Z"
                                    stroke="#4A4A4A"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </i>
                            </div>
                          </button>
                          <br />
                          {isloading ? (
                            <div
                              className="spinner-border text-warning"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- contact details col --> */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-5 mt-lg-2">
                <div className="card border-0 mb-2 bg-light-grey">
                  <div className="row pt-0 card-body">
                    <h5
                      className="fw-bold text-center text-lg-start mt-4 mt-lg-0"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content5
                            : cmspage?.page_content_jp.content5,
                      }}
                    >
                      {/* {t("Contact.Heading.Subheading.One")} */}
                    </h5>

                    <div className="border-0 text-dark border-bottom-grey pb-3 pb-lg-0">
                      <div className="row mt-3 mb-3">
                        <div className="col col-lg-auto">
                          <p
                            className="my-0 text-center text-lg-start"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content6
                                  : cmspage?.page_content_jp.content6,
                            }}
                          >
                            {/* {t("Footer.Address")} */}
                          </p>
                        </div>
                      </div>
                    </div>

                    <h5
                      className="fw-bold text-center text-lg-start mt-4 mt-lg-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content7
                            : cmspage?.page_content_jp.content7,
                      }}
                    >
                      {/* {t("Contact.Heading.Subheading.Three")} */}
                    </h5>

                    <div className="border-0 text-dark border-bottom-grey pb-3 pb-lg-0">
                      <div className="row mt-3">
                        <div className="col col-lg-auto">
                          <p
                            className="text-dark my-0 text-center text-lg-start"
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content8
                                  : cmspage?.page_content_jp.content8,
                            }}
                          >
                            {/* {t(
                              "Contact.Heading.Subheading.Three.Paragraph.One"
                            )} */}
                          </p>
                        </div>
                      </div>
                    </div>

                    <h5
                      className="fw-bold text-center text-lg-start mt-4 mt-lg-3"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content9
                            : cmspage?.page_content_jp.content9,
                      }}
                    >
                      {/* {t("Contact.Heading.Subheading.Two")} */}
                    </h5>

                    <div className="border-0 text-dark">
                      <div className="row mt-3">
                        <div className="col col-lg-auto">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content10
                                  : cmspage?.page_content_jp.content10,
                            }}
                          ></div>
                          {/* <p className="text-dark my-0 text-center text-lg-start">
                            {t("Contact.Heading.Subheading.Two.Paragraph.One")}
                          </p>
                          <p className="mt-3 text-center text-lg-start">
                            {t("Telephone")}
                          </p>
                          <p className="mt-3 text-center text-lg-start">
                            {t("Whatsapp")}
                          </p>
                          <p className="mt-3 text-center text-lg-start">
                            <a
                              className="d-inline px-0 py-0 text-decoration-underline text-dark"
                              href="https://page.line.me/597pbxos?openQrModal=true"
                            >
                              {t("Line")}
                            </a>
                          </p> */}
                          <div className="col-8 col-md-4 col-lg-7 mx-auto mx-lg-0">
                            <img
                              src={Line}
                              className="card-img-top mt-1"
                              alt="THE LUXURY FLEET LINE QR code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default withAlert()(Contact);
