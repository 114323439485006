import React, { Fragment, useEffect, useState, useMemo } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import ByTheHourForm from "../layout-new-theme/form-card/ByTheHourForm";
import SpecifiedDestinationForm from '../layout-new-theme/form-card/SpecifiedDestinationForm';
import { useDispatch, useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addSingleDestinationFormToState,
  addMultipleDestinationFormToState,
  addAirportFormToState,
  addByTheHourFormToState,
  addSpecifiedDestinationFormToState,
} from "../../actions/admin/booking";
import {
  checkTokyoAvailability,
  getTravelDateWithPickupTime,
  getTotal,
  isDateInMultiplierZone,
  getKey,
  getTotalPaxCount,
  getTotalLuggageCount,
  getTravelDateWithDropoffTime,
  getTotalLuggageCountArray,
  getTotalPaxCountArray,
} from "../../utils/Calculator";
import {
  getAvailableVehicleTypes,
  getAvailableVehicles,
} from "../../actions/admin/booking";
import moment from "../../utils/customMoment";
import { isAfter } from "date-fns";
import { getRouteCar, getRouteCarForTotalTime, getRouteCarPackage } from "../../actions/navitimeAPI";
import { getSetting } from "../../actions/admin/settings";
import VehicleTypeCard from "../layout-new-theme/cards/VehicleTypeCard";
import CarSelection from "../layout-new-theme/cards/CarSelection";
import { withAlert, types } from "react-alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { filterNewPackage } from "../../actions/admin/package";
import { ColorRing } from "react-loader-spinner";
import _ from 'lodash';
import LoginModal from "../auth/LoginModal";
import AlertModal from "./AlertModal";

const Discover = ({ alert, isAuthenticated }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("byTheHour");
  const { byTheHourFormData, specifiedDestinationFormData, cart } = useSelector(
    (state) => state.booking
  );
  const settings = useSelector((state) => state.setting);
  const { setting, lang } = settings;
  const [isloading, setIsloading] = useState(false);
  const [newtravelDate, setNewTravelDate] = useState(null);
  const [vehicleTypeNames, setVehicleTypeNames] = useState([]);
  const [garageArivalTime, setgarageArivalTime] = useState("");
  const [tripStartTime, setTripStartTime] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [tollFare, setTollFare] = useState(0);
  const [travelAreaPrice, setTravelAreaPrice] = useState(0);
  const [longDistanceSurcharge, setLongDistanceSurcharge] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalTimeWithoutPackage, setTotalTimeWithoutPackage] = useState(0);
  const [cutomerChangedDropOffTimeOnly, setcutomerChangedDropOffTimeOnly] = useState("");
  const [cutomerChangedDropOffDateTimeOnly, setcutomerChangedDropOffDateTimeOnly] = useState("");
  const [cutomerActualDropOffTime, setcutomerActualDropOffTime] = useState(null);
  const [cutomerDropOffTimeWithAdditinalTime, setCutomerDropOffTimeWithAdditinalTime] = useState(null);
  const [garageArivalTimeWithAdditionalTime, setGarageArivalTimeWithAdditionalTime] = useState(null);
  const [activatedPackages, setActivatedPackages] = useState([]);
  const [indexActivatedPackages, setIndexActivatedPackages] = useState(null);
  const [packageList, setPackageList] = useState([]);
  const [clickedVehicleType, setClickedVehicleType] = useState("");
  const [packageAdditionaltime, setPackageAdditionaltime] = useState(0);
  const [showDetailsPannels, setShowDetailsPannels] = useState(false);
  const [sfd, setSfd] = useState({
    pickuplocation: null,
    dropofflocations: [],
    traveldate: "",
    pickuptime: "",
    dropofftime: "",
    pax: null,
    flightNo: "",
    isFromAirport: null,
    isToAirport: null,
    isAirportTransfer: null,
    byTheHourBook: false,
    lugage: null,
    flightTracking: null,
    travelArea: null
  });
  const [note, setNote] = useState("");
  const [cartID, setCartID] = useState(null);
  const [carRoute, setCarRoute] = useState(null);
  const [isEquationApplied, setIsEquationApplied] = useState(false);

  //state for handle book more than 9 people
  const [bookingInfo, setBookingInfo] = useState([]);
  const [selectedManyVehicleType, setSelectedManyVehicleType] = useState([]);
  const [clickedManyVehicleType, setClickedManyVehicleType] = useState([]);
  const [countCarToChoose, setCountCarToChoose] = useState(0);

  const [distanceAppliedPackage, setDistanceAppliedPackage] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [formdatavalidation, setFormdatavalidation] = useState({
    pickuplocation: true,
    dropofflocations: true,
    traveldate: true,
    pickuptime: true,
    pax: true,
    flightNumber: true,
    duration: true,
    luggage: null,
  });

  /**
   * * Set the Cart ID when the page is loading. This should only happen IF THE CART IS NOT EMPTY! The Cart ID is used to identify bookings that
   * were made as part of the same purchase/ cart. So, if a cart has 3 bookings in it that are paid together, then they will all share the same cart ID.
   * The cart behaves as a "container" object for the bookings.
   */
  useEffect(() => {
    if (cart?.length === 0 || cart === null) return;
    setCartID(cart[0].randomId);
  }, [cart]);

  /**
   * * Set any active airport transfer packages to the state.
   */
  useEffect(() => {
    if (packageList === null) return;
    if (packageList?.length === 0) return;
    
    setActivatedPackages([packageList[0]]);
  }, [packageList]);

  /**
   * * Reset all the form values to their initial / empty values.
   */
  const resetFormValues = () => {
    setShowDetailsPannels(false);
    setSfd({
      pickuplocation: null,
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      pax: null,
      flightNo: "",
      isToAirport: null,
      isFromAirport: null,
      isAirportTransfer: null,
    });
    setcutomerChangedDropOffTimeOnly("");
  };

  /**
 * * Set the selected tab (By The Hour Destination, Specified Destination) based on which tab was active during the redirect from the
 * home-page (if the booking was started there)
 * ? By The Hour form tab will be selected
 */
  const byTheHourFormAvailableVehicle = (byTheHourFormData) => {
    setShowDetailsPannels(true);
    setTabName("byTheHour");
    setSfd(byTheHourFormData);
    dispatch(addByTheHourFormToState(byTheHourFormData));
    dispatch(addSpecifiedDestinationFormToState(null));
    checkAvailability(byTheHourFormData);
  };

  /**
 * * Set the selected tab (By The Hour Destination, Specified Destination) based on which tab was active during the redirect from the
 * home-page (if the booking was started there)
 * ? Specified Destination form tab will be selected
 */
  const specifiedDestinationFormAvailableVehicle = (specifiedDestinationFormData) => {
    setShowDetailsPannels(true);
    setTabName("specifiedDestination");
    setSfd(specifiedDestinationFormData);
    dispatch(addByTheHourFormToState(null));
    dispatch(addSpecifiedDestinationFormToState(specifiedDestinationFormData));
    checkAvailability(specifiedDestinationFormData);
  };

  /**
   * * Set the selected tab (By The Hour, Specified Destination) based on which tab was active during the redirect from the
   * home-page (if the booking was started there)
   * * Set state varibles
   * ? When the parameters change, this funcion will be fired
   * @param byTheHourFormData
   * @param specifiedDestinationFormData
   */
  useEffect(() => {
    if (!settings) dispatch(getSetting());
    if (byTheHourFormData) {
      byTheHourFormAvailableVehicle(byTheHourFormData);
    } else if (specifiedDestinationFormData) {
      specifiedDestinationFormAvailableVehicle(specifiedDestinationFormData);
    }

  }, [byTheHourFormData, specifiedDestinationFormData]);

  useEffect(() => {
    if (showDetailsPannels) {
      const element = document.getElementById("scroll01");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

  }, [showDetailsPannels]);

  /**
   * * This function will be called when the "Search available vehicles" button is clicked by the user
   * * After that, the listed state varibles will be set to their initial vaues
   */
  const availabilityValueReset = () => {
    setShowDetailsPannels(false);
    setActivatedPackages([]);
    setPackageList([]);
    setIndexActivatedPackages(null);
    setTotalTimeWithoutPackage(0);
    setDistanceAppliedPackage(0);
    setClickedVehicleType(null);
    setVehicleTypeNames([]);
    setBookingInfo([]);
    setSelectedManyVehicleType([]);
    setClickedManyVehicleType([]);
  };

  /**
   * * Main function here. Fire when the "Search available vehicles" button is clicked by the user.
   * ? sfd - Selected Form Data
   * @param sfd
   */
  const checkAvailability = async (sfd) => {
    setIsloading(true);
    availabilityValueReset();
    const buffer_time = setting.buffertime !== null ? setting.buffertime : 0;

    const totalPax = getTotalPaxCount(sfd.pax);

    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0
    ) {
      // alert.show(
      //   t("Please fill in all the required fields (highlighted in red)."),
      //   { type: types.ERROR }
      // );
      setIsloading(false);
    } else {
      setVehicleTypeNames([]);
      setgarageArivalTime(null);

      var hasTokyo = checkTokyoAvailability(
        sfd.pickuplocation,
        sfd.dropofflocations
      );

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);


      // Set the travel date to state variable
      setNewTravelDate(travel_date_with_pickup_time);

      // Set the buffer time to current time (buffer time can be specified in hours by the TLF admins in the settings page of the Admin Panel)
      // The buffer time determines how many hours ahead a booking can be. For example, if the buffer time is set to 12 hours, any booking must
      // be at least 12 hours in the future, from the current time specified here
      const date_with_buffer_time = moment()
        .tz("Asia/Tokyo")
        .add(buffer_time, "hours");

      // Validate the travel date and time after setting the buffer time. This must evaluate to a true value in order to continue the function
      var isafter = isAfter(
        travel_date_with_pickup_time._d,
        date_with_buffer_time._d
      );

      // "Tokyo" must be present in EITHER the pick-up OR drop-off address (due to legal conditions in the TLF business license). If false, show error.
      // Show another error if the buffer time is less than the number of hours specified in the Admin Panel settings
      if (hasTokyo === false) {
        // alert.show(t("Your pick-up or drop off point must be within Tokyo."), {
        //   type: types.INFO,
        // });
        setmodalAlertText("Your pick-up or drop off point must be within Tokyo.");
        setShowModalAlert(true);
      } else if (isafter === false) {
        setFormdatavalidation({
          ...formdatavalidation,
          pickuptime: false
        });
      } else {
        const via = getViaList(sfd);
        const travel_date_with_format = travel_date_with_pickup_time.format(
          "YYYY-MM-DDThh:mm:ss"
        );
        const car_r = await getRouteCar(
          "35.6235029,139.7410879",
          via,
          travel_date_with_format
        );

        setCountCarToChoose(1);

        // Set the data returned by the Navitime API call to the session variable
        setCarRoute(car_r);
      }
    }
    setIsloading(false);
  };

  useEffect(() => {
    setFormdatavalidation({
      pickuplocation: true,
      dropofflocations: true,
      traveldate: true,
      pickuptime: true,
      pax: true,
      flightNumber: true,
      duration: true,
      luggage: null
    });
  }, [tabName]);

  /**
   * * When the carRoute state changes, this function will fire
   */
  useEffect(() => {
    // Parameter value cannot be null
    if (carRoute === null) return;

    // Response cannot be null. The most common reason is due to the Navitime request limit allocated to the API key being exceeded, hence the error message
    // However, there could be other reasons, so additional troubleshooting may be required if the Navitime API is under the request limit
    // The limit can be viewed in the RapidAPI dashboard. See the comments in client/src/actions/navitimeAPI.js for more information. Afra has the login.
    if (carRoute[0].response != null) {
      const status = carRoute[0].response.status;
      const statusTest = carRoute[0].response.statusText;
      const erMsg =
        "Code :" +
        status +
        " | Message : " +
        statusTest +
        " | Navtime request limit exceeded.";
      // alert.show(erMsg, { type: types.ERROR });

      setmodalAlertText(erMsg);
      setShowModalAlert(true);
      setIsloading(false);
      return;
    }

    //  Set Navtime response data to the state here
    //?efdt=estimated final dropoff time
    var efdt = null;
    if (carRoute.length > 0) {
      const {
        trip_start_time,
        distance,
        fare,
        longDistanceSurcharge,
        total_time,
        estimated_final_dropoff_time,
        estimated_customer_final_dropoff_time,
        tdwdt,
        gatime,
        travelAreaPrice
      } = getSelectedRouteData(carRoute, sfd);
      //  The TLF team can set the "total trip time" in the Admin Panel settings. Bookings CANNOT exceed this time
      // If the time from the garage, back to the garage is longer than this "total trip time", it will throw an error.
      if (!sfd.byTheHourBook) {
        if (Number(total_time / 60) > Number(setting.totaltriptime)) {
          setmodalAlertText("For bookings longer than " + setting.totaltriptime + " hours, please contact our sales team.");
          setShowModalAlert(true);
          setIsloading(false);
          return;
        }
      }

      efdt = estimated_final_dropoff_time;
      setTripStartTime(trip_start_time._d);
      setTotalDistance(distance);
      setTollFare(fare);
      setTravelAreaPrice(travelAreaPrice);
      setLongDistanceSurcharge(longDistanceSurcharge)
      setTotalTime(total_time);

      // Set the customer set drop-off time (if the customer opts to choose a drop-off time, instead of letting the API automatically choose a time
      // Nothing to chage here. Values will be set in the top funtion

      setcutomerChangedDropOffTimeOnly(
        estimated_customer_final_dropoff_time.format("HH:mm")
      );
      setcutomerChangedDropOffDateTimeOnly(
        estimated_customer_final_dropoff_time.format("YYYY-MM-DD HH:mm")
      );

      setcutomerActualDropOffTime(tdwdt);
      setCutomerDropOffTimeWithAdditinalTime(tdwdt);
      setGarageArivalTimeWithAdditionalTime(gatime);
      setgarageArivalTime(gatime);

      const totallug = getTotalLuggageCount(sfd.lugage);
      const totalPax = getTotalPaxCount(sfd.pax);

      // Get all available vehicle types (available at the time requested for the booking; i.e. vehicle types which have free vehicles during the booking time)
      setVehicleTypeArray(trip_start_time, efdt, totalPax, totallug);

      const element = document.getElementById("scroll01");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [carRoute]);

  const getHighestPackage = async (packages) => {
    if (packages?.length > 1) {
      const result = packages
        .reduce((acc, obj, currentIndex) => {
          if (obj !== null) {
            const vehWithIdA = obj.vehicles.find(v => v.vehicle === "61a06a9a57475442649e582d");
            if (vehWithIdA && vehWithIdA.vehicleCost > (acc.biggestObject ? acc.biggestObject.vehicles.find(v => v.vehicle === "61a06a9a57475442649e582d").vehicleCost : -Infinity)) {
              return { biggestObject: { ...obj }, index: currentIndex };
            }
          }
          return acc;
        }, { biggestObject: null, index: -1 });

      return {highestPackage:[result.biggestObject], index: result.index};
    }else return {highestPackage:[packages[0]], index: 0};
  }

  /**
   * * Get the selected route's data
   * @param car_r - Data returned by the Navitime Route (Car) endpoint
   * @param sfd - form data(single destination, multi destination or airport transfer form data)
   * @param fare - return parameter - toll fare from the Navitime API
   */
  const getSelectedRouteDataWithPackageApplied = async (car_r, sfd, indexPackageApplied, appliedPackage) => {
    var start, end;
    var package_car_r;

    if(indexPackageApplied == 0){
      start = "35.6235029,139.7410879";
      const via = JSON.stringify([{lat: sfd.pickuplocation.lat, lon: sfd.pickuplocation.lon}]);
      end = sfd.dropofflocations[0].lat + "%2C" + sfd.dropofflocations[0].lon;
      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const start_time = travel_date_with_pickup_time.format(
        "YYYY-MM-DDThh:mm:ss"
      );

      package_car_r = await getRouteCarPackage(start, via, end, start_time, "true");

    }else if(indexPackageApplied == sfd.dropofflocations.length - 1){
      end = "35.6235029,139.7410879";
      const via = JSON.stringify([{lat: sfd.dropofflocations[sfd.dropofflocations.length - 1].lat, lon: sfd.dropofflocations[sfd.dropofflocations.length - 1].lon}]);
      start = sfd.dropofflocations[sfd.dropofflocations.length - 2].lat + "%2C" + sfd.dropofflocations[sfd.dropofflocations.length - 2].lon;
      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const start_time = travel_date_with_pickup_time.format(
        "YYYY-MM-DDThh:mm:ss"
      );

      package_car_r = await getRouteCarPackage(start, via, end, start_time, "true");
    }else{
      start = sfd.dropofflocations[indexPackageApplied-1].lat + "%2C" + sfd.dropofflocations[indexPackageApplied-1].lon;
      end = sfd.dropofflocations[indexPackageApplied].lat + "%2C" + sfd.dropofflocations[indexPackageApplied].lon;
      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const start_time = travel_date_with_pickup_time.format(
        "YYYY-MM-DDThh:mm:ss"
      );

      package_car_r = await getRouteCarPackage(start, "null", end, start_time, "false");
    }

    if (car_r) {
      if (sfd.byTheHourBook) return;

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const { travel_date_with_dropoff_time_initial } =
        getTravelDateWithDropoffTime(sfd);

      const summary_arr = car_r[car_r.length - 1]?.summary;

      const section = car_r[car_r.length - 1]?.sections;
      const section_length = section.length;

      const package_summary_arr = package_car_r[package_car_r.length - 1]?.summary;

      const final_drop_off_section = section[section_length - 2];

      
      const total_time = summary_arr.move.time;
      const package_total_time = package_summary_arr.move.time;

      var newtime = travel_date_with_pickup_time;

      const distance = summary_arr.move.distance;
      var appliedPackageDistance = distance - package_summary_arr.move.distance;

      if (summary_arr.move.fare != null) {
        var fare = summary_arr.move.fare.unit_1025_2 && package_summary_arr.move.fare.unit_1025_2
          ? (summary_arr.move.fare.unit_1025_2 - package_summary_arr.move.fare.unit_1025_2)
          : 0;
      } else {
        fare = 0;
      }

      const final_Drop_to_garrage_time_in_minutes = Number(
        final_drop_off_section.time
      );
      var estimated_customer_final_dropoff_time = moment(newtime).add(
        total_time - final_Drop_to_garrage_time_in_minutes,
        "minutes"
      );

      // isAfterEstimatedTime = true = > The customer set drop-off time is greater than the estimated time provided by the Navitime API
      const isCustomerDropOffAfterEstimatedTime =
        travel_date_with_dropoff_time_initial.isAfter(
          estimated_customer_final_dropoff_time
        );

      var userDdedAditionalTime = isCustomerDropOffAfterEstimatedTime
        ? travel_date_with_dropoff_time_initial.diff(
          estimated_customer_final_dropoff_time,
          "minutes"
        )
        : 0;


      if (isCustomerDropOffAfterEstimatedTime) {
        estimated_customer_final_dropoff_time = moment(newtime).add(
          total_time +
          userDdedAditionalTime -
          final_Drop_to_garrage_time_in_minutes,
          "minutes"
        );
      }

      let floatDistance = appliedPackageDistance / 200000;
      let intDistance = Math.floor(floatDistance);
      var longDistanceSurcharge = intDistance * 20000;

      return {
        fare,
        longDistanceSurcharge,
        appliedPackageDistance,
        total_time_without_package: total_time - package_total_time,
      };
    } else {
      return
    }
  };

  /**
   * * Set the vehicle type array
   * @param trip_start_time - trip start time,
   * @param efdt - estimated final dropoff time,
   * @param totalPax - total pax,
   * @param totallug - total lugage
   */
  const setVehicleTypeArray = async (
    trip_start_time,
    efdt,
    totalPax,
    totallug
  ) => {
    const v_types_arr = await getAvailableVehicleTypes(
      trip_start_time._d.toString(),
      efdt._d.toString(),
      totalPax,
      totallug
    );

    if (v_types_arr.length === 0) {
      setmodalAlertText("We have no available vehicles on the selected day.");
      setShowModalAlert(true);
      return;
    }

    if (specifiedDestinationFormData === null) {
      const sortedArray = v_types_arr.sort((a, b) => a.baseprice - b.baseprice);
      setVehicleTypeNames(sortedArray);

      setShowDetailsPannels(true);
    } else {

      if (specifiedDestinationFormData.dropofflocations.length > 0) {
        const pkges = await filterNewPackage({
          pickupLocation: specifiedDestinationFormData.pickuplocation,
          dropoffLocation: specifiedDestinationFormData.dropofflocations,
          isAirportTransfer: specifiedDestinationFormData.isAirportTransfer,
        });

        if(!pkges.every(element => element === null)){
          if(pkges.length > 1){
            const {highestPackage, index} = await getHighestPackage(pkges)

            const {
              fare,
              longDistanceSurcharge,
              appliedPackageDistance,
              total_time_without_package
            } = await getSelectedRouteDataWithPackageApplied(carRoute, sfd, index, highestPackage[0]);
      
            setTollFare(fare);
            setLongDistanceSurcharge(longDistanceSurcharge)
            setDistanceAppliedPackage(appliedPackageDistance)
            setTotalTimeWithoutPackage(total_time_without_package)
  
            setPackageList(highestPackage)
            setIndexActivatedPackages(index)
          }else{
            setPackageList(pkges);
          }
        }

      }

      const sortedArray = v_types_arr.sort((a, b) => a.baseprice - b.baseprice);
      setVehicleTypeNames(sortedArray);

      setShowDetailsPannels(true);
    }
  };

  /**
   * * Get the selected route's data
   * @param car_r - Data returned by the Navitime Route (Car) endpoint
   * @param sfd - form data(single destination, multi destination or airport transfer form data)
   * @param trip_start_time - return parameter - Time of dispatch from Garage (calculated by the system using the Navitime API)
   * @param distance - return parameter - route distance from the Navitime API
   * @param fare - return parameter - toll fare from the Navitime API
   * @param earlyFee - return parameter - early morning fee < 06:00
   * @param lateFee - return parameter - late night fee > 23:00
   * @param total_time - return parameter - total time in minutes
   * @param estimated_final_dropoff_time - return parameter - final drop off time
   * @param estimated_customer_final_dropoff_time - return parameter -  estimated final customer drop off time (i.e. what time does the driver drop off the customer at the destination)
   * @param tdwdt - return parameter -  travel date with dropoff time (string format)
   * @param gatime - return parameter - estimated final arrival time at the garage (string format)
   * @param travelAreaPrice - return parameter - travel area price for by the hour booking
   */
  const getSelectedRouteData = (car_r, sfd) => {
    if (car_r) {
      var byTheHourBook = sfd.byTheHourBook
      var totalDurationByTheHour = 0
      if (sfd.byTheHourBook) {
        byTheHourBook = true
        totalDurationByTheHour = getTotalDurationInMinutes(sfd.duration);
      }

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const { travel_date_with_dropoff_time_initial } =
        getTravelDateWithDropoffTime(sfd);

      const summary_arr = car_r[car_r.length - 1]?.summary;
      const sections_arr = car_r[car_r.length - 1]?.sections;
      const garage_to_pickup_time = sections_arr[1].time;

      const section = car_r[car_r.length - 1]?.sections;
      const section_length = section.length;

      //final_drop_off_section : dropoff customer to garage move (object)
      const final_drop_off_section = section[section_length - 2];

      // total_time : add aditional time - customer set drop-off time here;
      // const total_time = summary_arr.move.time + userDdedAditionalTime;
      const distance = summary_arr.move.distance;
      var total_time = sfd.byTheHourBook ? (garage_to_pickup_time + totalDurationByTheHour + Number(final_drop_off_section.time)) : summary_arr.move.time;

      //add break time
      var breakTime = 0;
      if (distance < 200000) {
        var additionalTime = (total_time / 30) * 5;
      } else {
        var additionalTime = (total_time / 30) * 7.5;

        breakTime += ((total_time + additionalTime) / 120 * 15);
      }

      const flightTrackingTime = sfd.flightTracking ? 60 : 0;

      total_time += (additionalTime + breakTime + flightTrackingTime); 


      var newtime = travel_date_with_pickup_time;
      var estimated_final_dropoff_time = moment(newtime).add(
        total_time,
        "minutes"
      );

      // Trip start time. No changes
      var trip_start_time = travel_date_with_pickup_time.subtract(
        garage_to_pickup_time,
        "minutes"
      );

      if (summary_arr.move.fare != null && sfd.byTheHourBook !== true) {
        var fare = summary_arr.move.fare.unit_1025_2
          ? summary_arr.move.fare.unit_1025_2
          : 0;
      } else {
        fare = 0;
      }

      let travelAreaPrice = 0;
      if (sfd.byTheHourBook){
        switch (sfd.travelArea) {
          case "withinCity":
            travelAreaPrice = Number(process.env.REACT_APP_WITHIN_CITY_PRICE);
            break;

          case "neighboringPerfecture":
            travelAreaPrice = Number(process.env.REACT_APP_NEIGHBORING_PERFECTURE_PRICE);
            break;
          case "undecided":
            travelAreaPrice = Number(process.env.REACT_APP_UNDECIDED_PRICE);
            break;
          default:
            break;
        }
      }else{
        travelAreaPrice = 0;
      }

      const final_Drop_to_garrage_time_in_minutes = Number(
        final_drop_off_section.time
      );
      var estimated_customer_final_dropoff_time = moment(newtime).add(
        total_time - final_Drop_to_garrage_time_in_minutes,
        "minutes"
      );

      // isAfterEstimatedTime = true = > The customer set drop-off time is greater than the estimated time provided by the Navitime API
      const isCustomerDropOffAfterEstimatedTime =
        travel_date_with_dropoff_time_initial.isAfter(
          estimated_customer_final_dropoff_time
        );

      var userDdedAditionalTime = isCustomerDropOffAfterEstimatedTime
        ? travel_date_with_dropoff_time_initial.diff(
          estimated_customer_final_dropoff_time,
          "minutes"
        )
        : 0;


      estimated_final_dropoff_time = moment(newtime).add(
        total_time + userDdedAditionalTime,
        "minutes"
      );

      if (isCustomerDropOffAfterEstimatedTime) {
        estimated_customer_final_dropoff_time = moment(newtime).add(
          total_time +
          userDdedAditionalTime -
          final_Drop_to_garrage_time_in_minutes,
          "minutes"
        );
      }

      const ecfdt = estimated_customer_final_dropoff_time.format("HH:mm:ss");
      var d_arr = ecfdt.split(":");
      var d_h = Number(d_arr[0]);
      var d_m = Number(d_arr[1]);

      var travel_date_with_dropoff_time = moment(newtravelDate).set({
        hour: d_h,
        minute: d_m,
      });
      const tdwdt = travel_date_with_dropoff_time._d;
      const gatime = estimated_final_dropoff_time._d;

      let floatDistance = distance / 200000;
      let intDistance = Math.floor(floatDistance);
      var longDistanceSurcharge = intDistance * 20000;

      return {
        trip_start_time,
        distance,
        fare,
        longDistanceSurcharge,
        total_time,
        estimated_final_dropoff_time,
        estimated_customer_final_dropoff_time,
        tdwdt,
        gatime,
        travelAreaPrice
      };
    } else {
      return
    }
  };

  /**
   * * Get total duration in minutes
   */
  const getTotalDurationInMinutes = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);
    return hours * 60 + minutes;
  }

  /**
   * * Merge the pickup and dropoff locations to the array. Required for the Navitime API.
   */
  const getViaList = (sfd) => {
    var via = [];
    var pickip_p_obj = {
      lat: sfd.pickuplocation.lat,
      lon: sfd.pickuplocation.lon,
    };
    via.push(pickip_p_obj);

    sfd.dropofflocations.map((d) => {
      var des_obj = {
        lat: d.lat,
        lon: d.lon,
      };
      via.push(des_obj);
    });
    return JSON.stringify(via);
  };

  // Return in minutes
  /**
   * * Set the time difference rounded by 30 mins
   * * Return in minutes
   */
  const getTimeDifferenceIN30MinRounded = async (val) => {
    try {
      var d_time = moment(cutomerActualDropOffTime).format("HH:mm:ss");
      var d_arr = d_time.split(":");
      var et_h = Number(d_arr[0]);
      // customer set drop-off time split
      var c_added_arr = val.split(":");
      var c_d_h = Number(c_added_arr[0]);
      var c_d_m = Number(c_added_arr[1]);

      if (et_h <= c_d_h) {
        var customer_changed_dropoff_datetime = moment(
          cutomerActualDropOffTime
        ).set({
          hour: c_d_h,
          minute: c_d_m,
        });
      } else {
        customer_changed_dropoff_datetime = moment(cutomerActualDropOffTime)
          .add(1, "days")
          .set({
            hour: c_d_h,
            minute: c_d_m,
          });
      }

      // this function
      var duration = moment.duration(
        moment(customer_changed_dropoff_datetime._d).diff(
          moment(cutomerActualDropOffTime)
        )
      );
      var minutes = duration.asMinutes();
      const rounded = Math.ceil(minutes / 30) * 30;
      // return rounded;
      setPackageAdditionaltime(rounded);
    } catch { }
  };


  /**
   * * Set the selected tab (Single Destination, Multiple Destination, Airport Transfer, By The Hour) in the UI. Check the tabName parameter value.
   */
  const getTab = () => {
    if (tabName === "byTheHour")
      return (
        <ByTheHourForm
          byTheHourForm={byTheHourFormData}
          checkByTheHourFormAvailableVehicle={byTheHourFormAvailableVehicle}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
        />
      );
    if (tabName === "specifiedDestination")
      return (
        <SpecifiedDestinationForm
          specifiedDestinationForm={specifiedDestinationFormData}
          checkSpecifiedDestinationFormAvailableVehicle={specifiedDestinationFormAvailableVehicle}
          cutomerChangedDropOffTimeOnly={cutomerChangedDropOffTimeOnly}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
        />
      );
  };

  /**
   * * Set equation values and state variables
   * * on click event => when the vehicle type is selected / clicked
   * * hourlyprice = 30 minute rate for the selected vehicle type (this can be specified by the TLF team when creating a vehicle type in the Admin Panel)
   * @param vehicle_type - Selected vehicle type
   */
  const applyToTheEquation = (vehicle_type, index = null) => {
    var newSelectedVehicleType;
    var newClickedManyVehicleType;
    var newBookingInfo;

    if (selectedManyVehicleType.length === 0 && selectedManyVehicleType[index] === undefined) {
      newSelectedVehicleType = [...selectedManyVehicleType, vehicle_type];
    } else {
      newSelectedVehicleType = [...selectedManyVehicleType];
      newSelectedVehicleType[index] = vehicle_type;
    }

    setSelectedManyVehicleType(newSelectedVehicleType);
    const zoneIncludedHoursRounded = getHoursInsideMultiplierZone(
      tripStartTime,
      garageArivalTimeWithAdditionalTime
    );

    // totHoursComplete = total hours - departure time from garage - return time to garage - customer set drop-off time
    const totHoursComplete = getTotalHours(garageArivalTimeWithAdditionalTime);
    const totalAmount = getTotal(
      vehicle_type,
      totHoursComplete,
      tollFare,
      travelAreaPrice,
      zoneIncludedHoursRounded,
      activatedPackages,
      packageAdditionaltime,
      longDistanceSurcharge,
      totalTimeWithoutPackage,
      sfd.dropofflocations
    ).total;

    const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
    const totHoursUsage = getTotalHoursUsage(cutomerChangedDropOffDateTimeOnly, travel_date_with_pickup_time);
    var childSeatList = [
      {
        childSeatType: "Infant",
        childSeatCount: 0,
      },
      {
        childSeatType: "Toddler",
        childSeatCount: 0,
      },
      {
        childSeatType: "Child",
        childSeatCount: 0,
      },
    ];

    if (getTotalPaxCount(sfd.pax) <= 9) {
      var paxList = [
        {
          paxtype: "Adult",
          paxcount: sfd.pax.adult,
        },
        {
          paxtype: "Child",
          paxcount: sfd.pax.child,
        },
        {
          paxtype: "Toddler",
          paxcount: sfd.pax.toddler,
        },
        {
          paxtype: "Baby",
          paxcount: sfd.pax.baby,
        },
      ];

      var luggageList = [
        {
          luggagetype: "CHECK-IN SIZE",
          luggagecount: sfd.lugage.checkinSize,
        },
        {
          luggagetype: "CABIN SIZE",
          luggagecount: sfd.lugage.cabinSize,
        },
      ];
    } else {
      var paxList = null;
      var luggageList = null;
    }
    // // -------------------------------------------------------------------------------------------------------------------------

    if (selectedManyVehicleType.length === 0 && selectedManyVehicleType[index] === undefined) {
      newClickedManyVehicleType = [...clickedManyVehicleType, vehicle_type.typename]

      newBookingInfo = [...bookingInfo, {
        vehicleTypeName: vehicle_type.typename,
        vehicleTypeID: vehicle_type._id,
        pax: paxList,
        luggage: luggageList,
        totalTime,
        baseprice: vehicle_type.baseprice,
        hourlyprice: vehicle_type.hourlyprice,
        tollFare,
        travelAreaPrice,
        longDistanceSurcharge: longDistanceSurcharge,
        isTimeRange: null,
        total: totalAmount,
        tripStartTime: tripStartTime,
        garageArivalTime: garageArivalTime,
        garageArivalTimeWithAdditionalTime: garageArivalTimeWithAdditionalTime,
        cutomerActualDropOffTime: cutomerActualDropOffTime,
        cutomerDropOffTimeWithAdditinalTime: cutomerDropOffTimeWithAdditinalTime,
        zoneHours: zoneIncludedHoursRounded,
        totalhours: totHoursComplete,
        totalHoursUsage: totHoursUsage,
        childSeat: childSeatList,
        childSeatPrice: 0,
        travelArea: sfd.travelArea,
        travelAreaPrice: travelAreaPrice
      }]


    } else {
      newClickedManyVehicleType = [...clickedManyVehicleType];
      newClickedManyVehicleType[index] = vehicle_type.typename;

      newBookingInfo = [...bookingInfo];
      newBookingInfo[index] = {
        vehicleTypeName: vehicle_type.typename,
        vehicleTypeID: vehicle_type._id,
        pax: paxList,
        luggage: luggageList,
        totalTime,
        baseprice: vehicle_type.baseprice,
        hourlyprice: vehicle_type.hourlyprice,
        tollFare,
        travelAreaPrice,
        longDistanceSurcharge: longDistanceSurcharge,
        isTimeRange: null,
        total: totalAmount,
        tripStartTime: tripStartTime,
        garageArivalTime: garageArivalTime,
        garageArivalTimeWithAdditionalTime: garageArivalTimeWithAdditionalTime,
        cutomerActualDropOffTime: cutomerActualDropOffTime,
        cutomerDropOffTimeWithAdditinalTime: cutomerDropOffTimeWithAdditinalTime,
        zoneHours: zoneIncludedHoursRounded,
        totalhours: totHoursComplete,
        totalHoursUsage: totHoursUsage,
        childSeat: childSeatList,
        childSeatPrice: 0,
        travelArea: sfd.travelArea,
        travelAreaPrice: travelAreaPrice
      }
    }

    setClickedManyVehicleType(newClickedManyVehicleType);
    setBookingInfo(newBookingInfo);

    if (getTotalPaxCount(sfd.pax) <= 9) {
      setIsEquationApplied(true);
    }
  };


  const getCurrentMaxPassenger = useMemo(() => {
    const updatedSfd = _.cloneDeep(sfd);
    var bookedPax = updatedSfd.pax;

    if (bookingInfo.length === 0) {
      return bookedPax;
    } else {
      bookingInfo.map((item, index) => {
        if (item !== null) {
          const paxArray = item.pax;
          if (paxArray !== null) {
            for (let ind = 0; ind < paxArray.length; ind++) {
              let key = paxArray[ind].paxtype.toLowerCase();
              if (bookedPax[key] !== undefined) {
                bookedPax[key] -= paxArray[ind].paxcount;
              }
            }
          }
        }
      })
      return bookedPax;
    }
  }, [bookingInfo])

  const getCurrentMaxLugage = useMemo(() => {
    const updatedSfd = _.cloneDeep(sfd);

    var bookedLugage = updatedSfd.lugage;

    if (bookingInfo.length === 0) {
      return bookedLugage;
    } else {
      bookingInfo.map((item, index) => {
        if (item !== null) {
          const lugageArray = item.luggage;
          if (lugageArray !== null) {
            for (let ind = 0; ind < lugageArray.length; ind++) {
              if (lugageArray[ind].luggagetype === "CHECK-IN SIZE") {
                var key = "checkinSize";
              } else if (lugageArray[ind].luggagetype === "CABIN SIZE") {
                var key = "cabinSize";
              }

              if (bookedLugage[key] !== undefined) {
                bookedLugage[key] -= lugageArray[ind].luggagecount;
              }
            }
          }
        }
      })
      return bookedLugage;
    }
  }, [bookingInfo])

  const getClickedManyVehicleType = useMemo(() => {
    return clickedManyVehicleType;
  }, [clickedManyVehicleType])

  /**
   * * Set pax values and state variables
   * * on click proceed event => after the vehicle type is selected / clicked
   */
  const applyPaxAndLugageToTheEquation = (pax, lugage, index) => {
    var paxList = [
      {
        paxtype: "Adult",
        paxcount: pax.adult,
      },
      {
        paxtype: "Child",
        paxcount: pax.child,
      },
      {
        paxtype: "Toddler",
        paxcount: pax.toddler,
      },
      {
        paxtype: "Baby",
        paxcount: pax.baby,
      },
    ];

    var luggageList = [
      {
        luggagetype: "CHECK-IN SIZE",
        luggagecount: lugage.checkinSize,
      },
      {
        luggagetype: "CABIN SIZE",
        luggagecount: lugage.cabinSize,
      },
    ];

    const editedBookingInfo = bookingInfo

    if (editedBookingInfo[index]) {
      editedBookingInfo[index].pax = paxList;
      editedBookingInfo[index].luggage = luggageList;

      setBookingInfo(editedBookingInfo);

      const totalPax = bookingInfo.map(item => item['pax']);
      const totalLugage = bookingInfo.map(item => item['luggage']);

      const totalPaxCount = getTotalPaxCountArray(totalPax);
      const totalLugageCount = getTotalLuggageCountArray(totalLugage);

      if (totalPaxCount < getTotalPaxCount(sfd.pax)) {
        if (bookingInfo[(countCarToChoose - 1)] !== undefined && bookingInfo[(countCarToChoose - 1)].pax !== null) {
          setCountCarToChoose(countCarToChoose + 1);
        }
      } else {
        // setIsEquationApplied(true);
        setShowModal(true);
      }
    }

  }

  useEffect(() => {
    const element = document.getElementById("scroll" + (countCarToChoose));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [countCarToChoose])

  useEffect(() => {
    if (isEquationApplied) {
      addTocart(true);
    }
  }, [isEquationApplied]);

  /**
   * * Round the time to the closest 30 min increment
   * @param value - time
   * @param f_val - return the rounded time value
   */
  const roundSixtyMinValue = (value) => {
    const inHours = value / 60;
    const onlydecemal = inHours % 1;
    const onlyHour = float2int(inHours);
    var f_val = 0;
    if (onlydecemal > 0 && onlydecemal < 0.5) {
      f_val = onlyHour + 0.5;
    } else if (onlydecemal > 0.5 && onlydecemal < 1) {
      f_val = onlyHour + 1;
    } else if (onlydecemal === 0) {
      f_val = onlyHour;
    } else if (onlydecemal === 0.5) {
      f_val = inHours;
    }
    return f_val;
  };

  const float2int = (value) => {
    return value | 0;
  };

  /**
   * * Get total hours (billed duration)
   */
  const getTotalHours = (garageArival) => {
    const totalduration = moment.duration(
      moment(garageArival).diff(moment(tripStartTime))
    );
    var total_minutes = totalduration.asMinutes();

    // // additional time
    // var breakTime = 0;
    // if (totalDistance < 200000) {
    //   var additionalTime = (total_minutes / 30) * 5;
    // } else {
    //   var additionalTime = (total_minutes / 30) * 7.5;

    //   breakTime += ((total_minutes + additionalTime) / 120 * 15);
    // }

    // const flightTrackingTime = sfd.flightTracking ? 60 : 0;

    // var totalHrs = roundSixtyMinValue(total_minutes + additionalTime + breakTime + flightTrackingTime);
    var totalHrs = roundSixtyMinValue(total_minutes);
    return totalHrs;
  };

  /**
   * * Get total hours (usage duration)
   */
  const getTotalHoursUsage = (dropoffTime, pickuptime) => {
    const totalduration = moment.duration(
      moment(dropoffTime).diff(moment(pickuptime))
    );
    var total_minutes = totalduration.asMinutes();
    var additionalTime = 0;

    // additional time
    // var breakTime = 0;
    // if (totalDistance > 200000) {
    //   additionalTime = (total_minutes / 30) * 5;
    // } else {
    //   additionalTime = (total_minutes / 30) * 7.5;

    //   breakTime += ((total_minutes + additionalTime) / 120 * 15);
    // }

    var totalHrs = roundSixtyMinValue(total_minutes + additionalTime);
    return totalHrs;
  };

  /**
   * * Get rounded travel hours with filter hours
   * @param p_date_time - trip start time
   * @param d_date_time - trip end time with customer added additional hours
   * Note: There is a different calculation required if part of the booking falls within the "Night zone" hours of 22:00-06:00 (1.25 multiplier; this is
   * part of the TLF business logic. The team or the TLF price list can explain more). That's why you see references to a "zone" in the variables etc.
   */
  const getHoursInsideMultiplierZone = (p_date_time, d_date_time) => {
    if (p_date_time && d_date_time) {
      var zone_minutes = 0;
      var margin_tem_pm = moment(newtravelDate).set({
        hour: 22,
        minute: 0,
      });
      var margin_five_am_drop_off = moment(d_date_time).set({
        hour: 6,
        minute: 0,
      });
      const pickup_datetime = moment(p_date_time.toString());
      const dropoff_datetime = moment(d_date_time.toString());
      const is_pick_in_zone = isDateInMultiplierZone(p_date_time);
      const is_dropoff_in_zone = isDateInMultiplierZone(d_date_time);
      if (is_dropoff_in_zone && !is_pick_in_zone) { //berangkat > 6 pagi & pulang > 10 malam
        const dropoff_duration = moment.duration(
          dropoff_datetime.diff(margin_tem_pm)
        );
        zone_minutes = dropoff_duration.asMinutes(); // 10 pm ke dropoff time
      } else if (!is_dropoff_in_zone && is_pick_in_zone) { //berangkat < 6 pagi & pulang < 10 malam
        // Get dropoff datetime
        const pickup_zone_duration = moment.duration(
          margin_five_am_drop_off.diff(pickup_datetime)
        );
        zone_minutes = pickup_zone_duration.asMinutes(); // pickuptime ke 6 am
      } else if (is_dropoff_in_zone && is_pick_in_zone) { // berangkat < 6 pagi & pulang > 10 malam
        const both_in_same_zone_duration = moment.duration(
          dropoff_datetime.diff(pickup_datetime)
        );
        zone_minutes = both_in_same_zone_duration.asMinutes(); // pickup ke dropoff
      } else if (!is_dropoff_in_zone && !is_pick_in_zone) { // berangkat > 6 pagi & pulang < 10 malam
        // Pickup point and drop-off point not in zone
        const is_pic_and_drop_same_day = pickup_datetime.isSame(
          dropoff_datetime,
          "day"
        );
        // 8 * 60 minutes in zone
        zone_minutes = !is_pic_and_drop_same_day ? 480 : 0;
      }

      var roundedValInHours = roundSixtyMinValue(zone_minutes);
    }
    return roundedValInHours;
  };

  /**
   * * Add item to the cart. Data gets added to the database at this point (if the cart expires with a booking in it, the "isAbandoned" flag gets
   * set to true). Then the booking gets shown in the "Abandoned Carts" view in the Admin Panel (for tracking purposes).
   * @param isCart - bool values. check if true and redirect to the cart page
   */
  const addTocart = async (isCart) => {
    // formValidation();
    const totalPax = getTotalPaxCount(sfd.pax);
    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0
    ) {
      // alert.show(t("Please fill in all the required fields"), {
      //   type: types.ERROR,
      // });
      setIsloading(false);
    } else {
      // check total time take
      const trip_total_time = Number(bookingInfo[0].totalhours);
      if (trip_total_time / 60 > Number(setting.totaltriptime)) {
        // alert.show(
        //   t(
        //     "For bookings longer than 16 hours, please contact our sales team."
        //   ),
        //   {
        //     type: types.ERROR,
        //   }
        // );

        setmodalAlertText("For bookings longer than 16 hours, please contact our sales team.");
        setShowModalAlert(true);
        setIsloading(false);
        return;
      }
      setIsloading(true);

      let appliedVehicle = [];
      let updatedBookingInfo = [...bookingInfo];

      await Promise.all(
        bookingInfo.map(async (i, ind) => {
          const availableVehicles = await getAvailableVehicles(
            tripStartTime,
            garageArivalTimeWithAdditionalTime,
            i.vehicleTypeName
          );

          if (availableVehicles.length > 0) {
            await availableVehicles.map(async (itm) => {
              if (!appliedVehicle.includes(itm._id)) {

                if (updatedBookingInfo[ind].vehicle === undefined) {
                  appliedVehicle.push(itm._id);
                  updatedBookingInfo[ind] = {
                    ...updatedBookingInfo[ind],
                    "vehicle": itm._id,
                  };
                }
              }
            })
          } else {
            // alert.show(
            //   "There are no vehicles available at the selected date and time.",
            //   { type: types.SUCCESS }
            // );
            setmodalAlertText("There are no vehicles available at the selected date and time.");
            setShowModalAlert(true);
          }
        })
      );

      setBookingInfo(updatedBookingInfo);


      setIsloading(false);

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const note_arr = [{ note: note }];

      var paxList = [
        {
          paxtype: "Adult",
          paxcount: sfd.pax.adult,
        },
        {
          paxtype: "Child",
          paxcount: sfd.pax.child,
        },
        {
          paxtype: "Toddler",
          paxcount: sfd.pax.toddler,
        },
        {
          paxtype: "Baby",
          paxcount: sfd.pax.baby,
        },
      ];

      var luggagelist = [
        {
          luggagetype: "CHECK-IN SIZE",
          luggagecount: sfd.lugage.checkinSize,
        },
        {
          luggagetype: "CABIN SIZE",
          luggagecount: sfd.lugage.cabinSize,
        },
      ];

      var meetAssistList = [
        {
          meetAssistType: "arrival",
          meetAssistName: "none",
          meetAssistPrice: 0
        },
        {
          meetAssistType: "departure",
          meetAssistName: "none",
          meetAssistPrice: 0
        }
      ]

      if (cartID === null) {
        var randomchar =
          Math.floor(Math.random() * 16777215).toString(16) +
          Math.floor(Math.random() * 1000 + 1);
        setCartID(randomchar);
      }

      // Set cart item added time all set to initial added time

      var addedTime = moment();
      if (cart?.length > 0) {
        addedTime = moment(cart[0].date);
      }

      const cart_itm = {
        key: getKey(),
        randomId: cartID === null ? randomchar : cartID,
        travelDateWithPickupTime: travel_date_with_pickup_time._d.toString(),
        pickuptime: sfd.pickuptime,
        pickuplocation: sfd.pickuplocation,
        destinations: sfd.dropofflocations,
        paxList,
        luggagelist,
        flightNo: sfd.flightNo,
        isFromAirport: sfd.isFromAirport,
        isToAirport: sfd.isToAirport,
        isAirportTransfer: sfd.isAirportTransfer === undefined ? false : sfd.isAirportTransfer,
        cutomerActualDropOffTime,
        garageArivalTime,
        cutomerChangedDropOffTimeOnly,
        bookingInfo: updatedBookingInfo,
        totalDistance,
        tripStartTime: updatedBookingInfo[0].tripStartTime,
        garageArivalTimeWithAdditionalTime: updatedBookingInfo[0].garageArivalTimeWithAdditionalTime,
        note_arr,
        availableVehicleId: null,
        date: addedTime,
        activePackage: getActivePackage(),
        byTheHourBook: sfd.byTheHourBook,
        duration: sfd.duration,
        byTheHourPax: sfd.byTheHourPax,
        byTheHourLugage: sfd.byTheHourLugage,
        meetAssist: meetAssistList,
        pronounced: "Mr.",
        dropoffDatetime: cutomerChangedDropOffDateTimeOnly,
        flightTracking: sfd.flightTracking,
      };
      
      if (isCart === true) {
        const cartItemArr = [cart_itm]
        const items = [];
        let totalPrice = 0;

        cart_itm.bookingInfo.map((bi, ind) => {
          const item = {
            index: ind,
            item_id: bi.vehicleTypeID,
            item_name: bi.vehicleTypeName,
            item_brand: "Toyota",
            affiliation: "Stripe",
            coupon: "",
            discount: 0,
            item_category: cart_itm.isAirportTransfer ? 'Airport Transfer' : cart_itm.byTheHourBook ? 'By The Hour' : 'Specified Destination',
            item_category2: cart_itm.destinations.length === 1 ? cart_itm.destinations[0].place_id : cart_itm.destinations[cart_itm.destinations.length - 1].place_id,
            price: bi.total,
            quantity: 1,
          };

          items.push(item);
        })

        const ecommerceData = {
          items: items
        }

        const dataToPush = {
          event: 'select_item',
          ecommerce: ecommerceData
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
        history.push('/booking-confirmation', { cart_item: JSON.stringify(cart_itm) });
      } else {
        window.location.reload(false);
      }

    }
  };

  /**
   * * Reset all the state variables to initial values
   */
  const resetform = () => {
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addAirportFormToState(null));
    dispatch(addByTheHourFormToState(null));
    setSfd({
      pickuplocation: null,
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      pax: null,
      flightNo: "",
      isFromAirport: null,
      isToAirport: null,
      isAirportTransfer: null
    });
    setPackageAdditionaltime(0);
    setActivatedPackages([]);
    setPackageList([]);
    setIndexActivatedPackages(null);
    setTotalTimeWithoutPackage(0);
    setDistanceAppliedPackage(0);
    setgarageArivalTime(null);
    setcutomerChangedDropOffTimeOnly("");
    setcutomerActualDropOffTime(null);
    setCutomerDropOffTimeWithAdditinalTime(null);
    setGarageArivalTimeWithAdditionalTime(null);
    setVehicleTypeNames([]);
    setClickedVehicleType("");
    setTollFare(0);
    setTotalTime(0);
    setTotalDistance(0);
    setLongDistanceSurcharge(0);
    setNote("");
  };

  /**
   * * Get the active Airport Transfer package from the array
   */
  const getActivePackage = () => {
    if (activatedPackages.length === 0) return null;
    const pkg = activatedPackages[0];
    if (pkg) return pkg;
  };

  const hasActivePackage = () => {
    return activatedPackages?.length > 0 ? true : false;
  };



  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertText, setmodalAlertText] = useState("");

  const handleOpenModalAlert = (item) => {
    setShowModalAlert(true);
  };

  const handleCloseModalAlert = () => {
    setShowModalAlert(false);
  };

  // useEffect(() => {
  //   if (showModal) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [showModal]);

  return (
    <FrontLayout>
      {/* <LoadingOverlay
        active={isloading}
        spinner={<ScaleLoader color="#36D7B7" />}
      > */}

      <AlertModal handleClose={handleCloseModalAlert} show={showModalAlert} text={modalAlertText} />
      <div className={`container-fluid ${tabName === "byTheHour" ? "hero-image-home-3-byTheHour" : "hero-image-home-3-specifiedDestination"}`}> 
        <div className="container px-md-3 custom-pt-8 d-flex flex-column justify-content-center">
          <div className="row">
            <div className="col-md-6  d-flex align-items-start home-title" >
              <h1
                className="fw-900 text-start mb-3 mb-lg-5"
              >
              {t("Book.Your.Journey")}
              </h1>
            </div>
            <div className="col-md-6">
              
          {/* <!-- select tab btns --> */}
          <div className="col">
            <ul className="nav nav-custom flex-md-row  text-center ">

            <li className={`nav-item py-md-0 col-6`}>
                <div
                      className={`nav-link nav-link-form px-3 px-lg-5  d-flex justify-content-center align-items-center  ${
                        tabName === "byTheHour" ? "active" : ""
                      }`}
                  onClick={() => {
                    resetFormValues();
                    setTabName("byTheHour");
                  }}
                  role={"button"}
                 
                >
                  
                  <span className="d-none d-md-block">
                      {t("ByTheHour.Destinations.Mobile")}
                  </span>
                  <span className="d-block d-md-none">
                    {t("ByTheHour.Destinations.Mobile")}
                  </span>
                </div>
              </li>
                  <li className={`nav-item py-md-0 col-6`}>
                <div
                      className={`nav-link nav-link-form px-3 px-lg-5 d-flex justify-content-center align-items-center   ${
                        tabName === "specifiedDestination" ? "active" : ""
                      }`}
                  onClick={() => {
                    resetFormValues();
                    setTabName("specifiedDestination");
                  }}
                  role={"button"}
                >
                {t("Specified.Destinations")}
                </div>
              </li>
            </ul>
          </div>
          {/* <!-- form part --> */}

          {getTab()}
            </div>
          </div>
        </div>
      </div>
      {/* </LoadingOverlay> */}
      {/* <!-- section 2 end --> */}
      {showDetailsPannels ? (
        <Fragment>
          {/* <!-- section 1 select vehicle --> */}
          {getTotalPaxCount(sfd.byTheHourBook ? sfd.pax : sfd.pax) <= 9 ? (
            <div className="container-fluid py-5 px-0 " id="scroll01">
              <div className="row mb-3 d-flex align-items-center">
                <div className="col px-0">
                  <hr />
                </div>
                <div className="col-auto text-center">
                  <h3>{t("Discover.Heading.Three")}</h3>
                </div>
                <div className="col px-0">
                  <hr />
                </div>
              </div>
              {/* <!-- select vehicle heading end --> */}
              <div className="container px-md-3 mt-4 mt-lg-3 pt-lg-5">
                {packageList.length > 0 && (
                  <div className="row mb-3 align-items-center justify-content-center">
                    <div className="col">
                      <div className="card">
                        <div
                          className="card-body bg-gold-light-100 border border-0"
                          style={{ padding: "0.5rem" }}
                        >
                          <p className="card-text font-weight-light text-center">
                            {lang == 'EN' ? `${packageList[0].packagename} is applied` : `「${packageList[0].packagenameJP}」 のパッケージに該当します`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row gy-4 mb-3">
                  {vehicleTypeNames.map((vt, i) => (
                    <>
                      <VehicleTypeCard
                        key={vt._id}
                        index={vt._id}
                        onClick={(vehicle_type) => {
                          // check auth
                          setClickedVehicleType(vehicle_type)
                          if (isAuthenticated) {
                            applyToTheEquation(vehicle_type);
                          } else {
                            setShowModal(true);
                          }
                        }}
                        vehicle_type={vt}
                        customerAddedAditionalMinutes={packageAdditionaltime}
                        activatedPackages={activatedPackages}
                        total_hours={getTotalHours(
                          garageArivalTimeWithAdditionalTime
                        )}
                        toll_fare={tollFare}
                        travel_area_price={travelAreaPrice}
                        zone_hours={getHoursInsideMultiplierZone(
                          tripStartTime,
                          garageArivalTimeWithAdditionalTime
                        )}
                        useModal={true}
                        longDistanceSurcharge={longDistanceSurcharge}
                        totalTimeWihtoutPackage={totalTimeWithoutPackage}
                        dropoffLocations={sfd.dropofflocations}
                      />
                    </>
                  ))}
                  <LoginModal
                    show={showModal}
                    vehicle_type={clickedVehicleType}
                    handleAfterLogin={(vehicle_type) => {
                      setShowModal(false);
                      applyToTheEquation(vehicle_type);
                    }}
                    handleCloseModal={handleCloseModal} />
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid py-5 px-0 " id="scroll01">
              <div className="row mb-3 d-flex align-items-center">
                <div className="col px-0">
                  <hr />
                </div>
                <div className="col-auto text-center">
                  <h3>{t("Discover.Heading.Three")}</h3>
                </div>
                <div className="col px-0">
                  <hr />
                </div>
              </div>

              <div className="row mb-3 d-flex align-items-center justify-content-center">
                <div className="col-md-8 text-center justify-content-center ps-3 pt-3 pb-2 mx-auto">
                  <p>
                    Considering the maximum capacity of our vehicle fleet, which can accommodate <b className="text-background-highlight">up to 9 individuals,</b> it will be necessary for you <span className="text-background-highlight">to make a reservation for <b >two vehicles</b>  in order to accommodate your group.</span>
                  </p>
                </div>
              </div>
              {/* <!-- select vehicle heading end --> */}

              {packageList.length > 0 && (
                <div className="row mb-3 align-items-center justify-content-center">
                  <div className="col-10">
                    <div className="card">
                      <div
                        className="card-body bg-gold-light-100 border border-0"
                        style={{ padding: "0.5rem" }}
                      >
                        <p className="card-text font-weight-light text-center">
                          {packageList[0].packagename} is applied
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {
                Array.from({ length: countCarToChoose }, (_, index) => (
                  <CarSelection
                    vehicleTypeNames={vehicleTypeNames}
                    clickedVehicleType={clickedVehicleType}
                    applyToTheEquation={applyToTheEquation}
                    packageAdditionaltime={packageAdditionaltime}
                    activatedPackages={activatedPackages}
                    total_hours={
                      getTotalHours(
                        garageArivalTimeWithAdditionalTime
                      )
                    }
                    tollFare={tollFare}
                    travelAreaPrice={travelAreaPrice}
                    zone_hours={
                      getHoursInsideMultiplierZone(
                        tripStartTime,
                        garageArivalTimeWithAdditionalTime
                      )
                    }
                    key={index}
                    index={index}
                    applyPaxAndLugageToTheEquation={applyPaxAndLugageToTheEquation}
                    idScroll={"scroll" + (index + 1)}
                    currentMaxPassenger={getCurrentMaxPassenger}
                    currentMaxLugage={getCurrentMaxLugage}
                    clickedManyVehicleType={getClickedManyVehicleType}
                    longDistanceSurcharge={longDistanceSurcharge}
                    totalTimeWihtoutPackage={totalTimeWithoutPackage}
                    dropoffLocations={sfd.dropofflocations}
                  />
                ))
              }
              <LoginModal
                show={showModal}
                handleAfterLogin={() => {
                  setShowModal(false);
                  setIsEquationApplied(true);
                }}
                handleCloseModal={handleCloseModal} />
            </div>
          )}
          {/* <!-- section 1 end --> */}



        </Fragment>
      ) : (
        ""
      )
      }
    </FrontLayout >
  );
};

Discover.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(withAlert()(Discover));
