import React from "react";
import { Fragment } from "react";
import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
// import { useSelector, useDispatch } from "react-redux";
// import history from "../routing/history";
// import jwtDecode from "jwt-decode";

const Layout = (props) => {
  // let token = localStorage.getItem("token");
  // const { exp, iat } = jwtDecode(token);
  // const expireTimeInSeconds = exp - iat;
  // console.log(Date().toString());
  // console.log(expireTimeInSeconds);
  // const expirationTime = exp * 1000 - 60000;
  // console.log(expirationTime);
  // if (Date.now() >= expirationTime) {
  //   localStorage.clear();
  //   history.push("/");
  // }
  return (
    <Fragment>
      {/* <HeaderBar /> */}
      <Navbar />
      {props.children}
    </Fragment>
  );
};

export default Layout;
