import React, { useState, useEffect } from "react";
import FrontLayout from "../../layout-new-theme/FrontLayout";
import back_icon from "../../../images/back_icon.svg";
import BookingItem from "./bookings/BookingItem";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  postRequest,
  addNote,
  saveFlightNumber,
  updateRequestPaymentStatus,
  updateBookingState,
  deleteCanceledRequestPaymentStatus,
} from "../../../actions/requestchangebooking";
import moment from "../../../utils/customMoment";
import { useTranslation } from "react-i18next";
import { useLoading } from "../LoadingContext";
import { useDispatch, useSelector } from "react-redux";
import { getBooking } from "../../../actions/admin/booking";
import { filterNewPackage } from "../../../actions/admin/package";
import {
  getRouteCarForTotalTime,
  getRouteCar,
} from "../../../actions/navitimeAPI";
import { getTotalEditBooking } from "../../../utils/Calculator";
import ReceiptModal from "./bookings/ReceiptModal";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const queryParams = {};

    // Iterate over all query parameters and store them in an object
    for (const [key, value] of searchParams) {
      if (queryParams[key]) {
        // If the key already exists, convert it to an array
        if (Array.isArray(queryParams[key])) {
          queryParams[key].push(value);
        } else {
          queryParams[key] = [queryParams[key], value];
        }
      } else {
        queryParams[key] = value;
      }
    }

    return queryParams;
  }, [search]);
}

const BookingDetailPage = ({ match }) => {
  let query = useQuery();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const bookingState = useSelector((state) => state.booking);
  const { loading, booking } = bookingState;
  const alertState = useSelector((state) => state.alert);
  const { alertType, isSuccess } = alertState;
  const bookingId = match.params.id;
  const history = useHistory();
  const { showLoading, closeLoading } = useLoading();
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [editBookingForm, setEditBookingForm] = useState({
    travelDate: null,
    pickupTime: null,
    dropoffTime: null,
    pickupLocation: null,
    dropoffLocation: [],
    duration: null,
  });
  const [dropoffTimeToBeDisplayed, setDropoffTimeToBeDisplayed] = useState("");
  const [initialPrice, setInitialPrice] = useState(0);
  const [priceAfterEditBooking, setPriceAfterEditBooking] = useState(0);
  const [additionalPayment, setAdditionalPayment] = useState(0);
  const [reqBodyRequestChange, setReqBodyRequestChange] = useState({});
  const [halfHourIntervalsData, setHalfHourIntervalsData] = useState([]);
  const buttonCloseModal = document.getElementById("closeModal");
  const buttonOpenRequestSubmitted = document.getElementById(
    "openRequestSubmitted"
  );
  const buttonOpenFailedSubmitRequest = document.getElementById(
    "openFailedSubmitRequest"
  );
  const openModalSuccessSubmit = document.getElementById(
    "openModalSuccessSubmit"
  );
  const openModalPriceHigherEditBooking = document.getElementById(
    "openModalPriceHigherEditBooking"
  );
  const openModalPriceSameEditBooking = document.getElementById(
    "openModalPriceSameEditBooking"
  );
  const openModalPriceLowerEditBooking = document.getElementById(
    "openModalPriceLowerEditBooking"
  );
  const buttonOpenCancellationSubmitted = document.getElementById(
    "openCancellationSubmitted"
  );

  const deleteQueryParams = (arrParams) => {
    const queryParams = new URLSearchParams(location.search);

    arrParams.map((val) => {
      if (queryParams.has(val)) {
        queryParams.delete(val);
        history.replace({
          search: queryParams.toString(),
        });
      }
    });
  };

  const updateRequestChangePaymentStatus = async () => {
    const updateRequest = await updateRequestPaymentStatus({
      requestId: query.requestId,
      bookingId: booking._id,
      stripeRef: query.stripeRef,
    });

    if (updateRequest.code == 200) {
      dispatch(updateBookingState(updateRequest.booking));
      deleteQueryParams(["requestId", "stripeRef"]);
    }
  };

  const deleteCanceledRequestId = async () => {
    const deleteRequest = await deleteCanceledRequestPaymentStatus({
      canceledRequestId: query.cancelRequestId,
      bookingId: booking._id,
    });

    if (deleteRequest.code == 200) {
      dispatch(updateBookingState(deleteRequest.booking));
      deleteQueryParams(["cancelRequestId"]);
    }
  };

  /**
   * * Get the selected route's data
   * @param total_time - return parameter - total time in minutes
   */
  const getTotalTime = (car_r) => {
    const summary_arr = car_r[car_r.length - 1].summary;

    const total_time = summary_arr.move.time;

    return {
      total_time,
    };
  };

  function populateDurationSelect(minimal_duration) {
    setHalfHourIntervalsData([]);
    const start_from = Math.round(minimal_duration / 30);
    const halfHourIntervals = Array.from({ length: 49 }, (_, i) => {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30;
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      const label = `${hours.toString().padStart(2, "0")} Hours ${minutes
        .toString()
        .padStart(2, "0")} Minutes`;
      return {
        value: time,
        label: label,
      };
    });
    const final_hour_data = halfHourIntervals.slice(start_from + 2);
    setHalfHourIntervalsData(final_hour_data);
  }

  const submitCancellation = async () => {
    showLoading(true);
    var cancelPercent = 0;
    if (isJSTDay(booking.travelDate)) cancelPercent = 100;
    else if (isAfter6pmOnDayBeforeJST(booking.travelDate)) cancelPercent = 50;
    else if (isAfter9amOnDayBeforeJST(booking.travelDate)) cancelPercent = 30;
    else if (isBefore9amOnDayBeforeJST(booking.travelDate)) cancelPercent = 20;

    let reqBody = {
      bookingId: booking._id,
      requestType: "cancel",
      lang: localStorage.getItem("lang"),
      partialCancellationFee: (booking.totalPrice * cancelPercent) / 100,
      totalRefundAmount:
        booking.totalPrice - (booking.totalPrice * cancelPercent) / 100,
      percentRefund: cancelPercent,
    };

    // console.log(reqBody);

    const createRequest = await postRequest(reqBody);

    // console.log(createRequest);
    if (createRequest.code == 201) {
      dispatch(updateBookingState(createRequest.booking));
      closeLoading(true);
      resetValue();
      buttonCloseModal.click();
      buttonOpenCancellationSubmitted.click();
    } else {
      closeLoading(true);
      resetValue();
      buttonCloseModal.click();
      buttonOpenFailedSubmitRequest.click();
    }
  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation. Fire when both pickup and dropoff location is filled.
   * ? sfd - Selected Form Data
   * @param sfd
   */
  const checkTotalTimeForDuration = async (sfd) => {
    // availabilityValueReset();
    const start = sfd.pickupLocation.lat + "%2C" + sfd.pickupLocation.lon;
    const end = sfd.dropoffLocation[0].lat + "%2C" + sfd.dropoffLocation[0].lon;

    const car_r = await getRouteCarForTotalTime(start, end);

    const { total_time } = getTotalTime(car_r);

    populateDurationSelect(total_time);
  };

  useEffect(() => {
    dispatch(getBooking(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (alertType !== undefined && alertType == "addNote") {
      if (isSuccess) {
        setSuccessMessage(
          "The Message・Inquiry has been successfully submitted"
        );
        closeLoading(true);
        openModalSuccessSubmit.click();
      } else {
        setSuccessMessage("Failed to submit Message");
        closeLoading(true);
        openModalSuccessSubmit.click();
      }
    } else if (alertType !== undefined && alertType == "changeFlightNumber") {
      if (isSuccess) {
        setSuccessMessage(
          "The Flight Number Info has been successfully updated"
        );
        closeLoading(true);
        openModalSuccessSubmit.click();
      } else {
        setSuccessMessage("Failed to change Flight Number");
        closeLoading(true);
        openModalSuccessSubmit.click();
      }
    }
  }, [alertState]);

  useEffect(() => {
    if (loading) {
      showLoading(true);
    } else {
      if (
        booking.byTheHourBook !== undefined &&
        booking.byTheHourBook == true
      ) {
        checkTotalTimeForDuration({
          pickupLocation: booking.picuplocation,
          dropoffLocation: booking.destinations,
        });
      }

      setDropoffTimeToBeDisplayed(
        moment(booking.bookingInfo[0].cutomerActualDropOffTime).format("HH:mm")
      );
      // console.log(query.requestId, query.stripeRef);
      if (query.requestId && query.stripeRef) {
        updateRequestChangePaymentStatus();
      }
      if (query.cancelRequestId) {
        deleteCanceledRequestId();
      }
      closeLoading(true);
    }
  }, [booking]);

  const resetValue = () => {
    setReqBodyRequestChange({});
    setHalfHourIntervalsData([]);
    setDropoffTimeToBeDisplayed("");
    setInitialPrice(0);
    setPriceAfterEditBooking(0);
    setAdditionalPayment(0);

    if (booking.byTheHourBook !== undefined && booking.byTheHourBook == true) {
      checkTotalTimeForDuration({
        pickupLocation: booking.picuplocation,
        dropoffLocation: booking.destinations,
      });
    }
    setIsEditBooking(false);
  };

  const getTravelDateWithPickupTime = (formData) => {
    if (formData === null) return;
    const dateString = formData.travelDate + " " + formData.pickupTime;
    // console.log(dateString, "dateString");
    const travel_date_with_pickup_time = moment(dateString, "YYYY-MM-DD HH:mm");
    var p_arr = formData.pickupTime.split(":");
    var p_h = Number(p_arr[0]);
    var p_m = Number(p_arr[1]);

    return { travel_date_with_pickup_time, p_h, p_m };
  };

  const addNoteHandler = (reqBody) => {
    showLoading(true);
    dispatch(addNote(reqBody));
  };

  const closeModalSuccessSubmitHandler = () => {
    setSuccessMessage("");
    buttonCloseModal.click();
  };

  const saveFlightNumberHandler = (reqBody) => {
    showLoading(true);
    dispatch(saveFlightNumber(reqBody));
  };

  const handleEditBookingButton = () => {
    if (!isEditBooking == true) {
      setEditBookingForm({
        travelDate: moment(booking.travelDate).format("YYYY-MM-DD"),
        pickupTime: booking.pickuptime,
        dropoffTime: moment(
          booking.bookingInfo[0].cutomerActualDropOffTime
        ).format("HH:mm"),
        pickupLocation: booking.picuplocation,
        dropoffLocation: booking.destinations,
        duration: booking.byTheHourBook ? booking.duration : null,
      });
    }
    setIsEditBooking(!isEditBooking);
    const element = document.getElementById("editBookingField");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  /**
   * * Get total duration in minutes
   */
  const getTotalDurationInMinutes = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const getTravelDateWithDropoffTime = (formData) => {
    if (formData === null) return;
    const dateString = formData.travelDate + " " + formData.dropoffTime;
    const travel_date_with_dropoff_time_initial = moment(
      dateString,
      "YYYY-MM-DD HH:mm"
    );
    return { travel_date_with_dropoff_time_initial };
  };

  /**
   * * Get the selected route's data
   * @param car_r - Data returned by the Navitime Route (Car) endpoint
   * @param sfd - form data(single destination, multi destination or airport transfer form data)
   * @param trip_start_time - return parameter - Time of dispatch from Garage (calculated by the system using the Navitime API)
   * @param distance - return parameter - route distance from the Navitime API
   * @param fare - return parameter - toll fare from the Navitime API
   * @param longDistanceSurcharge - return parameter - long distance surcharge
   * @param total_time - return parameter - total time in minutes
   * @param tdwdt - return parameter -  travel date with dropoff time (string format)
   * @param gatime - return parameter - estimated final arrival time at the garage (string format)
   */
  const getSelectedRouteData = (car_r, sfd, newtravelDate) => {
    if (car_r) {
      var byTheHourBook = false;
      var totalDurationByTheHour = 0;
      if (sfd.duration !== null) {
        byTheHourBook = true;
        totalDurationByTheHour = getTotalDurationInMinutes(sfd.duration);
      }

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const { travel_date_with_dropoff_time_initial } =
        getTravelDateWithDropoffTime(sfd);

      const summary_arr = car_r[car_r.length - 1]?.summary;
      const sections_arr = car_r[car_r.length - 1]?.sections;
      const garage_to_pickup_time = sections_arr[1].time;

      const section = car_r[car_r.length - 1]?.sections;
      const section_length = section.length;

      //final_drop_off_section : dropoff customer to garage move (object)
      const final_drop_off_section = section[section_length - 2];

      // total_time : add aditional time - customer set drop-off time here;
      // const total_time = summary_arr.move.time + userDdedAditionalTime;
      const total_time = byTheHourBook
        ? garage_to_pickup_time +
        totalDurationByTheHour +
        Number(final_drop_off_section.time)
        : summary_arr.move.time;

      var newtime = travel_date_with_pickup_time;
      var estimated_final_dropoff_time = moment(newtime).add(
        total_time,
        "minutes"
      );

      // Trip start time. No changes
      var trip_start_time = travel_date_with_pickup_time.subtract(
        garage_to_pickup_time,
        "minutes"
      );

      const distance = summary_arr.move.distance;
      if (summary_arr.move.fare != null && byTheHourBook !== true) {
        var fare = summary_arr.move.fare.unit_1025_2
          ? summary_arr.move.fare.unit_1025_2
          : 0;
      } else {
        fare = 0;
      }

      const final_Drop_to_garrage_time_in_minutes = Number(
        final_drop_off_section.time
      );
      var estimated_customer_final_dropoff_time = moment(newtime).add(
        total_time - final_Drop_to_garrage_time_in_minutes,
        "minutes"
      );

      // isAfterEstimatedTime = true = > The customer set drop-off time is greater than the estimated time provided by the Navitime API
      const isCustomerDropOffAfterEstimatedTime =
        travel_date_with_dropoff_time_initial.isAfter(
          estimated_customer_final_dropoff_time
        );

      var userDdedAditionalTime = isCustomerDropOffAfterEstimatedTime
        ? travel_date_with_dropoff_time_initial.diff(
          estimated_customer_final_dropoff_time,
          "minutes"
        )
        : 0;

      estimated_final_dropoff_time = moment(newtime).add(
        total_time + userDdedAditionalTime,
        "minutes"
      );

      // if (isCustomerDropOffAfterEstimatedTime) {
      //   estimated_customer_final_dropoff_time = moment(newtime).add(
      //     total_time +
      //     userDdedAditionalTime -
      //     final_Drop_to_garrage_time_in_minutes,
      //     "minutes"
      //   );
      // }

      const tdwdt = estimated_customer_final_dropoff_time;
      const gatime = estimated_final_dropoff_time;

      let floatDistance = distance / 200000;
      let intDistance = Math.floor(floatDistance);
      var longDistanceSurcharge = intDistance * 20000;

      return {
        trip_start_time,
        distance,
        fare,
        longDistanceSurcharge,
        total_time,
        tdwdt,
        gatime,
      };
    } else {
      return;
    }
  };

  /**
   * * Merge the pickup and dropoff locations to the array. Required for the Navitime API.
   */
  const getViaList = (editBookingForm) => {
    var via = [];
    var pickip_p_obj = {
      lat: editBookingForm.pickupLocation.lat,
      lon: editBookingForm.pickupLocation.lon,
    };
    via.push(pickip_p_obj);

    editBookingForm.dropoffLocation.map((d) => {
      var des_obj = {
        lat: d.lat,
        lon: d.lon,
      };
      via.push(des_obj);
    });
    return JSON.stringify(via);
  };

  const isDateInMultiplierZone = (pd_date) => {
    if (pd_date) {
      const mm_date = moment(pd_date.toString());
      const hour = Number(mm_date.hour());
      var isInZone =
        (0 <= hour && hour < 6) || (22 <= hour && hour < 24) ? true : false;
    }
    return isInZone;
  };

  const float2int = (value) => {
    return value | 0;
  };

  /**
   * * Round the time to the closest 30 min increment
   * @param value - time
   * @param f_val - return the rounded time value
   */
  const roundSixtyMinValue = (value) => {
    const inHours = value / 60;
    const onlydecemal = inHours % 1;
    const onlyHour = float2int(inHours);
    var f_val = 0;
    if (onlydecemal > 0 && onlydecemal < 0.5) {
      f_val = onlyHour + 0.5;
    } else if (onlydecemal > 0.5 && onlydecemal < 1) {
      f_val = onlyHour + 1;
    } else if (onlydecemal === 0) {
      f_val = onlyHour;
    } else if (onlydecemal === 0.5) {
      f_val = inHours;
    }
    return f_val;
  };

  /**
   * * Get rounded travel hours with filter hours
   * @param p_date_time - trip start time
   * @param d_date_time - trip end time with customer added additional hours
   * Note: There is a different calculation required if part of the booking falls within the "Night zone" hours of 22:00-06:00 (1.25 multiplier; this is
   * part of the TLF business logic. The team or the TLF price list can explain more). That's why you see references to a "zone" in the variables etc.
   */
  const getHoursInsideMultiplierZone = (
    p_date_time,
    d_date_time,
    newtravelDate
  ) => {
    if (p_date_time && d_date_time) {
      var zone_minutes = 0;
      var margin_tem_pm = moment(newtravelDate).set({
        hour: 22,
        minute: 0,
      });
      var margin_five_am_drop_off = moment(d_date_time).set({
        hour: 6,
        minute: 0,
      });
      const pickup_datetime = moment(p_date_time.toString());
      const dropoff_datetime = moment(d_date_time.toString());
      const is_pick_in_zone = isDateInMultiplierZone(p_date_time);
      const is_dropoff_in_zone = isDateInMultiplierZone(d_date_time);
      if (is_dropoff_in_zone && !is_pick_in_zone) {
        //berangkat > 6 pagi & pulang > 10 malam
        const dropoff_duration = moment.duration(
          dropoff_datetime.diff(margin_tem_pm)
        );
        zone_minutes = dropoff_duration.asMinutes(); // 10 pm ke dropoff time
      } else if (!is_dropoff_in_zone && is_pick_in_zone) {
        //berangkat < 6 pagi & pulang < 10 malam
        // Get dropoff datetime
        const pickup_zone_duration = moment.duration(
          margin_five_am_drop_off.diff(pickup_datetime)
        );
        zone_minutes = pickup_zone_duration.asMinutes(); // pickuptime ke 6 am
      } else if (is_dropoff_in_zone && is_pick_in_zone) {
        // berangkat < 6 pagi & pulang > 10 malam
        const both_in_same_zone_duration = moment.duration(
          dropoff_datetime.diff(pickup_datetime)
        );
        zone_minutes = both_in_same_zone_duration.asMinutes(); // pickup ke dropoff
      } else if (!is_dropoff_in_zone && !is_pick_in_zone) {
        // berangkat > 6 pagi & pulang < 10 malam
        // Pickup point and drop-off point not in zone
        const is_pic_and_drop_same_day = pickup_datetime.isSame(
          dropoff_datetime,
          "day"
        );
        // 8 * 60 minutes in zone
        zone_minutes = !is_pic_and_drop_same_day ? 480 : 0;
      }

      var roundedValInHours = roundSixtyMinValue(zone_minutes);
    }
    return roundedValInHours;
  };

  /**
   * * Get total hours (billed duration)
   */
  const getTotalHours = (garageArival, tripStartTime, totalDistance) => {
    const totalduration = moment.duration(
      moment(garageArival).diff(moment(tripStartTime))
    );
    var total_minutes = totalduration.asMinutes();

    // additional time
    var breakTime = 0;
    if (totalDistance > 200000) {
      var additionalTime = (total_minutes / 30) * 5;
    } else {
      var additionalTime = (total_minutes / 30) * 7.5;

      breakTime += ((total_minutes + additionalTime) / 120) * 15;
    }

    var totalHrs = roundSixtyMinValue(
      total_minutes + additionalTime + breakTime
    );
    return totalHrs;
  };

  /**
   * * Get total hours (usage duration)
   */
  const getTotalHoursUsage = (dropoffTime, pickuptime) => {
    const totalduration = moment
      .duration(moment(dropoffTime).diff(moment(pickuptime)))
      .asMinutes();

    var total_minutes = totalduration;
    var additionalTime = 0;

    var totalHrs = roundSixtyMinValue(total_minutes + additionalTime);
    return totalHrs;
  };

  // new func
  // Function to convert current time to JST timezone
  function getCurrentTimeInJST() {
    const now = new Date();
    const jstOffset = 9 * 60 + now.getTimezoneOffset(); // JST is UTC+9
    const jstTimestamp = now.getTime() + jstOffset * 60000; // Convert to milliseconds
    return new Date(jstTimestamp);
  }

  // Function to check if now is before 9 am on a day before jst
  function isBefore9amOnDayBeforeJST(jstDate) {
    const currentJST = getCurrentTimeInJST();
    const oneDayBeforeJST = new Date(jstDate);
    oneDayBeforeJST.setDate(oneDayBeforeJST.getDate() - 2); // Get one day before jst
    oneDayBeforeJST.setHours(9, 0, 0, 0); // Set time to 9 am
    return currentJST < oneDayBeforeJST;
  }

  // Function to check if now is after 9 am on a day before jst
  function isAfter9amOnDayBeforeJST(jstDate) {
    const currentJST = getCurrentTimeInJST();
    const oneDayBeforeJST = new Date(jstDate);
    oneDayBeforeJST.setDate(oneDayBeforeJST.getDate() - 2); // Get one day before jst
    oneDayBeforeJST.setHours(9, 0, 0, 0); // Set time to 9 am
    return currentJST > oneDayBeforeJST;
  }

  // Function to check if now is after 6 pm on a day before jst
  function isAfter6pmOnDayBeforeJST(jstDate) {
    const currentJST = getCurrentTimeInJST();
    const oneDayBeforeJST = new Date(jstDate);
    oneDayBeforeJST.setDate(oneDayBeforeJST.getDate() - 2); // Get one day before jst
    oneDayBeforeJST.setHours(18, 0, 0, 0); // Set time to 6 pm
    return currentJST > oneDayBeforeJST;
  }

  // Function to check if now is jst day
  function isJSTDay(jstDate) {
    const currentJST = getCurrentTimeInJST();
    const jstDay = new Date(jstDate);
    jstDay.setDate(jstDay.getDate() - 1);
    return (
      currentJST.getFullYear() === jstDay.getFullYear() &&
      currentJST.getMonth() === jstDay.getMonth() &&
      currentJST.getDate() === jstDay.getDate()
    );
  }

  const insertEditBooking = async () => {
    // if (!validationCheck()) return;
    showLoading(true);
    const { travel_date_with_pickup_time } =
      getTravelDateWithPickupTime(editBookingForm);

    const via = getViaList(editBookingForm);
    const travel_date_with_format = travel_date_with_pickup_time.format(
      "YYYY-MM-DDThh:mm:ss"
    );
    const car_r = await getRouteCar(
      "35.6235029,139.7410879",
      via,
      travel_date_with_format
    );

    const {
      trip_start_time,
      distance,
      fare,
      longDistanceSurcharge,
      total_time,
      tdwdt,
      gatime,
    } = getSelectedRouteData(
      car_r,
      editBookingForm,
      travel_date_with_pickup_time
    );

    var packages = [];
    if (booking.isAirportTransfer === true) {
      var dropoffLocation = editBookingForm.dropoffLocation[
        editBookingForm.dropoffLocation.length - 1
      ].address_components
        .find(
          (c) =>
            c.types[0].toLowerCase() === "locality" ||
            c.types[0].toLowerCase() === "administrative_area_level_1"
        )
        .long_name.toLowerCase();

      var pickupLocation = editBookingForm.pickupLocation.address_components
        .find(
          (c) =>
            c.types[0].toLowerCase() === "locality" ||
            c.types[0].toLowerCase() === "administrative_area_level_1"
        )
        .long_name.toLowerCase();

      // Airport package gets activated. Additional steps added.
      const pkges = await filterNewPackage({
        pickupLocation: editBookingForm.pickupLocation,
        dropoffLocation: editBookingForm.dropoffLocation,
        isAirportTransfer: booking.isAirportTransfer,
      });

      if (pkges.length > 0) {
        packages.push(pkges[0]);
      }
    }

    const zoneIncludedHoursRounded = getHoursInsideMultiplierZone(
      trip_start_time,
      gatime,
      travel_date_with_pickup_time
    );

    const totHoursComplete = getTotalHours(gatime, trip_start_time, distance);
    const totalUsageSuration = getTotalHoursUsage(
      tdwdt,
      travel_date_with_pickup_time
    );

    const { total, pricePerVehicle } = getTotalEditBooking(
      booking.bookingInfo,
      totHoursComplete,
      fare,
      zoneIncludedHoursRounded,
      packages,
      longDistanceSurcharge
    );

    var meetAssistPrice = 0;
    booking.meetAssist.map((val) => {
      meetAssistPrice += val.meetAssistPrice;
    });

    let reqBody = {
      travelDateRequest: travel_date_with_pickup_time,
      pickupTimeRequest: editBookingForm.pickupTime,
      dropoffTimeRequest: tdwdt,
      dropoffTimeHoursRequest: moment(tdwdt).format("HH:mm"),
      startFromGarage: trip_start_time,
      arrivalAtGarage: gatime,
      tollFare: fare,
      longDistanceSurcharge: longDistanceSurcharge,
      totalPrice: total + meetAssistPrice,
      pricePerVehicle: pricePerVehicle,
      bookingId: booking._id,
      requestType: "reschedule",
      pickupLocationRequest: editBookingForm.pickupLocation,
      destinationsRequest: editBookingForm.dropoffLocation,
      lang: localStorage.getItem("lang"),
    };

    setInitialPrice(booking.totalPrice);
    setPriceAfterEditBooking(total + meetAssistPrice);
    if (total + meetAssistPrice - booking.totalPrice == 0) {
      closeLoading(true);
      openModalPriceSameEditBooking.click();
    } else if (total + meetAssistPrice - booking.totalPrice < 0) {
      var cancelPercent = 0;
      if (isJSTDay(booking.travelDate)) cancelPercent = 100;
      else if (isAfter6pmOnDayBeforeJST(booking.travelDate)) cancelPercent = 50;
      else if (isAfter9amOnDayBeforeJST(booking.travelDate)) cancelPercent = 30;
      else if (isBefore9amOnDayBeforeJST(booking.travelDate))
        cancelPercent = 20;
      reqBody.partialCancellationFee =
        (((total + meetAssistPrice - booking.totalPrice) * cancelPercent) /
          100) *
        -1;
      reqBody.totalRefundAmount =
        (total + meetAssistPrice - booking.totalPrice) * -1 -
        (((total + meetAssistPrice - booking.totalPrice) * cancelPercent) /
          100) *
        -1;
      reqBody.percentRefund = cancelPercent;

      closeLoading(true);
      openModalPriceLowerEditBooking.click();
    } else {

      if (total + meetAssistPrice - booking.totalPrice <= 100)
        setAdditionalPayment(100);
      else setAdditionalPayment(total + meetAssistPrice - booking.totalPrice);

      closeLoading(true);
      openModalPriceHigherEditBooking.click();
    }

    setReqBodyRequestChange(reqBody);
  };

  const handleProceedToPaymentButton = async () => {
    showLoading(true);
    const createRequest = await postRequest(reqBodyRequestChange);

    if (createRequest.code == 201) {
      if (createRequest.session !== null) {
        closeLoading(true);
        resetValue();
        setTimeout(() => {
          window.open(createRequest.session.session.url, "_self");
        });
      } else {
        dispatch(updateBookingState(createRequest.booking));
        closeLoading(true);
        resetValue();
        buttonCloseModal.click();
        buttonOpenRequestSubmitted.click();
      }
    } else {
      closeLoading(true);
      resetValue();
      buttonCloseModal.click();
      buttonOpenFailedSubmitRequest.click();
    }
  };

  const onChangeTravelDate = (travelDate) => {
    setEditBookingForm({
      ...editBookingForm,
      travelDate: travelDate,
    });
  };

  const onChangeEditBooking = (e) => {
    setEditBookingForm({
      ...editBookingForm,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeEditBookingDuration = (e) => {
    setEditBookingForm({
      ...editBookingForm,
      duration: e.value,
    });
  };

  const onChangePickupLocation = (loc) => {
    setEditBookingForm({
      ...editBookingForm,
      pickupLocation: {
        lat: loc.geometry.location.lat(),
        lon: loc.geometry.location.lng(),
        label: loc.name + ", " + loc.formatted_address,
        formatted_address: loc.name + ", " + loc.formatted_address,
        value: loc.place_id,
        city: loc.address_components.find((c) =>
          c.types[0].includes("administrative_area_level_1")
        ).long_name,
      },
    });
  };

  const onChangeDropoffLocation = (place) => {
    setEditBookingForm({
      ...editBookingForm,
      dropoffLocation: place,
    });
  };

  const handleChangeDropoffValidation = (condition) => {
    // setformDataValidation(formDataValidation => ({
    //   ...formDataValidation,
    //   dropofflocations: condition
    // }))

  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
   * ? sfd - Selected Form Data
   * ? timeString - pickup time in string 'HH:MM'
   * @param sfd
   * @param timeString - not required
   * @param duration - selected duration
   */
  const estimateTimeToDestinationWithDuration = (sfd, timeString) => {
    const timeToAdd = timeString === undefined ? sfd.pickupTime : timeString;
    const duration = sfd.duration == null ? "0:0" : sfd.duration;

    const [hours, minutes] = timeToAdd.split(":").map(Number);
    const [hoursDuration, minutesDuration] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const totalDurations = hoursDuration * 60 + minutesDuration;
    const newTotalMinutes = (totalMinutes + totalDurations) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    setDropoffTimeToBeDisplayed(
      `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
        2,
        "0"
      )}`
    );
    // setEditBookingForm({
    //   ...editBookingForm,
    //   dropoffTime: `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`,
    // })
  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
   * ? sfd - Selected Form Data
   * ? timeString - pickup time in string 'HH:MM'
   * @param sfd
   * @param timeString - not required
   */
  const estimateTimeToDestination = async (sfd) => {
    const start = sfd.pickupLocation.lat + "%2C" + sfd.pickupLocation.lon;
    const end = sfd.dropoffLocation[0].lat + "%2C" + sfd.dropoffLocation[0].lon;

    const car_r = await getRouteCarForTotalTime(start, end);

    const { total_time } = getTotalTime(car_r);

    const timeToAdd = sfd.pickupTime;

    const [hours, minutes] = timeToAdd.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const newTotalMinutes = (totalMinutes + total_time) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    setDropoffTimeToBeDisplayed(
      `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
        2,
        "0"
      )}`
    );
    // setEditBookingForm({
    //   ...editBookingForm,
    //   dropoffTime: `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`,
    // })
  };

  useEffect(() => {
    checkTotalTimeForDuration({
      pickupLocation: editBookingForm.pickupLocation,
      dropoffLocation: editBookingForm.dropoffLocation,
    });
  }, [editBookingForm.dropoffLocation, editBookingForm.pickupLocation]);

  useEffect(() => {
    if (editBookingForm.duration !== null) {
      estimateTimeToDestinationWithDuration(editBookingForm);
    } else {
      estimateTimeToDestination(editBookingForm);
    }
  }, [
    editBookingForm.dropoffLocation,
    editBookingForm.pickupLocation,
    editBookingForm.pickupTime,
    editBookingForm.duration,
  ]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [type, setType] = useState("");
  useEffect(() => {
    if (!loading) {
      switch (booking.bookingStatus) {
        case "Confirmed":
          const dateString = booking.startTime;
          const givenDate = new Date(dateString);
          const currentDate = new Date();
          if (givenDate > currentDate) {
            setType("Upcoming");
          } else {
            setType("Completed");
          }
          break;
        case "Cancelled":
          setType("Cancelled");
          break;
        default:
          break;
      }
    }
  }, [booking, loading]);

  return (
    <FrontLayout>
      {/* //close modal */}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        id="closeModal"
        style={{
          display: "none",
        }}
      ></button>

      {/* //open reschedule submited */}
      <button
        type="button"
        className="btn btn-primary  rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#reschedule_submitted"
        id="openRequestSubmitted"
        style={{
          display: "none",
        }}
      >
        Proceed
      </button>

      {/* //open reschedule submited */}
      <button
        type="button"
        className="btn btn-primary rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#failed_submit"
        id="openFailedSubmitRequest"
        style={{
          display: "none",
        }}
      >
        Proceed
      </button>

      {/* //open success submit */}
      <button
        type="button"
        className="btn btn-primary rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#modal_success_submit"
        id="openModalSuccessSubmit"
        style={{
          display: "none",
        }}
      ></button>

      {/* open modal_price_higher_edit_booking modal */}
      <button
        type="button"
        className="btn btn-primary rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#modal_price_higher_edit_booking"
        id="openModalPriceHigherEditBooking"
        style={{
          display: "none",
        }}
      ></button>

      {/* open modal_price_same_edit_booking modal */}
      <button
        type="button"
        className="btn btn-primary rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#modal_price_same_edit_booking"
        id="openModalPriceSameEditBooking"
        style={{
          display: "none",
        }}
      ></button>

      {/* open modal_price_lower_edit_booking modal */}
      <button
        type="button"
        className="btn btn-primary rounded col-md-2 col-6"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#modal_price_lower_edit_booking"
        id="openModalPriceLowerEditBooking"
        style={{
          display: "none",
        }}
      ></button>

      {/* //open success cancel */}
      <button
        type="button"
        className="btn btn-primary px-5 rounded"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#request_cancellation_submited"
        id="openCancellationSubmitted"
        style={{
          display: "none",
        }}
      >
        Proceed
      </button>

      <div className="bg-page" id="editBookingField">
        <div className="container mt-1 pt-1">
          <div className="custom-mx">
            <div className=" pb-5 custom-mt-9 col-lg-12">
              <div className=" d-flex-auto row align-items-center mb-3">
                <div className="col">
                  <Link to="/my-page/bookings" style={{ textDecoration: "none" }}>
                    <p
                      className="fw-100 text-start text-dark noto d-inline back-to-vehicle-selection-button"
                    >
                      <div
                        className="col-auto back-to-vehicle-selection-button"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={back_icon}
                          className=""
                          alt="Clock icon"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp; Back to Bookings
                      </div>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                  <h3>Booking #{booking?.key}</h3>
                </div>
                <div className="col-auto d-none d-md-block">
                  {/* <button
                    type="button"
                    className="btn btn-primary rounded px-4"
                    onClick={() => handleOpenModal(booking)}
                  >
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp; <span className="white-text">Download Receipt</span>
                  </button> */}
                </div>
              </div>
              <div className=" my-3">
                {!loading ? (
                  <BookingItem
                    key={1}
                    type={type}
                    cardId={bookingId}
                    onSelect={() => { }}
                    isSelected={false}
                    bookingDetail={booking}
                    viewDetailButton={false}
                    isBookingList={false}
                    addNoteHandler={addNoteHandler}
                    saveFlightNumberHandler={saveFlightNumberHandler}
                    isEditBooking={isEditBooking}
                    onChangeEditBooking={onChangeEditBooking}
                    onChangeEditBookingDuration={onChangeEditBookingDuration}
                    onChangePickupLocation={onChangePickupLocation}
                    onChangeDropoffLocation={onChangeDropoffLocation}
                    handleChangeDropoffValidation={
                      handleChangeDropoffValidation
                    }
                    halfHourIntervalsData={halfHourIntervalsData}
                    editBookingForm={editBookingForm}
                    dropoffTimeToBeDisplayed={dropoffTimeToBeDisplayed}
                    onChangeTravelDate={onChangeTravelDate}
                  />
                ) : (
                  <>
                    <div className="row placeholder-glow">
                      <div
                        class=" placeholder"
                        style={{ height: "430px" }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
              {!loading && (
                type == "Upcoming" && <>
                  {isEditBooking ? (
                    <div className="row justify-content-center mt-5 gap-2">
                      <div className="col-md-3 d-grid">
                        <button
                          type="button"
                          className="btn btn-secondary rounded"
                          // data-bs-toggle="modal"
                          // data-bs-target="#request_reschedule"
                          onClick={() => {
                            handleEditBookingButton();
                          }}
                        >
                          Discard
                        </button>
                      </div>
                      <div className="col-md-3 d-grid">
                        <button
                          type="button"
                          className="btn btn-primary rounded"
                          onClick={() => {
                            insertEditBooking();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="row justify-content-center mt-5 gap-2">
                      <div
                        className="col-md-3 d-grid"
                        data-tip
                        data-for={
                          !booking?.requestChangeBookingData
                            ? ""
                            : booking.requestChangeBookingData.approvalStatus ==
                              0
                              ? "EditRequestTooltip"
                              : ""
                        }
                      >
                        <ReactTooltip
                          id="EditRequestTooltip"
                          backgroundColor="#4E4E4E"
                          textColor="#fffff"
                        >
                          <p className="text-white">
                            Following the submission of a booking modification
                            request, we will verify availability
                            <br />
                            within 24 hours, and notify you of the results via
                            email and on the website.
                          </p>
                        </ReactTooltip>
                        <button
                          type="button"
                          className="btn btn-secondary rounded button-primary-on-hover button-primary-on-hover"
                          onClick={() => {
                            handleEditBookingButton();
                          }}
                          disabled={
                            !booking?.requestChangeBookingData
                              ? false
                              : booking.requestChangeBookingData
                                .approvalStatus == 0
                                ? true
                                : false
                          }
                        // style={{pointerEvents : "auto"}}
                        >
                          {!booking?.requestChangeBookingData ? (
                           t('my_page.bookings_page.booking_item.edit_booking')
                          ) : booking.requestChangeBookingData.approvalStatus ==
                            0 ? (
                            <span>Edit Request Sent</span>
                          ) : (
                           t('my_page.bookings_page.booking_item.edit_booking')
                          )}
                        </button>
                      </div>

                      <div className="col-md-3 d-grid">
                        <button
                          type="button"
                          className="btn rounded"
                          style={{ backgroundColor: "#ED553B" }}
                          data-bs-toggle="modal"
                          data-bs-target="#request_cancellation"
                          disabled={
                            !booking?.requestChangeBookingData
                              ? false
                              : booking.requestChangeBookingData
                                .approvalStatus == 0
                                ? true
                                : false
                          }
                        >
                          <span className="text-white">{
                           t('my_page.bookings_page.booking_item.cancel_booking')}</span>
                        </button>
                      </div>
                    </div>
                  )}
                  {booking?.requestChangeBookingData &&
                    booking.requestChangeBookingData.approvalStatus === 0 && (
                      <>
                        <p className="text-center col-md-8 offset-md-2 mt-4">
                        {t('my_page.bookings_page.booking_item.on_request_note')}
                        </p>
                        
                      </>
                    )}
                </>
              )}
              <p className="text-center col-md-8 offset-md-2 mt-4" dangerouslySetInnerHTML={{
                __html : t('my_page.bookings_page.booking_item.contact_us_text')
              }}>
                
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="reschedule_submitted"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="reschedule_submitted_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered "
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content  border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => resetValue()}
                ></button>
              </div>
              <p className="text-center fs-16 noto mt-3 ">
                Your request to reschedule the booking has been successfully
                submitted
              </p>
              <p className="text-center">
                Upon processing your request, we'll inform you through both the
                website and email.
              </p>
              <div className="my-4">
                <div className="d-flex justify-content-center gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary rounded "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetValue()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="failed_submit"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="failed_submit_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered "
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content  border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => resetValue()}
                ></button>
              </div>
              <p className="text-center fs-16 noto mt-3 ">
                You have already request cancelation or reschedule of this
                booking
              </p>
              <p className="text-center">
                You can not request reschedule or cancelation of the same
                booking before approval by admin.
              </p>
              <div className="my-4">
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className="btn btn-secondary rounded "
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetValue()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal_success_submit"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="modal_success_submit_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered "
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content  border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    closeModalSuccessSubmitHandler();
                  }}
                ></button>
              </div>
              <p className="text-center fs-16 noto mt-3 ">{successMessage}</p>
              <div className="my-4">
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className="btn btn-secondary rounded "
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      closeModalSuccessSubmitHandler();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="modal_price_higher_edit_booking"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="modal_price_higher_edit_booking_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          style={{ maxWidth: "900px" }}
        >
          <div className="modal-content border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-md-5">
                <p className="text-center fs-16 noto mt-3 ">
                  Following the adjustments, there is a corresponding change in
                  the total price. As a result, please note that an additional
                  fee will be charged upon submitting this booking modification
                  request.
                </p>
                <div className="px-md-3">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="card-body bg-gold-light-100 border border-0">
                          <p className="card-text font-weight-light mb-0">
                            Initial Total Price: JPY{" "}
                            {initialPrice.toLocaleString()}
                          </p>
                          <p className="card-text font-weight-light mb-0">
                            <b>
                              Total Price after booking modification: JPY{" "}
                              {priceAfterEditBooking.toLocaleString()}
                            </b>
                          </p>
                          <p className="card-text font-weight-light">
                            <b>
                              Additional payment: JPY{" "}
                              {additionalPayment <= 0
                                ? "00,000"
                                : additionalPayment.toLocaleString()}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-start fs-14 noto mt-3 noto-sans">
                    ※ Regarding the additional fee, we will authorize the
                    amount, however,{" "}
                    <span style={{ fontWeight: "700" }}>
                      your card will not be charged until the availability of
                      the booking modification request is confirmed
                    </span>{" "}
                    by our operation team (within 24 hours).
                  </p>
                </div>
                <div className="my-4">
                  <div className="row justify-content-center gap-2">
                    <div className="col-md-4 d-grid order-2 order-md-1">
                      <button
                        type="button"
                        className="btn btn-secondary  rounded "
                        data-bs-dismiss="modal"
                      >
                        Back
                      </button>
                    </div>
                    <div className="col-md-4 d-grid order-1 order-md-2">
                      <button
                        type="button"
                        className="btn btn-primary  rounded "
                        onClick={() => handleProceedToPaymentButton()}
                      >
                        Proceed To Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="modal_price_same_edit_booking"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="modal_price_same_edit_booking_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-4">
                <p className="text-center fs-16 noto mt-3 mb-3 ">
                  Upon the rescheduling to the selected time slots, the price
                  will remain unchanged. Would you like to confirm this booking
                  reschedule request?
                </p>
                <div className="my-4">
                  <div className="d-flex justify-content-center gap-2">
                    <button
                      type="button"
                      className="btn btn-secondary px-5 rounded"
                      data-bs-dismiss="modal"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary px-5 rounded"
                      onClick={() => handleProceedToPaymentButton()}
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="modal_price_lower_edit_booking"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="modal_price_lower_edit_booking_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg "
          role="document"
          style={{ maxWidth: "901px" }}
        >
          <div className="modal-content border-top-gold ">
            <div className="modal-body ">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-2 px-md-5">
                <p className="text-center fs-16 noto mt-3 ">
                  It seems that your selected usage hours are shorter than the
                  previously booked one. Please note that a cancellation fee
                  will be applied for the reduced hours, as follows:
                </p>
                <div className="row mb-3">
                  <div className="col">
                    <div
                      className="card"
                      style={{ backgroundColor: "#F4F4F4" }}
                    >
                      <div className="card-body ">
                        <ul className="m-0">
                          <li style={{ fontWeight: "700" }}>
                            Upon confirmation until 9 am before the reservation
                            day will be 20%.
                          </li>
                          <li>
                            After 9 am on the day before the reservation will be
                            30%.
                          </li>
                          <li>
                            After 6 pm on the day before the reservation will be
                            50%
                          </li>
                          <li>
                            Reservation day cancellation fee will be 100%.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-start fs-14 noto-sans mt-3 ">
                  ※ Kindly keep in mind that the refund will be processed after
                  your transfer.
                </p>
                <div className="my-4">
                  <div className="row justify-content-center gap-2">
                    <div className="d-grid col-md-3 order-2 order-md-1">
                      <button
                        type="button"
                        className="btn btn-secondary rounded"
                        data-bs-dismiss="modal"
                      >
                        Back
                      </button>
                    </div>
                    <div className="d-grid col-md-3 order-1 order-md-2">
                      <button
                        type="button"
                        className="btn btn-primary  rounded"
                        onClick={() => handleProceedToPaymentButton()}
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="request_cancellation_submited"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="request_cancellation_submited_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered "
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content  border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <p className="text-center noto">
                Your request to cancel has been successfully submitted
              </p>
              <p className="text-center">
                Upon processing your request, we'll inform you through both the
                website and email.
              </p>
              <div className="my-4">
                <div className="d-flex justify-content-center gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary px-5 rounded"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="request_cancellation"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="request_cancellation_label"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered "
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content  border-top-gold">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <p className="text-center noto">
                Would you like to cancel your booking?
              </p>
              <div className="card bg-gold-light-100">
                <div className="card-body">
                  <p>
                    Before proceeding with the request to cancel your booking,
                    please note the following cancellation policy:
                  </p>
                  <ul>
                    <li>
                      <b>
                        Upon confirmation until 9 am before the reservation day
                        will be 20%.
                      </b>
                    </li>
                    <li>
                      After 9 am on the day before the reservation will be 30%.
                    </li>
                    <li>
                      After 6 pm on the day before the reservation will be 50%
                    </li>
                    <li>Reservation day cancellation fee will be 100%.</li>
                  </ul>
                </div>
              </div>
              <div className="mt-4">
                <div className="d-flex justify-content-center gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary px-5 rounded"
                    data-bs-dismiss="modal"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn px-5 rounded"
                    style={{ backgroundColor: "#ED553B" }}
                    onClick={() => submitCancellation()}
                  >
                    <span className="text-white"> Cancel Booking</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem && (
        <ReceiptModal
          data={selectedItem}
          handleClose={handleCloseModal}
          show={showModal}
        />
      )}
    </FrontLayout>
  );
};

export default BookingDetailPage;
