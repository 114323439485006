import React, { useEffect, useState } from "react";
import Calendar from "react-awesome-calendar";
// import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import Layout from "../../layout/Layout";
import {
  // getBookingsForCalander,
  getCalanderBookingsFilter,
} from "../../../actions/admin/booking";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
// import LoadingOverlay from "react-loading-overlay";
// import ScaleLoader from "react-spinners/ScaleLoader";
import Spinner from "../../layout/Spinner";
import { useLocation, Link, useHistory } from "react-router-dom";

const BookingCalander = () => {
  // const [isloading, setIsloading] = useState(false);
  const history = useHistory();
  const [eventData, setEventData] = useState([]);
  const [search, setSearch] = useState("null");
  const [dateRange, setdateRange] = useState({
    startDate: moment().add(-20, "days"),
    // localStorage.getItem("calstartDate") !== null
    //   ? moment(localStorage.getItem("calstartDate"))
    //   : moment(),
    endDate: moment().add(40, "days"),
    // localStorage.getItem("calendDate") !== null
    //   ? moment(localStorage.getItem("calendDate"))
    //   : moment().add(30, "days"),
  });
  const booking = useSelector((state) => state.booking);
  const { loading, calanderBooking } = booking;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(calanderBooking);
    const data = calanderBooking?.map((row) => {
      // console.log(moment(row.travelDate).format("YYYY-MM-DDTHH:mm:ss"));
      return {
        id: row._id,
        // color: "green",
        color: getLabelColor(row.vehicle?.vehicletype),
        // from: moment.utc(row.travelDate).toDate(),
        // from: "2021-10-24T15:00:00+05:30",
        // to: "2021-10-24T18:00:00+05:30",
        from: moment(row.travelDate).format("YYYY-MM-DDTHH:mm:ss"),
        to: moment(row.dropoffDatetime).format("YYYY-MM-DDTHH:mm:ss"),

        // to: moment.utc(row.dropoffDatetime).format("YYYY-MM-DDTHH:mm:ss+00:00"),
        // from: "2021-10-22T01:00:00+00:00",
        // to: "2021-10-22T05:00:00+00:00",
        title:
          "Cart ID : " +
          row.cartID +
          " / Customer : " +
          row.customer.name +
          " / Pickup location : " +
          row.picuplocation.label +
          " / Vehicle : " +
          row.vehicle?.vehicletype,
      };
    });
    setEventData(data);
  }, [calanderBooking]);

  useEffect(() => {
    dispatch(
      getCalanderBookingsFilter(
        search,
        dateRange.startDate,
        dateRange.endDate,
        false
      )
    );
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getBookingsFilter(search, dateRange.startDate, dateRange.endDate, false)
  //   );
  // }, [dateRange]);

  const calanderOnClickTimeline = (bookingid) => {
    console.log(bookingid);
    history.push("/admin/bookinglist/edit/" + bookingid);
    // `/admin/bookinglist/edit/${_id}`
  };

  const calanderOnchange = (mode) => {
    console.log(mode);
    if (mode.mode === "monthlyMode") {
      const selectedMonthStartDate = moment({
        year: mode.year,
        month: mode.month,
        day: mode.day,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }).add(-10, "days");
      // console.log("selectedMonthStartDate");
      console.log(selectedMonthStartDate);
      const selectedMonthEndDate = moment({
        year: mode.year,
        month: mode.month,
        day: mode.day,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }).add(40, "days");
      console.log("selectedMonthEndDate");
      console.log(selectedMonthEndDate);
      dispatch(
        getCalanderBookingsFilter(
          search,
          selectedMonthStartDate,
          selectedMonthEndDate,
          false
        )
      );
    }
  };

  const getLabelColor = (type) => {
    console.log("type");
    console.log(type);
    var color = "MidnightBlue";
    if (type === "Grand Cabin") color = "blue";
    else if (type === "Toyota Alphard") color = "green";
    else if (type === "Luxury Cabin" || type === "Toyota HiAce Luxury Cabin")
      color = "orange";
    else if (type === "Toyota Granace" || type === "Toyota HiAce Grand Cabin")
      color = "red";
    return color;
  };

  return (
    // <LoadingOverlay active={loading} spinner={<ScaleLoader color="#36D7B7" />}>
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Calendar
              events={eventData}
              onChange={calanderOnchange}
              onClickEvent={calanderOnClickTimeline}
            />
          )}
        </div>
      </main>
    </Layout>
    // </LoadingOverlay>
  );
};

export default BookingCalander;
