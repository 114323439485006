import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
/** The "Google Autocomplete" component is used in places like the client/src/components/layout-new-theme/form-card/AirportTransferForm.js,
 * client/src/components/layout-new-theme/form-card/MultipleDestinationForm.js and client/src/components/layout-new-theme/form-card/SingleDestinationForm.js
 * The form controllers for the pick-up and drop-off addresses use the Google Maps API to autocomplete the user's query > The data is then 
 * passed to the Navitime API for the rest.
 */
const GoogleAutoCompleteEditBooking = ({
  id,
  defaultValue,
  locationOnPlaceSelected,
  placeholder,
  ariaLabel,
  locationValue,
  emptyValue,
  isNotValid,
  isMulti = false,
}) => {
  const [filled, setFilled] = useState(false)
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyBlsHGa4BjOPWONDTO6zaJzpHyoT_TqOco",
    onPlaceSelected: (place) => {
      console.log("place--------");
      console.log(place);
      if (place.address_components && place.address_components.length >= 4) {
        setFilled(true);
        locationOnPlaceSelected(place, place.address_components.length ?? 0);
      } else {
        setFilled(false);
      }
    },
    // language: "en",
    options: {
      types: ["geocode", "establishment"],
      fields: ["geometry", "formatted_address", "address_components", "name", "place_id"],
      componentRestrictions: { country: "jp" },
    },
    // libraries: {
    //   defaultValue: ["places"],
    // },
  });

  const handleChange = event => {
    if (typeof emptyValue === 'function') {
      emptyValue();
    }
  };

  return (
    <input
      id={id}
      ref={ref}
      defaultValue={defaultValue}
      type="text"
      className={isNotValid ? "form-control invalid" : isMulti ? "form-control is-multi-dropoff" : "form-control"}
      placeholder={placeholder}
      onChange={handleChange}
      // aria-label={ariaLabel}
      // value={locationValue}
      style={{
        color: filled || defaultValue ? "#282828 !important" : "",
        fontWeight: filled || defaultValue? "bold" : "",
        fontSize: "14px",
      }}
    />
  );
};

export default GoogleAutoCompleteEditBooking;
