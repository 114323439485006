import React, { useState, useEffect, Fragment } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back_icon from "../../images/back_icon.svg";
import payment_method from "../../images/payment_method.svg";
import visa_icon from "../../icons/visa.svg";
import master_card_icon from "../../icons/master-card.svg";
import jcb_icon from "../../icons/jcb.svg";
import american_ex_icon from "../../icons/american_ex.svg";
import { customerRegister } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "./LoadingContext";
import ReactTooltip from "react-tooltip";

const CustomerRegisterPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const history = useHistory();
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const [countriesData, setCountriesData] = useState([]);
  const [countryCode, setCountryCode] = useState("JP");
  const [prefixPhoneNumber, setPrefixPhoneNumber] = useState("+81");
  const [pronounced, setPronounced] = useState("Mr.");
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  const formData = location.state.formData;
  const [buttonSubmit, setButtonSubmit] = useState(false);

  const { showLoading, closeLoading } = useLoading();

  //form 1
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [profileData, setProfileData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    phone: "",
    address: "",
    postcode: "",
    country: "Japan",
    pronounced: "Mr.",
    prefix_number: "+81",
    country_code: "JP",
    accessTokenGoogle: null,
    avatar: null,
  });

  useEffect(() => {
    profileData.email = formData.email;
    profileData.password = formData.password;
    profileData.confirmPassword = formData.password_confirmation;
    if (formData.sign_up_with_google)
      profileData.accessTokenGoogle = formData.access_token_google;
    if (formData.sign_up_with_google && formData.avatar !== null)
      profileData.avatar = formData.avatar;
  }, []);

  const [errorsProfileData, setErrorsProfileData] = useState({});

  const validateProfileForm = () => {
    const validationErrors = {};

    if (!profileData.email) {
      validationErrors.email = "Email is required";
    }

    if (!formData.sign_up_with_google) {
      if (!profileData.password) {
        return false;
        validationErrors.password = "Password is required";
      } else if (profileData.password.length < 8) {
        return false;
        validationErrors.password =
          "Password must be at least 8 characters long";
      }

      if (profileData.password !== profileData.confirmPassword) {
        return false;
        validationErrors.confirmPassword = "Passwords do not match";
      }
    }

    if (!profileData.name) {
      return false;
      validationErrors.name = "Name is required";
    }

    if (!profileData.phone) {
      return false;
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(profileData.phone)) {
      return false;
      validationErrors.phone = "Phone number must contain only digits";
    }

    if (!profileData.address) {
      return false;
      validationErrors.address = "Address is required";
    }

    if (!profileData.postcode) {
      return false;
      validationErrors.postcode = "Postcode is required";
    }

    if (!profileData.country) {
      return false;
      validationErrors.country = "Country is required";
    }
    if (agreeToTerms == false) {
      return false;
      validationErrors.agreeToTerms = "Agree to term and condition is required";
    }
    return true;

    setErrorsProfileData(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChangeProfile = (e) => {
    const validationErrors = {};
    const { name, value } = e.target;
    // console.log(name, value);
    if (!formData.sign_up_with_google) {
      if (name == "password" && value == "") {
        validationErrors.password = t("sign_up.password_required");
      } else if (name == "password" && value.length < 8) {
        validationErrors.password =
        t("sign_up.password_eight_long");
      }
      if (
        (name == "password" && value != profileData.confirmPassword) ||
        (name == "confirmPassword" && value != profileData.password)
      ) {
        validationErrors.confirmPassword = t("sign_up.pssword_not_match");
      }
    }
    if (name == "email" && value == "") {
      validationErrors.email = t("sign_up.email_required");
    }
    if (name == "email" && value != "") {
      if (validateEmail(value) == false) {
        validationErrors.email = t("sign_up.email_invalid_format");
      }
    }
    setErrorsProfileData(validationErrors);
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  //end form 1

  // form 2 start
  const [addPaymentMethodForm, setAddPaymentMethodForm] = useState(true);
  const [addPaymentMethodButton, setAddPaymentMethodButton] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("card");

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [countryOrRegion, setCountryOrRegion] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [cardholderNameError, setCardholderNameError] = useState("");
  const [countryOrRegionError, setCountryOrRegionError] = useState("");

  const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegex = /^5[1-5][0-9]{14}$/;
  const jcbRegex = /^(352[8-9]|35[3-8][0-9])[0-9]{12}$/;
  const amexRegex = /^3[47][0-9]{13}$/;

  const handleCardNumberChange = (e) => {
    const value = e.target.value;

    setCardNumber(value);
    if (value === "") {
      setCardNumberError("Card Number Required");
    } else {
      if (!checkCreditCardNumber(value)) {
        setCardNumberError("Card Number Format Invalid");
      } else {
        setCardNumberError("");
      }
    }
  };

  function checkCreditCardNumber(cardNumber) {
    return (
      visaRegex.test(cardNumber) ||
      mastercardRegex.test(cardNumber) ||
      jcbRegex.test(cardNumber) ||
      amexRegex.test(cardNumber)
    );
  }

  const handleExpiryDateChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.substring(0, 2) + "/" + inputValue.substring(2);
    }

    setExpiryDate(inputValue);
  };

  const handleCVVChange = (e) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setCVV(value);
      // if (value === "") {
      //   setCVVError("CVV Required");
      // } else {
      //   setCVVError("");
      // }
    }
  };

  const handleCardholderNameChange = (e) => {
    const value = e.target.value;
    setCardholderName(value);

    // if (value === "") {
    //   setCardholderNameError("Card Holder Name Required");
    // } else {
    //   setCardholderNameError("");
    // }
  };

  const handleCountryOrRegionChange = (e) => {
    const value = e.target.value;
    setCountryOrRegion(value);
    // if (value === "") {
    //   setCountryOrRegionError("Country Region Required");
    // } else {
    //   setCountryOrRegionError("");
    // }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  // form 2 end

  useEffect(() => {
    loadCountriesData();
  }, []);

  useEffect(() => {
    handleAddPaymentMethodButton();
  }, [
    cardNumber,
    expiryDate,
    cvv,
    cardholderName,
    countryOrRegion,
    paymentMethods,
  ]);

  const handleAddPaymentMethodButton = () => {
    if (
      cardNumber !== "" &&
      expiryDate !== "" &&
      cvv !== "" &&
      cardholderName !== "" &&
      countryOrRegion !== ""
    ) {
      setAddPaymentMethodButton(true);
    } else {
      setAddPaymentMethodButton(false);
    }
  };

  const deletePaymentMethod = (index) => {
    const confirmDelete = window.confirm(t("registration.delete_confirmation"));
    if (confirmDelete) {
      const updatedPaymentMethods = paymentMethods.filter(
        (_, i) => i !== index
      );
      setPaymentMethods(updatedPaymentMethods);
    }
  };

  // Function to handle the checkbox change
  const handleAgreeToTermsChange = (e) => {
    // console.log("ON CHECK", e.target.checked);
    setAgreeToTerms(e.target.checked);
  };

  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step == 1) {
      setStep(2);
    }
    if (step === 2) {
      if (addPaymentMethodForm) {
        const newPaymentMethod = {
          method: "card",
          cardNumber: cardNumber,
          mmyy: expiryDate,
          cvv: cvv,
          name: cardholderName,
          country: countryOrRegion,
          isActive: paymentMethods.length > 0 ? false : true,
        };
        setPaymentMethods((prevPaymentMethods) => [
          ...prevPaymentMethods,
          newPaymentMethod,
        ]);
        setAddPaymentMethodForm(false);
      } else {
        await registerUser();
      }
    }
  };

  const [errorMessage, setErrorMessage] = useState(null);

  // Function to show the error message
  const showError = (message) => {
    setErrorMessage(message);
  };

  // Function to hide the error message
  const hideError = () => {
    setErrorMessage(null);
  };

  const registerUser = async () => {
    showLoading(true);
    const billingInformation = {
      fullName: profileData.name,
      phoneNumber: profileData.phone,
      address: profileData.address,
      postCode: profileData.postcode,
      country: profileData.country,
      pronounced: pronounced,
      prefix_number: profileData.prefix_number,
      country_code: profileData.country_code,
    };

    const resp = await dispatch(customerRegister({
      name: profileData.name,
      email: profileData.email,
      password: profileData.password,
      billingInformation: billingInformation,
      paymentMethod: paymentMethods,
      googleSignUpToken: profileData.accessTokenGoogle,
      avatar: profileData.avatar,
      lang: lang,
    }));
    
    closeLoading(true);

    if (resp && resp.data && resp.data.code === 201) {
      if (resp.data.withGoogle) {
        history.push({
          pathname: "/",
        });
      } else {
        setRegisterSuccess(true);
      }
    } else {
      showError(resp.data.msg);
    }
  };

  // Function to handle country list clicked
  const handleLiClickCountryList = (dialCode, countryCode, country) => {
    setPrefixPhoneNumber(dialCode);
    setCountryCode(countryCode);
    setProfileData({
      ...profileData,
      ["country"]: country,
      ["prefix_number"]: dialCode,
      ["country_code"]: countryCode,
    });
  };

  const loadCountriesData = () => {
    fetch("/json/countries.json") // Replace with the actual path to your JSON file
      .then((response) => response.json())
      .then((data) => setCountriesData(data))
      .catch((error) => console.error("Error loading JSON data:", error));
  };

  // Function to handle pronounced list clicked
  const handleLiClickPronouncedList = (pronounced) => {
    setPronounced(pronounced);
  };

  function getCardType(cardNumber) {
    const cleanedCardNumber = cardNumber.replace(/\D/g, "");

    if (/^4\d{15}$/.test(cleanedCardNumber)) {
      return visa_icon;
    } else if (/^5\d{15}$/.test(cleanedCardNumber)) {
      return master_card_icon;
    } else if (/^3\d{14}$/.test(cleanedCardNumber)) {
      return american_ex_icon;
    } else if (/^35\d{14}$/.test(cleanedCardNumber)) {
      return jcb_icon;
    }

    return "Unknown"; // If none of the patterns match
  }

  function convertToMMYYYY(input) {}

  const handlePaymentMethodActivation = (index) => {
    const updatedPaymentMethods = paymentMethods.map((method, i) => ({
      ...method,
      isActive: i === index ? true : false,
    }));
    // Assuming you're using useState to manage paymentMethods
    // Update the state with the modified array
    setPaymentMethods(updatedPaymentMethods);
  };
  useEffect(() => {
    // if(agreeToTerms && profileData.email != "" && validateEmail(profileData.email) && profileData.password != "" && profileData.password_confirmation != "" && profileData.password == profileData.confirmPassword &&  ){
    if (validateProfileForm()) {
      setButtonSubmit(true);
    } else {
      setButtonSubmit(false);
    }
  }, [agreeToTerms, profileData]);
  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container  mt-1 pt-1">
          <div className="custom-mx">
            {!registerSuccess ? (
              <div className="container pb-5 custom-mt-9 col-lg-12">
                {errorMessage && (
                  <div className="alert alert-danger " role="alert">
                    <div className="d-flex justify-content-between">
                      {errorMessage}
                      <span onClick={hideError}>&times;</span>
                    </div>
                  </div>
                )}
                {/* BACK BUTTON - START */}
                {step === 2 ? (
                  <div
                    className=" d-flex-auto row align-items-center mb-3"
                    onClick={() => {
                      if (paymentMethods.length > 0) {
                        setAddPaymentMethodForm(false);
                      }
                      setStep(1);
                    }}
                  >
                    <div className="col-auto back-to-vehicle-selection-button">
                      <img src={back_icon} className="" alt="Clock icon" />
                    </div>
                    <div className="col">
                      <p className="fw-100 text-start text-dark noto d-inline back-to-vehicle-selection-button">
                        {t("registration.back")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* BACK BUTTON - END */}
                {/* TITLE - START */}
                <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                    <h3>{t("registration.create_an_account")}</h3>
                  </div>
                  <div className="col-md-6 d-none d-lg-block">
                    <h5 className="text-end">
                      {step == 2
                        ? t("registration.step_2")
                        : t("registration.step_1")}
                    </h5>
                  </div>
                </div>
                <div className="d-block d-lg-none">
                  <hr />
                </div>
                {/* TITLE - END */}
                {/* CARD - START */}
                {step === 1 ? (
                  <div className="bg-white card-shadow-page mt-3 rounded-3">
                    <div className="card-body-page">
                      <h5 className="my-3">
                        {t("registration.account_registration")}
                        <span className="text-danger">*</span>
                      </h5>
                      <div className="row g-3 mb-3">
                        <div className="col">
                          <div className="form-floating">
                          <input
                            type="text"
                            className={`form-control contact-form ${
                              errorsProfileData.email && "invalid"
                            }`}
                            id="emailAddress"
                            placeholder="Email address"
                            name="email"
                            value={profileData.email}
                            onChange={handleChangeProfile}
                            disabled={
                              formData.sign_up_with_google ? true : false
                            }
                          />
                          <label htmlFor="emailAddress">
                            <div className="d-flex align-items-center ">
                              <svg
                                width="17"
                                height="14"
                                viewBox="0 0 17 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.3 0.5H1.7C0.765 0.5 0.00849999 1.23125 0.00849999 2.125L0 11.875C0 12.7688 0.765 13.5 1.7 13.5H15.3C16.235 13.5 17 12.7688 17 11.875V2.125C17 1.23125 16.235 0.5 15.3 0.5ZM15.3 11.875H1.7V3.75L8.5 7.8125L15.3 3.75V11.875ZM8.5 6.1875L1.7 2.125H15.3L8.5 6.1875Z"
                                  fill="#DBBE78"
                                />
                              </svg>
                              &nbsp; {t("registration.email_address")}
                            </div>
                          </label>
                          {errorsProfileData.email && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.email}
                            </p>
                          )}
                          </div>
                         
                        </div>
                      </div>
                      <div
                        className={`row mb-3 ${
                          formData.sign_up_with_google ? "d-none" : "d-block"
                        }`}
                      >
                        <div className="col ">
                          <div className="form-floating">
                          <input
                            type="password"
                            className={`form-control contact-form ${
                              errorsProfileData.password && "invalid"
                            } ${
                              formData.sign_up_with_google
                                ? "d-none"
                                : "d-block"
                            }`}
                            id="password7"
                            placeholder="Password"
                            name="password"
                            value={profileData.password}
                            onChange={handleChangeProfile}
                          />
                          <label htmlFor="password7">
                            <div className="d-flex align-items-center">
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                                  fill="#DBBE78"
                                />
                              </svg>
                              &nbsp; {t("registration.password")}
                            </div>
                          </label>
                          {errorsProfileData.password && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.password}
                            </p>
                          )}
                          </div>
                          
                        </div>
                      </div>
                      <div
                        className={`row mb-3 ${
                          formData.sign_up_with_google ? "d-none" : "d-block"
                        }`}
                      >
                        <div className="col">
                          <div className="form-floating">
                          <input
                            type="password"
                            className={`form-control contact-form ${
                              errorsProfileData.confirmPassword && "invalid"
                            }`}
                            id="confirm-password"
                            placeholder="Password"
                            name="confirmPassword"
                            value={profileData.confirmPassword}
                            onChange={handleChangeProfile}
                          />
                          <label htmlFor="confirm-password">
                            <div className="d-flex align-items-center ">
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                                  fill="#DBBE78"
                                />
                              </svg>
                              &nbsp; {t("registration.password_confirm")}
                            </div>
                          </label>

                          {errorsProfileData.confirmPassword && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.confirmPassword}
                            </p>
                          )}</div>
                        </div>
                      </div>
                      {/* BILLING INFORMATION - START */}
                      <h5 className="my-4">
                        {t("registration.billing_information")}
                        <span className="text-danger">*</span>
                      </h5>
                      <div className="row g-3 mb-3">
                        <div className="col-md-6">
                          <div className="input-group" style={{height : "58px;"}}>
                            <button
                              className="btn bg-gold-light-100 fw-100 rounded dropdown-toggle p"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {pronounced}
                            </button>
                            <ul className="dropdown-menu">
                              <li
                                className="dropdown-item "
                                onClick={() =>
                                  handleLiClickPronouncedList("Mr.")
                                }
                              >
                                Mr.
                              </li>
                              <li
                                className="dropdown-item "
                                onClick={() =>
                                  handleLiClickPronouncedList("Ms.")
                                }
                              >
                                Ms.
                              </li>
                              <li
                                className="dropdown-item "
                                onClick={() =>
                                  handleLiClickPronouncedList("Mrs.")
                                }
                              >
                                Mrs.
                              </li>
                            </ul>

                            <input
                              type="text"
                              name="name"
                              className={`form-control contact-form p-3  ${
                                errorsProfileData.name && "invalid"
                              } `}
                              placeholder={t("registration.full_name")}
                              aria-label={t("registration.full_name")}
                              value={profileData.name}
                              onChange={handleChangeProfile}
                            />
                          </div>

                          {errorsProfileData.name && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.name}
                            </p>
                          )}
                        </div>

                        <div className="col-md-6">
                          <div className="input-group" style={{height : "58px;"}}>
                            <button
                              className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={`https://flagsapi.com/${countryCode}/flat/24.png`}
                              />
                              ({prefixPhoneNumber})
                            </button>
                            <ul className="dropdown-menu">
                              <div className="px-1 align-items-center mb-3 dropdown-search">
                                <input
                                  type="text"
                                  className={`form-control p-3 mb-2`}
                                  defaultValue={searchPhoneCode}
                                  onChange={(e) =>
                                    setSearchPhoneCode(e.target.value)
                                  }
                                />
                              </div>

                              {countriesData
                                ?.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(
                                      searchPhoneCode !== null
                                        ? searchPhoneCode.toLowerCase()
                                        : null
                                    )
                                )
                                ?.map((country, index) => (
                                  <Fragment key={country.code}>
                                    <li
                                      className="dropdown-item m-0"
                                      onClick={() =>
                                        handleLiClickCountryList(
                                          country.dial_code,
                                          country.code,
                                          country.name
                                        )
                                      }
                                    >
                                      <div className="row px-1 align-items-center">
                                        <div className="col-auto">
                                          <div className="bg-secondary bg-opacity-25 px-1">
                                            <img
                                              src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                              alt={country.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          ({country.dial_code}) {country.name}
                                        </div>
                                      </div>
                                    </li>
                                    <hr className="my-1" />
                                  </Fragment>
                                ))}
                            </ul>
                            <input
                              type="number"
                              className={`form-control contact-form p-3  ${
                                errorsProfileData.phone && "invalid"
                              } `}
                              id="phoneNumber"
                              placeholder={t("registration.phone_number")}
                              name="phone"
                              value={profileData.phone}
                              onChange={handleChangeProfile}
                            />
                          </div>

                          {errorsProfileData.phone && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.phone}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row g-3 mb-3">
                        <div className="col">
                          <div className="form-floating">
                          <input
                            type="text"
                            className={`form-control contact-form  ${
                              errorsProfileData.address && "invalid"
                            } `}
                            id="streetAddress"
                            placeholder="Address"
                            name="address"
                            value={profileData.address}
                            onChange={handleChangeProfile}
                          />
                          <label htmlFor="streetAddress">
                            {t("registration.billing_address")}
                          </label>
                          {errorsProfileData.address && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.address}
                            </p>
                          )}
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 mb-3">
                        <div className="col col-lg-6">
                          <div className="form-floating">
                          <input
                            type="text"
                            className={`form-control contact-form ${
                              errorsProfileData.postcode && "invalid"
                            } `}
                            id="postCode"
                            placeholder="Post code"
                            name="postcode"
                            value={profileData.postcode}
                            onChange={handleChangeProfile}
                          />
                          <label htmlFor="postCode">
                            {t("registration.post_code")}
                          </label>
                          {errorsProfileData.postcode && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.postcode}
                            </p>
                          )}
                          </div>
                        </div>

                        <div className="col col-lg-6 ">
                          <div className="form-floating ">
                          <input
                            disabled = {true}
                            style={{background : "#FBFBFB", color: "#A7A7A7", borderBottom : "1px solid #EFE2C2"}}
                            type="text"
                            className={`form-control contact-form ${
                              errorsProfileData.country && "invalid"
                            } `}
                            id="country"
                            placeholder="Country"
                            name="country"
                            value={profileData.country}
                            onChange={handleChangeProfile}
                            data-tip
                            data-for="countryTooltip"
                          />
                          <ReactTooltip
                                      id="countryTooltip"
                                      backgroundColor="#F6EEDC"
                                      textColor="#4E4E4E"
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: t("registration.country_tooltip"),
                                        }}
                                      ></span>
                                    </ReactTooltip>
                          <label htmlFor="country">
                            {t("registration.country")}
                          </label>
                          {errorsProfileData.country && (
                            <p className="m-0 text-danger">
                              {errorsProfileData.country}
                            </p>
                          )}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <h5>{t("registration.term_and_condition")}</h5>
                        <div className="bg-gold-light-50 p-3 my-3">
                            <div
                              className=" overflow-auto"
                              style={{ height: "381px" }}
                              dangerouslySetInnerHTML={{
                                __html: `${t('termConditionContent')}`
                              }}
                            >

                            </div>
                          </div>
                        <div className="form-group">
                          <div className="row  row-cols-auto">
                            <div className="col">
                              <p className="noto">
                                <input
                                  type="checkbox"
                                  checked={agreeToTerms}
                                  onChange={handleAgreeToTermsChange}
                                  id="tnc"
                                />
                                &nbsp;
                                <label
                                  for="tnc"
                                  dangerouslySetInnerHTML={{
                                    __html: `${t("registration.read_tnc")}`,
                                  }}
                                ></label>
                                {errorsProfileData.agreeToTerms && (
                                  <p className="mt-1 text-danger">
                                    {errorsProfileData.agreeToTerms}
                                  </p>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* BILLING INFORMATION - END */}
                    </div>
                  </div>
                ) : (
                  <div className="bg-white card-shadow-page mt-3 rounded-3">
                    <div className="card-body-page">
                      <h5 className="mx-3">
                        {t("registration.payment_method")}
                      </h5>
                      {addPaymentMethodForm ? (
                        <div className="mx-3">
                          {/* CREDIT CARD FLYWIRE - START */}
                          <div className="bg-light py-3  d-flex justify-content-between px-3 rounded-3 border-bottom-gold">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="paymentMethod"
                                  value="card"
                                  id="credit-card-flywire"
                                  checked={paymentMethod === "card"}
                                  onChange={handlePaymentMethodChange}
                                />
                                <label
                                  className="form-check-label noto-sans"
                                  htmlFor="credit-card-flywire"
                                >
                                  {t("registration.credit_card")}
                                  {/* <small>(via Flywire)</small> */}
                                </label>
                              </div>
                            </div>
                            <div className="col-auto">
                              <img
                                src={payment_method}
                                className=""
                                alt="Clock icon"
                              />
                            </div>
                          </div>
                          {paymentMethod === "card" && (
                            <>
                              <div className={`card mt-2 border-gold`}>
                                <div className="card-body">
                                  <h5 className="my-3">
                                    {t("registration.card_information")}
                                  </h5>
                                  <div className="row">
                                    <div className="col-12 col-md-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="number"
                                          className={`form-control contact-form ${
                                            cardNumberError ? "invalid" : ""
                                          }`}
                                          id="cardNumber"
                                          value={cardNumber}
                                          onChange={handleCardNumberChange}
                                        />
                                        <label htmlFor="formId1">
                                          {t("registration.card_number")}
                                        </label>
                                        {cardNumberError && (
                                          <p className="text-danger">
                                            {cardNumberError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className={`form-control contact-form ${
                                            expiryDateError ? "invalid" : ""
                                          }`}
                                          id="expiryDate"
                                          value={expiryDate}
                                          onChange={handleExpiryDateChange}
                                          maxLength="5"
                                        />
                                        <label htmlFor="formId1">
                                          {t("registration.mm_yy")}
                                        </label>
                                        {expiryDateError && (
                                          <p className="text-danger">
                                            {expiryDateError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="number"
                                          className={`form-control contact-form ${
                                            cvvError ? "invalid" : ""
                                          }`}
                                          id="cvv"
                                          value={cvv}
                                          onChange={handleCVVChange}
                                        />
                                        <label htmlFor="formId1">
                                          {t("registration.cvv")}
                                        </label>

                                        {cvvError && (
                                          <p className="text-danger">
                                            {cvvError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className={`form-control contact-form ${
                                            cardholderNameError ? "invalid" : ""
                                          }`}
                                          id="cardholderName"
                                          value={cardholderName}
                                          onChange={handleCardholderNameChange}
                                        />
                                        <label htmlFor="formId1">
                                          {t("registration.card_holder")}
                                        </label>

                                        {cardholderNameError && (
                                          <p className="text-danger">
                                            {cardholderNameError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className={`form-control contact-form ${
                                            countryOrRegionError
                                              ? "invalid"
                                              : ""
                                          }`}
                                          id="countryOrRegion"
                                          value={countryOrRegion}
                                          onChange={handleCountryOrRegionChange}
                                        />
                                        <label htmlFor="formId1">
                                          {t("registration.country_region")}
                                        </label>
                                        {countryOrRegionError && (
                                          <p className="text-danger">
                                            {countryOrRegionError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center mt-3">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="btn btn-primary col-md-3 py-2"
                                  disabled={
                                    cardNumber != "" &&
                                    checkCreditCardNumber(cardNumber) &&
                                    expiryDate != "" &&
                                    cvv != "" &&
                                    cardholderName != "" &&
                                    countryOrRegion != ""
                                      ? false
                                      : true
                                  }
                                >
                                  {t("registration.add_credit_card")}
                                </button>
                              </div>
                            </>
                          )}
                          {/* CREDIT CARD FLYWIRE - END */}
                        </div>
                      ) : (
                        <>
                          <div className="container bg-white">
                            {paymentMethods.map((item, index) => (
                              <div
                                className="row bg-light my-3  py-3 px-3 justify-content-between payment-method-custom-border-bottom"
                                key={index + 1}
                              >
                                <div className="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="paymentMethod"
                                      checked={item.isActive}
                                      id={`paymentMethod${index}`}
                                      onChange={() =>
                                        handlePaymentMethodActivation(index)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`paymentMethod${index}`}
                                    >
                                      <img
                                        src={getCardType(item.cardNumber)}
                                        alt=""
                                        srcSet=""
                                        className="me-2"
                                      />
                                      <span>
                                        {"••••" +
                                          item.cardNumber.slice(-4) +
                                          ", Exp.: " +
                                          item.mmyy}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn-close p-2"
                                    aria-label="Close"
                                    onClick={() => deletePaymentMethod(index)}
                                  ></button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-3 d-grid text-center">
                              <button
                                name=""
                                id=""
                                className="btn bg-gold-light-100  rounded py-2 px-4"
                                href="#"
                                role="button"
                                onClick={() => {
                                  setCardNumber("");
                                  setExpiryDate("");
                                  setCVV("");
                                  setCardholderName("");
                                  setCountryOrRegion("");
                                  setAddPaymentMethodForm(true);
                                }}
                              >
                                {t("registration.add_payment_method")}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* CARD - END */}
                <div className="row mt-4 justify-content-center">
                  <div className="col-md-4 d-grid text-center">
                    {(step === 1 || !addPaymentMethodForm) && (
                      <button
                        name=""
                        id=""
                        className="btn btn-primary rounded py-2 px-4"
                        href="#"
                        role="button"
                        onClick={handleSubmit}
                        disabled={!buttonSubmit}
                      >
                        {step === 1
                          ? t("registration.proceed")
                          : t("registration.register")}
                        <i className="ps-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 5L20 12L13 19M4 12H20H4Z"
                              stroke="#4A4A4A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </i>
                      </button>
                    )}
                    {paymentMethods.length === 0 && step === 2 ? (
                      <a
                        href="#"
                        className="mt-3 text-dark text-center noto-sans"
                        onClick={async (e) => {
                          e.preventDefault();
                          await registerUser();
                        }}
                      >
                        {t("registration.skip")}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container pb-5 custom-mt-9 col-lg-12">
                <h2>{t("registration.thank_you")}</h2>
                <p className="mt-3">
                  {lang === "EN" ? (
                    <>
                      {t("registration.complete_registration")}
                      <a>
                        <b>{profileData.email}</b>
                      </a>
                    </>
                  ) : (
                    <>
                      {t("registration.complete_registration")}
                      <a>
                        <b>{profileData.email}</b>
                      </a>
                      {t("registration.complete_registration_pt_2")}
                    </>
                  )}
                </p>
                <div className="row mt-5 align-items-center">
                  <div className="col-auto">
                    <p className="m-0">{t("registration.not_receive")}</p>
                  </div>

                  <div className="col-auto mt-2">
                    <a
                      name=""
                      id=""
                      className="btn btn-secondary px-4 rounded "
                      href="#"
                      role="button"
                    >
                      {t("registration.resend_email")}
                    </a>
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  <div className="col-12 col-md-4">
                    <div className="d-grid">
                      <a
                        name=""
                        id=""
                        className="btn btn-primary rounded"
                        href="/"
                        role="button"
                      >
                        {t("registration.back_to_home")}
                        <i className="ps-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 5L20 12L13 19M4 12H20H4Z"
                              stroke="#4A4A4A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default CustomerRegisterPage;
