import { getUploadUrl } from "../actions/admin/awss3";
import { getFileName } from "../utils/Calculator";

export const getFileDetails = async (file) => {
  const fileName = getFileName(file);
  const signedUrls = await getUploadUrl(fileName);
  // console.log("signedUrls");
  // console.log(signedUrls);
  const filePath = signedUrls.split("?")[0];
  return { signedUrls, filePath };
};
