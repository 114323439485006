import { useTranslation } from "react-i18next";
import BookingItem from "./BookingItem";
import { useState, useEffect } from "react";
import { getBookings } from "../../../../actions/customerBooking";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../layout/Spinner";
import { Link } from "react-router-dom";
import ReceiptModal from "./ReceiptModal";
const UpcomingBookings = ({ userRole }) => {
  const { t } = useTranslation();
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelection = (cardId) => {
    // Check if the card is already in the selectedCards array
    const isCardSelected = selectedCards.includes(cardId);

    // If the card is selected, remove it from the array; otherwise, add it
    const updatedSelectedCards = isCardSelected
      ? selectedCards.filter((id) => id !== cardId)
      : [...selectedCards, cardId];

    setSelectedCards(updatedSelectedCards);
  };

  const customerBooking = useSelector((state) => state.customerBooking);
  const { loading, customerBookings } = customerBooking;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookings("upcoming", userRole == "individual" ? "paid" : "Charge Pending" ,  "date"));
  }, [dispatch]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>

      {userRole === "business" && (
        <div className=" d-none d-md-block">
          <div className="row justify-content-between">
            <div className="col-6">
              <div className="col-8 form-floating">
                <input
                  type="text"
                  className="form-control form-control-search"
                  placeholder=""
                />
                <label className="label-search" style={{paddingTop : "5rem !important" }}>
                  <div className="d-flex align-items-center">
                    <i className="pe-2 d-flex align-self-center">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.41706 7.49888H7.93349L7.76209 7.33361C8.36197 6.6358 8.72311 5.72987 8.72311 4.74437C8.72311 2.54688 6.94186 0.765625 4.74437 0.765625C2.54688 0.765625 0.765625 2.54688 0.765625 4.74437C0.765625 6.94186 2.54688 8.72311 4.74437 8.72311C5.72987 8.72311 6.6358 8.36197 7.33361 7.76209L7.49888 7.93349V8.41706L10.5595 11.4715L11.4715 10.5595L8.41706 7.49888ZM4.74437 7.49888C3.2202 7.49888 1.98985 6.26853 1.98985 4.74437C1.98985 3.2202 3.2202 1.98985 4.74437 1.98985C6.26853 1.98985 7.49888 3.2202 7.49888 4.74437C7.49888 6.26853 6.26853 7.49888 4.74437 7.49888Z"
                          fill="#7C7B7B"
                        />
                      </svg>
                    </i>
                    {t("Search")}
                  </div>
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <p className="m-0 text-muted">{t("Sort By")}</p>
                </div>
                <select className="form-select form-select-search col">
                  <option value="1">Pickup Date ・ Latest → Oldest</option>
                  <option value="1">Pickup Date ・ Oldest → Latest</option>
                </select>
                <div className="col-auto">
                  <div className="d-grid gap-2">
                    {/* <button
                      type="button"
                      name=""
                      id=""
                      className="btn btn-primary rounded px-4 py-2 "
                      disabled={selectedCards.length > 0 ? false : true}
                    >
                      <span className="text-white">
                        <svg
                          width="9"
                          height="14"
                          viewBox="0 0 9 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                            fill="white"
                          />
                        </svg>{" "}
                        &nbsp;
                        {t("Download Receipt")}
                      </span>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-auto pe-0">
              <button
                type="button"
                name=""
                id=""
                className="btn btn-primary rounded px-4 mx-0 "
                disabled={selectedCards.length > 0 ? false : true}
              >
                <span className="text-white">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="" />
                    <label class="form-check-label" for=""> 
                  {t("Select Booking(s)")} </label>
                  </div>
                </span>
              </button>
            </div>
            <div className="col-auto ">
              <button
                type="button"
                name=""
                id=""
                className="btn btn-primary rounded px-4 mx-0 py-2 "
                disabled={selectedCards.length > 0 ? false : true}
              >
                <span className="text-white">
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                      fill="white"
                    />
                  </svg>{" "}
                  &nbsp;
                  {t("Download Receipt")}
                </span>
              </button>
            </div>
          </div>
        </div>
      ) }

{loading ? (
        <div className="row text-center">
          <Spinner />
        </div>
      ) : customerBookings.length > 0 ? (
        <div className="my-3">
          {customerBookings.map((customerBooking, i = 0) => (
            <Link
              to={{
                pathname: `/booking-detail/${customerBooking._id}`,
                state: { type: "Completed" },
              }}
              className="text-decoration-none text-dark card-item"
              style={{}}
              onMouseEnter={(e) => (e.currentTarget.style.color = "")}
            >
              <BookingItem
                type={"Upcoming"}
                cardId={customerBooking.id}
                onSelect={handleCardSelection}
                isSelected={selectedCards.includes(1)}
                bookingDetail={customerBooking}
                handleOpenModal={handleOpenModal}
              />
            </Link>
          ))}

          {selectedItem && (
            <ReceiptModal
              data={selectedItem}
              handleClose={handleCloseModal}
              show={showModal}
            />
          )}
        </div>
      ) : (
        <div className="border d-flex justify-content-center align-items-center py-5 mt-3 mb-5 rounded">
          <p>{t("my_page.bookings_page.no_booking")}</p>
        </div>
      )}
    </>
  );
};

export default UpcomingBookings;
