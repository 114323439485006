import React, { useState, useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useDispatch } from "react-redux";
import {
  getBookingsByCartID,
  updateCart,
  removeFromCartReduxStore,
} from "../../actions/admin/booking";
import { checkStripeRefSession } from "../../actions/stripeAPI";
import NewItemCardPaymentSuccess from "../layout-new-theme/cards/NewItemCardPaymentSuccess";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLoading } from "./LoadingContext";
import { useTranslation } from "react-i18next";

const PaymentSuccess = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [message, setMessage] = useState(
    "PaymentSuccess.AwaitingConfirmationBooking"
  );
  const [successPayment, setSuccessPayment] = useState(false);
  const [booking, setBooking] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { showLoading, closeLoading } = useLoading();

  useEffect(() => {
    showLoading(true);
    updateBookingCartItems();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerClass = isMobile
    ? "container-fluid bg-white "
    : "container-fluid bg-light-grey";

  const updateBookingCartItems = async () => {
    const stripe_ref = match.params.stripe_ref;
    try {
      const stripe_validity_session = await checkStripeRefSession(stripe_ref);

      if(!stripe_validity_session){
        const bookings = await getBookingsByCartID(stripe_ref);

        setBooking(bookings);
  
        const paymentStatust = bookings[0].paymentStatus;

        if (localStorage.getItem("cart") !== null) {
          if (bookings[0].withCart) {
            localStorage.removeItem("cart");
            dispatch(removeFromCartReduxStore(null));
            localStorage.removeItem("dtacus");
          }
        }

        if (paymentStatust === "Charge Pending") {
          setMessage("PaymentSuccess.RecievedBooking");
          setSuccessPayment(true);
          console.log("Payment is alrady completed");
        } else {
          console.log("Your Payment Status ", paymentStatust);
        }

      }else{
        const arrCartId = stripe_validity_session.client_reference_id.split("-");

        if (arrCartId[0] === "CB") {
          const bookings = await getBookingsByCartID(arrCartId[1]);
  
          setBooking(bookings);
  
          const paymentStatust = bookings[0].paymentStatus;
  
          if (stripe_validity_session.status === "complete") {
            var customerInfo = bookings[0].customer;
  
            setMessage("PaymentSuccess.RecievedBooking");
            setSuccessPayment(true)
  
            if (bookings) {
              bookings.map((val) => {
                const items = [];
                let totalPrice = 0;
        
                val.bookingInfo.map((bi, ind) => {
                  const item = {
                    index: ind,
                    item_id: bi.vehicleTypeID._id,
                    item_name: bi.vehicleTypeName,
                    item_brand: "Toyota",
                    affiliation: "Stripe",
                    coupon: "",
                    discount: 0,
                    item_category: val.isAirportTransfer ? 'Airport Transfer' : val.byTheHourBook ? 'By The Hour' : 'Specified Destination',
                    item_category2: val.destinations.length === 1 ? val.destinations[0].place_id : val.destinations[val.destinations.length - 1].place_id,
                    item_category3: (bi.childSeat[0].childSeatCount + bi.childSeat[1].childSeatCount + bi.childSeat[2].childSeatCount) > 0 ? "Child Seats" : "",
                    location_id: "",
                    price: bi.total,
                    quantity: 1,
                  };
        
                  items.push(item);
                })
                
                totalPrice = val.totalPrice
        
                const ecommerceData = {
                  currency: "JPY",
                  transcaction_id: val._id, 
                  value: totalPrice,
                  items: items
                }
          
                const dataToPush = {
                  event: 'purchase',
                  ecommerce: ecommerceData
                };
        
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(dataToPush);
              })
            }
  
            // remove cart from local store
            if (localStorage.getItem("cart") !== null) {
              if (bookings[0].withCart) {
                localStorage.removeItem("cart");
                dispatch(removeFromCartReduxStore(null));
                localStorage.removeItem("dtacus");
              }
            }
          }
  
          if (paymentStatust === "paid") {
            setMessage("PaymentSuccess.RecievedBooking");
            setSuccessPayment(true);
            console.log("Payment is alrady completed");
          } else {
            console.log("Your Payment Status ", paymentStatust);
          }
        }
      }

      closeLoading(true);
    } catch (error) {
      closeLoading(true);
      console.error(error);
    }
  };

  return (
    <FrontLayout>
      <div className={containerClass}>
        <div className="custom-mx mt-3 pt-3 mt-sm-5 pt-sm-5">
          <div className="container pb-5 pb-md-0 pt-5 pt-md-0 custom-mt-6 col-lg-12">
            <div className="row pb-5 d-flex justify-content-lg-center">
              <div className="col-12 col-md-10 px-5 py-5 ">
                <div className="row mb-5">
                  {successPayment ? (
                    <div className="text-center mb-3">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="Vector"
                          d="M30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM24 45L9 30L13.23 25.77L24 36.51L46.77 13.74L51 18L24 45Z"
                          fill="#3CAEA3"
                        />
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                  <h1 className="fw-bold text-center text-dark my-3">
                    {t(message)}
                  </h1>
                  <p className="text-center mb-3">
                    We will confirm the availability of the service and inform
                    you within 24 hours. If the service is available, we will
                    provide you with detailed information about the fleet and
                    driver a day prior to your scheduled pickup. If,
                    regrettably, the service is not attainable, we will notify
                    you and proceed with processing a refund. 
                  </p>
                  <p className="text-center mb-3">
                  Kindly be aware that upon receipt of your booking request,
                    our
                    &nbsp;
                      <a href="#" className="text-dark">
                         cancellation policy 
                      </a>
                      &nbsp; 
                    will be implemented should you wish to cancel the
                    reservation.
                  </p>

                  {/* DESKTOP CARD */}
                  {booking.map((dataItem, index) => (
                    <div className="mb-3">
                      <NewItemCardPaymentSuccess cart={dataItem} key={index} />
                    </div>
                  ))}
                  {/* DESKTOP CARD */}

                  <p className="text-center mb-3 ">
                    {t("PaymentSuccess.InquiryMessage")}
                    <br />
                    {`${t(
                      "PaymentSuccess.InquiryMessage.Link"
                    )} info@theluxuryfleet.com`}
                    <br />

                    {`${t(
                      "PaymentSuccess.InquiryMessage.PhoneNumber"
                    )} 03 6822 8772`}
                  </p>
                  <div className=" d-grid mt-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm text-dark "
                      onClick={() => history.push("/")}
                    >
                      <div className="d-flex justify-content-center py-2 align-items-center d-inline">
                        <div>{t("PaymentSuccess.BackToHomeButton")}</div>
                        <i className="ps-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 5L20 12L13 19M4 12H20H4Z"
                              stroke="#4A4A4A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </i>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default PaymentSuccess;
