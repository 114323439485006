import React from "react";
import { Document, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ReceiptDocument from "../invoiceAndBilling/documents/ReceiptDocument"

const ReceiptModal = ({ data, handleClose, show }) => {
  return (
    <>
      {show && <div className="modal-backdrop show"></div>}
      <div
        className={show ? "modal fade show" : "modal fade"}
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-pdf">
              <h5 className="modal-pdf-title">Receipt</h5>
              <button
              onClick={handleClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-body-pdf mt-5">
              <PDFViewer style={{ width: "100%", height: "480px" }}>
                <Document>
                  <ReceiptDocument
                    bookingId={data.key}
                    total={data.totalPrice}
                    date={data.date}
                  />
                </Document>
              </PDFViewer>
            </div>

            <div className="modal-footer modal-footer-pdf">
              <PDFDownloadLink
                document={
                  <Document>
                    <ReceiptDocument
                      bookingId={data.key}
                      total={data.totalPrice}
                      date={data.date}
                    />
                  </Document>
                }
                fileName={"invoice"}
              >
                <button
                  type="button"
                  className="btn btn-primary rounded"
                  style={{ width: "210px", height: "42px" }}
                  data-bs-dismiss="modal"
                >
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp; <span className="white-text">Download</span>
                </button>
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptModal;
