import React, { useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useEffect } from "react";
import PageNotFound from "../404page/PageNotFound";
import { resetPassword } from "../../actions/auth";
import { useLoading } from "./LoadingContext";

const ResetPassword = ({ match }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(true);
  const [successResetPassword, setSuccessResetPassword] = useState(false);
  const { showLoading, closeLoading } = useLoading();

  const [pageExpired, setPageExpired] = useState(false);

  const token = match.params.token;

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
    setIsValidEmail(isValid);
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    // Validate password length (at least 8 characters)
    setIsValidPassword(passwordValue.length >= 8);
    // Check if password confirmation matches password
    setIsPasswordConfirmed(passwordValue === passwordConfirmation);
  };

  const handlePasswordConfirmationChange = (event) => {
    const confirmationValue = event.target.value;
    setPasswordConfirmation(confirmationValue);
    // Check if password confirmation matches password
    setIsPasswordConfirmed(password === confirmationValue);
  };

  const handleUpdatePassword = async () => {
    showLoading(true);
    
    // Validation check
    if (isValidEmail && isValidPassword && isPasswordConfirmed) {
      const resetPasswordResponse = await resetPassword(email, token, password, passwordConfirmation);
      if(resetPasswordResponse.code == "201" || resetPasswordResponse.code == "200"){
        setSuccessResetPassword(true);
      }
    } else {

    }
    closeLoading(true);
  };

  useEffect(() => {
    var jwt = JSON.parse(atob(token.split(".")[1]));
    var user = jwt.user;
    if(user){
      setEmail(user.email);
    }
    if (jwt.exp * 1000 < Date.now()) {
      setPageExpired(true);
    }
  }, []);

  return pageExpired ? (
    <PageNotFound />
  ) : (
    <FrontLayout>
      <div className="custom-mx mt-3 pt-3 mt-sm-5 pt-sm-5">
        <div className="container pb-5 pb-md-0 pt-5 pt-md-0 custom-mt-6 col-lg-12">
          <div className="row pb-5 d-flex justify-content-lg-center">
            <div className="col-12 col-md-10 px-5 py-5 ">
              <div className="row mb-5">
              {successResetPassword ? 
                    <div className="container">
                      <h2>Password Successfully Reset</h2>
                      <p className="mt-3">Your password has been successfully reset. Please log in using your latest password. Thank you.</p>
                     
                    </div> : <div className="col-md-6 offset-md-3">
                  
                   <div className="card">
                    <div className="card-body">
                      <h5 className="mb-3">Reset Password</h5>
                      <div
                        className={`form-floating mb-3 ${
                          isValidEmail ? "" : "has-danger"
                        }`}
                      >
                        <input
                          type="email"
                          className={`form-control ${
                            isValidEmail ? "" : "invalid"
                          }`}
                          id="emailInput"
                          placeholder="name@example.com"
                          value={email}
                          onChange={handleEmailChange}
                          required
                          disabled
                        />
                        <label htmlFor="emailInput">Email address</label>
                        {isValidEmail ? (
                          ""
                        ) : (
                          <p className="text-danger">
                            Please enter a valid email address
                          </p>
                        )}
                      </div>
                      <div
                        className={`form-floating mb-3 ${
                          isValidPassword ? "" : "has-danger"
                        }`}
                      >
                        <input
                          type="password"
                          className={`form-control ${
                            isValidPassword ? "" : "invalid"
                          }`}
                          id="passwordInput"
                          placeholder="New Password"
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <label htmlFor="passwordInput">New Password</label>
                        {isValidPassword ? (
                          ""
                        ) : (
                          <p className="text-danger">
                            Password must be at least 8 characters long
                          </p>
                        )}
                      </div>
                      <div
                        className={`form-floating mb-3 ${
                          isPasswordConfirmed ? "" : "has-danger"
                        }`}
                      >
                        <input
                          type="password"
                          className={`form-control ${
                            isPasswordConfirmed ? "" : "invalid"
                          }`}
                          id="passwordConfirmationInput"
                          placeholder="Password Confirmation"
                          value={passwordConfirmation}
                          onChange={handlePasswordConfirmationChange}
                          required
                        />
                        <label htmlFor="passwordConfirmationInput">
                          Password Confirmation
                        </label>
                        {isPasswordConfirmed ? (
                          ""
                        ) : (
                          <p className="text-danger">
                            Password confirmation does not match
                          </p>
                        )}
                      </div>
                      <div className="d-grid">
                        <button
                          className={`btn btn-primary py-2 ${
                            isValidEmail &&
                            isValidPassword &&
                            isPasswordConfirmed
                              ? ""
                              : "disabled"
                          }`}
                          onClick={handleUpdatePassword}
                          disabled={
                            !isValidEmail ||
                            !isValidPassword ||
                            !isPasswordConfirmed
                          }
                        >
                          Update Password
                        </button>
                      </div>
                    </div>
                  </div> 
                </div> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default ResetPassword;
