import React, { useState, useEffect } from "react";
import GoogleAutoComplete from "../google-input/GoogleAutoComplete";
import CustomGoogleAutoComplete from "../google-input/CustomGoogleAutoComplete";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateRandom } from "../../../utils/Calculator";

const MultipleDropOff = ({
  setDestinations,
  dropofflocations,
  isNotValidFirstLocation,
  changeValidation,
  isUseAddDestination,
}) => {
  const search = useLocation();
  const { t } = useTranslation();
  const [inputFields, setInputFields] = useState([{}]);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setInputFields(dropofflocations);
  }, [dropofflocations]);

  const addInputField = () => {
    const hasData = inputFields[inputFields.length - 1]?.label;
    if (inputFields.length === 0) {
      setInputFields([...inputFields, {}]);
      changeValidation(true);
    } else if (hasData !== undefined) {
      setInputFields([...inputFields, {}]);
      changeValidation(true);
    } else {
      changeValidation(false);
    }
  };

  const removeInputFields = (index, value) => {
    const newValues = inputFields.filter((i) => i.value !== value);
    setInputFields(newValues);
    setDestinations(newValues);
  };

  const handleEmpty = () => {
    console.log("HANDLE EMPTY");
  };

  const locationSelected = (index, place) => {
    if (place === undefined) return;
    var newPLace = { ...place };
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    newPLace.lat = place.geometry.location.lat;
    newPLace.lon = place.geometry.location.lng;
    newPLace.label = place.name + ", " + place.formatted_address;
    newPLace.formatted_address = place.name + ", " + place.formatted_address;
    newPLace.value = generateRandom(1000, 200000);
    newPLace.city = city;
    newPLace.place_id = place.place_id;
    newPLace.types = place.types;

    let list = inputFields.slice();

    list.splice(index, 1, newPLace);

    setInputFields(list);
    setDestinations(list);

    changeValidation(true);
  };

  return (
    <div>
      {inputFields.map((location, index) => {
        let error = false;
        return (
          <div className="row  " key={"loc" + (Math.random() + 1).toString(36).substring(7)}>
            <div
              className={`col  ml-2 ${inputFields.length !== 1 ? `input-box` : ``}`}
            >
              <div className={`form-floating position-relative ${inputFields.length !== 1 ? `mb-3` : ``}`}>

                <CustomGoogleAutoComplete
                  id={"multi goog" + (Math.random() + 1).toString(36).substring(7)}
                  placeholder="Drop-off address"


                  onPlaceSelected={(location) => {
                    locationSelected(index, location)
                  }}
                  defaultValue={location?.label === undefined ? null : location?.label}
                  invalidText={"SpecifiedDestination.Pickup.Specific.Validation"}
                  isInvalid={errors[index]}
                />
                {inputFields.length !== 1 ? <i className="fa fa-close icon" onClick={() => removeInputFields(index, location.value)}></i> : ``}

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.06 17C8.31857 16.5509 7.68539 15.969 7.06732 15.3717C5.78586 14.1332 4.69952 12.7486 3.92913 11.1349C3.4659 10.1652 3.1449 9.15128 3.04084 8.08144C2.78656 5.46756 3.71556 3.38715 5.94154 1.93996C7.09837 1.18777 8.3941 0.899756 9.77248 1.03041C12.5616 1.29463 14.8035 3.31076 15.3292 6.02858C15.5424 7.13098 15.4434 8.22503 15.169 9.30573C14.7825 10.8268 14.0444 12.1763 13.102 13.4206C12.1197 14.7176 10.9713 15.8508 9.66339 16.8234C9.57317 16.8906 9.47079 16.9416 9.37428 17H9.06ZM14.2177 7.24535C14.1863 6.94606 14.1716 6.64343 14.1208 6.34748C13.6286 3.48858 10.793 1.6628 7.9766 2.38994C5.65831 2.9881 3.99207 5.13989 4.25978 7.8072C4.37139 8.91878 4.71547 9.95899 5.23871 10.9412C6.19959 12.7444 7.55196 14.2204 9.12294 15.5123C9.1926 15.5695 9.24043 15.5708 9.3105 15.5136C10.7997 14.2914 12.0929 12.8989 13.0449 11.2167C13.7419 9.9857 14.1833 8.67668 14.2173 7.24535H14.2177Z"
                        fill="#DBBE78"
                      />
                      <path
                        d="M9.22054 3.84454C11.1327 3.84663 12.699 5.40444 12.6957 7.3016C12.6923 9.20169 11.1264 10.7532 9.21341 10.752C7.30213 10.7507 5.73535 9.19167 5.73829 7.29451C5.74164 5.39484 7.30801 3.84288 9.22054 3.84454ZM9.22054 9.51893C10.4697 9.51434 11.4516 8.52714 11.4486 7.27823C11.4457 6.0577 10.4462 5.07384 9.21299 5.0776C7.95713 5.08136 6.9803 6.07064 6.98534 7.33333C6.99037 8.54008 7.99573 9.52352 9.22096 9.51893H9.22054Z"
                        fill="#DBBE78"
                      />
                    </svg>
                  </div>
                </span>
                <label htmlFor="dropOffAddress" className="label-icon">
                  {t("Drop.Off.Address")}
                </label>
              </div>
            </div>
            {index === inputFields.length - 1 && (
              <div className="col-lg-auto col-sm-12 col-md-12 d-grid d-md-block ps-md-0 mt-3 mt-md-0">
                <button
                  disabled={!isUseAddDestination}
                  type="button"
                  className="btn btn-add-destination btn-lg rounded-3 d-flex justify-content-center w-full px-3 py-3"
                  onClick={addInputField}
                >
                  <div className="d-flex align-items-center px-2 d-inline">
                    <div className="pe-2 d-inline">
                      <i className="bi bi-plus"></i>
                    </div>
                    {t("Add.Destination")}
                  </div>
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleDropOff;
