const Receipts = () => {
  return (
    <>
      <div className="mx-md-3 mt-2">
        {/* DESKTOP VIEW */}
        <div className="table-responsive d-none d-md-block mt-4">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Receipt ID</th>
                <th scope="col">Title</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-table-myPage">2023/07/31</td>
                <td className="td-table-myPage">24F3BF790</td>
                <td className="td-table-myPage">
                  Receipt for TLF June Invoice
                </td>
                <td className="td-table-myPage">
                  <div className="row justify-content-center">
                    <div className="col-5 d-grid">
                      <button
                        type="button"
                        className="btn bg-gold-light-100 rounded"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1480_14875)">
                            <path
                              d="M8.68664 3.25781C5.06709 3.25781 1.976 5.50917 0.723633 8.68713C1.976 11.8651 5.06709 14.1165 8.68664 14.1165C12.3062 14.1165 15.3973 11.8651 16.6496 8.68713C15.3973 5.50917 12.3062 3.25781 8.68664 3.25781ZM8.68664 12.3067C6.68865 12.3067 5.06709 10.6851 5.06709 8.68713C5.06709 6.68914 6.68865 5.06759 8.68664 5.06759C10.6846 5.06759 12.3062 6.68914 12.3062 8.68713C12.3062 10.6851 10.6846 12.3067 8.68664 12.3067ZM8.68664 6.51541C7.48495 6.51541 6.51491 7.48544 6.51491 8.68713C6.51491 9.88882 7.48495 10.8589 8.68664 10.8589C9.88833 10.8589 10.8584 9.88882 10.8584 8.68713C10.8584 7.48544 9.88833 6.51541 8.68664 6.51541Z"
                              fill="#DBBE78"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1480_14875">
                              <rect
                                width="17.3738"
                                height="17.3738"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        &nbsp; View
                      </button>
                    </div>
                    <div className="col-7 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary rounded "
                      >
                        <svg
                          width="9"
                          height="14"
                          viewBox="0 0 9 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                            fill="white"
                          />
                        </svg>
                        &nbsp; <span className="white-text">Download</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* MOBILE VIEW */}
        <div className="card mb-3 d-block d-md-none">
          <div className="card-body">
            <div className="row justify-content-between align-items-center mb-3">
              <div className="col fs-14">2023/07/31</div>
            </div>
            <p className="fs-16 noto">TLF July Invoice</p>
            <p>
              <b>Receipt ID : </b>24F3BF790
            </p>
            <div className="row p-0">
              <div className="col-auto">
                <button type="button" className="btn bg-gold-light-100 rounded">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1480_14875)">
                      <path
                        d="M8.68664 3.25781C5.06709 3.25781 1.976 5.50917 0.723633 8.68713C1.976 11.8651 5.06709 14.1165 8.68664 14.1165C12.3062 14.1165 15.3973 11.8651 16.6496 8.68713C15.3973 5.50917 12.3062 3.25781 8.68664 3.25781ZM8.68664 12.3067C6.68865 12.3067 5.06709 10.6851 5.06709 8.68713C5.06709 6.68914 6.68865 5.06759 8.68664 5.06759C10.6846 5.06759 12.3062 6.68914 12.3062 8.68713C12.3062 10.6851 10.6846 12.3067 8.68664 12.3067ZM8.68664 6.51541C7.48495 6.51541 6.51491 7.48544 6.51491 8.68713C6.51491 9.88882 7.48495 10.8589 8.68664 10.8589C9.88833 10.8589 10.8584 9.88882 10.8584 8.68713C10.8584 7.48544 9.88833 6.51541 8.68664 6.51541Z"
                        fill="#DBBE78"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1480_14875">
                        <rect width="17.3738" height="17.3738" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  &nbsp; View
                </button>
              </div>
              <div className="col-auto p-0">
                <button type="button" className="btn btn-primary rounded ">
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.58824H6V2.29412V0H3V2.29412V4.58824H0L4.5 9.94118L9 4.58824ZM0 11.4706V13L9 13.0294V12.2647V11.5L0 11.4706Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp; <span className="white-text">Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Receipts;
