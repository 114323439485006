import React, { useState, useEffect, Fragment } from "react";
// import { Link } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import {
  getBooking,
  updateBookingStatus,
  getAvailableVehicles,
  updateDriver,
  changeVehicle,
  addNote,
  getCart,
} from "../../../actions/admin/booking";
import Alert from "../../layout/Alert";
import { setAlert } from "../../../actions/alert";
import Select from "react-select";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "../../../utils/customMoment";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { bookingStatusOptions } from "../../../utils/dataLists";
import { getAllDrivers } from "../../../actions/admin/driver";
import {
  getTemplate,
  getTemplates,
  sendMail,
} from "../../../actions/admin/mail";
import { getSetting } from "../../../actions/admin/settings";
import { getBookingCancelEmailTemplate } from "../../../utils/EmailTemplate";
import { useLoading } from "../../../components/front-pages-new-theme/LoadingContext";

// var cancelBookingTemplate = require("../../../email-template/cancel-booking.html");

const ManageBooking = ({ match }) => {
  const [activeVehicles, setActiveVehicles] = useState([]);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [ddltemplate, setDDlTemplates] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [driverSelectedOption, setDriverSelectedOption] = useState([]);
  const [ddltemplateOption, setddlTemplateOption] = useState(null);
  const { showLoading, closeLoading } = useLoading();

  const dispatch = useDispatch();
  const template_state = useSelector((state) => state.template);
  const { templates } = template_state;
  const bookingState = useSelector((state) => state.booking);
  // const settings = useSelector((state) => state.setting);
  // const { setting } = settings;
  const { loading, booking, adminCart } = bookingState;
  // const vehicle = useSelector((state) => state.vehicle);
  // const { vehicles } = vehicle;
  const [style, setStyle] = useState("");
  const [note, setNote] = useState("");
  const [bookingStatus, setBookingStatus] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [subject, setSubject] = useState("");
  const [updatedHtmlstate, setUpdatedHtmlstate] = useState(null);
  const [totalPax, setTotalPax] = useState(0);

  const [labelData, setLabelData] = useState({
    _id: null,
    byTheHourBook: false,
    isAirportTransfer: false,
    isAbandoned: false,
    cartID: "",
    stripeRef: "",
    paymentStatus: "",
    name: "",
    email: "",
    phone: "",
    pickuptime: "",
    travelDate: null,
    picuplocation: null,
    lat: "",
    lon: "",
    userAddedDropoffDatetime: null,
    dropoffDatetime: null,
    dropofftime: "",
    destinations: [],
    vehiclename: "",
    vehicletype: "",
    registration: "",
    // newly added
    totalTime: 0,
    baseprice: 0,
    hourlyprice: 0,
    tollFare: 0,
    isTimeRange: null,
    total: 0,
    vehicleTypeName: null,
    vehicleTypeID: null,
    tripStartTime: null,
    garageArivalTime: null,
    garageArivalTimeWithAdditionalTime: null,
    cutomerActualDropOffTime: null,
    cutomerDropOffTimeWithAdditinalTime: null,
    zoneHours: 0,
    totalhours: 0,
    // ----------
    estimate: 0,
    totalDistance: 0,
    bookingStatus: "",
    drivername: null,
    driveremail: "",
    driverphone: "",
    pax: null,
    message: null,
    luggage: null,
    package: "",
    rate: null,
    flightNo: "",
    isFromAirport: null,
    bookingInfo: [{
      "isTimeRange": null,
      "_id": "",
      "vehicleTypeName": "Toyota Alphard",
      "vehicleTypeID": "61a06a9a57475442649e582d",
      "travelArea": "",
      "travelAreaPrice": 0,
      "pax": [
        {
          "_id": "655f1125517037718c4904c4",
          "paxtype": "Adult",
          "paxcount": 5
        },
        {
          "_id": "655f1125517037718c4904c5",
          "paxtype": "Child",
          "paxcount": 0
        },
        {
          "_id": "655f1125517037718c4904c6",
          "paxtype": "Toddler",
          "paxcount": 0
        },
        {
          "_id": "655f1125517037718c4904c7",
          "paxtype": "Baby",
          "paxcount": 0
        }
      ],
      "luggage": [
        {
          "_id": "655f1125517037718c4904c8",
          "luggagetype": "CHECK-IN SIZE",
          "luggagecount": 0
        },
        {
          "_id": "655f1125517037718c4904c9",
          "luggagetype": "CABIN SIZE",
          "luggagecount": 1
        }
      ],
      "totalTime": 0,
      "baseprice": 0,
      "hourlyprice": 0,
      "tollFare": 0,
      "total": 0,
      "tripStartTime": "",
      "garageArivalTime": "",
      "garageArivalTimeWithAdditionalTime": "",
      "cutomerActualDropOffTime": "",
      "cutomerDropOffTimeWithAdditinalTime": "",
      "zoneHours": 0,
      "totalhours": 0,
      "vehicle": {
        "isactive": true,
        "_id": "",
        "vehiclename": "",
        "vehicletype": "",
        "registration": "",
        "date": "",
        "__v": 0
      },
      "driver": {
        "isactive": true,
        "_id": "",
        "drivername": null,
        "driveremail": "",
        "driverphone": "",
        "__v": 0
      },
      "infantSizeSeat": 0,
      "toddlerSizeSeat": 0,
      "childSizeSeat": 0,
      "childSeatPrice": 0,
      "meetAssistPrice": 0,
      "totalhoursUsage": 0,
      "meetAssist": [
        {
          meetAssistName: "none",
          meetAssistType: "arrival",
          meetAssistPrice: 0
        },
        {
          meetAssistName: "none",
          meetAssistType: "departure",
          meetAssistPrice: 0
        }
      ],
      "travelArea": "undecided",
      "travelAreaPrice": 0
    }]
  });

  useEffect(() => {
    dispatch(getSetting());
    fetchActiveVehicles(activeVehicleCallBackFunc);
  }, []);

  useEffect(() => {
    fetchActiveDrivers(activeDriversCallBackFunc);
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    if (!templates) return;
    const data = templates.map((row) => {
      return {
        value: row._id,
        label: row.templatename,
        subject: row.subject,
      };
    });
    setDDlTemplates(data);
  }, [templates]);

  const activeDriversCallBackFunc = (e_data) => {
    setActiveDrivers(e_data);
  };

  const fetchActiveDrivers = async (callback) => {
    const vt_data = await getAllDrivers();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.name,
      };
    });
    callback(data);
  };

  const activeVehicleCallBackFunc = (e_data) => {
    setActiveVehicles(e_data);
  };

  const fetchActiveVehicles = async (callback) => {
    const vt_data = await getAllActiveVehicles();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.vehiclename,
      };
    });
    callback(data);
  };

  useEffect(() => {
    showLoading(true);
    dispatch(getBooking(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (!loading && booking !== null) {
      booking.bookingInfo.map((val, ind) => {
        console.log("RUN")
        if (typeof val?.driver !== "undefined" || val?.driver != null) {
          const d_opt = activeDrivers.find(
            (opd) => opd.value === val?.driver?._id
          );
          var newArrayDriver = driverSelectedOption;
          newArrayDriver[ind] = d_opt;
          setDriverSelectedOption(newArrayDriver);
        } else {
          var newArrayDriver = driverSelectedOption;
          newArrayDriver[ind] = null;
          setDriverSelectedOption(newArrayDriver);
        }

        if (typeof val?.vehicle !== "undefined" || val?.vehicle != null) {
          const opt = activeVehicles.find(
            (op) => op.label === val?.vehicle.vehiclename
          );
          var newArray = selectedOption;
          newArray[ind] = opt;
          setSelectedOption(newArray);
        } else {
          var newArray = selectedOption;
          newArray[ind] = null;
          setSelectedOption(newArray);
        }
      })

      var bkStatus =
        loading || !booking?.bookingStatus ? "" : booking?.bookingStatus;
      const bk_opt = bookingStatusOptions.find((op) => op.label === bkStatus);
      setBookingStatus(bk_opt);
      switch (bkStatus) {
        case "Confirmed":
          setStyle("badge bg-success py-2");
          break;
        case "Payment Pending":
          setStyle("badge bg-primary py-2");
          break;
        case "Inquiry":
          setStyle("badge bg-warning py-2");
          break;
        case "Cancelled":
          setStyle("badge bg-danger py-2");
          break;
        default:
          setStyle("badge bg-dark py-2");
          break;
      }

      console.log(booking);
      setLabelData({
        _id: loading || !booking?._id ? "" : booking?._id,
        isAbandoned: loading || !booking?.isAbandoned ? "" : booking?.isAbandoned,
        cartID: loading || !booking?.cartID ? "" : booking?.cartID,
        stripeRef: loading || !booking?.stripeRef ? "" : booking?.stripeRef,
        byTheHourBook: loading || !booking?.byTheHourBook ? false : booking?.byTheHourBook,
        isAirportTransfer: loading || !booking?.isAirportTransfer ? false : booking?.isAirportTransfer,
        paymentStatus:
          loading || !booking?.paymentStatus ? "" : booking?.paymentStatus,
        name: loading || !booking?.customer.name ? "" : booking?.customer.name,
        email: loading || !booking?.customer.email ? "" : booking?.customer.email,
        phone: loading || !booking?.customer.phone ? "" : booking?.customer.phone,
        package: loading || !booking?.activePackage ? "" : booking?.activePackage,
        dropoffFlightNo: loading || !booking?.dropoffFlightNo ? "" : booking?.dropoffFlightNo,
        pickupFlightNo: loading || !booking?.pickupFlightNo ? "" : booking?.pickupFlightNo,
        isFromAirport:
          loading || !booking?.isFromAirport ? "" : booking?.isFromAirport,
        rate: loading || !booking?.rate ? "" : booking?.rate,
        pickuptime: loading || !booking?.pickuptime ? "" : booking?.pickuptime,
        dropofftime: loading || !booking?.dropofftime ? "" : booking?.dropofftime,
        travelDate:
          loading || !booking?.travelDate
            ? ""
            : moment(booking?.travelDate).format("YYYY/MM/DD hh:mm A"),
        cutomerDropOffTimeWithAdditinalTime:
          loading || !booking?.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime
            ? ""
            : moment(booking?.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD hh:mm A"),
        dropoffDatetime:
          loading || !booking?.dropoffDatetime
            ? ""
            : moment(booking?.dropoffDatetime).format("YYYY/MM/DD hh:mm A"),
        userAddedDropoffDatetime:
          loading || !booking?.userAddedDropoffDatetime
            ? ""
            : moment(booking?.userAddedDropoffDatetime).format(
              "YYYY/MM/DD hh: Amm"
            ),
        picuplocation:
          loading || !booking?.picuplocation ? "" : booking?.picuplocation.label,
        lat: loading || !booking?.picuplocation ? "" : booking?.picuplocation.lat,
        lon: loading || !booking?.picuplocation ? "" : booking?.picuplocation.lon,
        destinations:
          loading || !booking?.destinations ? null : booking?.destinations,
        // vehiclename: vn,
        // vehicletype: vt,
        // registration: reg,
        // drivername: dname,
        // driveremail: demail,
        // driverphone: dphone,
        estimate: loading || !booking?.estimate ? "" : booking?.estimate,
        totalDistance:
          loading || !booking?.totalDistance ? "" : booking?.totalDistance,
        bookingStatus:
          loading || !booking?.bookingStatus ? "" : booking?.bookingStatus,

        pax: loading || !booking?.pax ? "" : booking?.pax,
        message: loading || !booking?.message ? "" : booking?.message,
        luggage: loading || !booking?.luggage ? "" : booking?.luggage,
        bookingInfo: loading || !booking?.bookingInfo ? "" : booking?.bookingInfo,
        totalPrice: loading || !booking?.totalPrice ? "" : booking?.totalPrice,
        meetAssist: loading || !booking?.totalPrice ? [{
          meetAssistName: "none",
          meetAssistType: "arrival",
          meetAssistPrice: 0
        },
        {
          meetAssistName: "none",
          meetAssistType: "departure",
          meetAssistPrice: 0
        }] : booking?.meetAssist
      });

      const isVehicleControlerDisabled =
        booking?.bookingStatus === "Inquiry" ||
          booking?.bookingStatus === "Payment Pending" ||
          booking?.bookingStatus === "Cancelled"
          ? true
          : false;

      if (booking?.cartID !== null) dispatch(getCart(booking?.cartID));

      setIsDisabled(isVehicleControlerDisabled);
      closeLoading(true);
    }
  }, [booking]);

  const addNotes = () => {
    showLoading(true);
    if (note !== "") {
      const note_arr = [{ note: note, isAdmin: true }];

      dispatch(addNote(labelData._id, note_arr));
    }
    closeLoading(true);
    setNote("");
  };

  const changeBookingVehicle = (index = 0) => {
    showLoading(true);
    if (selectedOption[index] !== null) {
      const vehicleID = selectedOption[index].value;
      dispatch(changeVehicle(labelData._id, vehicleID, index));
    }
    closeLoading(true);
  };

  const updateBookingDriver = async (index = 0) => {
    showLoading(true);
    console.log(driverSelectedOption)
    if (driverSelectedOption[index] !== null)
      dispatch(updateDriver(labelData._id, driverSelectedOption[index].value, index));
    closeLoading(true);
  };

  const updateBooking = async () => {
    showLoading(true);
    // get available vehicles

    if (labelData.bookingStatus !== bookingStatus.label) {
      if (bookingStatus.label === "Confirmed") {
        const available_vehicles = await getAvailableVehicles(
          moment(labelData.tripStartTime),
          moment(labelData.garageArivalTimeWithAdditionalTime),
          labelData.vehicleTypeName
        );
        if (available_vehicles) {
          const available_vehicle_id = available_vehicles[0]._id;
          dispatch(
            updateBookingStatus(
              labelData._id,
              bookingStatus.label,
              available_vehicle_id
            )
          );
        } else {
          dispatch(
            setAlert("Vehicles are not available for the date.", "danger")
          );
        }
      } else if (
        bookingStatus.label === "Cancelled" ||
        bookingStatus.label === "Inquiry" ||
        bookingStatus.label === "Payment Pending"
      ) {
        dispatch(updateBookingStatus(labelData._id, bookingStatus.label, null));
      }
    }
    closeLoading(true);
  };

  const sendMailToClient = async () => {
    showLoading(true);

    const mailRes = await sendMail(
      labelData.email,
      "Your booking has been cancelled",
      getBookingCancelEmailTemplate(labelData)
    );

    if (mailRes.msg === "success") {
      dispatch(
        setAlert("Booking cancelation email sent to the customer.", "success")
      );
    } else {
      dispatch(setAlert("email is not sent", "error"));
    }
    closeLoading(true);
  };

  const sendCustomMailToClient = async () => {
    showLoading(true);
    const selectedTemplate = await getTemplate(ddltemplateOption.value);
    var htmltemp = selectedTemplate.htmlemail;
    if (htmltemp.includes("{{pickuplocation}}"))
      htmltemp = htmltemp.replace(
        "{{pickuplocation}}",
        booking.picuplocation.label
      );
    if (htmltemp.includes("{{dropofflocation}}"))
      htmltemp = htmltemp.replace(
        "{{dropofflocation}}",
        booking.destinations
          .map((d) => {
            return d.label;
          })
          .join(" / ")
      );
    if (htmltemp.includes("{{drivername}}"))
      htmltemp = htmltemp.replace(
        "{{drivername}}",
        booking.driver ? booking.driver?.name : "N/A"
      );
    if (htmltemp.includes("{{vehiclename}}"))
      htmltemp = htmltemp.replace(
        "{{vehiclename}}",
        booking.vehicle.vehiclename
      );
    if (htmltemp.includes("{{vehicletype}}"))
      htmltemp = htmltemp.replace(
        "{{vehicletype}}",
        booking.vehicle.vehicletype
      );
    if (htmltemp.includes("{{pickuptime}}"))
      htmltemp = htmltemp.replace(
        "{{pickuptime}}",
        moment(booking.travelDate).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{dropofftime}}"))
      htmltemp = htmltemp.replace(
        "{{dropofftime}}",
        moment(
          booking.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime
        ).format("YYYY/MM/DD hh:m Am")
      );
    if (htmltemp.includes("{{customername}}"))
      htmltemp = htmltemp.replace("{{customername}}", booking.customer.name);
    if (htmltemp.includes("{{cart}}"))
      htmltemp = htmltemp.replace("{{cart}}", getAdminCart());
    if (htmltemp.includes("{{packagename}}"))
      htmltemp = htmltemp.replace(
        "{{packagename}}",
        booking.activePackage ? booking.activePackage?.packagename : "N/A"
      );
    if (htmltemp.includes("{{totalcost}}"))
      htmltemp = htmltemp.replace(
        "{{totalcost}}",
        booking.bookingAmountInfo.total
      );

    if (htmltemp.includes("{{dropoffFlightno}}"))
      htmltemp = htmltemp.replace("{{dropoffFlightno}}", booking.dropoffFlightNo);
    if (htmltemp.includes("{{pickupFlightno}}"))
      htmltemp = htmltemp.replace("{{pickupFlightno}}", booking.pickupFlightNo);
    if (htmltemp.includes("{{vehicleregistration}}"))
      htmltemp = htmltemp.replace(
        "{{vehicleregistration}}",
        booking.vehicle?.registration
      );
    setUpdatedHtmlstate(htmltemp);
    closeLoading(true);
  };

  useEffect(() => {
    if (updatedHtmlstate) sendUpdatedEmailTemplate();
  }, [updatedHtmlstate]);

  const sendUpdatedEmailTemplate = async () => {
    await sendMail(labelData.email, subject, updatedHtmlstate);
    dispatch(setAlert("Email has been sent.", "success"));
  };

  const getAdminCart = () => {
    var crt = "";
    const htmlCart = adminCart.map((c) => {
      const pickup = c.picuplocation.label;
      const destinations = c.destinations
        .map((d) => {
          return d.label;
        })
        .join(" / ");
      const vehecle = c.vehicle?.vehiclename;
      const pax = c.pax
        .map((k) => {
          return k.paxtype + ": " + k.paxcount;
        })
        .join(" / ");
      const picktime = moment(c.travelDate).format("YYYY/MM/DD hh:mm A");
      const droptime = moment(
        c.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime
      ).format("YYYY/MM/DD hh:mm A");

      crt +=
        "<span>Pickup : " +
        pickup +
        "destinations : " +
        destinations +
        " vehecle : " +
        vehecle +
        " Pax : " +
        pax +
        " Picup time :" +
        picktime +
        " Drop off time : " +
        droptime +
        "</span><br/>";
    });
    return crt;
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar
          title={!labelData.isAbandoned ? `Manage Booking` : `Abandoned cart`}
        />
        {!loading && booking !== null && (
          <Fragment>
            <div className="container-fluid">
              <div className="row">
                <div className="row mb-3">
                  <div className="col">
                    <Link
                      className="btn btn-secondary"
                      to={
                        !labelData.isAbandoned
                          ? `/admin/bookinglist`
                          : `/admin/abandentcartlist`
                      }
                    >
                      <i
                        className="fa fa-arrow-left fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Back To List
                    </Link>
                  </div>
                </div>
                {/* <Alert /> */}
                <div className="col-md-6">
                  <div className="card text-dark mb-3">
                    <div className="card-header">Customer Information</div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col">
                          <h6 className="card-title">Name</h6>
                          <p className="card-text py-1">{labelData.name}</p>
                        </div>
                        <div className="col">
                          <h6 className="card-title">Phone</h6>
                          <p className="card-text py-1">{labelData.phone}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Email</h6>
                          <p className="card-text py-1">{labelData.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      Booking Information{" "}
                      <span
                        style={{
                          border:
                            "1px solid #" + labelData.cartID.substring(0, 6),
                          borderRadius: "5px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Cart ID : {labelData.cartID} | {labelData.paymentStatus}
                      </span>
                    </div>
                    <div className="card-body text-dark">
                      <div className="row">
                        <div className="col mb-2">
                          <h6 className="card-title">Booking Type</h6>
                          <p className={`card-text py-1 ${labelData.byTheHourBook ? 'badge bg-secondary py-2' : labelData.isAirportTransfer ? 'badge bg-light py-2 text-dark' : 'badge bg-dark py-2'}`}>
                            {labelData.byTheHourBook ? 'By The Hour' : labelData.isAirportTransfer ? 'Aiport Transfer' : 'Specified Destination'}
                          </p>
                          <h6 className="card-title">Current Status</h6>
                          <p className={`card-text py-1 ${style}`}>
                            {labelData.bookingStatus}
                          </p>
                          <h6 className="card-title">Total Distance</h6>
                          <p className="card-text py-1 text-start">
                            {labelData.totalDistance / 1000} KM
                          </p>
                          <h6 className="card-title">Pickup Time</h6>
                          <p className="card-text py-1 mb-2">
                            {labelData.travelDate}
                          </p>
                          <h6 className="card-title">Dropoff Time</h6>
                          <p className="card-text py-1 mb-2">
                            {moment(booking?.bookingInfo[0]?.cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD hh:mm A")}
                          </p>
                          <h6 className="card-title">
                            Total Hours Usage
                          </h6>
                          <p className="card-text py-1">
                            {labelData.bookingInfo[0].totalhoursUsage}
                          </p>
                        </div>
                        <div className="col">
                          <div className="row">
                            <h6 className="card-title">Update Status</h6>

                            <div className="d-flex justify-content-start">
                              <div className="col">
                                <Select
                                  classNamePrefix="select pick-up location"
                                  autoComplete="off"
                                  value={bookingStatus}
                                  onChange={(e) => {
                                    setBookingStatus(e);
                                  }}
                                  options={bookingStatusOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col ps-2 pt-3">
                              <button
                                // type="submit"
                                disabled={labelData.isAbandoned}
                                className="btn btn-secondary ms-1"
                                onClick={updateBooking}
                              >
                                <i
                                  className="fa fa-wrench fa-fw"
                                  aria-hidden="true"
                                ></i>{" "}
                                Update
                              </button>

                              {labelData.bookingStatus === "Cancelled" ? (
                                <button
                                  disabled={labelData.isAbandoned}
                                  onClick={sendMailToClient}
                                  className="btn btn-secondary ms-1"
                                >
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Send
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title mt-4">Calculated dispatch from Garage</h6>
                              <p className="card-text py-1">
                                {moment(labelData.bookingInfo[0].tripStartTime).format("YYYY/MM/DD hh:mm A")}
                              </p>
                              <h6 className="card-title">
                                Calculated arrival at Garage
                              </h6>
                              <p className="card-text py-1">
                                {moment(labelData.bookingInfo[0].garageArivalTimeWithAdditionalTime).format("YYYY/MM/DD hh:mm A")}
                              </p>
                            </div>
                          </div>


                        </div>
                      </div>
                      <h6 className="card-title">Pickup Location</h6>
                      <p className="card-text py-1">
                        {labelData.picuplocation}
                      </p>

                      <h6 className="card-title">Destinations</h6>
                      {labelData.destinations ? (
                        labelData.destinations.map((d) => (
                          <p key={d._id} className="card-text py-1">
                            {d.label}
                          </p>
                        ))
                      ) : (
                        <h4>No destinations found...</h4>
                      )}
                      <h6 className="card-title">Stripe Ref</h6>
                      <p>{labelData.stripeRef || "No Stripe Reference found"}</p>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      {" "}
                      <Alert />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-header">Other Information</div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-5">
                          <h6 className="card-title">Pax</h6>
                          <p className="card-text py-1">
                            {labelData.pax ? (
                              labelData.pax.map((d) => (
                                <span key={d._id} className="card-text py-1">
                                  {" "}
                                  {d.paxtype}:[{d.paxcount}] /
                                </span>
                              ))
                            ) : (
                              <h4>No Pax found...</h4>
                            )}
                          </p>
                        </div>
                        <div className="col-7">
                          <h6 className="card-title">Luggages</h6>
                          <p className="card-text py-1">
                            {labelData.luggage ? (
                              labelData.luggage.map((d) => (
                                <span key={d._id} className="card-text py-1">
                                  {" "}
                                  {d.luggagetype}:[{d.luggagecount}] /
                                </span>
                              ))
                            ) : (
                              <h4>No Luggage found...</h4>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Notes</h6>
                          <p className="card-text py-1">
                            {labelData.message ? (
                              labelData.message.map((d) => (
                                <p key={d._id} className="card-text py-1">
                                  {moment(d.date).format("YYYY/MM/DD hh:mm A")}
                                  {" (" + (d.isAdmin ? "admin" : "customer") + ")"}
                                  {" :  "}
                                  {d.note}
                                </p>
                              ))
                            ) : (
                              <h4>No Luggage found...</h4>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="label mb-2">Note :</label>
                            <div className="col">
                              <textarea
                                className={`form-control form-control-admin`}
                                rows="2"
                                placeholder="Text here..."
                                name="note"
                                value={note}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-secondary ms-1"
                            onClick={addNotes}
                          >
                            <i
                              className="fa fa-wrench fa-fw"
                              aria-hidden="true"
                            ></i>{" "}
                            Add Note
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">
                      Price and Route Information
                    </div>
                    <div className="card-body">
                      {labelData.bookingInfo.map((item, index) => {
                        return (
                          <div>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">Vehicle Type {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.vehicleTypeName}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Base Price {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.baseprice} JPY
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">30 Minutes Rate {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.hourlyprice} JPY
                                </p>
                              </div>
                            </div>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">Total Hours {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.totalhours}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Night Zone Hours {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.zoneHours}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Toll Fare {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.tollFare} JPY
                                </p>
                              </div>
                            </div>
                            <div className="row pb-2">
                              <div className="col">
                                <h6 className="card-title">User Added Estimate {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                <p className="card-text py-1">
                                  {item.estimate || "--"} JPY
                                </p>
                              </div>

                              {labelData.byTheHourBook && (
                                <>
                                  <div className="col">
                                    <h6 className="card-title">Selected Travel Area {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                    {item.travelArea == "withinCity" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        Within the city
                                      </p>
                                    )}
                                    {item.travelArea == "neighboringPerfecture" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        To the neighboring city
                                      </p>
                                    )}
                                    {item.travelArea == "undecided" && (
                                      <p className="card-text py-1 py-1 mb-1 text-success">
                                        Undecided
                                      </p>
                                    )}
                                  </div>

                                  <div className="col">
                                    <h6 className="card-title">Travel Area Price {(labelData.bookingInfo.length > 1 ? index + 1 : '')}</h6>
                                    <p className="card-text py-1">
                                      {item.travelAreaPrice || "--"} JPY
                                    </p>
                                  </div>
                                </>
                              )}

                            </div>
                          </div>
                        );
                      })}
                      <hr className="hr" />
                      <div className="row mt-4">

                        <div className="col">
                          <h6 className="card-title">Activated Package</h6>
                          {labelData.package !== null ? (
                            <div className="card-text py-1 py-1 mb-1">
                              <span>{labelData.package.packagename} </span>
                            </div>
                          ) : (
                            <div className="card-text py-1 p-1 mb-1">
                              No package available
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <h6 className="card-title">Dropoff Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {labelData.dropoffFlightNo}
                          </p>
                        </div>
                        <div className="col">
                          <h6 className="card-title">Pickup Flight No.</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark ">
                            {labelData.pickupFlightNo}
                          </p>
                        </div>
                        <div className="col">
                          <h6 className="card-title">Package Flat Fee</h6>
                          <p className="card-text py-1 py-1 mb-1 text-dark">
                            {labelData.package?.vehicles?.find((v) => v.vtype === labelData.vehicleTypeName)?.vehicleCost ?? "--"}
                            {" "}
                            JPY
                          </p>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col">
                          <h6 className="card-title">Meet Service</h6>
                          {labelData?.meetAssist && labelData.meetAssist[0].meetAssistName == "meetAssist" && (
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              Meet & Assist (Arrival)
                            </p>
                          )}
                          {labelData?.meetAssist && labelData.meetAssist[0].meetAssistName == "vipMeetAssist" && (
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              VIP Meet & Assist (Arrival)
                            </p>
                          )}
                          {labelData?.meetAssist && labelData.meetAssist[1].meetAssistName == "meetAssist" && (
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              Meet & Assist (Departure)
                            </p>
                          )}
                          {labelData?.meetAssist && labelData.meetAssist[1].meetAssistName == "vipMeetAssist" && (
                            <p className="card-text py-1 py-1 mb-1 text-success">
                              VIP Meet & Assist (Departure)
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <h6 className="card-title">Total booking cost</h6>
                          <p className="card-text py-1 py-1 mb-1 text-success">
                            {labelData.totalPrice} JPY
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    {/* <div className="card-header">Email Template</div> */}
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-4">
                          <h6 className="card-title">Template Name</h6>
                        </div>
                        <div className="col">
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="select pick-up location"
                            value={ddltemplateOption}
                            onChange={(e) => {
                              setSubject(e.subject);
                              setddlTemplateOption(e);
                            }}
                            options={ddltemplate}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <h6 className="card-title">Email Subject</h6>
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col ps-2">
                          <div className="mt-3 ps-0">
                            <button
                              disabled={labelData.isAbandoned}
                              onClick={sendCustomMailToClient}
                              className="btn btn-secondary ms-1"
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Send Email
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`card mb-3`}>
                    <div className="card-header">Assigned vehicle</div>
                    {labelData.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-4">
                              <h6 className="card-title">Vehicle Name {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {isDisabled ? (
                                  <span className="badge bg-info">
                                    Vehicle not assigned.
                                  </span>
                                ) : (
                                  item.vehicle.vehiclename
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">Vehicle Type {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {item.vehicle.vehicletype}
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">Registration {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {item.vehicle.registration}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">Change Vehicle {(index + 1)}</h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    isDisabled={isDisabled}
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={selectedOption[index]}
                                    onChange={(e) => {
                                      selectedOption[index] = e;
                                      // setSelectedOption(newArr);
                                    }}
                                    options={activeVehicles}
                                  />
                                </div>
                                <button
                                  disabled={isDisabled}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={(e) => {
                                    setSelectedOption(selectedOption => ({ ...selectedOption, [index]: e }));
                                  }}
                                >
                                  <i
                                    className="fa fa-wrench fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Driver Information</div>
                    {labelData.bookingInfo.map((item, index) => {
                      return (
                        <div className="card-body">
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">Driver Name {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {item?.driver?.name !== undefined ? (
                                  item?.driver?.name
                                ) : (
                                  <span className="badge bg-info">
                                    Driver not assigned.
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-8">
                              <h6 className="card-title">Driver Email {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {item?.driver?.email}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h6 className="card-title">Driver Phone {(index + 1)}</h6>
                              <p className="card-text py-1">
                                {item?.driver?.phone}
                              </p>
                            </div>
                            <div className="col-7">
                              <h6 className="card-title">Update Driver {(index + 1)}</h6>
                              <div className="d-flex justify-content-start">
                                <div className="col-sm-5">
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select pick-up location"
                                    value={driverSelectedOption[index]}
                                    onChange={(e) => {
                                      setDriverSelectedOption(driverSelectedOption => ({ ...driverSelectedOption, [index]: e }));
                                    }}
                                    options={activeDrivers}
                                  />
                                </div>
                                <button
                                  disabled={labelData.isAbandoned}
                                  type="submit"
                                  className="btn btn-secondary ms-1"
                                  onClick={() => updateBookingDriver(index)}
                                >
                                  <i
                                    className="fa fa-plus-circle fa-fw"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

      </main>
    </Layout>
  );
};

export default ManageBooking;
