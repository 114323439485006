import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import customization from "../../images/customization-icon.svg";
import productivity from "../../images/productivity-icon.svg";
import peace from "../../images/peace-of-mind-icon.svg";
import privacy from "../../images/privacy-icon.svg";
import communication from "../../images/communication-icon.svg";
import professionalism from "../../images/professionalism-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";

const ExecutiveTransfer = () => {
  const page_name = "cms-executive-transfer";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-executive-transfer d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Services")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Executive.Transfer")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("ExecutiveTransfer.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- service type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./airport-transfer"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content4
                      : cmspage?.page_content_jp.content4,
                }}
              ></Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./weddings-anniversaries"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content5
                      : cmspage?.page_content_jp.content5,
                }}
              >
                {/* {t("Weddings.Anniversaries")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./private-tour"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content6
                      : cmspage?.page_content_jp.content6,
                }}
              >
                {/* {t("Private.Tour")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                aria-current="page"
                to="./executive-transfer"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content7
                      : cmspage?.page_content_jp.content7,
                }}
              >
                {/* {t("Executive.Transfer")} */}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- service type nav --> */}
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-5 mt-lg-3 mb-0 mb-md-5 pt-md-5 pb-md-2">
        <div className="row mx-0">
          <div className="col col-lg-10 offset-lg-1">
            <p
              className="text-dark"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content8
                    : cmspage?.page_content_jp.content8,
              }}
            >
              {/* {t("ExecutiveTransfer.Intro.Additional")} */}
            </p>
            <h6
              className="text-dark intro py-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content9
                    : cmspage?.page_content_jp.content9,
              }}
            >
              {/* {t("ExecutiveTransfer.Intro.Golf")} */}
            </h6>
            <ul>
              <li
                className="text-dark my-1"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content10
                      : cmspage?.page_content_jp.content10,
                }}
              >
                {/* {t("ExecutiveTransfer.Intro.Additional.Point")} */}
              </li>
            </ul>
            <h6
              className="text-dark intro py-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content11
                    : cmspage?.page_content_jp.content11,
              }}
            >
              {/* {t("ExecutiveTransfer.Intro.Additional.Point.Description")} */}
            </h6>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col col-lg-10 offset-lg-1">
            <p
              className="text-dark"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content12
                    : cmspage?.page_content_jp.content12,
              }}
            >
              {/* {t("ExecutiveTransfer.Paragraph.One")} */}
            </p>
            <h6
              className="text-dark intro py-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content13
                    : cmspage?.page_content_jp.content13,
              }}
            >
              {/* {t("ExecutiveTransfer.Paragraph.Two")} */}
            </h6>
            <p
              className="text-dark"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content14
                    : cmspage?.page_content_jp.content14,
              }}
            >
              {/* {t("ExecutiveTransfer.Paragraph.Three")} */}
            </p>
            <h6
              className="text-dark intro py-3"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content15
                    : cmspage?.page_content_jp.content15,
              }}
            >
              {/* {t("ExecutiveTransfer.Paragraph.Four")} */}
            </h6>
            <p className="text-dark">
              {t("ExecutiveTransfer.Paragraph.Five")}&nbsp;
              <Link
                to="./contact"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content16
                      : cmspage?.page_content_jp.content16,
                }}
              >
                {/* {t("Urgent.Inquiries")} */}
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 4 why us --> */}
      <div className="row mx-0 mt-5 mb-md-3 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content17
                  : cmspage?.page_content_jp.content17,
            }}
          >
            {/* {t("ExecutiveTransfer.Heading.Two")} */}
          </h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 4 why us cards --> */}
      <div className="container px-md-3 mt-md-4 mb-md-5 py-5">
        <div className="row mx-0 gy-4 mb-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img
                        src={customization}
                        className="card-img"
                        alt="Customization"
                      />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content18
                              : cmspage?.page_content_jp.content18,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.One")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content19
                          : cmspage?.page_content_jp.content19,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.One.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img
                        src={productivity}
                        className="card-img"
                        alt="Productivity"
                      />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content20
                              : cmspage?.page_content_jp.content20,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.Two")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content21
                          : cmspage?.page_content_jp.content21,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.Two.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img
                        src={peace}
                        className="card-img"
                        alt="Peace of mind"
                      />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content22
                              : cmspage?.page_content_jp.content22,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.Three")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content23
                          : cmspage?.page_content_jp.content23,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.Three.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img src={privacy} className="card-img" alt="Privacy" />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content24
                              : cmspage?.page_content_jp.content24,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.Four")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content25
                          : cmspage?.page_content_jp.content25,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.Four.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img
                        src={communication}
                        className="card-img"
                        alt="Communication and manners"
                      />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content26
                              : cmspage?.page_content_jp.content26,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.Five")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content27
                          : cmspage?.page_content_jp.content27,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.Five.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img
                        src={professionalism}
                        className="card-img"
                        alt="Professionalism"
                      />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5
                        className="card-title fs-5 mb-0"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content28
                              : cmspage?.page_content_jp.content28,
                        }}
                      >
                        {/* {t("ExecutiveTransfer.Heading.Two.Card.Six")} */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p
                    className="card-text pt-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content29
                          : cmspage?.page_content_jp.content29,
                    }}
                  >
                    {/* {t("ExecutiveTransfer.Heading.Two.Card.Six.Description")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 4 why us end --> */}
      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-services"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto">
          <div className="row">
            <div className="col pb-4 pt-5 mb-4 mt-5 d-flex flex-column justify-content-center">
              <div className="card-title mt-5 mt-md-0">
                <h3
                  className="text-gold"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content30
                        : cmspage?.page_content_jp.content30,
                  }}
                >
                  {/* {t("CTA.Banner.Alt.Heading")} */}
                </h3>
              </div>

              <h6
                className="col-12 col-md-7 text-gold intro mb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content31
                      : cmspage?.page_content_jp.content31,
                }}
              >
                {/* {t("CTA.Banner.Alt.Intro")} */}
              </h6>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {/* {t("Book.Now")} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content32
                            : cmspage?.page_content_jp.content32,
                      }}
                    ></div>
                    <i className="ps-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 5L20 12L13 19M4 12H20H4Z"
                          stroke="#4A4A4A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default ExecutiveTransfer;
