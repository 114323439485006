import React, { useRef, useState, useEffect, Fragment } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import payment_method from "../../images/payment_method.svg";
import { postBusinessLicense, businessRegister } from "../../actions/auth";
import { withAlert, types } from "react-alert";
import businessData from "../../assets/json/businessData.json";
const CustomerRegisterPage = ({ alert }) => {
  const { t } = useTranslation();
  const [countriesData, setCountriesData] = useState([]);
  const [isRegister, setisRegister] = useState(false);
  const contentRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  useEffect(() => {
    const contentDiv = contentRef.current;

    function handleScroll() {
      // Check if the user has scrolled to the bottom
      if (contentDiv.scrollTop + contentDiv.clientHeight >= (contentDiv.scrollHeight - 10)) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }

    contentDiv.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      contentDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [profileData, setProfileData] = useState({
    //SECTION 1
    email: "",
    password: "",
    confirmPassword: "",
    //SECTION 2
    businessName: "",
    businessPhoneNumber: "",
    businessAddress: "",
    businessIndustry: "",
    businessType: "",
    businessRegistrationNumber: "",
    website: "",
    //SECTION 3
    contactName: "",
    contactPhoneNumber: "",
    contactEmail: "",
    // SECTION 4
    billingPostCode: "",
    billingCountry: "",
    billingName: "",
    billingPhoneNumber: "",
    billingAddress: "",
    //SECTION 5 (Payment Method)
    //SECTION 6 (Business License)
    //SECTION 7 (TNC)
  });
  const isValidEmail = (email) => {
    // Ekspresi reguler untuk memeriksa format alamat email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [selectedCategory, setSelectedCategory] = useState(
    profileData.businessIndustry
  );
  const [selectedType, setSelectedType] = useState(profileData.businessType);
  const [typeOptions, setTypeOptions] = useState([]);
  useEffect(() => {
    // Update type options when selectedCategory changes
    const category = businessData.find(
      (item) => item.category === selectedCategory
    );

    setTypeOptions(category ? category.types : []);
    // Reset selectedType when selectedCategory changes
    setSelectedType("");
  }, [selectedCategory]);

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [countryOrRegion, setCountryOrRegion] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [emailRecipients, setEmailRecipients] = useState([{ email: "" }]);
  const [recipientErrors, setRecipientErrors] = useState([]);


  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  const addRecipient = () => {
    const newErrors = [...recipientErrors];

    // Periksa apakah ada input kosong
    if (emailRecipients.some((recipient) => recipient.email === "")) {
      newErrors.push({
        index: emailRecipients.length,
        message: "Please enter a valid email",
      });
      setRecipientErrors(newErrors);
      return;
    }

    // Periksa apakah input memiliki format alamat email yang valid
    const invalidIndex = emailRecipients.findIndex(
      (recipient) => !isValidEmail(recipient.email)
    );
    if (invalidIndex !== -1) {
      newErrors.push({
        index: invalidIndex,
        message: "Please enter a valid email",
      });
      setRecipientErrors(newErrors);
      return;
    }

    setEmailRecipients([...emailRecipients, { email: "" }]);
    setRecipientErrors([]);
  };

  const handleRecipientChange = (index, value) => {
    const updatedRecipients = [...emailRecipients];
    updatedRecipients[index].email = value;
    setEmailRecipients(updatedRecipients);
  };

  const removeRecipient = (index) => {
    const updatedRecipients = [...emailRecipients];
    updatedRecipients.splice(index, 1);
    setEmailRecipients(updatedRecipients);
    // Hapus pesan kesalahan jika input dihapus
    setRecipientErrors(
      recipientErrors.filter((error) => error.index !== index)
    );
  };

  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [cardholderNameError, setCardholderNameError] = useState("");
  const [countryOrRegionError, setCountryOrRegionError] = useState("");

  const [businessLicenseFile, setBusinessLicenseFile] = useState(null);

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  // Function to handle the checkbox change
  const handleAgreeToTermsChange = (e) => {
    setAgreeToTerms(e.target.checked);
  };

  const [errorsProfileData, setErrorsProfileData] = useState({});
  const [countryCode1, setCountryCode1] = useState("JP");
  const [prefixPhoneNumber1, setPrefixPhoneNumber1] = useState("81");
  const [countryCode2, setCountryCode2] = useState("JP");
  const [prefixPhoneNumber2, setPrefixPhoneNumber2] = useState("81");
  const [countryCode3, setCountryCode3] = useState("JP");
  const [prefixPhoneNumber3, setPrefixPhoneNumber3] = useState("81");
  const [errorsEmailRecipients, setErrorsEmailRecipients] = useState({});

  const businessLicenseInput = useRef(null);
  const handleButtonChooseFile = (event) => {
    businessLicenseInput.current.click();
  };
  const handleChangeBusinessLicense = (event) => {
    const fileUploaded = event.target.files[0];
    setBusinessLicenseFile(fileUploaded);
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value;

    setCardNumber(value);
    if (value === "") {
      setCardNumberError("Card Number Required");
    } else {
      if (!checkCreditCardNumber(value)) {
        setCardNumberError("Card Number Format Invalid");
      } else {
        setCardNumberError("");
      }
    }
  };

  function checkCreditCardNumber(cardNumber) {
    return (
      visaRegex.test(cardNumber) ||
      mastercardRegex.test(cardNumber) ||
      jcbRegex.test(cardNumber) ||
      amexRegex.test(cardNumber)
    );
  }

  const handleCardholderNameChange = (e) => {
    const value = e.target.value;
    setCardholderName(value);

    if (value === "") {
      setCardholderNameError("Card Holder Name Required");
    } else {
      setCardholderNameError(""); // Clear the error if it's valid
    }
  };

  const handleCVVChange = (e) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setCVV(value);
      // if (value === "") {
      //   setCVVError("CVV Required");
      // } else {
      //   setCVVError("");
      // }
    } 
  };

  const handleCountryOrRegionChange = (e) => {
    const value = e.target.value;
    setCountryOrRegion(value);
    if (value === "") {
      setCountryOrRegionError("Country Region Required");
    } else {
      setCountryOrRegionError(""); // Clear the error if it's valid
    }
  };

  useEffect(() => {
    loadCountriesData();
  }, []);

  const loadCountriesData = () => {
    fetch("/json/countries.json") // Replace with the actual path to your JSON file
      .then((response) => response.json())
      .then((data) => setCountriesData(data))
      .catch((error) => console.error("Error loading JSON data:", error));
  };

  const handleLiClickCountryList1 = (dialCode, countryCode) => {
    setPrefixPhoneNumber1(dialCode);
    setCountryCode1(countryCode);
  };
  const handleLiClickCountryList2 = (dialCode, countryCode) => {
    setPrefixPhoneNumber2(dialCode);
    setCountryCode2(countryCode);
  };
  const handleLiClickCountryList3 = (dialCode, countryCode) => {
    setPrefixPhoneNumber3(dialCode);
    setCountryCode3(countryCode);
  };
  const handleExpiryDateChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.substring(0, 2) + "/" + inputValue.substring(2);
    }
    setExpiryDate(inputValue);
  };

  const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegex = /^5[1-5][0-9]{14}$/;
  const jcbRegex = /^(352[8-9]|35[3-8][0-9])[0-9]{12}$/;
  const amexRegex = /^3[47][0-9]{13}$/;

  const validateProfileForm = () => {
    const validationErrors = {};
    if (!profileData.email) {
      validationErrors.email = "Email is required";
    }
    if (!profileData.password) {
      validationErrors.password = "Password is required";
    } else if (profileData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }
    if (profileData.password !== profileData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }
    // Section 2
    if (!profileData.businessName) {
      validationErrors.businessName = "Business Name is required";
    }
    if (!profileData.businessPhoneNumber) {
      validationErrors.businessPhoneNumber =
        "Business Phone Number is required";
    }
    if (!profileData.businessAddress) {
      validationErrors.businessAddress = "Business Address is required";
    }
    if (!profileData.businessIndustry) {
      validationErrors.businessIndustry = "Business Industry is required";
    }
    if (!profileData.businessType) {
      validationErrors.businessType = "Business Type is required";
    }
    if (!profileData.businessRegistrationNumber) {
      validationErrors.businessRegistrationNumber =
        "Business Registration Number is required";
    }
    // Section 3
    if (!profileData.contactName) {
      validationErrors.contactName = "Contact Name is required";
    }
    if (!profileData.contactPhoneNumber) {
      validationErrors.contactPhoneNumber = "Contact Phone Number is required";
    }
    if (!profileData.contactEmail) {
      validationErrors.contactEmail = "Contact Email is required";
    } else {
      if (!validateEmail(profileData.contactEmail)) {
        validationErrors.contactEmail = "Email format invalid";
      }
    }
    // Section 4
    if (!profileData.billingPostCode) {
      validationErrors.billingPostCode = "Billing post code is required";
    }
    if (!profileData.billingCountry) {
      validationErrors.billingCountry = "Billing country is required";
    }
    if (!profileData.billingName) {
      validationErrors.billingName = "Billing name is required";
    }
    if (!profileData.billingPhoneNumber) {
      validationErrors.billingPhoneNumber = "Billing phone number is required";
    }
    if (!profileData.billingAddress) {
      validationErrors.billingAddress = "Billing address is required";
    }

    // Section 5 Payment Method

    if (!cardNumber) {
      setCardNumberError("Card Number is required");
      validationErrors.carNumber = "Card number is required";
    }

    if (!expiryDate) {
      setExpiryDateError("Expiry Date is required");
      validationErrors.expiryDate = "Billing address is required";
    }

    if (!cvv) {
      setCVVError("CVV is required");
      validationErrors.cvv = "CVV is required";
    }

    if (!cardholderName) {
      setCardholderNameError("Cardholder name is required");
      validationErrors.cardholderName = "Cardholder is required";
    }

    if (!countryOrRegion) {
      setCountryOrRegionError("Country is required");
      validationErrors.countryOrRegion = "Country is required";
    }

    // Section 6 (Busines License)
    if (!businessLicenseFile) {
      validationErrors.businessLicenseFile =
        "Business license file is required";
    }

    //Section 7 (TNC)
    if (agreeToTerms == false) {
      validationErrors.agreeToTerms = "Agree to term and condition is required";
    }

    setErrorsProfileData(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateProfileForm()) {
      const uploadBusinessLicenseFileResponse = await postBusinessLicense(
        businessLicenseFile
      );
      if (uploadBusinessLicenseFileResponse.status == 200) {
        // console.log(uploadBusinessLicenseFileResponse.data.fileName);
        console.log(emailRecipients);
        const businessRegisterResponse = await businessRegister({
          name: profileData.businessName,
          email: profileData.email,
          password: profileData.password,
          businessInformation: {
            businessName: profileData.businessName,
            businessPhoneNumber: `${profileData.billingPhoneNumber}`,
            businessAddress: profileData.businessAddress,
            businessType: profileData.businessType,
            businessIndustry: profileData.businessIndustry,
            businessRegistrationNumber: profileData.businessRegistrationNumber,
            website: profileData.website,
            prefix_number : prefixPhoneNumber1,
            country_code : countryCode1,
          },
          authorizedRepresentativeContact: {
            fullName: profileData.contactName,
            phoneNumber: `${profileData.contactPhoneNumber}`,
            prefix_number : prefixPhoneNumber2,
            country_code : countryCode2,
            emailAddress: profileData.contactEmail,
          },
          billingInformation: {
            contactName: profileData.billingName,
            phoneNumber: `${profileData.billingPhoneNumber}`,
            billingAddress: profileData.billingAddress,
            postCode: profileData.billingPostCode,
            country: profileData.billingCountry,
            prefix_number : prefixPhoneNumber3,
            country_code : countryCode3,
          },
          paymentMethod: [
            {
              method: "card",
              cardNumber: cardNumber,
              mmyy: expiryDate,
              cvv: cvv,
              name: cardholderName,
              country: countryOrRegion,
            },
          ],
          license: uploadBusinessLicenseFileResponse.data.fileName,
          emailRecipients: emailRecipients,
        });
        // console.log(businessRegisterResponse);
        if (businessRegisterResponse.status == 200) {
          setisRegister(true);
          window.scrollTo(0, 0);
        } else {
          alert.show(t("Register failed"), { type: types.ERROR });
        }
      } else {
        alert.show(t("Upload file failed"), { type: types.ERROR });
      }
    } else {
      alert.show(
        t("Please fill in all the required fields (highlighted in red)."),
        { type: types.ERROR }
      );
    }
  };

  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container mt-1 pt-1">
          <div className="custom-mx">
            <div className="container pb-5 custom-mt-9 col-lg-12">
              <div className="row align-items-center">
                {!isRegister ? (
                  <div className="col-12">
                    <h3>Busines Registration</h3>
                    <div className="d-block d-lg-none">
                      <hr />
                    </div>
                    <div className="bg-white card-shadow-page mt-3 rounded-3">
                      <div className="card-body-page">
                        <h5 className="my-3">
                          Account Information{" "}
                          <span className="text-danger">*</span>{" "}
                        </h5>
                        <div className="row g-3 mb-3">
                          <div className="col fs-8">
                            <div className="form-floating">
                              <input
                                type="text"
                                className={`form-control contact-form ${
                                  errorsProfileData.email && "invalid"
                                }`}
                                id="emailAddress"
                                placeholder="Email address"
                                name="email"
                                value={profileData.email}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="emailAddress">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="17"
                                    height="14"
                                    viewBox="0 0 17 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.3 0.5H1.7C0.765 0.5 0.00849999 1.23125 0.00849999 2.125L0 11.875C0 12.7688 0.765 13.5 1.7 13.5H15.3C16.235 13.5 17 12.7688 17 11.875V2.125C17 1.23125 16.235 0.5 15.3 0.5ZM15.3 11.875H1.7V3.75L8.5 7.8125L15.3 3.75V11.875ZM8.5 6.1875L1.7 2.125H15.3L8.5 6.1875Z"
                                      fill="#DBBE78"
                                    />
                                  </svg>
                                  &nbsp; {t("registration.email_address")}
                                </div>
                              </label>
                            </div>
                            {errorsProfileData.email && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.email}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col  fs-8">
                            <div className="form-floating">
                              <input
                                type="password"
                                className={`form-control contact-form ${
                                  errorsProfileData.password && "invalid"
                                }`}
                                id="password"
                                placeholder="Password"
                                name="password"
                                value={profileData.password}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="password">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="12"
                                    height="16"
                                    viewBox="0 0 12 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                                      fill="#DBBE78"
                                    />
                                  </svg>
                                  &nbsp; {t("registration.password")}
                                </div>
                              </label>
                            </div>
                            {errorsProfileData.password && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.password}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row g-3 mb-3">
                          <div className="col  fs-8">
                            <div className="form-floating">
                              <input
                                type="password"
                                className={`form-control contact-form ${
                                  errorsProfileData.confirmPassword && "invalid"
                                }`}
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={profileData.confirmPassword}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="password">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="12"
                                    height="16"
                                    viewBox="0 0 12 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125ZM10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.675 3.875C3.675 2.5925 4.7175 1.55 6 1.55C7.2825 1.55 8.325 2.5925 8.325 3.875V5.375H3.675V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375Z"
                                      fill="#DBBE78"
                                    />
                                  </svg>
                                  &nbsp; {t("registration.password_confirm")}
                                </div>
                              </label>
                            </div>
                            {errorsProfileData.confirmPassword && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.confirmPassword}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* SECTION 2 */}

                        <h5 className="my-3">
                          Business Information{" "}
                          <span className="text-danger">*</span>
                        </h5>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6">
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.businessName && "invalid"
                                  }`}
                                  id="businessName"
                                  placeholder="Confirm Password"
                                  name="businessName"
                                  value={profileData.businessName}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="password">
                                  <div className="d-flex align-items-center">
                                    Business Name
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.businessName && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.businessName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group ">
                              <button
                                className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={`https://flagsapi.com/${countryCode1}/flat/24.png`}
                                />{" "}
                                ({prefixPhoneNumber1})
                              </button>
                              <ul className="dropdown-menu">
                                <div className="px-1 align-items-center mb-3 dropdown-search">
                                  <input
                                    type="text"
                                    className={`form-control p-3 mb-2`}
                                    defaultValue={searchPhoneCode}
                                    onChange={(e) =>
                                      setSearchPhoneCode(e.target.value)
                                    }
                                    autoComplete={"new-password"}
                                  />
                                </div>
                                {countriesData
                                ?.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(
                                      searchPhoneCode !== null
                                        ? searchPhoneCode.toLowerCase()
                                        : null
                                    )
                                )
                                ?.map((country, index) => (
                                  <Fragment key={country.code}>
                                    <li
                                      className="dropdown-item m-0"
                                      onClick={() =>
                                        handleLiClickCountryList1(
                                          country.dial_code,
                                          country.code,
                                          country.name
                                        )
                                      }
                                    >
                                      <div className="row px-1 align-items-center">
                                        <div className="col-auto">
                                          <div className="bg-secondary bg-opacity-25 px-1">
                                            <img
                                              src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                              alt={country.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          ({country.dial_code}) {country.name}
                                        </div>
                                      </div>
                                    </li>
                                    <hr className="my-1" />
                                  </Fragment>
                                ))}
                              </ul>
                              <input
                                type="number"
                                className={`form-control contact-form p-3  ${
                                  errorsProfileData.businessPhoneNumber &&
                                  "invalid"
                                } `}
                                id="businessPhoneNumber"
                                placeholder={t("Business Phone Number")}
                                name="businessPhoneNumber"
                                value={profileData.businessPhoneNumber}
                                onChange={handleChangeProfile}
                              />
                            </div>

                            {errorsProfileData.businessPhoneNumber && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.businessPhoneNumber}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row g-3 mb-3">
                          <div className="col  fs-8">
                            <div className="form-floating">
                              <input
                                type="text"
                                className={`form-control contact-form ${
                                  errorsProfileData.businessAddress && "invalid"
                                }`}
                                id="businessAddress"
                                placeholder="Confirm Password"
                                name="businessAddress"
                                value={profileData.businessAddress}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="businessAddress">
                                <div className="d-flex align-items-center">
                                  &nbsp; {t("Business Address")}
                                </div>
                              </label>
                              {errorsProfileData.businessAddress && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.businessAddress}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col ">
                                <div className="form-floating">
                                  <select
                                    className={`form-select account-info-select  ${
                                      errorsProfileData.businessIndustry &&
                                      "invalid"
                                    }`}
                                    id="businessIndustry"
                                    placeholder="Confirm Password"
                                    name="businessIndustry"
                                    value={profileData.businessIndustry}
                                    onChange={(e) => {
                                      handleChangeProfile(e);
                                      setSelectedCategory(e.target.value);
                                    }}
                                  >
                                    <option selected>Choose</option>
                                    {businessData.map((item, index) => (
                                      <option key={index} value={item.category}>
                                        {item.category}
                                      </option>
                                    ))}
                                  </select>
                                  <label for="businessIndustry">
                                    {t("Business Industries")}
                                  </label>
                                </div>
                                {errorsProfileData.businessIndustry && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.businessIndustry}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col ">
                                <div className="form-floating">
                                  <select
                                    className={`form-select account-info-select  ${
                                      errorsProfileData.businessType &&
                                      "invalid"
                                    }`}
                                    id="businessType"
                                    placeholder="Confirm Password"
                                    name="businessType"
                                    value={profileData.businessType}
                                    onChange={handleChangeProfile}
                                  >
                                    {(
                                      businessData.find(
                                        (item) =>
                                          item.category === selectedCategory
                                      ) || {}
                                    ).types?.map((type, index) => (
                                      <option key={index} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                  <label for="businessType">
                                    {t("Business Type")}
                                  </label>
                                </div>
                                {errorsProfileData.businessType && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.businessType}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.businessRegistrationNumber &&
                                      "invalid"
                                    }`}
                                    id="businessRegistrationNumber"
                                    placeholder="Confirm Password"
                                    name="businessRegistrationNumber"
                                    value={
                                      profileData.businessRegistrationNumber
                                    }
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="businessRegistrationNumber">
                                    <div className="d-flex align-items-center">
                                      &nbsp; {t("Business Registration Number")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.businessRegistrationNumber && (
                                  <p className="m-0 text-danger">
                                    {
                                      errorsProfileData.businessRegistrationNumber
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.website && "invalid"
                                    }`}
                                    id="website"
                                    placeholder="Website"
                                    name="website"
                                    value={profileData.website}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="website">
                                    <div className="d-flex align-items-center">
                                      &nbsp; {t("Website (Optional)")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.website && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.website}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* SECTION 3 */}

                        <h5 className="my-3">
                          Authorized Representative Contacts
                          <span className="text-danger">*</span>
                        </h5>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6">
                            <div className="col  fs-8">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.contactName && "invalid"
                                  }`}
                                  id="contactName"
                                  placeholder="Name"
                                  name="contactName"
                                  value={profileData.contactName}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="contactName">
                                  <div className="d-flex align-items-center">
                                  {t("registration.full_name")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.contactName && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.contactName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group ">
                              <button
                                className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={`https://flagsapi.com/${countryCode2}/flat/24.png`}
                                />{" "}
                                ({prefixPhoneNumber2})
                              </button>
                              <ul className="dropdown-menu">
                                <div className="px-1 align-items-center mb-3 dropdown-search">
                                  <input
                                    type="text"
                                    className={`form-control p-3 mb-2`}
                                    defaultValue={searchPhoneCode}
                                    onChange={(e) =>
                                      setSearchPhoneCode(e.target.value)
                                    }
                                    autoComplete={"new-password"}
                                  />
                                </div>
                                {countriesData
                                ?.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(
                                      searchPhoneCode !== null
                                        ? searchPhoneCode.toLowerCase()
                                        : null
                                    )
                                )
                                ?.map((country, index) => (
                                  <Fragment key={country.code}>
                                    <li
                                      className="dropdown-item m-0"
                                      onClick={() =>
                                        handleLiClickCountryList2(
                                          country.dial_code,
                                          country.code
                                        )
                                      }
                                    >
                                      <div className="row px-1 align-items-center">
                                        <div className="col-auto">
                                          <div className="bg-secondary bg-opacity-25 px-1">
                                            <img
                                              src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                              alt={country.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          ({country.dial_code}) {country.name}
                                        </div>
                                      </div>
                                    </li>
                                    <hr className="my-1" />
                                  </Fragment>
                                ))}
                              </ul>
                              <input
                                type="number"
                                className={`form-control contact-form p-3  ${
                                  errorsProfileData.contactPhoneNumber &&
                                  "invalid"
                                } `}
                                id="contactPhoneNumber"
                                placeholder={t("Phone Number")}
                                name="contactPhoneNumber"
                                value={profileData.contactPhoneNumber}
                                onChange={handleChangeProfile}
                              />
                            </div>

                            {errorsProfileData.contactPhoneNumber && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.contactPhoneNumber}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row g-3 mb-3">
                          <div className="col  fs-8">
                            <div className="form-floating">
                              <input
                                type="email"
                                className={`form-control contact-form ${
                                  errorsProfileData.contactEmail && "invalid"
                                }`}
                                id="contanctEmailAddress"
                                placeholder="Email address"
                                name="contactEmail"
                                value={profileData.contactEmail}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="contanctEmailAddress">
                                <div className="d-flex align-items-center">
                                  {t("registration.email_address")}
                                </div>
                              </label>
                            </div>
                            {errorsProfileData.contactEmail && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.contactEmail}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="py-3" id="EmailRecipientsSection">
                          <h5 className="my-3">
                            Additional Email Recipients (optional)
                          </h5>
                          <p>
                            ※ For extra CC recipients of generated emails
                            besides the two registered emails, kindly add the
                            email address(es) below:
                          </p>
                          <div className="row">
                            {emailRecipients.map((recipient, index) => (
                              <div className="col-12 input-box" key={index}>
                                <div className="form-floating mt-3">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsProfileData.email && "invalid"
                                    }`}
                                    id={`emailAddress-${index}`}
                                    placeholder="Email address"
                                    value={recipient.email}
                                    onChange={(e) =>
                                      handleRecipientChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {emailRecipients.length !== 1 && (
                                    <i
                                      className="fa fa-close icon"
                                      onClick={() => removeRecipient(index)}
                                    ></i>
                                  )}
                                  <label htmlFor={`emailAddress-${index}`}>
                                    <div className="d-flex align-items-center">
                                      <svg
                                        width="17"
                                        height="14"
                                        viewBox="0 0 17 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3 0.5H1.7C0.765 0.5 0.00849999 1.23125 0.00849999 2.125L0 11.875C0 12.7688 0.765 13.5 1.7 13.5H15.3C16.235 13.5 17 12.7688 17 11.875V2.125C17 1.23125 16.235 0.5 15.3 0.5ZM15.3 11.875H1.7V3.75L8.5 7.8125L15.3 3.75V11.875ZM8.5 6.1875L1.7 2.125H15.3L8.5 6.1875Z"
                                          fill="#DBBE78"
                                        />
                                      </svg>
                                      &nbsp; 
                                  {t("registration.email_address")}
                                    </div>
                                  </label>
                                </div>
                                {recipientErrors.some(
                                  (error) => error.index === index
                                ) && (
                                  <p className="m-0 text-danger">
                                    {
                                      recipientErrors.find(
                                        (error) => error.index === index
                                      ).message
                                    }
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>

                        <button
                          type="button"
                          class="btn bg-gold-light-100"
                          onClick={addRecipient}
                        >
                          <span className="mx-5"> Add Email Address</span>
                        </button>

                        {/* SECTION 4 */}
                        <h5 className="my-3">
                         {t("registration.billing_information")}
                          <span className="text-danger">*</span>
                        </h5>
                        <div className="row g-3 mb-3">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.billingPostCode &&
                                      "invalid"
                                    }`}
                                    id="billingPostCode"
                                    placeholder="Post Codea"
                                    name="billingPostCode"
                                    value={profileData.billingPostCode}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="billingPostCode">
                                    <div className="d-flex align-items-center">
                                      {t("Post Code")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.billingPostCode && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.billingPostCode}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col  fs-8">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      errorsProfileData.billingCountry &&
                                      "invalid"
                                    }`}
                                    id="contanctEmailAddress"
                                    placeholder="Country"
                                    name="billingCountry"
                                    value={profileData.billingCountry}
                                    onChange={handleChangeProfile}
                                  />
                                  <label htmlFor="contanctEmailAddress">
                                    <div className="d-flex align-items-center">
                                      {t("Country")}
                                    </div>
                                  </label>
                                </div>
                                {errorsProfileData.billingCountry && (
                                  <p className="m-0 text-danger">
                                    {errorsProfileData.billingCountry}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3 g-3">
                          <div className="col-md-6">
                            <div className="col fs-8">
                              <div className="form-floating ">
                                <input
                                  type="text"
                                  className={`form-control contact-form ${
                                    errorsProfileData.billingName && "invalid"
                                  }`}
                                  id="billingName"
                                  placeholder="Name"
                                  name="billingName"
                                  value={profileData.billingName}
                                  onChange={handleChangeProfile}
                                />
                                <label htmlFor="billingName">
                                  <div className="d-flex align-items-center">
                                    {t("Contact Name")}
                                  </div>
                                </label>
                              </div>
                              {errorsProfileData.billingName && (
                                <p className="m-0 text-danger">
                                  {errorsProfileData.billingName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group ">
                              <button
                                className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={`https://flagsapi.com/${countryCode3}/flat/24.png`}
                                />{" "}
                                ({prefixPhoneNumber3})
                              </button>
                              <ul className="dropdown-menu">
                                <div className="px-1 align-items-center mb-3 dropdown-search">
                                  <input
                                    type="text"
                                    className={`form-control p-3 mb-2`}
                                    defaultValue={searchPhoneCode}
                                    onChange={(e) =>
                                      setSearchPhoneCode(e.target.value)
                                    }
                                    autoComplete={"new-password"}
                                  />
                                </div>
                                {countriesData
                                ?.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(
                                      searchPhoneCode !== null
                                        ? searchPhoneCode.toLowerCase()
                                        : null
                                    )
                                )
                                ?.map((country, index) => (
                                  <Fragment key={country.code}>
                                    <li
                                      className="dropdown-item m-0"
                                      onClick={() =>
                                        handleLiClickCountryList3(
                                          country.dial_code,
                                          country.code
                                        )
                                      }
                                    >
                                      <div className="row px-1 align-items-center">
                                        <div className="col-auto">
                                          <div className="bg-secondary bg-opacity-25 px-1">
                                            <img
                                              src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                              alt={country.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          ({country.dial_code}) {country.name}
                                        </div>
                                      </div>
                                    </li>
                                    <hr className="my-1" />
                                  </Fragment>
                                ))}
                              </ul>
                              <input
                                type="number"
                                className={`form-control contact-form p-3  ${
                                  errorsProfileData.billingPhoneNumber &&
                                  "invalid"
                                } `}
                                id="billingPhoneNumber"
                                placeholder={t("Phone Number")}
                                name="billingPhoneNumber"
                                value={profileData.billingPhoneNumber}
                                onChange={handleChangeProfile}
                              />
                            </div>

                            {errorsProfileData.billingPhoneNumber && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.billingPhoneNumber}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row mb-3 g-3">
                          <div className="col  fs-8">
                            <div className="form-floating">
                              <input
                                type="text"
                                className={`form-control contact-form ${
                                  errorsProfileData.billingAddress && "invalid"
                                }`}
                                id="billingAddress"
                                placeholder="billingAddress"
                                name="billingAddress"
                                value={profileData.billingAddress}
                                onChange={handleChangeProfile}
                              />
                              <label htmlFor="billingAddress">
                                <div className="d-flex align-items-center">
                                  
                                {t("registration.billing_address")}
                                </div>
                              </label>
                            </div>
                            {errorsProfileData.billingAddress && (
                              <p className="m-0 text-danger">
                                {errorsProfileData.billingAddress}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* SECTION 5 */}

                        <h5 className="my-3">
                         {t("registration.payment_method")} <span className="text-danger">*</span>
                        </h5>

                        <div className="px-2">
                          <div className="row justify-content-between align-items-center bg-light border-bottom-gold rounded">
                            <div className="col  py-3 px-3 ">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="paymentMethod"
                                  value="card"
                                  id="credit-card-flywire"
                                  checked="true"
                                  //   onChange={handlePaymentMethodChange}
                                />
                                <label
                                  className="form-check-label noto-sans"
                                  htmlFor="credit-card-flywire"
                                >
                                  Credit Card <small>(via Flywire)</small>
                                </label>
                              </div>
                            </div>
                            <div className="col-auto">
                              <img
                                src={payment_method}
                                className=""
                                alt="Clock icon"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="card border-gold mt-2 mb-5">
                          <div className="card-body">
                            <h5 className="my-3">{t("registration.card_information")}</h5>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      cardNumberError && "invalid"
                                    }`}
                                    id="cardNumber"
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                  />
                                  <label htmlFor="formId1">
                                    {t("registration.card_number")}
                                  </label>
                                  {cardNumberError && (
                                    <p className="text-danger">
                                      {cardNumberError}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      expiryDateError && "invalid"
                                    }`}
                                    id="expiryDate"
                                    value={expiryDate}
                                    onChange={handleExpiryDateChange}
                                    maxLength="5"
                                  />
                                  <label htmlFor="formId1">{t("registration.mm_yy")}</label>
                                  {expiryDateError && (
                                    <p className="text-danger">
                                      {expiryDateError}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="form-floating mb-3">
                                  <input
                                    type="number"
                                    className={`form-control contact-form ${
                                      cvvError && "invalid"
                                    }`}
                                    id="cvv"
                                    value={cvv}
                                    onChange={handleCVVChange}
                                  />
                                  <label htmlFor="formId1">{t("registration.cvv")}</label>

                                  {errorsProfileData.cvvError && (
                                    <p className="text-danger">
                                      {errorsProfileData.cvvError}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      cardholderNameError ? "invalid" : ""
                                    }`}
                                    id="cardholderName"
                                    value={cardholderName}
                                    onChange={handleCardholderNameChange}
                                  />
                                  <label htmlFor="formId1">
                                  
                                  {t("registration.card_holder")}
                                  </label>

                                  {cardholderNameError && (
                                    <p className="text-danger">
                                      {cardholderNameError}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    className={`form-control contact-form ${
                                      countryOrRegionError ? "invalid" : ""
                                    }`}
                                    id="countryOrRegion"
                                    value={countryOrRegion}
                                    onChange={handleCountryOrRegionChange}
                                  />
                                  <label htmlFor="formId1">
                                  {t("registration.country")}
                                  </label>
                                  {countryOrRegionError && (
                                    <p className="text-danger">
                                      {countryOrRegionError}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* SECTION 6 */}
                        <h5 className="my-3">
                          Business License{" "}
                          <span className="text-danger">*</span>
                        </h5>
                        <div className="container border-dashsed-gold bg-grey-light mb-3">
                          <div className="my-5">
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="m-0">
                                {" "}
                                {businessLicenseFile != undefined
                                  ? businessLicenseFile.name
                                  : "Upload File in PDF or JPEG format"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              {errorsProfileData.businessLicenseFile && (
                                <p className="text-danger">
                                  {errorsProfileData.businessLicenseFile}
                                </p>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                type="button"
                                className="btn btn-outline-primary px-5 my-3 rounded"
                                onClick={handleButtonChooseFile}
                              >
                                Choose File
                              </button>
                              <input
                                type="file"
                                name=""
                                ref={businessLicenseInput}
                                id=""
                                className="d-none"
                                onChange={handleChangeBusinessLicense}
                                accept="image/jpeg,.pdf"
                              />
                            </div>
                          </div>
                        </div>

                        {/* SECTION 7 */}
                        <h5 className="">
                          {t("Terms & Conditions")}{" "}
                          <span className="text-danger">*</span>
                        </h5>
                        <div className="bg-gold-light-50 p-4 mt-3">
                            <div
                              className=" overflow-auto custom-scrollbar"
                              ref={contentRef}
                              style={{ height: "381px" }}
                              dangerouslySetInnerHTML={{
                                __html: `${t('termConditionContent')}`
                              }}
                            >

                            </div>
                          </div>
                        <div className="form-group mt-3">
                          <div className="row row-cols-auto">
                            <div className="col">
                              <p className="noto">
                                <input
                                  type="checkbox"
                                  id="tnc"
                                  checked={agreeToTerms}
                                  onChange={handleAgreeToTermsChange}
                                  disabled={isScrolledToBottom ? false : true}
                                />{" "}
                                &nbsp;{" "}
                                <label htmlFor="tnc">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `${t("term.condition")}`,
                                    }}
                                  ></span>
                                </label>
                                {errorsProfileData.agreeToTerms && (
                                  <p className="mt-1 text-danger">
                                    {errorsProfileData.agreeToTerms}
                                  </p>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-grid gap-2 col-12 col-md-3 mx-auto mt-5">
                      <button
                        type="button"
                        className="btn btn-primary rounded"
                        onClick={handleSubmit}
                      >
                        Register
                        <i className="ps-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 5L20 12L13 19M4 12H20H4Z"
                              stroke="#4A4A4A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="container pb-5 custom-mt-9 col-lg-12">
                    <h2>Thank you for signing up. </h2>
                    <p className="mt-3">
                      {" "}
                      Kindly confirm your account by clicking the link in the
                      mail we sent to your registered email address : 
                      <a href="">
                        <b> &nbsp; {profileData.email}</b>
                      </a>
                    </p>
                    <div className="row mt-5 align-items-center">
                      <div className="col-auto">
                        <p className="m-0">
                          We will conduct a thorough verification of your
                          business based on the details you've registered after
                          you have confirmed your email. ※Please note that this
                          business verification procedure may take up to{" "}
                          <b className="text-danger fs-14">7 days</b>. As soon as the
                          process is completed, we will promptly notify you via
                          email.
                        </p>
                      </div>

                      <div className="col-auto mt-3">
                        <a
                          name=""
                          id=""
                          className="btn btn-secondary px-5  =rounded"
                          href="#"
                          role="button"
                        >
                          {t("Resend Email")}
                        </a>
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-12 col-md-4">
                        <div className="d-grid">
                          <a
                            name=""
                            id=""
                            className="btn btn-primary rounded"
                            href="/"
                            role="button"
                          >
                            {t("Back to Home")}{" "}
                            <i className="ps-2">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 5L20 12L13 19M4 12H20H4Z"
                                  stroke="#4A4A4A"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default withAlert()(CustomerRegisterPage);
