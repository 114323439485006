import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const UserItem = ({
  user: { _id, name, email, date, signUpWithGoogle, verifiedStatus, role },
  count,
  currentUser,postApproveUser,
}) => {
  const handleApprove = () => {
    postApproveUser(_id); // Call postApproveUser with _id parameter
  };
  return (
    <tr>
      <td>{count + 1}</td>
      <td>
        {email === currentUser.email ? (
          <span>
            {name}{" "}
            <span className="badge" style={{ backgroundColor: "#d3ac50" }}>
              {" [Current User]"}
            </span>
          </span>
        ) : (
          <span>{name}</span>
        )}
      </td>
      <td>{email}</td>
      <td>{signUpWithGoogle ? "Google" : "Email"}</td>
      <td>{verifiedStatus ? "Verified" : "Not Verified"}</td>
      <td>
        <Moment format="YYYY/MM/DD">{date}</Moment>
      </td>
      <td>
        <div className="row">
        {!verifiedStatus && role == "business" && (
          <button name="" id="" class="col-auto btn btn-primary me-2" href="#" role="button" onClick={handleApprove}>
            Approve
          </button>
        )}
        {!verifiedStatus && role == "business" && (
          <button name="" id="" class="col-auto btn btn-secondary " href="#" role="button">
            Detail
          </button>
        )}
        </div>
      </td>
    </tr>
  );
};

export default UserItem;
