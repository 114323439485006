import customAxios from "../../utils/customAxios";
import { getSettingForMail } from "../admin/settings";
import setting_file from "../../utils/settings.json";
import { setAlert } from "../alert";
import { GET_TEMPLATES, GET_TEMPLATE, TEMPLATE_ERROR } from "../types";

export const deleteTemplate = (id) => async (dispatch) => {
  try {
    const res = await customAxios.delete(
      `/api/admin/sendmail/delete-template/${id}`
    );
    if (res) dispatch(setAlert("Template deleted", "success"));
    // dispatch({
    //   type: GET_TEMPLATES,
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// get all active vehicles
export const getTemplate = async (id) => {
  try {
    const res = await customAxios.get(`/api/admin/sendmail/get-template/${id}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const getTemplates = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/sendmail/template");
    dispatch({
      type: GET_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEMPLATE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const saveEmailTemplate =
  (_id, htmlemail, design, templatename, iscustomeremail, user, subject) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await customAxios.post(
        "/api/admin/sendmail/save-template",
        {
          _id,
          htmlemail,
          design,
          templatename,
          iscustomeremail,
          user,
          subject,
        },
        config
      );
      // console.log(res.data);
      dispatch(setAlert("Template added", "success"));
    } catch (err) {
      const errors = err.response.data.errors;
      return errors;
    }
  };

// get address by serach word
export const sendMail = async (tomail, subject, bodyHtml, bookings = null) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await customAxios.post(
      "/api/admin/sendmail",
      {
        tomail,
        subject,
        bodyHtml,
        bookings: bookings,
      },
      config
    );
    return res.data;
  } catch (err) {
    return [err];
  }
};
