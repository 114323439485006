import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { getBookingsFilter } from "../../../actions/admin/booking";
import BookingItem from "./BookingItem";
import { Fragment } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import {
  bookingStatusOptions,
  bookingTypeOptions,
} from "../../../utils/dataLists";
import { getAllDrivers } from "../../../actions/admin/driver";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { getVehicleTypes } from "../../../actions/admin/vehicletype";

const BookingList = () => {
  /**
   * * Set the initial state varible to display bookings within a one month range
   */
  const [dateRange, setdateRange] = useState({
    startDate:
      localStorage.getItem("startDate") !== null
        ? moment(localStorage.getItem("startDate"))
        : moment(),
    endDate:
      localStorage.getItem("endDate") !== null
        ? moment(localStorage.getItem("endDate"))
        : moment().add(30, "days"),
  });

  const booking = useSelector((state) => state.booking);
  const { loading, bookings } = booking;
  const vehicleType = useSelector((state) => state.vehicletype);
  const { vehicletypes } = vehicleType;
  const [bookingStatus, setBookingStatus] = useState(null);
  const [bookingTypeStatus, setBookingTypeStatus] = useState(null);
  const [driverSelectedOption, setDriverSelectedOption] = useState(null);
  const [vehicleTypeOption, setVehicleTypeOption] = useState(null);
  const [vehicleSelectedOption, setVehicleSelectedOption] = useState(null);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [activeVehicles, setActiveVehicles] = useState([]);
  const dispatch = useDispatch();

  const activeVehicleTypes = vehicletypes.map((d) => ({
    value: d._id,
    label: d.typename,
  }));

  /**
   * * Sets all the form values to their initial values
   */
  useEffect(() => {
    fetchActiveDrivers(activeDriversCallBackFunc);
    fetchActiveVehicles(activeVehicleCallBackFunc);
    dispatch(getVehicleTypes());
  }, []);

  const activeVehicleCallBackFunc = (e_data) => {
    setActiveVehicles(e_data);
  };

  const activeDriversCallBackFunc = (e_data) => {
    setActiveDrivers(e_data);
  };

  const fetchActiveVehicles = async (callback) => {
    const vt_data = await getAllActiveVehicles();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.vehiclename,
      };
    });
    callback(data);
  };

  /**
   * * Get all available drivers
   */
  const fetchActiveDrivers = async (callback) => {
    const vt_data = await getAllDrivers();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.name,
      };
    });
    callback(data);
  };

  /**
   * * Set the selected date range in state and cache variables.
   */
  const handleCallback = (start, end, label) => {
    setdateRange({ startDate: start, endDate: end });
    localStorage.setItem("startDate", start);
    localStorage.setItem("endDate", end);
  };

  const searchClick = (e) => {};

  /**
   * * The filter will be changed / updated when any state variable is changed
   */
  useEffect(() => {
    addFilters();
  }, [
    bookingStatus,
    dateRange,
    driverSelectedOption,
    vehicleTypeOption,
    vehicleSelectedOption,
    bookingTypeStatus,
    vehicletypes,
  ]);

  /**
   * * Prepare the filter options based on the selected values or default options and 
   * then dispatche an action (getBookingsFilter) with the updated filter settings to retrieve the filtered bookings.
   */
  const addFilters = () => {
    const bs =
      bookingStatus === null || bookingStatus?.length === 0
        ? bookingStatusOptions
        : bookingStatus;

    console.log(driverSelectedOption);
    const dso =
      driverSelectedOption === null || driverSelectedOption?.length === 0
        ? activeDrivers
        : driverSelectedOption;
    const isAllDrivers =
      driverSelectedOption === null || driverSelectedOption?.length === 0
        ? true
        : false;

    const vto =
      vehicleTypeOption === null || vehicleTypeOption?.length === 0
        ? activeVehicleTypes
        : vehicleTypeOption;
    console.log(vehicleTypeOption);

    const vso =
      vehicleSelectedOption === null || vehicleSelectedOption?.length === 0
        ? activeVehicles
        : vehicleSelectedOption;
    console.log(vehicleSelectedOption);

    const isAllVehicles =
      vehicleSelectedOption === null || vehicleSelectedOption?.length === 0
        ? true
        : false;

    const btoption =
      bookingTypeStatus === null || bookingTypeStatus?.length === 0
        ? bookingTypeOptions
        : bookingTypeStatus;
    // return;
    dispatch(
      getBookingsFilter(
        "search",
        dateRange.startDate,
        dateRange.endDate,
        false,
        bs,
        dso,
        vto,
        vso,
        btoption,
        isAllDrivers,
        isAllVehicles
      )
    );
  };

  return (
    <Layout>
      <main
        className="col-md-9 ms-sm-auto col-lg-10 px-md-4"
        style={{ backgroundColor: "#fbfbfb" }}
      >
        {/* <TitleBar title="Booking List" /> */}

        <div className="row py-4">
          <hr />
          <div className="col-md-3">
            <h1 className="h5">Booking List</h1>
          </div>
        </div>
        <div>
          <h5 className="mb-3">Filter options</h5>
        </div>
        <div>
          <div className="row">
            <div className="col-sm">
              <span>Booking Status</span>

              <Select
                isMulti
                className=""
                classNamePrefix="filter-select"
                value={bookingStatus}
                onChange={(e) => {
                  setBookingStatus(e);
                }}
                options={bookingStatusOptions}
                isSearchable={false}
              />
            </div>
            <div className="col-sm">
              <span>Driver</span>

              <Select
                className=""
                classNamePrefix="filter-select"
                value={driverSelectedOption}
                onChange={(e) => {
                  setDriverSelectedOption(e);
                }}
                isMulti
                options={activeDrivers}
                isSearchable={false}
              />

              {/* <input
                type="text"
                className=""
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              /> */}
            </div>
            <div className="col-sm">
              <span>Pickup Date [From -To]</span>
              <DateRangePicker
                onCallback={handleCallback}
                initialSettings={dateRange}
                onApply={(e) => {
                  searchClick(e);
                }}
              >
                <input type="text" className="filter-date" />
              </DateRangePicker>
              {/* <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={(e) => searchClick(e)}
              >
                Search
              </button> */}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-sm">
              <span>Vehicle Type</span>

              <Select
                isMulti
                className=""
                classNamePrefix="filter-select"
                value={vehicleTypeOption}
                onChange={(e) => {
                  setVehicleTypeOption(e);
                }}
                options={activeVehicleTypes}
                isSearchable={false}
              />
            </div>
            <div className="col-sm">
              <span>Vehicle</span>

              <Select
                isMulti
                className=" "
                classNamePrefix="filter-select"
                value={vehicleSelectedOption}
                onChange={(e) => {
                  setVehicleSelectedOption(e);
                }}
                options={activeVehicles}
                isSearchable={false}
              />
            </div>
            <div className="col-sm">
              <span>Booking Type</span>

              <Select
                isMulti
                className=" "
                classNamePrefix="filter-select"
                value={bookingTypeStatus}
                onChange={(e) => {
                  setBookingTypeStatus(e);
                }}
                options={bookingTypeOptions}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <table className="table-sort table table-sm table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">CartID</th>
                    <th scope="col">Vehicle Type</th>
                    <th scope="col">Booking Status</th>
                    <th scope="col">Booking Type</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Pickup</th>
                    <th scope="col">Dropoff</th>
                    <th scope="col">Pickup Location</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {bookings.map((booking, i = 0) => (
                    <BookingItem
                      key={booking._id}
                      bookings={booking}
                      count={i++}
                    />
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default BookingList;
